var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-video-slider slide" }, [
    _c("div", { staticClass: "image-relative" }, [
      _vm.isHostedVideo
        ? _c("iframe", {
            ref: "iframe",
            staticClass: "background-image",
            attrs: { src: _vm.assetUrl },
          })
        : _c(
            "video",
            {
              ref: "video",
              staticClass: "background-image video-background",
              attrs: { controls: "" },
            },
            [_c("source", { attrs: { type: "video/mp4", src: _vm.assetUrl } })]
          ),
      _c("img", {
        attrs: {
          src: _vm.resourceUrl("images/placeholder-for-full-size-image.png"),
          alt: "Placeholder",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }