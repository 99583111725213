<template>
  <component :is="componentVariant" :data="data" :hash="hash" :order-no="orderNo" />
</template>

<script>
import SectionContactV1 from "../../sections/contact/SectionContactV1";
import SectionContactV2 from "../../sections/contact/SectionContactV2";
export default {
  name: "SectionContact",
  props: {
    variant: {
      required: true,
      type: String,
      default: '',
    },
    data: {
      required: true,
      type: Object | Array,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  data() {
    return {
      componentVariant: null
    }
  },
  created() {
    this.handleVariant(this.variant);
  },
  watch: {
    variant: function (newVariant, oldVariant) {
      this.handleVariant(newVariant);
    }
  },
  methods: {
    handleVariant(variant) {
      switch (variant) {
        case 'v2':
          this.componentVariant = SectionContactV2;
          break;
        default:
          this.componentVariant = SectionContactV1;
          break;
      }
    }
  }
}
</script>

<style lang="scss">
  .section-contact {

  }
</style>