<template>
  <div class="section section-contact" :class="{ [`section${orderNo}`]: true }">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <OrganismContactForm />
    <div
      v-if="
        data.socialTitle ||
        data.instagramUrl ||
        data.linkedinUrl ||
        data.facebookUrl ||
        data.youtubeUrl ||
        data.title ||
        data.subtitle
      "
      class="title-contacts"
    >
      <div class="wrapper-title-contacts">
        <div v-if="data.title || data.subtitle">
          <AtomTitle :title="data.title" :subtitle="data.subtitle" />
        </div>
        <div
          v-if="
            data.socialTitle ||
            data.instagramUrl ||
            data.linkedinUrl ||
            data.facebookUrl ||
            data.youtubeUrl
          "
        >
          <div class="social">
            <div
              v-if="data.socialTitle"
              class="title"
              v-html="data.socialTitle"
            ></div>
            <a
              v-if="data.instagramUrl"
              :href="data.instagramUrl"
              target="_blank"
            >
              <AtomIcon icon="instagram" :size="40" color="#FFF" />
            </a>
            <a v-if="data.linkedinUrl" :href="data.linkedinUrl" target="_blank">
              <AtomIcon icon="linkedin-squared" :size="40" color="#FFF" />
            </a>
            <a v-if="data.facebookUrl" :href="data.facebookUrl" target="_blank">
              <AtomIcon icon="facebook-squared" :size="40" color="#FFF" />
            </a>
            <a v-if="data.youtubeUrl" :href="data.youtubeUrl" target="_blank">
              <AtomIcon icon="youtube-play" :size="40" color="#FFF" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <OrganismTeam :team-data="data.contacts" />
    <div class="sticky-contact" v-if="data.stickyText">
      <div class="image-element">
        <img
          :src="resourceUrl(randomContact.image)"
          :alt="randomContact.name"
        />
      </div>
      <div class="contact-info" v-if="data.stickyText">
        <h5>{{ randomContact.name }}</h5>
        <p>{{ data.stickyText }}</p>
      </div>
      <div class="button-anchor" v-if="data.stickyButtonText">
        <AtomButton
          :label="data.stickyButtonText"
          :on-click="goToNextSection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrganismTeam from "../../../components/organisms/portfolio/OrganismTeam";
import OrganismContactForm from "../../../components/organisms/project/OrganismContactForm";
import AtomTitle from "@/components/atoms/common/AtomTitle.vue";
import AtomIcon from "@/components/atoms/common/AtomIcon.vue";
import AtomButton from "@/components/atoms/common/AtomButton.vue";
import { smoothScrollToTargetId } from "@/helpers/util";
export default {
  name: "SectionContactV1",
  components: {
    AtomButton,
    AtomIcon,
    AtomTitle,
    OrganismContactForm,
    OrganismTeam,
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  computed: {
    randomContact() {
      return (
        this.data?.stickyContacts[
          Math.floor(Math.random() * this.data?.stickyContacts?.length)
        ] || []
      );
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
    goToNextSection() {
      smoothScrollToTargetId(`#${this.hash}`);
    },
  },
};
</script>

<style lang="scss">
.section-contact {
  position: relative;
  .navigation-target {
    position: absolute;
    top: -3.563rem;
    left: 0;
  }
  .title-contacts {
    padding: 2.5rem 0.625rem 0;
    background: $black;
    @media only screen and (max-width: 767px) {
      padding: 32px 16px 20px;
    }
    .wrapper-title-contacts {
      display: flex;
      justify-content: space-between;
      max-width: 80.334rem;
      padding: 0 1rem;
      margin: 0 auto;
      @media only screen and (max-width: 767px) {
        max-width: 100%;
        padding: 0;
        display: block;
      }
    }
    .atom-title {
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
      h2,
      h3 {
        color: $white !important;
      }
    }
    .social {
      text-align: right;
      @media only screen and (max-width: 767px) {
        text-align: left;
      }
      .title {
        margin-bottom: 0.5rem;
        @include font-main(2rem, $white, $regular, 2.375rem);
        @media only screen and (max-width: 767px) {
          margin-bottom: 5px;
          @include font-main(22px, $white, $regular, 26px);
        }
        span {
          @include font-main(2rem, $white, $bold, 2.375rem);
          @media only screen and (max-width: 767px) {
            @include font-main(22px, $white, $bold, 26px);
          }
        }
      }
      a {
        display: inline-block;
        margin-right: 1.5rem;
        @media only screen and (max-width: 767px) {
          margin-right: 12px;
        }
        &:last-child {
          margin-right: 0;
        }
        .icon {
          font-size: 2.5rem !important;
          @media only screen and (max-width: 767px) {
            font-size: 20px !important;
          }
          &:before {
            margin: 0;
          }
        }
      }
    }
  }
  .organism-team {
    padding: 2.5rem 1.25rem 10rem;
    margin: 0 -0.625rem;
    background: $black;
    @media only screen and (max-width: 767px) {
      padding: 30px 16px 100px;
      margin: 0;
    }
    .parent-molecule-team {
      max-width: 80.334rem;
      padding: 0 1rem;
      margin: 0 auto;
      @media only screen and (max-width: 767px) {
        max-width: 100%;
        padding: 0;
      }
    }
    .molecule-team {
      margin: 0 -0.313rem;
      @media only screen and (max-width: 767px) {
        margin: 0;
      }
      .atom-padding {
        margin-bottom: 0.625rem;
        padding: 0 0.313rem;
        @media only screen and (max-width: 767px) {
          padding: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
  .sticky-contact {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 1.25rem;
    bottom: 1.25rem;
    background: $white;
    box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.05);
    border-radius: 0.25rem;
    padding: 1rem;
    z-index: 999;
    @media only screen and (max-width: 767px) {
      right: 16px;
      bottom: 16px;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      padding: 10px;
      max-width: calc(100% - 32px);
    }
    .image-element {
      flex: 0 0 5rem;
      max-width: 5rem;
      margin-right: 1rem;
      @media only screen and (max-width: 767px) {
        flex: 0 0 60px;
        max-width: 60px;
        margin-right: 10px;
      }
      img {
        max-width: 5rem;
        object-fit: cover;
        border-radius: 0.5rem;
        display: block;
        @media only screen and (max-width: 767px) {
          max-width: 60px;
          border-radius: 8px;
        }
      }
    }
    .contact-info {
      h5 {
        margin-bottom: 0.5rem;
        @include font-main(1rem, $black, $medium, 1.188rem);
        @media only screen and (max-width: 767px) {
          margin-bottom: 8px;
          @include font-main(16px, $black, $medium, 19px);
        }
      }
      p {
        @include font-main(0.75rem, $black, $regular, 1.125rem);
        @media only screen and (max-width: 767px) {
          @include font-main(12px, $black, $regular, 14px);
        }
      }
    }
    .button-anchor {
      margin-left: 1rem;
      @media only screen and (max-width: 767px) {
        margin-left: 10px;
      }
      .button-atom {
        @media only screen and (max-width: 767px) {
          padding: 7px 20px 5px;
        }
        span {
          font-size: 0.875rem;
          @media only screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
</style>
