<template>
  <div :class="{section: true, 'section-statements': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <OrganismStatementsSlider :slider-statements-data="data.statements" />
  </div>
</template>

<script>
import OrganismStatementsSlider from "../../components/organisms/common/OrganismStatementsSlider";

export default {
  name: "SectionStatements",
  components: {OrganismStatementsSlider},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  }
}
</script>

<style lang="scss">
  .section-statements {
    padding: 4.375rem 0;
    border-bottom: 1px solid #ECF5F8;
    @media only screen and (max-width: 767px) {
      padding: 40px 0;
    }
  }
</style>