<template>
  <div class="select-atom-wrapper">
    <label :for="`${id}`">{{ label }}</label>
    <div class="select-inline-block">
      <select :id="`${id}`" ref="selectTarget" class="select2select" v-model="selected">
        <option v-for="(option, index) in options" v-bind:value="option.value" v-bind:disabled="option.disabled" :key="index">
          {{ option.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import select2 from 'select2';

export default {
  name: "AtomSelect",
  props: {
    id: {
      required: false,
      type: String,
      default: "",
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    options: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
    selectProps: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    }
  },
  computed: {
    selected:  {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  },
  mounted() {
    if (this.options.length) {
      this.$refs.selectTarget.value = this.options[0].value;
    }
    $(this.$refs.selectTarget).select2({
      search: true,
      ...this.$props.selectProps
    });
    $(this.$refs.selectTarget).on('change.select2', this.handleChange);
  },
  beforeUpdate() {
    if (!$(this.$refs.selectTarget).data('select2')) {
      $(this.$refs.selectTarget).select2('destroy');
    }
    $(this.$refs.selectTarget).off('change.select2');
  },
  updated() {
    $(this.$refs.selectTarget).select2({
      search: true,
      ...this.$props.selectProps
    } );
    $(this.$refs.selectTarget).on('change.select2', this.handleChange);
    if (!this.selected && this.options.length) {
      this.selected = this.options[0].value;
      $(this.$refs.selectTarget).val(this.selected).trigger('change');
    }
  },
  beforeDestroy() {
    if (!$(this.$refs.selectTarget).data('select2')) {
      $(this.$refs.selectTarget).select2('destroy');
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e.target.value);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~select2/dist/css/select2.min.css";
  .rowPadding11px {
    padding: 0 11px;
  }

</style>
