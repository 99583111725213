<template>
  <div class="molecule-contact-form">
    <form ref="contactForm" @submit="submitForm" action="javascript:void(0);">
      <div class="row">
        <div class="col col-xs-12 col-6">
          <div class="row">
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="companyNumber"
                @input="companyNumber = $event"
                placeholder="Company Registration Number"
              />
            </div>
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="firstName"
                @input="firstName = $event"
                placeholder="First Name*"
                :error="errors.firstName"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="companyName"
                @input="companyName = $event"
                placeholder="Company Name*"
                :error="errors.companyName"
              />
            </div>
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="lastName"
                @input="lastName = $event"
                placeholder="Last Name*"
                :error="errors.lastName"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="companyAddress"
                @input="companyAddress = $event"
                placeholder="Company Address"
              />
            </div>
          </div>
        </div>
        <div class="col col-xs-12 col-6">
          <div class="row">
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="phone"
                @input="phone = $event"
                placeholder="Phone Number*"
                :error="errors.phone"
              />
            </div>
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="email"
                @input="email = $event"
                placeholder="Email*"
                :error="errors.email"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <AtomInput
                :type="inputTypes.TEXTAREA"
                :value="message"
                @input="message = $event"
                placeholder="Message"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row bottom-form align-items-center">
        <div class="col col-xs-12 col-6">
          <AtomCheck
            id="checkbox-contact-footer"
            ref="check"
            class="row-checkbox"
            :label="termsAndConditionsText"
            :error="errors.agree"
          />
        </div>
        <div class="col col-xs-12 col-6">
          <div class="button-contact-form text-right">
            <AtomButton label="Send" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AtomInput, { EnumInputTypes } from "../../atoms/common/AtomInput";
import AtomButton from "../../atoms/common/AtomButton";
import AtomCheck from "../../atoms/common/AtomCheck";
import config from "../../../config";

export default {
  name: "MoleculeContactForm",
  components: {
    AtomCheck,
    AtomButton,
    AtomInput,
  },
  data() {
    return {
      errors: {},
      companyNumber: "",
      companyName: "",
      firstName: "",
      lastName: "",
      companyAddress: "",
      phone: "",
      email: "",
      message: "",
      inputTypes: EnumInputTypes,
    };
  },
  computed: {
    client() {
      return config.CLIENT;
    },
    termsAndConditionsText() {
      return (
        this.$store.state?.base?.meta?.general?.termsAndConditionsText ||
        "I agree to the Terms & Conditions and Privacy Policy*"
      );
    },
    activeProject() {
      return this.$store.state.project?.activeProject || null;
    },
    projects() {
      return this.$store.state.project?.projects || [];
    },
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      const projects = this.projects.filter(
        (p) => p.city_page_slug === this.activeProject
      );
      const { currentRoute } = this.$router;
      const { params = {} } = currentRoute;
      let projectId = -1;
      if (
        typeof params["projectSlug"] !== "undefined" &&
        params["projectSlug"] === this.activeProject
      ) {
        projectId = projects?.[0]?.id || -1;
      } else if (
        typeof params["projectId"] !== "undefined" &&
        params["projectId"] === this.activeProject
      ) {
        projectId =
          this.projects.find((p) => p.slug === this.activeProject)?.id || -1;
      }
      if (this.validateInputs() !== null || projectId === -1) return;

      const payload = {
        company_number: this.companyNumber === "" ? null : this.companyNumber,
        company_name: this.companyName,
        company_address:
          this.companyAddress === "" ? null : this.companyAddress,
        contact_firstname: this.firstName,
        contact_lastname: this.lastName,
        contact_phone_number: this.phone,
        contact_email: this.email,
        notes: this.message,
        project_id: projectId,
        status: 0,
      };

      if (window && "dataLayer" in window) {
        window.dataLayer.push({
          event: "contact_request_submit",
          category: "contact_request",
          label: "contact_request",
        });
      }

      if (window && "analyticsLayer" in window) {
        window.analyticsLayer.send({
          event: "contact_request_submit",
          payload: payload,
        });
      }

      this.$request
        .POST(this.$urls.landlord.contact(), payload)
        .then((res) => {
          if (res.error) {
            alert(res.error.message);
            return;
          }
          this.$refs.contactForm.reset();
          if (this.client === "one" && config.RUNTIME_ENV === "production") {
            if ("gtag_report_conversion" in window) {
              window.gtag_report_conversion();
            }
            if ("lintrk" in window) {
              window.lintrk("track", { conversion_id: 4403868 });
            }
          }
          this.$parent.$refs?.successMessage?.toggleShow();
          this.$refs.check.checked = false;
        })
        .catch((e) => {
          alert(e.message);
        });
    },
    validateInputs() {
      this.errors = {};
      if (this.companyName === "") {
        this.errors.companyName = "Company name is required.";
      }
      if (this.firstName === "") {
        this.errors.firstName = "First Name is required.";
      }
      if (this.lastName === "") {
        this.errors.lastName = "Last Name is required.";
      }
      if (this.phone === "" || !this.phone.match(/^[+]?\d{10,13}$/)) {
        this.errors.phone = "Phone is required.";
      }
      if (!this.validEmail(this.email)) {
        this.errors.email = "Email is not valid.";
      }
      if (!this.$refs.check.checked) {
        this.errors.agree = "Please agree to the Terms and Conditions first.";
      }
      if (Object.keys(this.errors).length === 0) {
        return null;
      }
      return this.errors;
    },

    validEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
};
</script>

<style lang="scss">
.molecule-contact-form {
  margin-top: 1.875rem;
  @media only screen and (max-width: 767px) {
    margin-top: 30px;
  }
  .error-message {
    color: red;
    padding: 0.625rem 0 0.938rem;
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: 400;
    width: 100%;
  }
  .row {
    margin: 0 -0.75rem;
    @media only screen and (max-width: 767px) {
      margin: 0 -10px;
    }
    .col {
      padding: 0 0.75rem;
      @media only screen and (max-width: 767px) {
        padding: 0 10px;
      }
    }
  }
  .bottom-form {
    margin-top: 1.25rem;
    @media only screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }
  .input-atom-wrapper {
    margin-bottom: 0.938rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 10px;
    }
    input,
    textarea {
      margin-bottom: 0.313rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
  }
  .button-atom {
    width: 7.5rem;
    max-width: 100%;
    @media only screen and (max-width: 767px) {
      width: auto;
    }
  }
}
</style>
