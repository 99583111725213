<template>
  <div id="content-slide-portfolio-custom-dots" class="owl-dots molecule-content-thumb-slider-brochure">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculeContentThumbSliderBrochure"
}
</script>

<style lang="scss">
  .molecule-content-thumb-slider-brochure {
    position: absolute;
    left: 0;
    bottom: 2.5rem;
    width: 48rem;
    margin: 0;
    padding: 0 4.25rem;
    height: 0.25rem;
    z-index: 15;
    pointer-events: initial !important;
    @media only screen and (max-width: 767px) {
      bottom: 12%;
      left: 0;
      width: 100%;
      height: 4px;
    }
  }
</style>