var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-background-image": true,
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _c(
        "div",
        {
          staticClass: "row background-image",
          style:
            "background-image: url(" + _vm.resourceUrl(_vm.data.image) + ")",
        },
        [
          _c("div", { staticClass: "col col-12" }, [
            _c(
              "div",
              { staticClass: "content-header" },
              [
                _c("div", { staticClass: "gradient-background" }),
                _c("AtomTitle", {
                  attrs: { title: _vm.data.title, subtitle: _vm.data.subtitle },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }