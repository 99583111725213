<template>
  <div v-if="video" v-lazyload="{handle: handleLazyLoad, url: resourceUrlVideo}" :class="{'atom-project-video-background': true, 'active': isActive}">
    <video muted="" autoplay="" playsinline="" loop="" preload="metadata" style="object-fit: cover !important;">
      <source :src="`${resourceUrlVideo}`" type="video/mp4">
    </video>
  </div>
  <div v-else v-lazyload="{handle: handleLazyLoad, url: resourceUrl}" :class="{'atom-project-slider-background': true, 'active': isActive}" :style="{'background-image': `url(${resourceUrl})`}"></div>
</template>

<script>
export default {
  name: "AtomProjectSliderBackground",
  props: {
    image: {
      required: true,
      type: String,
      default: ''
    },
    video: {
      required: true,
      type: String,
      default: ''
    },
    activeBackground: {
      required: true,
      type: String,
      default: "item-0",
    },
    backgroundId: {
      required: true,
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isInView: false,
    }
  },
  mounted() {
    this.isInView = true;
  },
  computed: {
    isActive() {
      return this.$props.activeBackground === this.backgroundId;
    },
    resourceUrl() {
      if (!this.isInView) return '';
      return `${this.$store.getters.cdnBase}/${this.$props.image}`;
    },
    resourceUrlVideo() {
      if (!this.isInView) return '';
      return `${this.$store.getters.cdnBase}/${this.$props.video}`;
    }
  },
  methods: {
    handleLazyLoad() {
      this.isInView = true;
    }
  }
}
</script>

<style lang="scss" scoped>
  .atom-project-slider-background {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 0.5s ease-in-out 0s;
    &.active {
      opacity: 1;
    }
  }
  .atom-project-video-background {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: all 0.5s ease-in-out 0s;
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    &.active {
      opacity: 1;
    }
  }
</style>