<template>
  <div class="organism-location" :class="{ 'no-sidebar': places.length <= 0 }">
    <AtomImageTitle v-if="places.length > 0" :image="locationData.image">
      <AtomTitle
        color="#FFFFFF"
        color2="#fff"
        :title="locationData.title"
        :subtitle="locationData.address"
      />
    </AtomImageTitle>
    <div v-if="places.length > 0" class="button-toggle" @click="toggleSidebar">
      <span
        >{{ showSidebar ? "Hide" : "Show" }} points of interest
        <AtomIcon
          color="#423687"
          :size="18"
          :icon="showSidebar ? 'minus' : 'plus'"
      /></span>
    </div>
    <MoleculeGoogleMap
      v-if="activeProject"
      ref="map"
      :map-config="locationData.map"
      :places="places"
      :active-center-marker-id="activeProject"
    />
    <div
      v-if="places.length > 0"
      :class="{ sidebar: true, active: showSidebar }"
    >
      <div class="sidebar-locations">
        <MoleculeSidebarRoutes
          :places="places"
          :default-mode="locationData.defaultMode"
        />
      </div>
      <div class="search-sidebar" @click="focusSearch">
        <AtomTitle color="#272626" color2="#272626" :title="`Distance from:`" />
        <div class="result-text" v-if="hasSearchResult">
          {{ searchResult.addressText }}
        </div>
        <div class="result-text" v-if="hasSearchResult">
          <AtomIcon icon="driving" /> {{ searchResult.text }}
        </div>
        <div class="result-text" v-if="hasSearchError">
          {{ searchResult.text }}
        </div>
        <AtomTitle
          color="#272626"
          color2="#272626"
          :subtitle="`Please follow this format: ${locationData.address}`"
        />
        <AtomInput
          ref="searchInput"
          placeholder="Insert address here"
          class="search-input"
          @input="queriedAddress = $event"
          :value="queriedAddress"
        />
        <AtomButton
          label="check"
          :disabled="searchDisabled"
          :on-click="handleSearch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MoleculeGoogleMap from "../../molecules/common/MoleculeGoogleMap";
import AtomImageTitle from "../../atoms/common/AtomImageTitle";
import AtomTitle from "../../atoms/common/AtomTitle";
import AtomIcon from "../../atoms/common/AtomIcon";
import MoleculeSidebarRoutes from "../../molecules/project/MoleculeSidebarRoutes";
import AtomInput from "../../atoms/common/AtomInput";
import AtomButton from "../../atoms/common/AtomButton";

const isMobile = true;

export default {
  name: "OrganismLocation",
  components: {
    AtomButton,
    AtomInput,
    MoleculeSidebarRoutes,
    AtomIcon,
    AtomTitle,
    AtomImageTitle,
    MoleculeGoogleMap,
  },
  props: {
    locationData: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showSidebar: !isMobile,
      queriedAddress: "",
      searchResult: {
        success: true,
        text: "",
        addressText: "",
        directionsResponse: {},
        queryResults: null,
      },
    };
  },
  computed: {
    searchDisabled() {
      return !this.queriedAddress;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    hasSearchResult() {
      return this.searchResult.text !== "" && this.searchResult.queryResults;
    },
    hasSearchError() {
      return this.searchResult.text !== "" && !this.searchResult.success;
    },
    places() {
      return this.locationData?.places || [];
    },
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    showDirectionsForMarkerId(id) {
      this.$refs.map.triggerMarkerById(id);
    },
    handleSearch() {
      const result = this.$refs.map.distanceFrom(this.queriedAddress);
      this.searchResult = result;
      this.$refs.searchInput.resetInput();
    },
    focusSearch() {
      this.$refs.searchInput.focus();
    },
  },
};
</script>

<style lang="scss">
.organism-location {
  position: relative;
  padding-right: 34.188rem;
  height: 53.125rem;
  @media only screen and (max-width: 767px) {
    padding-right: 0;
    height: auto;
  }
  &.no-sidebar {
    padding-right: 0;
  }
  .molecule-google-map {
    position: relative;
    @media only screen and (max-width: 767px) {
      height: 640px;
    }
  }
  .button-toggle {
    display: none;
    @media only screen and (max-width: 767px) {
      display: block;
      span {
        display: block;
        position: relative;
        padding: 13px 40px 13px 15px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #272626;
        .icon {
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .hide-sidebar-location {
        display: none;
      }
      &.active {
        .show-sidebar-location {
          display: none;
        }
        .hide-sidebar-location {
          display: block;
        }
      }
    }
  }
  .atom-image-title {
    position: absolute;
    right: 0;
    top: 0;
    width: 34.188rem;
    @media only screen and (max-width: 767px) {
      position: relative;
      width: 100%;
    }
    .background-gradient {
      padding: 7.938rem 4.375rem 1.608rem;
      @media only screen and (max-width: 767px) {
        padding: 75px 20px 30px;
      }
      .atom-title {
        h3 {
          margin-top: 0.5rem;
          @media only screen and (max-width: 767px) {
            margin-top: 8px;
            text-transform: none;
          }
        }
      }
    }
  }
  .sidebar {
    position: absolute;
    top: 12.5rem;
    right: 0;
    width: 34.188rem;
    height: 76.5%;
    transition: all 0.5s ease-in-out 0s;
    background: $white;
    @media only screen and (max-width: 767px) {
      top: 198px;
      right: initial;
      left: -110%;
      width: 100%;
      height: 655px;
      overflow: hidden;
      &.active {
        left: 0;
      }
    }
    .sidebar-locations {
      height: 22.6rem;
      //height: 40.938rem;
      overflow: hidden;
      overflow-y: auto;
      @media only screen and (max-width: 767px) {
        height: 426px;
        //height: 655px;
      }
    }
    .search-sidebar {
      background: rgba(228, 228, 228, 0.2);
      padding: 2.25rem 1.25rem;
      min-height: 18rem;
      @media only screen and (max-width: 767px) {
        background: #e4e4e4;
        min-height: 174px;
        padding: 20px;
      }
      .atom-title {
        h2 {
          font-weight: $medium;
          font-size: 1rem;
          line-height: 1.188rem;
          text-transform: initial;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
        h3 {
          font-weight: $regular;
          font-size: 0.75rem;
          line-height: 0.875rem;
          margin-top: 0.5rem;
          @media only screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 14px;
            margin-top: 8px;
          }
        }
      }

      .search-input {
        margin: 1.75rem 0;
        input {
          background: transparent;
          padding: 0;
          font-size: 16px;
          &::placeholder {
            color: $blackOpacity02;
          }
        }
      }

      .result-text {
        font-size: 14px;
      }

      .button-atom {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
}
</style>
