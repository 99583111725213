<template>
  <div :class="{'organism-image-slider': true, 'no-videos': !(hasImages && hasVideos)}">
    <MoleculeImageSlider ref="slider" :slider-data="sliderData" :type-of="typeOf" @initialized="handleCarouselInitialized"/>

    <div class="bottom-thumb">
      <div class="row row-brochure align-items-center">
        <div class="col col-xs-12 col-6">
          <div class="title-brochure">
            <h4>Gallery</h4>
            <div class="button-change" v-if="sliderData.brochureData">
              <a :href="`${resourceUrl(sliderData.brochureData)}`" class="icon-element" target="_blank">
                <AtomIcon icon="file-arrow-down" :size="20" color="#fff"/>
                <span class="title">Download Brochure</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col col-xs-12 col-6 text-right">
          <div class="change-carousel" v-if="hasImages && hasVideos">
            <div class="button-change photoButton active" @click="handlePhotoClick" data-type="image">
              <div class="icon-element">
                <AtomIcon icon="picture" :size="20" color="#fff"/>
                <span class="title">Photo</span>
              </div>
            </div>
            <div class="button-change videoButton" @click="handleVideoClick" data-type="video">
              <div class="icon-element">
                <AtomIcon icon="video" :size="20" color="#fff"/>
                <span class="title">Video</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MoleculeImageThumbSlider ref="thumbSlider" v-if="carouselInitialized" :thumbnails="thumbData" @thumb-change="handleThumbChange" :typeOf="typeOf" />

    </div>

  </div>
</template>

<script>
import MoleculeImageSlider from "../../molecules/common/MoleculeImageSlider";
import MoleculeImageThumbSlider from "../../molecules/common/MoleculeImageThumbSlider";
import AtomIcon from "../../atoms/common/AtomIcon";

export default {
  name: "OrganismImageSlider",
  components: {AtomIcon, MoleculeImageThumbSlider, MoleculeImageSlider},
  props: {
    sliderData: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      typeOf: 'image',
      carouselInitialized: false,
    }
  },
  computed: {
    thumbData() {
      return this.typeOf === 'image' ? this.sliderData.images : this.sliderData.videos;
    },
    brochureData() {
      return this.sliderData.brochure;
    },
    hasImages() {
      return this.sliderData.images ? this.sliderData.images.length > 0 : false;
    },
    hasVideos() {
      return this.sliderData.videos ? this.sliderData.videos.length > 0 : false;
    }
  },
  beforeUpdate() {
    if (this.typeOf !== 'image' && !this.hasVideos) {
      this.typeOf = 'image';
    }
  },
  methods: {
    handleThumbChange(index) {
      this.$refs.slider.instance.trigger('to.owl.carousel', [index, 300]);
    },
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
    handlePhotoClick() {
      const photoButton = this.$el.querySelector('.photoButton');
      const videoButton = this.$el.querySelector('.videoButton');
      if(!photoButton.classList.contains('active')){
        photoButton.classList.add('active');
        this.typeOf = 'image';
        videoButton.classList.remove('active');
      }
    },
    handleVideoClick() {
      const videoButton = this.$el.querySelector('.videoButton');
      const photoButton = this.$el.querySelector('.photoButton');
      if(!videoButton.classList.contains('active')){
        videoButton.classList.add('active');
        this.typeOf = 'video';
        photoButton.classList.remove('active');
      }
    },
    handleCarouselInitialized(initialized) {
      this.carouselInitialized = initialized;
    }
  }
}
</script>

<style lang="scss">
.organism-image-slider {
  position: relative;
  background: #000;
  .background-image {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  img {
    display: block;
    width: 100%;
  }
  .bottom-thumb {
    width: 100%;
    background: $black;
    overflow: hidden;
    padding: 1.5rem 1.25rem;
    @media only screen and (max-width: 767px) {
      padding: 0 10px 30px;
    }
  }
  .owl-nav {
    button {
      position: absolute;
      bottom: -11.1rem;
      line-height: 2.7rem !important;
      @media only screen and (max-width: 767px) {
        line-height: 43px !important;
        bottom: -236px;
        transform: none;
      }

      &.owl-prev {
        left: 1.563rem;
        @media only screen and (max-width: 767px) {
          left: 5px;
        }
      }

      &.owl-next {
        right: 1.563rem;
        @media only screen and (max-width: 767px) {
          right: 5px;
        }
      }
    }
  }
  &.no-videos {
    .owl-nav {
      button {
        @media only screen and (max-width: 767px) {
          bottom: -158px;
        }
      }
    }
  }
  .row-brochure {
    padding: 0 2.5rem 1.5rem;
    @media only screen and (max-width: 767px) {
      padding: 0 0 20px;
    }
    .title-brochure {
      h4 {
        display: inline-block;
        vertical-align: middle;
        @include font-main(1.375rem, $white, $medium, 3rem);
        height: 3rem;
        @media only screen and (max-width: 767px) {
          height: 48px;
          line-height: 48px;
          font-size: 18px;
        }
      }
      .button-change {
        padding: 0 0 0 1.875rem;
        @media only screen and (max-width: 767px) {
          padding: 0 0 0 20px;
        }
      }
    }
  }
  .change-carousel {
    display: inline-block;
    @media only screen and (max-width: 767px) {
      text-align: center;
      margin-top: 20px;
    }
  }
  .button-change {
    display: inline-block;
    vertical-align: middle;
    padding: 0 0.75rem;
    cursor: pointer;
    @media only screen and (max-width: 767px) {
      padding: 0;
      margin-right: 20px;
      &:last-child {
        margin: 0;
      }
    }

    .icon-element {
      display: block;
      text-align: center;
      background: $main-color;
      transition: all 0.2s ease-in-out 0s;
      padding: 0.75rem;
      @media only screen and (max-width: 767px) {
        padding: 8px;
      }

      .icon {
        margin-right: 0.625rem;
        display: inline-block;
        vertical-align: middle;
        font-size: 1.25rem !important;
        @media only screen and (max-width: 767px) {
          font-size: 22px !important;
          margin-right: 10px;
        }
      }
    }

    .title {
      display: inline-block;
      vertical-align: middle;
      font-weight: $medium;
      font-size: 0.875rem;
      line-height: 1.063rem;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      color: $white;
      transition: all 0.2s ease-in-out 0s;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.04em;
      }
    }

    &:hover, &.active {
      .icon-element {
        background: $second-color;
      }
    }
  }
  .molecule-image-slider {
    @media only screen and (max-width: 767px) {
      padding: 30px 10px 40px;
    }
  }
}
</style>