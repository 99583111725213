<template>
  <div class="atom-video-slider slide">
    <div class="image-relative">
      <div
        class="overlay-play"
        v-if="sliderVideo.image && !isPlaying"
        :style="`background-image: url(${resourceUrl(sliderVideo.image)})`"
      >
        <span class="play-video" v-if="!isHostedVideo" @click="play">
          <AtomIcon icon="play" />
        </span>
        <span class="play-video" v-else @click="playIframe">
          <AtomIcon icon="play" />
        </span>
      </div>
      <iframe
        ref="iframe"
        v-if="isHostedVideo"
        class="background-image"
        :src="assetUrl"
      ></iframe>
      <video
        ref="video"
        v-else
        class="background-image video-background"
        controls=""
      >
        <source type="video/mp4" :src="assetUrl" />
      </video>
      <img
        :src="resourceUrl('images/placeholder-for-full-size-image.png')"
        alt="Placeholder"
      />
    </div>
  </div>
</template>

<script>
import AtomIcon from "../AtomIcon";
export default {
  name: "AtomVideoSlider",
  components: { AtomIcon },
  props: {
    sliderVideo: {
      required: true,
      type: Object,
      default: () => {
        return {
          video: "",
          image: "",
          thumb: "",
        };
      },
    },
  },
  data() {
    return {
      isPlaying: false,
    };
  },
  computed: {
    cdnBase() {
      return this.$store.getters.cdnBase;
    },
    isHostedVideo() {
      if (
        !this.assetUrl.includes("youtube") &&
        !this.assetUrl.includes("vimeo")
      ) {
        return false;
      }
      return true;
    },
    assetUrl() {
      if (this.sliderVideo.video.includes("://")) {
        if (this.sliderVideo.video.includes("youtube")) {
          return this.sliderVideo.video + "?enablejsapi=1"; // we need to add this to be able to stop/pause a youtube video programmatically.
        }
        return this.sliderVideo.video;
      }
      return `${this.cdnBase}/${this.sliderVideo.video}`;
    },
  },
  methods: {
    play() {
      this.$refs.video.play();
      this.isPlaying = true;
    },
    playIframe() {
      this.isPlaying = true;
    },
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .atom-video-slider {
    position: relative;
    .image-relative {
      position: relative;
      .background-image {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
        z-index: 9;
      }
      iframe {
        background: $black;
      }
      .video-background {
        background: $black;
      }
      img {
        display: block;
        width: 100%;
      }
      .overlay-play {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        .play-video {
          width: 9.25rem;
          height: 9.25rem;
          border-radius: 9.25rem;
          background: $blackOpacity05;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.2s ease-in-out 0s;
          @media only screen and (max-width: 767px) {
            width: 60px;
            height: 60px;
            border-radius: 60px;
          }
          .icon {
            font-size: 3.125rem !important;
            color: $white !important;
            @media only screen and (max-width: 767px) {
              font-size: 20px !important;
            }
            &:before {
              width: auto;
              margin: 0;
            }
          }
          &:hover {
            background: $black;
          }
        }
      }
    }
  }
</style>
