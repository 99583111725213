<template>
  <div class="pinInfo"></div>
</template>

<script>
export default {
  name: "MoleculePinInfoContent",
  components: {},
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function () {
        return {
          highlightAnchor: "",
          pinTitle: "",
          pinDescription: "",
        };
      },
    },
  },
};
</script>

<style lang="scss"></style>
