var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "atom-specs-info": true, active: _vm.isActive },
      on: { click: _vm.handleClick },
    },
    [_c("p", { domProps: { innerHTML: _vm._s(_vm.text) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }