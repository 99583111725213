<template>
  <div class="organism-contact-form">
    <AtomTitle
      title="Leave us a <b>message</b>"
      subtitle="Leave us a message and will get back to you as soon as possible"
    />
    <MoleculeContactForm />
    <MoleculeSendRequest
      ref="successMessage"
      message="Thank you for reaching out ELI Parks. We appreciate your inquiry and a member of our team will be in contact with you shortly. Have a great day!"
    />
  </div>
</template>

<script>
import AtomTitle from "../../atoms/common/AtomTitle";
import MoleculeContactForm from "../../molecules/common/MoleculeContactForm";
import MoleculeSendRequest from "@/components/molecules/project/MoleculeSendRequest.vue";
export default {
  name: "OrganismContactForm",
  components: { MoleculeSendRequest, MoleculeContactForm, AtomTitle },
};
</script>

<style lang="scss">
.organism-contact-form {
  max-width: 80.334rem;
  padding: 4rem 1rem;
  margin: 0 auto;
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    padding: 32px 16px;
  }
  .atom-title {
    h3 {
      margin-top: 0.375rem;
      @media only screen and (max-width: 767px) {
        margin-top: 8px;
      }
    }
  }
  .atom-check-box {
    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
}
</style>
