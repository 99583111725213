<template>
  <div class="col col-xs-12 col-3 atom-list-circle">
    <span class="circle"><span></span></span>
    <h4>{{ title }}</h4>
    <AtomVerticalLine/>
    <AtomContentText color="#fff" :text="`${text}`" />
  </div>
</template>

<script>
import AtomVerticalLine from "../common/AtomVerticalLine";
import AtomContentText from "../common/AtomContentText";
export default {
  name: "AtomListCircle",
  components: {AtomContentText, AtomVerticalLine},
  props: {
    title: {
      required: true,
      type: String,
      default: ''
    },
    text: {
      required: true,
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .atom-list-circle {
    margin-bottom: 4.375rem;
    padding: 0 2.188rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
    .circle {
      width: 50px;
      height: 50px;
      border: 0.188rem solid $white;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.875rem;
      @media only screen and (max-width: 1200px) {
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }
      @media only screen and (max-width: 767px) {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border-width: 3px;
        margin-bottom: 25px;
      }
      span {
        display: block;
        width: 30px;
        height: 30px;
        background: $second-color;
        border-radius: 30px;
        @media only screen and (max-width: 1200px) {
          width: 20px;
          height: 20px;
          border-radius: 20px;
        }
        @media only screen and (max-width: 767px) {
          width: 30px;
          height: 30px;
          border-radius: 30px;
        }
      }
    }
    h4 {
      font-weight: $medium;
      font-size: 1.5rem;
      line-height: 1.813rem;
      color: $white;
      margin-bottom: 1.25rem;
      @media only screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 20px;
      }
    }
    .atom-vertical-line {
      margin-bottom: 0.938rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 15px;
      }
    }
  }
</style>