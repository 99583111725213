<template>
  <div
    :class="{
      section: true,
      'section-discover-area': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle :title="data.title" :subtitle="data.subtitle" />
    </div>
    <div class="image-full">
      <img :src="`${resourceUrl(data.image)}`" alt="Image Full Size" />
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";

export default {
  name: "SectionDiscoverArea",
  components: { AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss">
.section-discover-area {
  padding: 5.25rem 0 0;
  @media only screen and (max-width: 767px) {
    padding: 40px 0 0;
  }
  .atom-title {
    padding: 0 3.75rem;
    margin-bottom: 3.125rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
      padding: 0 10px;
    }
    h2 {
      margin-bottom: 0.75rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
    h3 {
      font-weight: 400;
    }
  }
  .image-full {
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
