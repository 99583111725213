<template>
  <a class="link-atom" :href="`${href}`" @click="$event => $emit('click', $event)">
    <span>{{ label }}</span>
    <AtomIcon v-if="icon" :icon="icon" :size="14"></AtomIcon>
  </a>
</template>

<script>
import AtomIcon from "./AtomIcon";
export default {
  name: "AtomButtonLink",
  components: {AtomIcon},
  props: {
    href: {
      required: false,
      type: String,
      default: "javascript:void(0)",
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    icon: {
      required: false,
      type: String,
      default: "",
    }
  }
}
</script>

<style lang="scss">
  .link-atom {
    display: inline-block;
    padding: 0.75rem 0.625rem;
    background: $second-color;
    border: 2px solid $second-color;
    box-sizing: border-box;
    text-align: center;
    @media only screen and (max-width: 767px) {
      padding: 8px 20px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      font-weight: $semiBold;
      font-size: 1rem;
      line-height: 1.25rem;
      text-transform: uppercase;
      color: $white;
      @media only screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .icon {
      font-size: 0.875rem !important;
      color: $white !important;
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.313rem;
      @media only screen and (max-width: 767px) {
        font-size: 12px !important;
        margin-left: 5px;
      }
    }
    &:hover {
      background: $main-color;
      border-color: $main-color;
      @media only screen and (max-width: 1200px) {
        background: $second-color;
        border-color: $second-color;
      }
    }
  }
</style>