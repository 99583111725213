var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atom-tenant" },
    _vm._l(_vm.tenants, function (tenant, index) {
      return _c("a", { key: index, attrs: { href: tenant.url } }, [
        _c("img", { attrs: { src: _vm.resourceUrl(tenant.image), alt: "" } }),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }