<template>
  <div class="molecule-sidebar-results">
    <div v-for="(building, i) in listData" :key="i" class="building-list">
      <div
        v-for="(floor, j) in building.floors"
        :key="j"
        class="floor-info-parent"
      >
        <MoleculeSidebarResult
          :building="building"
          :floor="floor"
          @buildingInteractionsRef="
            (value) => $emit('buildingInteractionsRef', value)
          "
        />
      </div>
    </div>
    <!-- end floor-description -->
  </div>
</template>

<script>
import MoleculeSidebarResult from "./MoleculeSidebarResult";

export default {
  name: "MoleculeSidebarResults",
  data() {
    return {
      isSpaceListExpanded: false,
      listData: [],
      filters: {},
    };
  },
  components: { MoleculeSidebarResult },
  props: {},
  computed: {
    projectData() {
      return this.$store.state.project.project;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
  },
  watch: {
    "$store.state.project.project.buildings": function () {
      this.refreshListData();
    },
    "$store.state.project.activeProject": function (value) {
      this.refreshListData();
    },
    "$store.state.project.filters": {
      deep: true,
      handler: function (filters) {
        this.filters = filters;
        this.refreshListData();
      },
    },
  },
  mounted() {
    this.refreshListData();
  },
  methods: {
    refreshListData() {
      this.listData = [];
      const buildings = [...this.projectData.buildings];
      buildings
        .filter((b) => {
          if (this.filters.building) {
            return b.id === parseInt(this.filters.building);
          }
          return true;
        })
        .sort((x, y) => x.order - y.order)
        .forEach((b) => {
          const building = {
            ...b,
            floors: [],
          };
          const floors = [...this.projectData.floors];
          floors
            .sort((a, b) =>
              a.order_no > b.order_no ? 1 : b.order_no > a.order_no ? -1 : 0
            )
            .filter((f) => {
              if (this.filters.floor) {
                return (
                  f.no === parseInt(this.filters.floor) &&
                  f.building_id === b.id
                );
              }
              return f.building_id === b.id;
            })
            .forEach((f, i) => {
              const floor = {
                ...f,
                availableSurface: 0,
                isFullyLeased: false,
                leasedSpaces: 0,
                spaces: [],
              };
              const spaces = [...this.projectData.spaces];
              spaces
                .sort((a, b) =>
                  a.space_code > b.space_code
                    ? 1
                    : b.space_code > a.space_code
                    ? -1
                    : 0
                )
                .filter((s) => {
                  return s.floor_id === f.id;
                })
                .forEach((s, i) => {
                  const space = {
                    ...s,
                    people: 0,
                  };
                  const unitSQM = s.sqm / 10.763910417;
                  space.people = Math.floor(space.sqm / 8);
                  if (this.unitOfMeasure === 1) {
                    space.people = Math.floor(unitSQM / 8);
                  }
                  const spaceCodes = space.space_code.split(";");
                  if (spaceCodes.length === 1 && space.available) {
                    floor.availableSurface += space.sqm;
                  }
                  if (!space.available && (space.visibility_status !== 'available')) {
                    floor.leasedSpaces += 1;
                  }
                  if (this.filters.surface) {
                    if (s.sqm_range === this.filters.surface && s.available) {
                      floor.spaces.push(space);
                    }
                  } else {
                    floor.spaces.push(space);
                  }
                });

              if (floor.leasedSpaces === floor.spaces.length) {
                floor.isFullyLeased = true;
              }

              floor.spaces.sort((a, b) => {
                if (a.sqm < b.sqm) {
                  return -1;
                }
                if (a.sqm > b.sqm) {
                  return 1;
                }
                return 0;
              });

              if (this.filters.surface) {
                if (floor.spaces.length) {
                  building.floors.push(floor);
                }
              } else {
                building.floors.push(floor);
              }
            });
          this.listData.push(building);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.molecule-sidebar-results {
  .col-padding-4px {
    padding: 0 4px;
  }
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .trigger-click-view-space {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 41px;
    z-index: 9;
    cursor: pointer;
  }
  .floor-info-parent {
    margin-bottom: 8px;
  }
  .row-floor {
    position: relative;
    display: block;
    vertical-align: middle;
    width: 100%;
    padding: 12px 16px;
    cursor: pointer;
    background: #f7f7f7;
    &.action-disabled {
      pointer-events: none;
      background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 2px,
        #f7f7f7 1px,
        #f7f7f7 4px
      );
      .clearfix {
        margin: 0;
      }
      .get-space-floor {
        opacity: 0.3;
      }
      .leased-overlay {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 50%;
        margin-left: -46px;
        font-size: 14px;
        color: $black;
        font-weight: $regular;
        line-height: 16px;
      }
      h6 {
        max-width: 130px;
      }
    }
    .clearfix {
      position: relative;
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .hover-buttons {
      display: none;
      text-align: center;
      padding-bottom: 10px;
      .wrapper-btn {
        display: block;
      }
      .icon-edit {
        display: block;
        background: $white;
        border-radius: 2px;
        padding: 8px 10px;
        font-size: 14px;
        color: $black;
        font-weight: $regular;
        line-height: 17px;
        text-decoration: none;
        .icon {
          margin-right: 5px;
        }
        &:hover {
          background: $black;
          color: $white;
          .icon {
            color: $white !important;
          }
        }
      }
      .icon-select {
        display: block;
        border-radius: 2px;
        background: #4cc66d;
        padding: 8px 10px;
        font-size: 14px;
        color: $white;
        font-weight: $regular;
        line-height: 17px;
        text-decoration: none;
        svg {
          margin-right: 5px;
        }
        &.select-show {
          background: #4cc66d;
          &:hover {
            background: #3ade66;
          }
          .icon {
            margin-right: 5px;
            font-size: 15px !important;
            color: $white !important;
          }
        }
        &.btn-remove-item {
          background: #fcdfdf;
          color: #f93d3d;
          &:hover {
            background: #f93d3d;
            color: $white;
          }
        }
      }
    }
    .topTitle {
      position: relative;
      padding: 8px 239px 8px 0;
      margin-bottom: 10px;
    }
    h6 {
      display: block;
      font-size: 14px;
      color: $black;
      font-weight: $regular;
      line-height: 17px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      color: $black;
      font-weight: $regular;
      line-height: 17px;
      b {
        font-size: 14px;
        line-height: 17px;
      }
      &.floor-number {
        color: #7e7e7e;
        font-weight: $light;
        font-size: 14px;
        line-height: 17px;
        span {
          font-weight: $medium;
          margin: -1px 0 0 4px;
          font-size: 14px;
          line-height: 17px;
        }
      }
      &.when-text.db {
        color: $black;
        font-weight: $regular;
      }
    }
    .sqm-gla-total {
      color: #7e7e7e;
      font-weight: $light;
      position: absolute;
      top: 2px;
      right: 30px;
      b {
        color: $black;
        font-weight: $semiBold;
      }
    }
    .column3 {
      width: 32%;
    }
    .column2 {
      width: 49%;
    }
    .column3,
    .column2 {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }
  .show-all-spaces {
    display: none;
    .row-floor {
      border: none;
      background: #f7f7f7;
      border-radius: 0;
      margin-top: 2px;
      padding: 12px 16px 2px 16px;
      cursor: initial;
      transition: all 0.2s ease-in-out 0s;
      &.leased-element-style {
        padding: 12px 16px;
        pointer-events: none;
        background: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent 2px,
          #f7f7f7 1px,
          #f7f7f7 4px
        );
        .clearfix {
          margin: 0;
        }
        .get-space-floor {
          opacity: 0.3;
        }
        .leased-overlay {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 16px;
          font-size: 14px;
          color: $black;
          font-weight: $regular;
          line-height: 17px;
        }
      }
      .clearfix {
        position: relative;
        .icon-lock {
          opacity: 0;
          position: absolute;
          right: 0;
          top: 2px;
          cursor: pointer;
          font-size: 14px;
          color: $black;
          font-weight: $light;
          line-height: 17px;
          transition: all 0.2s ease-in-out 0s;
          &.hide-space {
            display: none;
          }
        }
      }
      .line-element {
        margin-right: 12px;
        .icon {
          font-size: 14px !important;
        }
        &.line-checked {
          .icon {
            color: #4cc66d !important;
          }
        }
      }
      .textInfo {
        font-size: 14px;
        color: $black;
        font-weight: $light;
        line-height: 20px;
        margin-bottom: 9px;
      }
      .get-sqm {
        margin-right: 16px;
      }
      .get-sqm,
      .get-nr-people {
        font-weight: $light;
      }
      &:hover {
        background: #eaeaea;
        .clearfix {
          .icon-lock {
            opacity: 1;
          }
        }
      }
      &.activation {
        background: #eaeaea;
        .hover-buttons {
          display: block;
        }
        .icon-lock {
          &.view-space {
            display: none;
          }
          &.hide-space {
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }
  .parent-floor {
    margin-bottom: 0;
    transition: all 0.2s ease-in-out 0s;
    .arrow-custom {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.2s ease-in-out 0s;
      margin-top: -3px;

      &.rotate-icon {
        top: 30%;
        transform: rotate(180deg);
      }
    }
    &:hover {
      background: #eaeaea;
    }
    &.active {
      background: #eaeaea;
    }
  }
  .row-floor-active {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $main-color;
    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .clearfix {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }
  .nr-cube {
    width: 22px;
    height: 22px;
    line-height: 23px !important;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    background: $black;
    color: $white !important;
    border-radius: 4px;
    margin-top: -2px;
    margin-right: 5px;
    font-weight: $bold !important;
    &.nrCubeWhite {
      color: $black !important;
      background: $white;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .hiddenMobileElements {
    display: none !important;
  }
  .hiddenDesktopElements {
    display: block !important;
  }
  .mobileTextLeft {
    text-align: left !important;
  }

  .organism-building {
    #canvas-wrapper {
      width: 100%;
    }
  }
  .molecule-sidebar-results {
    .row-floor {
      &.action-disabled {
        .leased-overlay {
          font-size: 13px;
        }
      }
      h6 {
        font-size: 13px;
      }
      span {
        font-size: 13px;
        b {
          font-size: 13px;
        }
        &.floor-number {
          font-size: 13px;
          span {
            font-size: 13px;
          }
        }
      }
    }
  }

  .project {
    .organism-sidebar-building {
      display: block;
      right: 50% !important;
      top: auto;
      width: 362px;
      bottom: 79px !important;
      border-radius: 4px;
      height: calc(100vh - 204px) !important;
      @include prefix(transform, translateX(50%), ("webkit", "moz", "ms", "o"));
      @include prefix(transition, none, ("webkit", "moz", "ms", "o"));
      .header-box {
        .info-building {
          z-index: 99999;
          height: calc(100vh - 254px);
        }
      }
      .show-all-spaces {
        .row-floor {
          .line-element {
            margin-right: 5px;
          }
          .get-sqm {
            margin-right: 8px;
          }
          .clearfix {
            .icon-lock {
              top: 0;
              opacity: 1 !important;
              font-size: 13px;
              .icon {
                font-size: 12px !important;
              }
            }
          }
          span {
            font-size: 13px;
            b {
              font-size: 13px;
            }
          }
          .hover-buttons {
            a {
              padding: 6px 5px;
              font-size: 12px;
              .icon {
                font-size: 13px !important;
              }
            }
          }
          &:hover {
            background: #f7f7f7;
          }
          &.active {
            background: #eaeaea !important;
          }
          &.leased-element-style {
            .clearfix {
              .icon-lock {
                opacity: 0 !important;
              }
            }
            .leased-overlay {
              font-size: 13px;
            }
          }
        }
      }
    }
    .button-back {
      left: 0;
      .btn-cube {
        width: 55px;
        height: 55px;
        &:hover {
          @include prefix(transform, scale(1), ("webkit", "moz", "ms", "o"));
        }
      }
      &.active {
        top: 0;
      }
    }
  }
}
</style>
