<template>
  <div
    :class="{
      section: true,
      'section-tenants': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title text-center">
      <AtomTitle :title="data.title" />
    </div>
    <AtomTenants :tenants="data.tenants" />
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";
import AtomTenants from "../../components/atoms/common/AtomTenants";

export default {
  name: "SectionTenants",
  components: { AtomTenants, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.section-tenants {
  border-bottom: 1px solid #ecf5f8;
  padding: 4.688rem 3.75rem 3.75rem;
  @media only screen and (max-width: 767px) {
    padding: 35px 10px 30px;
  }
  .atom-title {
    margin-bottom: 1.875rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}
</style>
