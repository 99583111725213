import { Observer } from "babylonjs/Misc/observable";
import { isDesktop } from "@/helpers/mobile/DeviceType";
import {
  ICameraInput,
  Nullable,
  UniversalCamera,
  PointerInfo,
  Vector2,
  PointerEventTypes,
} from "babylonjs";

export class FreeCameraKeyboardWalkInput
  implements ICameraInput<UniversalCamera>
{
  camera: Nullable<UniversalCamera> = null;
  previousPosition: {
    x: number;
    y: number;
  } | null = null;
  touchEnabled = true;
  buttons = [0, 1, 2];
  angle = { x: 0, y: 0 };
  observer?: Nullable<Observer<PointerInfo>>;
  cameraMinFov = 0.2;
  cameraMaxFov = 1.5;
  lastPoint = new Vector2(0, 0);
  prevDiff = -1;
  curDiff = 0;
  zoomValue = 0.05;
  isPinch = false;

  attachControl(): void {
    if (!this.camera) return console.error("Camera Search is null");
    const element = this.camera.getEngine().getInputElement();
    if (!element) return console.error("Element is null");
    this.observer = this.camera
      .getScene()
      .onPointerObservable.add((eventData) => {
        this.pointerInput(eventData);
      }, PointerEventTypes.POINTERDOWN | PointerEventTypes.POINTERUP | PointerEventTypes.POINTERMOVE);
    // Add FOV zoom
    element.focus();
    if (isDesktop()) {
      this.fovChange = this.fovChange.bind(this);
      element.addEventListener("wheel", this.fovChange);
    } else {
      this.mobileSetInitPoint = this.mobileSetInitPoint.bind(this);
      this.mobilePinch = this.mobilePinch.bind(this);
      this.pinchEnd = this.pinchEnd.bind(this);
      element.addEventListener("touchstart", this.mobileSetInitPoint);
      element.addEventListener("touchmove", this.mobilePinch);
      element.addEventListener("touchend", this.pinchEnd);
    }
  }

  detachControl(): void {
    if (!this.camera) return console.error("Camera Search is null");
    const element = this.camera.getEngine().getInputElement();
    if (this.observer && element) {
      this.camera.getScene().onPointerObservable.remove(this.observer);
      this.observer = null;
      this.previousPosition = null;
      element.removeEventListener("wheel", this.fovChange);
      element.removeEventListener("touchstart", this.mobileSetInitPoint);
      element.removeEventListener("touchmove", this.mobilePinch);
      element.removeEventListener("touchend", this.pinchEnd);
    }
  }

  getClassName(): string {
    return "FreeCameraSearchInput";
  }

  getSimpleName(): string {
    return "MouseSearchCamera";
  }

  pointerInput(p: PointerInfo) {
    if (!this.camera) return console.error("Camera is null");
    const evt = p.event as PointerEvent;
    if (this.isPinch) return;
    if (!this.touchEnabled && evt.pointerType === "touch") {
      return;
    }
    if (
      p.type !== PointerEventTypes.POINTERMOVE &&
      !this.buttons.includes(evt.button)
    ) {
      return;
    }
    if (p.type === PointerEventTypes.POINTERDOWN) {
      this.previousPosition = {
        x: evt.clientX,
        y: evt.clientY,
      };
      evt.preventDefault();
    } else if (p.type === PointerEventTypes.POINTERUP) {
      this.previousPosition = null;
      evt.preventDefault();
    } else if (p.type === PointerEventTypes.POINTERMOVE) {
      if (!this.previousPosition) {
        return;
      }
      const offsetX = evt.clientX - this.previousPosition.x;
      const offsetY = evt.clientY - this.previousPosition.y;
      this.angle.x += offsetX;
      this.angle.y -= offsetY;
      this.angle.x -= offsetX;
      this.angle.y += offsetY;
      if (this.camera.getScene().useRightHandedSystem) {
        this.camera.cameraRotation.y -=
          offsetX / this.camera.angularSensibility;
      } else {
        this.camera.cameraRotation.y +=
          offsetX / this.camera.angularSensibility;
      }
      this.camera.cameraRotation.x += offsetY / this.camera.angularSensibility;
      this.previousPosition = {
        x: evt.clientX,
        y: evt.clientY,
      };
      evt.preventDefault();
    }
  }

  fovChange(evt: WheelEvent) {
    if (!this.camera) return console.error("Camera is null");
    if (evt.deltaY < 0 && this.camera.fov > this.cameraMinFov) {
      this.camera.fov -= this.zoomValue;
    } else if (evt.deltaY > 0 && this.camera.fov < this.cameraMaxFov) {
      this.camera.fov += this.zoomValue;
    }
    evt.preventDefault();
  }

  mobileSetInitPoint(evt: TouchEvent) {
    this.lastPoint = new Vector2(
      evt.targetTouches[0].clientX,
      evt.targetTouches[0].clientY
    );
    evt.preventDefault();
  }

  mobilePinch(evt: TouchEvent) {
    if (evt.targetTouches.length === 2 && this.camera) {
      this.isPinch = true;
      this.curDiff = Math.abs(
        evt.targetTouches[0].clientX - evt.targetTouches[1].clientX
      );

      if (this.prevDiff > 0) {
        if (
          this.curDiff > this.prevDiff &&
          this.camera.fov > this.cameraMinFov
        ) {
          // zoom in
          this.camera.fov -= this.zoomValue;
        } else if (
          this.curDiff < this.prevDiff &&
          this.camera.fov < this.cameraMaxFov
        ) {
          // zoom out
          this.camera.fov += this.zoomValue;
        }
      }
      this.prevDiff = this.curDiff;
      evt.preventDefault();
    }
  }

  pinchEnd(evt: TouchEvent) {
    if (evt.targetTouches.length === 0) {
      this.isPinch = false;
    }
  }
}
