<template>
  <div
    ref="sectionParking"
    :class="{
      section: true,
      'section-parking': true,
      'section-parking-v2': true,
      [`section${orderNo}`]: true,
    }"
    v-if="data.enabled !== false"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title" v-if="data.title">
      <AtomTitle :title="data.title" :subtitle="data.subtitle" />
    </div>
    <OrganismParkingAccessV2 :parking-access="data.items" />
  </div>
</template>

<script>
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
import OrganismParkingAccessV2 from "../../../../components/organisms/common/OrganismParkingAccessV2";
export default {
  name: "SectionParking",
  components: { OrganismParkingAccessV2, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.section-parking-v2 {
  padding: 7.5rem 0 0;
  @media only screen and (max-width: 900px) {
    padding: 32px 0 0;
  }
  .top-title {
    margin-bottom: 2.25rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 10px;
    }
    .atom-title {
      @media only screen and (max-width: 767px) {
        margin-bottom: 15px;
      }
      h2 {
        text-transform: none;
        @include font-main(3.25rem, $blueDark !important, $regular, 3.875rem);
        @media only screen and (max-width: 767px) {
          @include font-main(32px, $blueDark !important, $regular, 39px);
        }
        b {
          @include font-main(3.25rem, $blueDark !important, $bold, 3.875rem);
          @media only screen and (max-width: 767px) {
            @include font-main(32px, $blueDark !important, $bold, 39px);
          }
        }
      }
      h3 {
        @include font-main(1.375rem, $blueGreen !important, $regular, 1.625rem);
        @media only screen and (max-width: 767px) {
          @include font-main(16px, $blueGreen !important, $regular, 19px);
        }
      }
    }
    .button-atom {
      background: transparent;
      padding: 0.75rem 2rem;
      font-weight: $regular;
      border-color: $blue;
      @media only screen and (max-width: 767px) {
        padding: 8px 15px;
      }
      span {
        color: $black;
        text-transform: none;
      }
      &:hover {
        background: $blue;
        span {
          color: $white;
        }
      }
    }
  }
}
</style>
