var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-padding" }, [
    _c(
      "div",
      {
        class: { "atom-gradient-background": true, active: _vm.isActive },
        on: { click: _vm.handleClick },
      },
      [
        _vm.sold
          ? _c("div", { staticClass: "sold-element" }, [_vm._v("Divested")])
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "lazyload",
                rawName: "v-lazyload",
                value: { handle: _vm.handleLazyLoad, url: _vm.resourceUrl },
                expression: "{handle: handleLazyLoad, url: resourceUrl}",
              },
            ],
            staticClass: "atom-project-slider-thumb",
            style: { "background-image": "url(" + _vm.resourceUrl + ")" },
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.title) + " "),
              _c("b", [_vm._v(_vm._s(_vm.subtitle))]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }