var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.enabled !== false
    ? _c(
        "div",
        {
          class:
            ((_obj = {
              section: true,
              "section-brochure-button": true,
            }),
            (_obj["section" + _vm.orderNo] = true),
            _obj),
        },
        [
          _c("a", {
            staticClass: "navigation-target",
            attrs: { id: _vm.hash, href: "javascript:void(0);" },
          }),
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col col-xs-12 col-sm-7 col-8" }, [
              _c(
                "div",
                { staticClass: "top-title" },
                [
                  _c("AtomTitle", {
                    attrs: {
                      color: _vm.data.colorTitle,
                      color2: _vm.data.colorText,
                      title: _vm.data.title,
                      subtitle: _vm.data.subtitle,
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  staticClass: "text",
                  style: "color: " + _vm.data.colorText + ";",
                },
                [_vm._v(_vm._s(_vm.data.text))]
              ),
            ]),
            _c("div", { staticClass: "col col-xs-12 col-sm-5 col-4" }, [
              _c("div", { staticClass: "button-brochure text-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "button-atom",
                    attrs: {
                      href: _vm.resourceUrl(_vm.data.buttonUrl),
                      target: "_blank",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.data.buttonText))])]
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }