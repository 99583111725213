import { Vector3 } from "babylonjs";

export enum SCENE_VIEW {
  TWO_DIMENSIONAL,
  DEFAULT,
  FIRST_PERSON,
}
export enum CAMERA_TYPE {
  ARC_ROTATE,
  UNIVERSAL,
}
export default {
  ENABLE_PIPELINE: false,
  DO_NOT_SYNC_BB: true,
  MESH_LAYER_MASK: 0x10000000,
  VIRTUAL_TOUR_CAMERA_MASK: 0x20000000,
  CAMERA_3D_LAYER_MASK: 0x30000000,
  MINIMAP_LAYER_MASK: 0x40000000,
  UPPER_BETA_LIMIT: 1.5708,
  PANNING_SENSIBILITY: 40,
  HIGHLIGHT_ALPHA: (3 * Math.PI) / 2,
  VIRTUAL_TOUR_CAMERA_SPEED: 0.25,
  VIRTUAL_TOUR_CAMERA_FOV: 1.3,
  CAMERA_DEFAULT_FOV: 0.7,
  ANGULAR_SENSIBILITY_MOBILE: 1000,
  ANGULAR_SENSIBILITY_DESKTOP: 2000,
  PIN_TEXTURE: "/images/projects/sthlm/360/plus-sign.jpg",
  POINTER_CIRCLE_TEXTURE: "/images/vt-pointer.png",
  POINTER_POSITIONY: 25,
  CONTINUOUS_RENDERING: false,
  MAX_RENDER_TIME: 100,
  ADAPT_TO_DEVICE_RATIO: false,
  POINTER_CIRCLE_SIZE: 0.6,
  CAMERA_3D_VIEW_DEFAULT_VALUES: {
    alpha: 1,
    beta: 1,
    radius: 100,
    target: Vector3.Zero(),
  },
  PIN_POP_UP_OFFSET: [28, 32],
  HIGHLIGHT_ZOOM: 100,
  RENDER_ID_TOP: 3,
  MULTIPLE_CEILINGS: false,
  MIN_Z_FLOOR_ARC_ROTATE: 10,
  MIN_Z_FLOOR_UNIVERSAL: 0,
  CAMERE_YOFFSET_PORTRAIT_MOBILE: 10,
  SPACE_VISIBILITY: 0.25,
  PIN_STYLES: {
    color: "white",
    fontFamily: "Effra",
    fontSize: 12,
    fontWeight: "500",
  },
  DEBUG_PINS: false,
  PIN_DEFAULT_SIZE: 2,
  // Names
  SKYBOX_NAME: "SkyBox",
  PIN_NAME: "Pin",
  MINIMAP_PLANE_NAME: "minimapPlane",
  UNIVERSAL_CAMERA_NAME: "UniversalCamera",
  ARC_ROTATE_CAMERA_NAME: "ArcRotateCamera",
  VIEWPORT_CAMERA_NAME: "ViewportCamera",
  CAMERA_TYPE: {
    arcRotate: "ArcRotate",
    universal: "Universal",
  },
  FUNCT_FREQUENCY: 200,
};

export const PIN_ICON = {
  energy: `<svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3633 10.1289L4.39844 17.9688C4.15234 18.1797 3.87109 18.25 3.625 18.25C3.37891 18.25 3.13281 18.1797 2.95703 18.0391C2.53516 17.7578 2.35938 17.1953 2.57031 16.7031L5.27734 10.375H1.375C0.882812 10.375 0.460938 10.0938 0.320312 9.67188C0.144531 9.21484 0.25 8.72266 0.601562 8.40625L9.60156 0.566406C9.98828 0.214844 10.5508 0.214844 10.9727 0.53125C11.4297 0.8125 11.5703 1.375 11.3594 1.86719L8.65234 8.16016H12.5898C13.0469 8.16016 13.4688 8.47656 13.6445 8.89844C13.8203 9.32031 13.7148 9.8125 13.3633 10.1289Z" fill="#51B4FF"/>
          </svg>`,
  green_areas: `<svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8828 12.8359L14.6172 11.3945C14.8633 11.2188 15.0742 11.0078 15.1797 10.7266C15.4961 10.0938 15.3906 9.35547 14.9336 8.82812L13.8438 7.5625C14.0195 7.42188 14.125 7.21094 14.2305 7.03516C14.4766 6.47266 14.3711 5.83984 13.9492 5.38281L9.69531 0.8125C9.41406 0.460938 8.95703 0.25 8.5 0.25C8.00781 0.25 7.55078 0.460938 7.26953 0.8125L3.01562 5.38281C2.59375 5.83984 2.48828 6.47266 2.73438 7.03516C2.83984 7.21094 2.98047 7.42188 3.12109 7.5625L2.03125 8.82812C1.57422 9.35547 1.46875 10.0938 1.78516 10.7266C1.89062 11.0078 2.10156 11.2188 2.34766 11.3945L1.08203 12.8359C0.589844 13.4336 0.449219 14.207 0.800781 14.9102C1.08203 15.5781 1.75 16 2.48828 16H7.65625V17.4414C7.65625 17.8984 8.00781 18.25 8.5 18.25C8.95703 18.25 9.34375 17.8984 9.34375 17.4062V16H14.4766C15.2148 16 15.8828 15.5781 16.1641 14.9102C16.5156 14.207 16.375 13.4336 15.8828 12.8359ZM14.6523 14.207C14.6172 14.2773 14.5469 14.3125 14.4766 14.3125H9.34375V13.5391L10.75 12.0977C11.1016 11.7812 11.1016 11.2539 10.75 10.9375C10.4336 10.5859 9.90625 10.5859 9.58984 10.9375L9.34375 11.1484V6.71875C9.30859 6.22656 8.95703 5.875 8.5 5.875C8.00781 5.875 7.62109 6.22656 7.65625 6.71875V8.33594L7.375 8.08984C7.02344 7.77344 6.49609 7.77344 6.17969 8.08984C5.82812 8.44141 5.82812 8.96875 6.17969 9.28516L7.65625 10.7266V14.3125H2.48828C2.41797 14.3125 2.34766 14.2773 2.3125 14.207C2.27734 14.1719 2.27734 14.0664 2.34766 13.9609L5.72266 10.0586H3.40234L6.42578 6.29688H4.49219L8.46484 1.9375L12.4727 6.29688H10.5391L13.5625 10.0586H11.2422L14.6172 13.9609C14.6875 14.0664 14.6875 14.1719 14.6523 14.207Z" fill="#4CC66D"/>
              </svg>`,
  certifications: `<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.18359 12.168C9.42969 11.8867 9.53516 11.5 9.46484 11.1484C9.25391 10.4102 9.25391 10.5156 9.46484 9.8125C9.53516 9.42578 9.42969 9.03906 9.18359 8.79297C8.65625 8.26562 8.69141 8.33594 8.51562 7.59766C8.41016 7.24609 8.12891 6.96484 7.77734 6.85938C7.07422 6.68359 7.17969 6.71875 6.65234 6.19141C6.37109 5.94531 5.98438 5.83984 5.63281 5.91016C4.92969 6.12109 5.03516 6.12109 4.33203 5.91016C3.98047 5.83984 3.59375 5.94531 3.3125 6.19141C2.78516 6.71875 2.89062 6.68359 2.1875 6.85938C1.83594 6.96484 1.55469 7.24609 1.44922 7.59766C1.27344 8.33594 1.30859 8.26562 0.78125 8.79297C0.535156 9.03906 0.429688 9.42578 0.535156 9.8125C0.710938 10.5156 0.710938 10.4102 0.535156 11.1484C0.429688 11.5 0.535156 11.8867 0.78125 12.168C1.30859 12.6953 1.27344 12.5898 1.44922 13.3281C1.55469 13.6797 1.83594 13.9609 2.1875 14.0664C2.67969 14.207 2.57422 14.1719 2.75 14.2422V18.25L5 17.125L7.25 18.25V14.2422C7.39062 14.1719 7.28516 14.207 7.77734 14.0664C8.12891 13.9609 8.375 13.6797 8.51562 13.3281C8.69141 12.5898 8.65625 12.6953 9.18359 12.168ZM5 12.625C3.73438 12.625 2.75 11.6406 2.75 10.375C2.75 9.14453 3.73438 8.125 5 8.125C6.23047 8.125 7.21484 9.14453 7.21484 10.375C7.21484 11.6406 6.23047 12.625 5 12.625ZM17.832 3.55469L15.1953 0.917969C14.7734 0.496094 14.2109 0.25 13.6133 0.25H7.21484C5.94922 0.25 4.96484 1.26953 4.96484 2.5V4.92578C4.96484 4.92578 5.73828 4.57422 6.65234 4.92578L6.6875 2.53516C6.6875 2.21875 6.93359 1.97266 7.25 1.97266H12.875V4.75C12.875 5.38281 13.3672 5.875 14 5.875H16.7773V16C16.7773 16.3164 16.4961 16.5625 16.2148 16.5625H8.375L8.33984 18.25H16.2148C17.4453 18.25 18.4648 17.2656 18.4648 16V5.13672C18.5 4.53906 18.2539 3.97656 17.832 3.55469Z" fill="#E9AF1B"/>
                  </svg>`,
  features: `<svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.1875 12.625C22.4688 12.625 22.75 12.9062 22.75 13.1875V14.3125C22.75 14.6289 22.4688 14.875 22.1875 14.875H20.5C20.5 16.7383 18.9883 18.25 17.125 18.25C15.2617 18.25 13.75 16.7383 13.75 14.875H9.25C9.25 16.7383 7.73828 18.25 5.875 18.25C4.01172 18.25 2.5 16.7383 2.5 14.875H1.9375C0.988281 14.875 0.25 14.1367 0.25 13.1875V1.9375C0.25 1.02344 0.988281 0.25 1.9375 0.25H13.1875C14.1016 0.25 14.875 1.02344 14.875 1.9375V3.625H16.6328C17.2305 3.625 17.8281 3.87109 18.25 4.29297L20.957 7C21.3789 7.45703 21.625 8.01953 21.625 8.61719V12.625H22.1875ZM5.875 16.5625C6.78906 16.5625 7.5625 15.8242 7.5625 14.875C7.5625 13.9609 6.78906 13.1875 5.875 13.1875C4.92578 13.1875 4.1875 13.9609 4.1875 14.875C4.1875 15.8242 4.92578 16.5625 5.875 16.5625ZM14.875 5.875V9.25H19.375V8.61719L16.6328 5.875H14.875ZM17.125 16.5625C18.0391 16.5625 18.8125 15.8242 18.8125 14.875C18.8125 13.9609 18.0391 13.1875 17.125 13.1875C16.1758 13.1875 15.4375 13.9609 15.4375 14.875C15.4375 15.8242 16.1758 16.5625 17.125 16.5625Z" fill="#F57A7A"/>
            </svg>`,
  building: `<svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0625 0.285156H1.9375C0.988281 0.285156 0.25 1.02344 0.25 1.97266V17.1602C0.25 17.7578 0.742188 18.2852 1.375 18.2852H5.3125V15.4727C5.3125 14.5234 6.05078 13.7852 7 13.7852C7.91406 13.7852 8.6875 14.5234 8.6875 15.4727V18.2852H12.625C13.2227 18.2852 13.75 17.7578 13.75 17.1602V1.97266C13.75 1.02344 12.9766 0.285156 12.0625 0.285156ZM5.875 10.9375C5.875 11.2539 5.59375 11.5 5.3125 11.5H4.1875C3.87109 11.5 3.625 11.2539 3.625 10.9375V9.8125C3.625 9.53125 3.87109 9.25 4.1875 9.25H5.3125C5.59375 9.25 5.875 9.53125 5.875 9.8125V10.9375ZM5.875 7.5625C5.875 7.87891 5.59375 8.125 5.3125 8.125H4.1875C3.87109 8.125 3.625 7.87891 3.625 7.5625V6.4375C3.625 6.15625 3.87109 5.875 4.1875 5.875H5.3125C5.59375 5.875 5.875 6.15625 5.875 6.4375V7.5625ZM5.875 4.1875C5.875 4.50391 5.59375 4.75 5.3125 4.75H4.1875C3.87109 4.75 3.625 4.46875 3.625 4.1875V3.0625C3.625 2.78125 3.87109 2.5 4.1875 2.5H5.3125C5.59375 2.5 5.875 2.78125 5.875 3.0625V4.1875ZM10.375 10.9375C10.375 11.2539 10.0938 11.5 9.8125 11.5H8.6875C8.37109 11.5 8.125 11.2539 8.125 10.9375V9.8125C8.125 9.53125 8.37109 9.25 8.6875 9.25H9.8125C10.0938 9.25 10.375 9.53125 10.375 9.8125V10.9375ZM10.375 7.5625C10.375 7.87891 10.0938 8.125 9.8125 8.125H8.6875C8.37109 8.125 8.125 7.87891 8.125 7.5625V6.4375C8.125 6.15625 8.37109 5.875 8.6875 5.875H9.8125C10.0938 5.875 10.375 6.15625 10.375 6.4375V7.5625ZM10.375 4.1875C10.375 4.50391 10.0938 4.75 9.8125 4.75H8.6875C8.37109 4.75 8.125 4.46875 8.125 4.1875V3.0625C8.125 2.78125 8.37109 2.5 8.6875 2.5H9.8125C10.0938 2.5 10.375 2.78125 10.375 3.0625V4.1875Z" fill="#4C51C6"/>
            </svg>`,
  parking: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.5625 6H6.875V8.25H8.5625C9.16016 8.25 9.6875 7.75781 9.6875 7.125C9.6875 6.52734 9.16016 6 8.5625 6ZM13.625 0.375H2.375C1.10938 0.375 0.125 1.39453 0.125 2.625V13.875C0.125 15.1406 1.10938 16.125 2.375 16.125H13.625C14.8555 16.125 15.875 15.1406 15.875 13.875V2.625C15.875 1.39453 14.8555 0.375 13.625 0.375ZM8.5625 10.5H6.875V11.625C6.875 12.2578 6.34766 12.75 5.75 12.75C5.11719 12.75 4.625 12.2578 4.625 11.625V4.875C4.625 4.27734 5.11719 3.75 5.75 3.75H8.5625C10.3906 3.75 11.9375 5.29688 11.9375 7.125C11.9375 8.98828 10.3906 10.5 8.5625 10.5Z" fill="#E97E1B"/>
            </svg>`,
  plus: `<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.0938 6.875C13.0938 7.28516 12.7715 7.57812 12.3906 7.57812H7.70312V12.2656C7.70312 12.6758 7.38086 12.998 7 12.998C6.58984 12.998 6.29688 12.6758 6.29688 12.2656V7.57812H1.60938C1.19922 7.57812 0.90625 7.28516 0.90625 6.9043C0.90625 6.49414 1.19922 6.17188 1.60938 6.17188H6.29688V1.48438C6.29688 1.10352 6.58984 0.810547 7 0.810547C7.38086 0.810547 7.70312 1.10352 7.70312 1.48438V6.17188H12.3906C12.7715 6.17188 13.0938 6.49414 13.0938 6.875Z" fill="white"/>
        </svg>`,
  arrow_right: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" fill="white"/>
        </svg>`,
};
