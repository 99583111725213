<template>
  <div
    class="atom-contact"
    :class="{
      'no-margin': !image & !name & !position & !email & !nr & !urlLinkedin,
    }"
  >
    <div
      v-if="image || name || position || email || nr || urlLinkedin"
      class="atom-gradient-background"
    >
      <div v-if="image" class="image-team">
        <img :src="`${resourceUrl(image)}`" :alt="`${name}`" />
      </div>
      <div class="row content-info align-items-center same-height">
        <div class="col col-7" v-if="name">
          <span class="name">{{ name }}</span>
        </div>
        <div class="col col-12">
          <span v-if="position">{{ position }}</span>
          <a v-if="email" :href="`mailto:${email}`">{{ email }}</a>
          <a v-if="nr" :href="`tel:${nr}`">{{ nr }}</a>
          <a v-if="urlLinkedin" :href="urlLinkedin">www.linkedin.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../../config";
export default {
  name: "AtomContact",
  components: {},
  props: {
    image: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
      default: "",
    },
    nr: {
      required: true,
      type: String,
      default: "",
    },
    email: {
      required: true,
      type: String,
      default: "",
    },
    position: {
      required: true,
      type: String,
      default: "",
    },
    urlLinkedin: {
      required: true,
      type: String,
      default: "javascript:void(0)",
    },
  },
  computed: {
    client() {
      return config.CLIENT;
    },
  },
  methods: {
    handleTracking() {
      if (
        config.CLIENT === "one" &&
        config.RUNTIME_ENV === "production" &&
        "lintrk" in window
      ) {
        window.lintrk("track", { conversion_id: 4403868 });
      }
    },
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss">
.atom-contact {
  position: relative;

  .atom-gradient-background {
    position: relative;
    z-index: 5;
    padding: 1px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8911939775910365) 0%,
      rgba(255, 255, 255, 0.5634628851540616) 50%,
      $blackOpacity00 100%
    );

    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }

    .content-info {
      padding: 0.938rem 0.313rem;
      background: $contactColor;
      @media only screen and (max-width: 767px) {
        padding: 15px 0;
      }
      &.same-height {
        min-height: 11rem;
        @media only screen and (max-width: 767px) {
          min-height: 0;
        }
      }

      .col {
        padding: 0 0.625rem;
        @media only screen and (max-width: 767px) {
          padding: 0 10px;
        }
      }

      .col-5 {
        min-height: 3.75rem;
        justify-content: center;
        @media only screen and (max-width: 767px) {
          min-height: auto;
        }
      }

      .col-12 {
        margin-top: 0.438rem;
        @media only screen and (max-width: 767px) {
          margin-top: 10px;
        }

        span,
        a {
          font-weight: 300;
          font-size: 12px;
          line-height: 1.125rem;
          color: #ffffff;
          text-decoration: none;
          display: inline-block;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      .inline-block {
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.625rem;
        @media only screen and (max-width: 767px) {
          margin-right: 5px;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .name {
        color: $white;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25rem;
        max-width: 9.063rem;
        text-transform: uppercase;
        display: block;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 20px;
          max-width: 300px;
        }
      }

      .icon-transparent {
        width: 2.063rem;
        height: 2.063rem;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(4px);
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 767px) {
          width: 30px;
          height: 30px;
        }

        .icon {
          font-size: 1.25rem !important;
          @media only screen and (max-width: 767px) {
            font-size: 18px !important;
          }
        }
      }

      .phone-icon {
        .nr-hover {
          position: absolute;
          display: block;
          background: $main-color;
          border-radius: 0.188rem;
          right: 2.5rem;
          color: $white;
          font-size: 0.875rem;
          letter-spacing: 1px;
          font-weight: 500;
          overflow: hidden;
          max-width: 0;
          padding: 0;
          transition: all 0.2s ease-in-out 0s;
          @media only screen and (max-width: 1200px) {
            display: none !important;
          }
        }

        &:hover {
          .nr-hover {
            max-width: 9.375rem;
            padding: 0.5rem 0.938rem;
          }
        }
      }
    }
  }
}
</style>
