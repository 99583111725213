var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "owl-dot col4 atom-content-thumb-slider-brochure",
      on: { click: _vm.handleClick },
    },
    [_c("span", { staticClass: "line" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }