var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "molecule-image-thumb-slider",
      attrs: { id: "owl-custom-dots" },
    },
    _vm._l(_vm.thumbImages, function (sliderImage, index) {
      return _c("AtomImageThumbSlider", {
        key: index,
        attrs: { "slider-image": sliderImage, "active-thumb": _vm.activeThumb },
        on: { click: _vm.handleThumbChange },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }