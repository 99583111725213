var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "organism-sidebar-accordion",
      class: { active: _vm.isMobileMenuOpen },
    },
    [
      _c(
        "div",
        { staticClass: "space-results" },
        [
          _c("MoleculeSidebarAccordionResults", {
            attrs: {
              "pins-info": _vm.pinsInfo,
              "sidebar-title": _vm.sidebarTitle,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }