var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.enabled !== false
    ? _c(
        "div",
        {
          class:
            ((_obj = {
              section: true,
              "section-parking": true,
            }),
            (_obj["section" + _vm.orderNo] = true),
            (_obj.topPadding = _vm.data.heading || _vm.data.description),
            _obj),
        },
        [
          _c("a", {
            staticClass: "navigation-target",
            attrs: { id: _vm.hash, href: "javascript:void(0);" },
          }),
          _vm.data.heading || _vm.data.description
            ? _c(
                "div",
                { staticClass: "top-title" },
                [
                  _c("AtomTitle", {
                    attrs: {
                      title: _vm.data.heading,
                      subtitle: _vm.data.description,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("OrganismParkingAccess", {
            attrs: {
              "parking-access": _vm.data.items,
              "image-url": _vm.data.image,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }