<template>
  <div class="organism-explore">
    <MoleculeExplore>
      <div class="row row-card-explore">
        <div
          class="col col-xs-12 col-4"
          v-for="(card, index) in exploreCard"
          :key="index"
        >
          <div class="card-explore" :class="{ 'url-hover': card.url }">
            <a v-if="card.url" class="link" :href="`${card.url}`"></a>
            <span class="time" v-if="card.time"
              ><AtomIcon icon="walking" /> {{ card.time }}</span
            >
            <div
              class="background-image"
              :style="{ 'background-image': `url(${resourceUrl(card.image)})` }"
            ></div>
            <div class="background-gradient"></div>
            <img
              :src="resourceUrl('images/placeholder-for-image.png')"
              alt="Placeholder"
            />
            <div :class="{ 'bottom-title': true, 'text-left': card.time }">
              <h6 v-html="card.title"></h6>
              <div class="content" v-if="card.text" v-html="card.text"></div>
            </div>
          </div>
        </div>
      </div>
    </MoleculeExplore>
  </div>
</template>

<script>
import MoleculeExplore from "../../molecules/common/MoleculeExplore";
import AtomIcon from "../../atoms/common/AtomIcon";
export default {
  name: "OrganismExplore",
  components: { AtomIcon, MoleculeExplore },
  props: {
    exploreCard: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
  },
};
</script>

<style lang="scss">
.organism-explore {
  .row-card-explore {
    margin: 0 -0.438rem;
    @media only screen and (max-width: 767px) {
      margin: 0;
    }
    .col {
      padding: 0 0.438rem;
      margin-bottom: 0.875rem;
      @media only screen and (max-width: 767px) {
        padding: 0;
        margin-bottom: 10px;
      }
    }
    .card-explore {
      position: relative;
      overflow: hidden;
      .link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
      }
      .background-image {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in-out 0s;
      }
      img {
        display: block;
        width: 100%;
      }
      .background-gradient {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          $blackOpacity00 30%,
          $blackOpacity09 85%
        );
        z-index: 3;
        transition: all 0.3s ease-in-out 0s;
      }
      .time {
        position: absolute;
        left: 1.5rem;
        top: 1.5rem;
        z-index: 5;
        padding: 0.438rem 0.75rem;
        background: $blackOpacity04;
        border-radius: 6.25rem;
        color: $white;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.063rem;
        @media only screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 15px;
          border-radius: 100px;
          padding: 5px 8px;
          left: 10px;
          top: 10px;
        }
        .icon {
          font-size: 1.063rem !important;
          color: #fff !important;
          margin-right: 0.375rem;
          @media only screen and (max-width: 767px) {
            font-size: 12px !important;
            margin-right: 2px;
          }
        }
      }
      .bottom-title {
        position: absolute;
        bottom: 0.875rem;
        width: 100%;
        padding: 0 1.5rem;
        z-index: 5;
        text-align: center;
        @media only screen and (max-width: 767px) {
          bottom: 10px;
          padding: 0 10px;
        }
        &.text-left {
          text-align: left;
        }
        h6 {
          color: $white;
          font-weight: 400;
          font-size: 1.375rem;
          line-height: 1.625rem;
          @media only screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 24px;
          }
          b {
            font-size: 1.375rem;
            line-height: 1.625rem;
            @media only screen and (max-width: 767px) {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
        .content {
          font-weight: 400;
          margin-top: 0.5rem;
          font-size: 1rem;
          line-height: 1.188rem;
          color: $whiteOpacity08;
          max-height: 4.875rem;
          overflow: hidden;
          overflow-y: auto;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 17px;
            margin-top: 4px;
            max-height: 78px;
          }
          h5 {
            text-transform: uppercase;
            margin-bottom: 0.25rem;
            @media only screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 4px;
            }
          }
          p {
            margin-bottom: 0.5rem;
            @media only screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 5px;
            }
          }
          a {
            color: $white;
            text-decoration: underline !important;
            font-weight: $medium;
            @media only screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 17px;
            }
          }
        }
      }
      &:hover {
        .background-image {
          width: 110%;
          height: 110%;
        }
        .background-gradient {
          background: linear-gradient(180deg, $blackOpacity00 10%, $black 85%);
        }
      }
      &.url-hover {
        .background-gradient {
          background: linear-gradient(
            180deg,
            $blackOpacity00 44.25%,
            $blackOpacity08 85.42%
          );
        }
        &:hover {
          .background-gradient {
            background: linear-gradient(
              180deg,
              rgba(20, 50, 117, 0) 0%,
              rgba(20, 50, 117, 0.6) 85.42%
            );
          }
        }
      }
    }
  }
}
</style>
