<template>
  <component
    :is="componentVariant"
    :data="data"
    :hash="hash"
    :order-no="orderNo"
  />
</template>

<script>
import SectionParkingV1 from "./parking/SectionParkingV1";
import SectionParkingV2 from "./parking/SectionParkingV2";
export default {
  name: "SectionParking",
  props: {
    variant: {
      required: true,
      type: String,
      default: "",
    },
    data: {
      required: true,
      type: Object | Array,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      componentVariant: null,
    };
  },
  created() {
    this.handleVariant(this.variant);
  },
  watch: {
    variant: function (newVariant, oldVariant) {
      this.handleVariant(newVariant);
    },
  },
  methods: {
    handleVariant(variant) {
      switch (variant) {
        case "v2":
          this.componentVariant = SectionParkingV2;
          break;
        default:
          this.componentVariant = SectionParkingV1;
          break;
      }
    },
  },
};
</script>

<style lang="scss"></style>
