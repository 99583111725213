var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "parentFloorRef",
        staticClass: "parent-floor row-floor",
        class: _vm.floor.isFullyLeased ? "action-disabled" : "activated-floor",
        attrs: { id: _vm.building.code + "_" + _vm.floor.code },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleFloorClick(_vm.building.code, _vm.floor.code)
          },
        },
      },
      [
        _c("div", { staticClass: "clearfix" }, [
          _c("div", { staticClass: "column2" }, [
            _c("h6", { staticClass: "get-space-floor space-floor" }, [
              _vm._v(_vm._s(_vm.building.name)),
            ]),
          ]),
          _c("div", { staticClass: "column2 text-right" }, [
            _c("span", { staticClass: "when-text floor-number" }, [
              _vm._v("Floor "),
              _c("span", [_vm._v(_vm._s(_vm.floor.no))]),
            ]),
          ]),
        ]),
        !_vm.floor.isFullyLeased
          ? _c("div", { staticClass: "clearfix" }, [
              _c("div", { staticClass: "column1" }, [
                _c("span", { staticClass: "when-text db" }, [
                  _c("span", { staticClass: "nr-cube" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.floor.spaces.filter(function (s) {
                            return (
                              (s.available &&
                                s.visibility_status !== "hidden") ||
                              s.visibility_status === "available"
                            )
                          }).length
                        ) +
                        "\n          "
                    ),
                  ]),
                  _vm._v("\n          Options Available\n        "),
                ]),
                _vm.surfaceMultiplier && ![0, 1].includes(_vm.surfaceMultiplier)
                  ? _c("span", { staticClass: "sqm-gla-total" }, [
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            (
                              Number(_vm.floor.surface) -
                              Number(_vm.floor.surface) *
                                Number(_vm.surfaceMultiplier)
                            ).toFixed(2)
                          )
                        ),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.unitOfMeasureString) +
                          " " +
                          _vm._s(_vm.surfaceName) +
                          "\n        "
                      ),
                    ])
                  : _c("span", { staticClass: "sqm-gla-total" }, [
                      _c("b", [_vm._v(_vm._s(_vm.floor.surface))]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.unitOfMeasureString) +
                          " GLA\n        "
                      ),
                    ]),
                _c(
                  "span",
                  {
                    staticClass: "arrow-custom",
                    class: { "rotate-icon": _vm.isSpaceListExpanded },
                  },
                  [_c("AtomIcon", { attrs: { icon: "sort-down", size: 15 } })],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.floor.isFullyLeased
          ? _c("div", { staticClass: "leased-overlay" }, [
              _c("span", [_vm._v("Floor Fully Leased")]),
            ])
          : _vm._e(),
      ]
    ),
    !_vm.floor.isFullyLeased
      ? _c(
          "div",
          {
            staticClass: "show-all-spaces",
            class: { active: _vm.isSpaceListExpanded },
          },
          _vm._l(_vm.floorSpaces, function (space, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "row-floor row-floor-active",
                class: {
                  active: space.isActive,
                  "leased-element-style":
                    !space.available && space.visibility_status !== "available",
                },
                attrs: {
                  "data-building": _vm.building.code,
                  "data-floor": _vm.floor.code,
                  "data-floor-model": _vm.floor.code,
                  "data-space": space.space_code,
                  "data-unit": space.space,
                  "data-type": space.type,
                  "data-active": space,
                },
              },
              [
                _c("span", {
                  staticClass: "trigger-click-view-space",
                  on: {
                    click: function ($event) {
                      return _vm.viewOrHideSpace(
                        space.space_code,
                        _vm.building.code,
                        _vm.floor.code,
                        _vm.floor.floor_type.model,
                        space
                      )
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "parent-general-row-floor",
                    attrs: { "data-unit": space.id },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "general-dow-floor",
                        attrs: { "data-unit": space.id },
                      },
                      [
                        _c("div", { staticClass: "clearfix" }, [
                          _c("div", { staticClass: "column1" }, [
                            !_vm.spaceIsRequested(space.id)
                              ? _c(
                                  "span",
                                  { staticClass: "line-element" },
                                  [
                                    _c("AtomIcon", {
                                      attrs: { icon: "minus" },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  { staticClass: "line-element line-checked" },
                                  [_c("AtomIcon", { attrs: { icon: "ok" } })],
                                  1
                                ),
                            _vm.surfaceMultiplier &&
                            ![0, 1].includes(_vm.surfaceMultiplier)
                              ? _c("span", { staticClass: "get-sqm db" }, [
                                  _c("b", { staticClass: "nr-sqm" }, [
                                    _vm._v(
                                      _vm._s(
                                        (
                                          Number(space.sqm) -
                                          Number(space.sqm) *
                                            Number(_vm.surfaceMultiplier)
                                        ).toFixed(2)
                                      )
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.unitOfMeasureString) +
                                      " " +
                                      _vm._s(_vm.surfaceName) +
                                      "\n              "
                                  ),
                                ])
                              : _c("span", { staticClass: "get-sqm db" }, [
                                  _c("b", { staticClass: "nr-sqm" }, [
                                    _vm._v(_vm._s(space.sqm)),
                                  ]),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.unitOfMeasureString) +
                                      " GLA\n              "
                                  ),
                                ]),
                            _vm.showGeneratedNumberOfPeople
                              ? _c(
                                  "span",
                                  { staticClass: "get-nr-people db" },
                                  [
                                    _c("b", { staticClass: "max-people" }, [
                                      _vm._v(_vm._s(space.people)),
                                    ]),
                                    _vm._v(" People"),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              { staticClass: "icon view-space icon-lock" },
                              [
                                _c("AtomIcon", {
                                  attrs: { icon: "eye", size: 15 },
                                }),
                                _vm._v(" view space"),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "icon hide-space icon-lock" },
                              [
                                _c("AtomIcon", {
                                  attrs: { icon: "eye-slash", size: 15 },
                                }),
                                _vm._v(" hide space"),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        !space.available &&
                        space.visibility_status !== "available"
                          ? _c("div", { staticClass: "leased-overlay" }, [
                              _c("span", [_vm._v("LEASED")]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "clearfix" }, [
                          _c("div", { staticClass: "hover-buttons" }, [
                            _c("div", { staticClass: "row row-padding-11px" }, [
                              _c(
                                "div",
                                { staticClass: "col-6 col-padding-4px" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "icon icon-edit",
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exploreSpace(
                                            space.space_code,
                                            _vm.building.code,
                                            _vm.floor.code,
                                            _vm.floor.floor_type.model,
                                            space
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("AtomIcon", {
                                        attrs: { icon: "cube-1", size: 15 },
                                      }),
                                      _vm._v(
                                        "\n                    3D Explore"
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6 col-padding-4px" },
                                [
                                  _c("div", { staticClass: "wrapper-btn" }, [
                                    !_vm.spaceIsRequested(space.id)
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "icon icon-select select-show",
                                            attrs: {
                                              href: "javascript:void(0)",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addSpaceToCart(
                                                  space,
                                                  _vm.building,
                                                  _vm.floor
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("AtomIcon", {
                                              attrs: {
                                                icon: "list-checked",
                                                size: 15,
                                              },
                                            }),
                                            _vm._v(
                                              "\n                      Add to my list"
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "a",
                                          {
                                            staticClass:
                                              "icon icon-select btn-remove-item",
                                            attrs: {
                                              href: "javascript:void(0)",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeSpaceFromCart(
                                                  space,
                                                  _vm.building,
                                                  _vm.floor
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("- Remove from list")]
                                        ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _vm.getCombinedSpace(space.can_combine_with_id)
                          ? _c("div", { staticClass: "combine" }, [
                              _c("p", [
                                _vm._v(
                                  "\n              Can be combined with the studio on\n              " +
                                    _vm._s(
                                      _vm.getCombinedSpace(
                                        space.can_combine_with_id
                                      ).floor.floor_type.name
                                    ) +
                                    "\n            "
                                ),
                              ]),
                              _c("div", { staticClass: "clearfix" }, [
                                _c("div", { staticClass: "hover-buttons" }, [
                                  _c(
                                    "div",
                                    { staticClass: "row row-padding-11px" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-6 col-padding-4px",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "icon icon-edit",
                                              attrs: {
                                                href: "javascript:void(0)",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.exploreSpace(
                                                    _vm.getCombinedSpace(
                                                      space.can_combine_with_id
                                                    ).space_code,
                                                    _vm.building.code,
                                                    _vm.getCombinedSpace(
                                                      space.can_combine_with_id
                                                    ).floor.code,
                                                    _vm.getCombinedSpace(
                                                      space.can_combine_with_id
                                                    ).floor.floor_type.model,
                                                    _vm.getCombinedSpace(
                                                      space.can_combine_with_id
                                                    )
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("AtomIcon", {
                                                attrs: {
                                                  icon: "cube-1",
                                                  size: 15,
                                                },
                                              }),
                                              _vm._v(
                                                "\n                      3D Explore"
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-6 col-padding-4px",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "wrapper-btn" },
                                            [
                                              !_vm.spaceIsRequested(
                                                _vm.getCombinedSpace(
                                                  space.can_combine_with_id
                                                ).id
                                              )
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "icon icon-select select-show",
                                                      attrs: {
                                                        href: "javascript:void(0)",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.addBothSpacesToCart(
                                                            space,
                                                            _vm.getCombinedSpace(
                                                              space.can_combine_with_id
                                                            ),
                                                            _vm.building,
                                                            _vm.floor,
                                                            _vm.getCombinedSpace(
                                                              space.can_combine_with_id
                                                            ).floor
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("AtomIcon", {
                                                        attrs: {
                                                          icon: "list-checked",
                                                          size: 15,
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                        Add both to my list"
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "icon icon-select btn-remove-item",
                                                      attrs: {
                                                        href: "javascript:void(0)",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.removeBothSpacesFromCart(
                                                            space,
                                                            _vm.getCombinedSpace(
                                                              space.can_combine_with_id
                                                            ),
                                                            _vm.building,
                                                            _vm.floor,
                                                            _vm.getCombinedSpace(
                                                              space.can_combine_with_id
                                                            ).floor
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "- Remove both from list"
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }