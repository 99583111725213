const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
    }
    return "desktop";
};

const isMobile = () => {
    return deviceType() === "mobile";
}

const isTablet = () => {
    return deviceType() === "tablet";
}

const isDesktop = () => {
    return deviceType() === "desktop";
}

export {
    deviceType,
    isTablet,
    isMobile,
    isDesktop
}