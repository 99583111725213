var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "organism-image-slider": true,
        "no-videos": !(_vm.hasImages && _vm.hasVideos),
      },
    },
    [
      _c("MoleculeImageSlider", {
        ref: "slider",
        attrs: { "slider-data": _vm.sliderData, "type-of": _vm.typeOf },
        on: { initialized: _vm.handleCarouselInitialized },
      }),
      _c(
        "div",
        { staticClass: "bottom-thumb" },
        [
          _c("div", { staticClass: "row row-brochure align-items-center" }, [
            _c("div", { staticClass: "col col-xs-12 col-6" }, [
              _c("div", { staticClass: "title-brochure" }, [
                _c("h4", [_vm._v("Gallery")]),
                _vm.sliderData.brochureData
                  ? _c("div", { staticClass: "button-change" }, [
                      _c(
                        "a",
                        {
                          staticClass: "icon-element",
                          attrs: {
                            href:
                              "" + _vm.resourceUrl(_vm.sliderData.brochureData),
                            target: "_blank",
                          },
                        },
                        [
                          _c("AtomIcon", {
                            attrs: {
                              icon: "file-arrow-down",
                              size: 20,
                              color: "#fff",
                            },
                          }),
                          _c("span", { staticClass: "title" }, [
                            _vm._v("Download Brochure"),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "col col-xs-12 col-6 text-right" }, [
              _vm.hasImages && _vm.hasVideos
                ? _c("div", { staticClass: "change-carousel" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-change photoButton active",
                        attrs: { "data-type": "image" },
                        on: { click: _vm.handlePhotoClick },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "icon-element" },
                          [
                            _c("AtomIcon", {
                              attrs: {
                                icon: "picture",
                                size: 20,
                                color: "#fff",
                              },
                            }),
                            _c("span", { staticClass: "title" }, [
                              _vm._v("Photo"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-change videoButton",
                        attrs: { "data-type": "video" },
                        on: { click: _vm.handleVideoClick },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "icon-element" },
                          [
                            _c("AtomIcon", {
                              attrs: { icon: "video", size: 20, color: "#fff" },
                            }),
                            _c("span", { staticClass: "title" }, [
                              _vm._v("Video"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.carouselInitialized
            ? _c("MoleculeImageThumbSlider", {
                ref: "thumbSlider",
                attrs: { thumbnails: _vm.thumbData, typeOf: _vm.typeOf },
                on: { "thumb-change": _vm.handleThumbChange },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }