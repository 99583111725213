<template>
    <div class="atom-icon-and-text">
      <div class="box-content">
        <div class="border-color" :style="`border-color: ${borderColor}`">
          <img v-if="iconImage" :src="resourceUrl(iconImage)" :alt="`icon ${title}`">
          <AtomIcon v-else :icon="icon" />
        </div>
        <div v-if="hasTitle" class="title" v-html="title"></div>
        <div v-if="hasDescription" class="description" v-html="description"></div>
      </div>
    </div>
</template>

<script>
import AtomIcon from "./AtomIcon";
export default {
  name: "AtomIconAndText",
  components: {AtomIcon},
  props: {
    borderColor: {
      required: false,
      type: String,
      default: '#000'
    },
    icon: {
      required: false,
      type: String,
      default: 'eye'
    },
    iconImage: {
      required: false,
      type: String,
      default: ''
    },
    title: {
      required: false,
      type: String,
      default: ''
    },
    description: {
      required: false,
      type: String,
      default: ''
    },
  },
  computed: {
    hasTitle() {
      return this.title && this.title !== ''
    },
    hasDescription() {
      return this.description && this.description !== ''
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    }
  }
}
</script>

<style lang="scss">
  .atom-icon-and-text {
    .box-content {
      max-width: 8.75rem;
      margin: 0 auto 2.5rem;
      @media only screen and (max-width: 767px) {
        margin: 0 auto 20px;
        max-width: 130px;
      }
      .border-color {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8.75rem;
        height: 8.75rem;
        border: 0.5rem solid transparent;
        border-radius: 8.75rem;
        margin: 0 auto 1rem;
        @media only screen and (max-width: 767px) {
          width: 90px;
          height: 90px;
          border: 4px solid transparent;
          border-radius: 90px;
          margin: 0 auto 10px;
        }
        .icon {
          font-size: 2.5rem !important;
          color: $blue !important;
          &:before {
            margin: 0;
            width: auto;
          }
          @media only screen and (max-width: 767px) {
            font-size: 25px !important;
          }
        }
      }
      .title {
        font-weight: 400;
        font-size: 1.375rem;
        line-height: 1.625rem;
        text-align: center;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
</style>