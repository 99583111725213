import { CameraManager } from "@/components/organisms/project/building/3D/core/managers/CameraManager";
import {
  Matrix,
  PointerEventTypes,
  PointerInfo,
  Scene,
  Vector3,
} from "babylonjs";
import Constants, { SCENE_VIEW } from "../builders/Constants";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
import { Utils } from "../builders/Utils";

export class EventManager {
  cameraManager: CameraManager;
  pointerCount: number;
  sceneManager = BabylonClientManager.getSceneManager();
  constructor(cameraManager: CameraManager) {
    this.cameraManager = cameraManager;
    this.pointerCount = 0;
  }

  pointerEvents = (pointerInfo: PointerInfo) => {
    switch (pointerInfo.type) {
      case PointerEventTypes.POINTERDOWN:
        this.onPointerDown(pointerInfo);
        break;

      case PointerEventTypes.POINTERUP:
        this.onPointerUp(pointerInfo);
        break;

      case PointerEventTypes.POINTERMOVE:
        this.onPointerMove(pointerInfo);
        break;

      case PointerEventTypes.POINTERPICK:
        this.onPointerPick(pointerInfo);
        break;

      default:
        break;
    }
  };

  onPointerDown(pointerInfo: PointerInfo) {
    this.sceneManager?.renderScene();
    const pickinfo = pointerInfo.pickInfo;
    if (pickinfo?.hit) {
      if (pickinfo?.pickedMesh?.name !== "pin") {
        this.sceneManager?.pinManager?.unselectPin();
      }
    } else {
      this.sceneManager?.pinManager?.unselectPin();
    }
    if (this.sceneManager?.actualSceneView === SCENE_VIEW.FIRST_PERSON) {
      this.pointerCount++;
    } else {
      this.sceneManager?.scene?.stopAllAnimations();
    }
  }

  onPointerUp(pointerInfo: PointerInfo) {
    if (this.sceneManager?.actualSceneView === SCENE_VIEW.FIRST_PERSON) {
      this.sceneManager?.scene?.onAfterRenderObservable.addOnce(() => {
        this.pointerCount = 0;
      });
    }
  }

  onPointerMove(pointerInfo: PointerInfo) {
    if (this.sceneManager?.actualSceneView === SCENE_VIEW.FIRST_PERSON) {
      this.sceneManager?.renderScene(-1);
      if (this.pointerCount > 1) return;

      const scene = this.sceneManager?.scene as Scene;
      if (pointerInfo.pickInfo) {
        let pickedPoint = pointerInfo.pickInfo.pickedPoint;
        this.sceneManager?.minimap?.minimapPointer?.togglePointer(true);
        if (this.checkIfMinimap(scene)) {
          pickedPoint = this.getPickedPointFromMinimap(scene, false);
          const hit = Utils.getMeshUnderneath(scene, pickedPoint);
          if (!hit || (hit && hit.length < 2)) {
            this.sceneManager?.minimap?.minimapPointer?.togglePointer(false);
          }
        }

        if (pickedPoint) {
          this.sceneManager?.pointerCircle?.move(
            pickedPoint.x,
            this.sceneManager?.pointerCircle.yPos,
            pickedPoint.z
          );
          this.sceneManager?.minimap?.minimapPointer?.move(
            pickedPoint.x,
            Constants.POINTER_POSITIONY,
            pickedPoint.z
          );
        }
      }
    }
  }

  onPointerPick(pointerInfo: PointerInfo) {
    if (this.sceneManager?.actualSceneView === SCENE_VIEW.FIRST_PERSON) {
      this.sceneManager?.renderScene();
      if (this.pointerCount > 1) return;
      const scene = this.sceneManager?.scene as Scene;
      if (pointerInfo.pickInfo) {
        let pickedPoint = pointerInfo.pickInfo.pickedPoint;
        if (this.checkIfMinimap(scene)) {
          pickedPoint = this.getPickedPointFromMinimap(scene, true);
          if (pickedPoint.equals(Vector3.Zero())) return;
          const hit = Utils.getMeshUnderneath(scene, pickedPoint);
          if (!hit || (hit && hit.length < 2)) return;
        } else {
          if (pointerInfo.pickInfo.pickedMesh) {
            if (this.sceneManager.isCameraMovementValid(pointerInfo.pickInfo))
              return;
          }
        }

        if (pickedPoint && this.sceneManager.cameraFirstPerson) {
          this.sceneManager.cameraManager?.moveUniversalCamera(
            {
              target: {
                x: pickedPoint.x,
                y: this.sceneManager.cameraFirstPerson.metadata.y,
                z: pickedPoint.z,
              },
            },
            4
          );
        }
      }
    }
  }

  private checkIfMinimap(scene: Scene) {
    const pointerX = scene.pointerX;
    const pointerY = scene.pointerY;
    const viewport = this.sceneManager?.minimap?.htmlViewport;
    const dimensions = this.sceneManager?.minimap?.dimensions;
    let isMinimap = false;
    if (viewport && dimensions) {
      if (
        pointerX >= viewport.x &&
        pointerX <= viewport.x + viewport.width &&
        pointerY <= dimensions[1] - viewport.y &&
        pointerY >= dimensions[1] - (viewport.y + viewport.height)
      ) {
        isMinimap = true;
      }
    }

    return isMinimap;
  }

  private getPickedPointFromMinimap(scene: Scene, checkIntersection: boolean) {
    const htmlViewport = this.sceneManager?.minimap?.htmlViewport;
    const viewport = this.sceneManager?.minimap?.viewportCamera.viewport;
    const dimensions = this.sceneManager?.minimap?.dimensions;
    const camera = this.sceneManager?.minimap?.minimapRTT.activeCamera;
    let pickedPoint = Vector3.Zero();
    if (viewport && dimensions && camera && htmlViewport) {
      const relativeX = (scene.pointerX - htmlViewport?.x) / viewport?.width;
      const relativeY =
        (scene.pointerY -
          (dimensions[1] - htmlViewport?.y - htmlViewport?.height)) /
        viewport?.height;

      // check locked area intersection only on pointerDown
      if (checkIntersection) {
        const minimapRay = scene.createPickingRay(
          relativeX,
          relativeY,
          Matrix.Identity(),
          camera,
          false
        );
        const hit = scene.pickWithRay(minimapRay);
        if (hit && hit.pickedMesh) {
          if (this.sceneManager?.isCameraMovementValid(hit)) return pickedPoint;
        }
      }

      pickedPoint = Vector3.Unproject(
        new Vector3(relativeX, relativeY, 0),
        dimensions[0],
        dimensions[1],
        Matrix.Identity(),
        camera.getViewMatrix(),
        camera.getProjectionMatrix()
      );
    }

    return pickedPoint;
  }
}
