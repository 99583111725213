<template>
  <div
    :class="{
      section: true,
      'section-specs-v2': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="row title-top">
      <div class="col col-xs-12 col-8">
        <AtomTitle :title="data.title" :subtitle="data.subtitle" />
      </div>
      <div class="col col-xs-12 col-4">
        <div class="text-right">
          <div class="tabs">
            <div
              class="tab-trigger"
              v-for="(tab, i) in data.tabs"
              :class="{ active: activeTab === tab.tabId }"
              @click="handleTabChange(tab.tabId)"
              :key="i"
            >
              {{ tab.tabLabel }}
              <AtomIcon icon="active-line" />
            </div>
            <AtomToggle v-model="toggled" />
          </div>
        </div>
      </div>
    </div>
    <div v-for="(tab, i) in data.tabs" :key="i">
      <div v-if="activeTab === tab.tabId">
        <OrganismTabsTechnicalSpecsV2 :sub-tabs="tab.subTabs">
          <a
            v-if="hasResourcesModal"
            class="button-atom"
            :href="`${resourceUrl(data.resources)}`"
            target="_blank"
            ><span>Download Resources</span></a
          >
        </OrganismTabsTechnicalSpecsV2>
      </div>
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
import AtomIcon from "../../../../components/atoms/common/AtomIcon";
import OrganismTabsTechnicalSpecsV2 from "../../../../components/organisms/project/building/OrganismTabsTechnicalSpecsV2";
import AtomToggle from "../../../../components/atoms/common/AtomToggle";

export default {
  name: "SectionSpecsV2",
  components: { AtomToggle, OrganismTabsTechnicalSpecsV2, AtomIcon, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  data() {
    let activeTab = "";
    if (this.data.tabs && this.data.tabs.length) {
      activeTab = this.data.tabs[0].tabId;
    }
    return {
      activeTab,
      toggled: false,
    };
  },
  computed: {
    hasResourcesModal() {
      return false;
    },
  },
  beforeUpdate() {
    if (this.data.tabs && this.data.tabs.length && !this.activeTab) {
      this.activeTab = this.data.tabs[0].tabId;
    }
  },
  methods: {
    handleTabChange(tabId) {
      this.activeTab = tabId;
      this.toggled = tabId === "exterior";
    },
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
  watch: {
    toggled: function (value) {
      this.activeTab = value ? "exterior" : "interior";
    },
  },
};
</script>

<style lang="scss">
.section-specs-v2 {
  padding-top: 3.75rem;
  @media only screen and (max-width: 767px) {
    padding-top: 22px;
  }
  .title-top {
    @media only screen and (max-width: 767px) {
      margin-bottom: 10px;
    }
    .atom-title {
      margin-bottom: 0.5rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
    .text-right {
      @media only screen and (max-width: 767px) {
        text-align: center;
      }
    }
    .tabs {
      position: relative;
      display: inline-block;
      margin-top: 1rem;
      @media only screen and (max-width: 767px) {
        margin: 10px 0 20px;
      }
      .tab-trigger {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-weight: $regular;
        font-size: 1.375rem;
        line-height: 1.625rem;
        color: $blueDark;
        transition: all 0.2s ease-in-out 0s;
        cursor: pointer;
        @media only screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 22px;
        }
        .icon {
          opacity: 0;
          font-size: 0.313rem !important;
          color: $blue !important;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -0.938rem;
          transition: all 0.2s ease-in-out 0s;
          @media only screen and (max-width: 767px) {
            font-size: 4px !important;
            bottom: -13px;
          }
          &:before {
            width: auto;
            margin: 0;
          }
        }
        &:first-child {
          margin-right: 6rem;
          @media only screen and (max-width: 767px) {
            margin-right: 96px;
          }
        }
        &:hover,
        &.active {
          color: $blue;
          .icon {
            opacity: 1;
          }
        }
      }
    }
  }
  .tabs-elements {
    position: relative;
    border-bottom: 1px solid #ecf5f8;
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
    .sub-tabs {
      white-space: nowrap;
      overflow-x: auto;
      padding-bottom: 1px;
      .sub-tab-trigger {
        cursor: pointer;
        display: inline-block;
        vertical-align: bottom;
        font-weight: $bold;
        font-size: 1.125rem;
        line-height: 1.313rem;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out 0s;
        margin-right: 1.875rem;
        border-bottom: 0.25rem solid transparent;
        padding-bottom: 0.313rem;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 19px;
          margin-right: 20px;
          border-bottom: 3px solid transparent;
          padding-bottom: 4px;
        }
        &:hover,
        &.active {
          color: $blue;
        }
        &.active {
          border-color: $blue;
        }
      }
    }
    .button-atom {
      background: transparent;
      padding: 0.75rem 2rem;
      font-weight: $regular;
      border-color: $blue;
      margin-bottom: 0.313rem;
      @media only screen and (max-width: 767px) {
        padding: 8px 15px;
        position: absolute;
        right: 0;
        top: 10px;
      }
      span {
        color: $black;
        text-transform: none;
      }
      &:hover {
        background: $blue;
        span {
          color: $white;
        }
      }
    }
  }
  .statements {
    position: relative;
    overflow: hidden;
    padding: 2rem 0;
    @media only screen and (max-width: 767px) {
      padding: 0 0 25px;
    }
    & > .col {
      position: static;
    }
    .statement {
      h2 {
        margin-bottom: 1rem;
        font-size: 2.75rem;
        line-height: 3.25rem;
        @media only screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 23px;
        }
      }
      h3 {
        color: $black !important;
      }
    }
    .icon-quotation-mark {
      font-size: 11.75rem !important;
      opacity: 0.04;
      position: absolute;
      left: 0;
      top: 0.938rem;
      @media only screen and (max-width: 767px) {
        font-size: 120px !important;
        left: 0;
        top: 4px;
      }
      &:before {
        width: auto;
        margin: 0;
      }
    }
    .person-info {
      margin-top: 1.5rem;
      @media only screen and (max-width: 767px) {
        margin-top: 16px;
      }
      h5 {
        font-weight: $bold;
        font-size: 1.375rem;
        line-height: 1.625rem;
        margin-bottom: 0.188rem;
        @media only screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 3px;
        }
      }
      h6 {
        font-weight: $bold;
        font-size: 1rem;
        line-height: 1.375rem;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
  .image-technical-specs {
    position: relative;
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
