import {
  Scene,
  Mesh,
  AbstractMesh,
  TransformNode,
  Vector3,
  Ray,
} from "babylonjs";
import Constants from "./Constants";

export class Utils {
  static checkSpaceName(name: string) {
    return name.split(";");
  }

  static formatSpaceName(name: string[]) {
    let realName = name[0];
    for (let i = 1; i < name.length; i++) {
      realName = realName + "_" + name[i];
    }
    return realName;
  }

  static setSceneProperties(scene: Scene, options: unknown) {
    Object.assign(scene, options);
  }

  static setNodeProperties(
    objects: string[],
    scene: Scene,
    options: Record<string, unknown>
  ) {
    for (const object of objects) {
      const node = scene.getNodeByName(object);
      if (!node) return console.warn("Node not defined", object);
      const meshes = node.getChildMeshes();
      for (const mesh of meshes) {
        Object.assign(mesh, options);
      }
    }
  }

  static toggleNodes(nodes: TransformNode[] | Mesh[], isEnabled: boolean) {
    for (const node of nodes) {
      node.setEnabled(isEnabled);
    }
  }

  static setMeshesProperties(meshes: Mesh[], options: Record<string, unknown>) {
    for (const mesh of meshes) {
      Object.assign(mesh, options);
    }
  }

  static setDefaultMeshProps(mesh: AbstractMesh) {
    mesh.freezeWorldMatrix();
    if (mesh.material) {
      mesh.material.freeze();
      mesh.isPickable = false;
      mesh.enablePointerMoveEvents = false;
      mesh.layerMask = Constants.MESH_LAYER_MASK;
      mesh.doNotSyncBoundingInfo = Constants.DO_NOT_SYNC_BB;
    }
  }

  static findNode(nodeName: string, scene: Scene) {
    const node = scene.getNodeByName(nodeName);
    if (!node) {
      console.warn("Mesh not found " + nodeName);
      return null;
    }
    return node;
  }

  static mergeMeshes(meshes: Mesh[]) {
    return Mesh.MergeMeshes(meshes, false);
  }

  static updateSceneProperties(scene: Scene, val: boolean) {
    scene.skipFrustumClipping = val;
    scene.autoClear = val;
    scene.autoClearDepthAndStencil = val;
  }

  static convertToCamel(word: string) {
    return word.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
  }

  static getMeshUnderneath(scene: Scene, position: Vector3) {
    return scene.multiPickWithRay(
      new Ray(position, new Vector3(0, -1, 0), 100)
    );
  }

  static throttleFunction(callback: () => void, frequency: number) {
    let lastRunTime = 0;
    return function () {
      const currentTime = performance.now();
      if (currentTime - lastRunTime < frequency) return;

      lastRunTime = currentTime;
      callback();
    };
  }
}
