<template>
  <div class="atom-statements-slider slide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AtomStatementsSlider",
  props: {}
}
</script>

<style lang="scss" scoped>
  .atom-statements-slider {
    position: relative;
  }
</style>