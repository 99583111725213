var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section section-contact",
      class: ((_obj = {}), (_obj["section" + _vm.orderNo] = true), _obj),
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _c("OrganismContactForm"),
      _vm.data.socialTitle ||
      _vm.data.instagramUrl ||
      _vm.data.linkedinUrl ||
      _vm.data.facebookUrl ||
      _vm.data.youtubeUrl ||
      _vm.data.title ||
      _vm.data.subtitle
        ? _c("div", { staticClass: "title-contacts" }, [
            _c("div", { staticClass: "wrapper-title-contacts" }, [
              _vm.data.title || _vm.data.subtitle
                ? _c(
                    "div",
                    [
                      _c("AtomTitle", {
                        attrs: {
                          title: _vm.data.title,
                          subtitle: _vm.data.subtitle,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data.socialTitle ||
              _vm.data.instagramUrl ||
              _vm.data.linkedinUrl ||
              _vm.data.facebookUrl ||
              _vm.data.youtubeUrl
                ? _c("div", [
                    _c("div", { staticClass: "social" }, [
                      _vm.data.socialTitle
                        ? _c("div", {
                            staticClass: "title",
                            domProps: {
                              innerHTML: _vm._s(_vm.data.socialTitle),
                            },
                          })
                        : _vm._e(),
                      _vm.data.instagramUrl
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.data.instagramUrl,
                                target: "_blank",
                              },
                            },
                            [
                              _c("AtomIcon", {
                                attrs: {
                                  icon: "instagram",
                                  size: 40,
                                  color: "#FFF",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.data.linkedinUrl
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.data.linkedinUrl,
                                target: "_blank",
                              },
                            },
                            [
                              _c("AtomIcon", {
                                attrs: {
                                  icon: "linkedin-squared",
                                  size: 40,
                                  color: "#FFF",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.data.facebookUrl
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.data.facebookUrl,
                                target: "_blank",
                              },
                            },
                            [
                              _c("AtomIcon", {
                                attrs: {
                                  icon: "facebook-squared",
                                  size: 40,
                                  color: "#FFF",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.data.youtubeUrl
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.data.youtubeUrl,
                                target: "_blank",
                              },
                            },
                            [
                              _c("AtomIcon", {
                                attrs: {
                                  icon: "youtube-play",
                                  size: 40,
                                  color: "#FFF",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _c("OrganismTeam", { attrs: { "team-data": _vm.data.contacts } }),
      _vm.data.stickyText
        ? _c("div", { staticClass: "sticky-contact" }, [
            _c("div", { staticClass: "image-element" }, [
              _c("img", {
                attrs: {
                  src: _vm.resourceUrl(_vm.randomContact.image),
                  alt: _vm.randomContact.name,
                },
              }),
            ]),
            _vm.data.stickyText
              ? _c("div", { staticClass: "contact-info" }, [
                  _c("h5", [_vm._v(_vm._s(_vm.randomContact.name))]),
                  _c("p", [_vm._v(_vm._s(_vm.data.stickyText))]),
                ])
              : _vm._e(),
            _vm.data.stickyButtonText
              ? _c(
                  "div",
                  { staticClass: "button-anchor" },
                  [
                    _c("AtomButton", {
                      attrs: {
                        label: _vm.data.stickyButtonText,
                        "on-click": _vm.goToNextSection,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }