<template>
  <div class="loader-element">
    <div class="percent-content">
      <p>Loading 3D assets, please don’t close the page</p>
      <span class="number-percent">{{ percentage }}%</span>
      <span class="line-main-color"></span>
    </div>
    <span class="line-percent" :style="{ width: `${percentage}%` }"></span>
  </div>
</template>

<script>
import buildingStore from "../../organisms/project/building/store";
export default {
  name: "AtomLoaderLoadingBar",
  data() {
    return {
      percentage: 0,
      seconds: 30,
      maxPercentage: 90,
      interval: 0,
      closeInterval: 0,
      closeTimeRemaining: 0,
      startedCountdown: false,
    };
  },
  computed: {
    buildingStore() {
      return buildingStore;
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      const percentageChange = Math.floor(
        Math.random() * Math.round(this.maxPercentage / this.seconds) + 1
      );
      let percentage = this.percentage;
      percentage += percentageChange;
      if (percentage > this.maxPercentage) {
        this.percentage = 99;
        clearInterval(this.interval);
        this.interval = 0;
        // this.startCloseCountdown();
        return;
      }
      this.percentage = percentage;
    }, 1000);
    this.buildingStore.subscribe((payload, state) => {
      if (state.finishedLoadingAssets && this.percentage < 100) {
        clearInterval(this.interval);
        this.interval = 0;
        this.percentage = 100;
        this.startCloseCountdown();
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.closeInterval);
  },
  methods: {
    closeLoadingScreen() {
      this.buildingStore.dispatch("changeLoadingScreenStatus", false);
      clearInterval(this.interval);
      clearInterval(this.closeInterval);
    },
    startCloseCountdown() {
      this.closeInterval = setInterval(() => {
        this.closeLoadingScreen();
        clearInterval(this.closeInterval);
        this.closeInterval = 0;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-element {
  position: absolute;
  width: 100%;
  height: 100%;
  .percent-content {
    position: absolute;
    top: 6.563rem;
    left: 3.75rem;
    z-index: 5;
    p {
      opacity: 0.6;
      margin-bottom: 0.313rem;
      @include font-main(1rem, $white, $regular, 1.188rem);
    }
    .number-percent {
      display: block;
      margin-bottom: 1rem;
      @include font-main(6.25rem, $white, $bold, 7.438rem);
    }
    .line-main-color {
      display: block;
      width: 6.25rem;
      height: 1px;
      background: $second-color;
    }
  }
  .line-percent {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background: $second-color;
    opacity: 0.5;
  }
  @media only screen and (max-width: 1200px) {
    .percent-content {
      top: 60px;
      left: 0;
      padding: 0 20px;

      p {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 17px;
      }

      .number-percent {
        margin-bottom: 15px;
        font-size: 100px;
        line-height: 119px;
      }

      .line-main-color {
        width: 100px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .percent-content {
      top: 40px;
      p {
        margin-bottom: 4px;
        font-size: 12px;
        line-height: 15px;
      }
      .number-percent {
        margin-bottom: 5px;
        font-size: 40px;
        line-height: 45px;
      }
      .line-main-color {
        width: 100px;
      }
    }
  }
}
</style>
