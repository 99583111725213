<template>
  <div :class="{section: true, 'section-specs': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <AtomImageTitle :image="data.image">
      <AtomTitle color2="#fff" :title="data.title" :subtitle="data.subtitle" />
    </AtomImageTitle>
    <OrganismTechnicalSpecs :bullet-technical-data="data.items" :image-specs="data.imageSpecs" />
  </div>
</template>

<script>
import AtomImageTitle from "../../../../components/atoms/common/AtomImageTitle";
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
import OrganismTechnicalSpecs from "../../../../components/organisms/project/OrganismTechnicalSpecs";
export default {
  name: "SectionSpecsV1",
  components: {OrganismTechnicalSpecs, AtomTitle, AtomImageTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  }
}
</script>

<style lang="scss">
  .section-specs {
    .atom-title {
      h2 {
        font-weight: $medium;
        font-size: 0.875rem;
        line-height: 1.063rem;
        letter-spacing: 0.05em;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 17px;
        }
      }
      h3 {
        margin-top: 0.5rem;
        font-weight: $medium;
        font-size: 1.375rem;
        line-height: 1.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 22px;
          line-height: 26px;
          margin-top: 8px;
        }
      }
    }
    .image-technical-specs {
      position: relative;
      img {
        display: block;
        width: 100%;
      }
    }
  }
</style>