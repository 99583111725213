var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "lavContainer",
    staticClass: "atom-lottie-animation",
    style: {
      width: this.width ? this.width + "px" : "100%",
      height: this.height ? this.height + "px" : "100%",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }