import { render, staticRenderFns } from "./MoleculeCertifications.vue?vue&type=template&id=496601a1&scoped=true&"
import script from "./MoleculeCertifications.vue?vue&type=script&lang=js&"
export * from "./MoleculeCertifications.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeCertifications.vue?vue&type=style&index=0&id=496601a1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496601a1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('496601a1')) {
      api.createRecord('496601a1', component.options)
    } else {
      api.reload('496601a1', component.options)
    }
    module.hot.accept("./MoleculeCertifications.vue?vue&type=template&id=496601a1&scoped=true&", function () {
      api.rerender('496601a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/common/MoleculeCertifications.vue"
export default component.exports