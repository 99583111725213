<template>
  <div
    class="molecule-filters-pins-3d"
    v-if="
      allPinsData.length > 0 &&
      uniquePinCategoryData[0].category !== '' &&
      uniquePinCategoryData[0].category !== undefined &&
      uniquePinCategoryData[0].category
    "
  >
    <div
      class="category-filter"
      v-if="defaultMode !== ''"
      :class="{ active: defaultMode === '' }"
      @click="filterPins3D('')"
    >
      <div class="icon-element">
        <AtomIcon icon="pin-all" />
      </div>
      <p>See All</p>
    </div>
    <div
      class="category-filter"
      v-if="defaultMode === ''"
      :class="{ active: defaultMode === 'none' }"
      @click="filterPins3D('none')"
    >
      <div class="icon-element">
        <AtomIcon icon="eye-slash" />
      </div>
      <p>Hide</p>
    </div>
    <div
      class="category-filter"
      v-for="(pin, index) in uniquePinCategoryData"
      :key="index"
      :class="{
        active: defaultMode === pin.category,
        [`hover-${pin.category}`]: `hover-${pin.category}`,
      }"
      @click="filterPins3D(pin.category)"
    >
      <div class="icon-element">
        <AtomIcon :icon="`pin-${pin.category}`" />
      </div>
      <p>{{ pin.category | replace("_", " ") }}</p>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../../atoms/common/AtomIcon";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
export default {
  name: "MoleculeFiltersPins3D",
  components: { AtomIcon },
  data() {
    return {
      defaultMode: "",
    };
  },
  filters: {
    replace: function (st, rep, repWith) {
      const result = st.split(rep).join(repWith);
      return result;
    },
  },
  computed: {
    allPinsData() {
      return (
        this.$store.getters?.getActiveProjectSettings?.building?.pinsData
          ?.pins || []
      );
    },
    uniquePinCategoryData() {
      const uniquePinCategory = [
        ...new Map(
          this.allPinsData.map((item) => [item["category"], item])
        ).values(),
      ];
      return uniquePinCategory;
    },
  },
  methods: {
    filterPins3D(category) {
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.showPins(category);
      }
      this.defaultMode = category;
    },
  },
};
</script>

<style lang="scss" scoped>
.molecule-filters-pins-3d {
  position: absolute;
  left: 1.25rem;
  bottom: 1.875rem;
  z-index: 9;
  padding: 0.75rem 0.625rem;
  background: $white;
  box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  @media only screen and (max-width: 1200px) {
    left: 0.625rem;
    padding: 0.5rem 0.25rem;
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
  .category-filter {
    text-align: center;
    display: inline-block;
    white-space: nowrap;
    margin: 0 0.375rem;
    cursor: pointer;
    @media only screen and (max-width: 1200px) {
      display: block;
      white-space: initial;
      margin: 0 0 0.75rem 0;
      &:last-child {
        margin: 0;
      }
    }
    .icon-element {
      width: 2.5rem;
      height: 2.5rem;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 6.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 0.25rem;
      transition: all 0.2s ease-in-out 0s;
      .icon {
        font-size: 1.1rem !important;
        color: $black !important;
        transition: all 0.2s ease-in-out 0s;
        &.icon-pin-all,
        &.eye-slash {
          font-size: 0.8rem !important;
        }
        &:before {
          width: auto;
          margin: 0;
        }
      }
    }
    p {
      @include font-main(0.75rem, $black, $medium, 0.875rem);
      letter-spacing: -0.04rem;
      text-transform: capitalize;
      @media only screen and (max-width: 1200px) {
        @include font-main(0.625rem, $black, $medium, 0.75rem);
      }
    }
    &.hover-building {
      .icon-element {
        .icon {
          color: #4c51c6 !important;
        }
      }
    }
    &.hover-parking {
      .icon-element {
        .icon {
          color: #e97e1b !important;
        }
      }
    }
    &.hover-energy {
      .icon-element {
        .icon {
          color: #51b4ff !important;
        }
      }
    }
    &.hover-green_areas {
      .icon-element {
        .icon {
          color: #4cc66d !important;
        }
      }
    }
    &.hover-certifications {
      .icon-element {
        .icon {
          color: #e9af1b !important;
        }
      }
    }
    &.hover-features {
      .icon-element {
        .icon {
          color: #f57a7a !important;
        }
      }
    }
    &:hover {
      .icon-element {
        background: rgba(0, 0, 0, 0.2);
        @media only screen and (max-width: 1200px) {
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &.hover-building {
        .icon-element {
          background: rgba(76, 81, 198, 0.2);
          @media only screen and (max-width: 1200px) {
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
      &.hover-parking {
        .icon-element {
          background: rgba(233, 126, 27, 0.2);
          @media only screen and (max-width: 1200px) {
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
      &.hover-energy {
        .icon-element {
          background: rgba(81, 180, 255, 0.2);
          @media only screen and (max-width: 1200px) {
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
      &.hover-green_areas {
        .icon-element {
          background: rgba(76, 198, 109, 0.2);
          @media only screen and (max-width: 1200px) {
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
      &.hover-certifications {
        .icon-element {
          background: rgba(233, 175, 27, 0.2);
          @media only screen and (max-width: 1200px) {
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
      &.hover-features {
        .icon-element {
          background: rgba(245, 122, 122, 0.2);
          @media only screen and (max-width: 1200px) {
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }
    &.active {
      .icon-element {
        background: $black;
        .icon {
          color: $white !important;
        }
      }
      &.hover-building {
        .icon-element {
          background: #4c51c6 !important;
        }
      }
      &.hover-parking {
        .icon-element {
          background: #e97e1b !important;
        }
      }
      &.hover-energy {
        .icon-element {
          background: #51b4ff !important;
        }
      }
      &.hover-green_areas {
        .icon-element {
          background: #4cc66d !important;
        }
      }
      &.hover-certifications {
        .icon-element {
          background: #e9af1b !important;
        }
      }
      &.hover-features {
        .icon-element {
          background: #f57a7a !important;
        }
      }
    }
  }
}
</style>
