<template>
  <div class="organism-list-circle">
    <MoleculeListCircle>
      <AtomListCircle v-for="(list, index) in listCircleData" :title="list.title" :text="list.text" :key="index" />
    </MoleculeListCircle>
  </div>
</template>

<script>
import MoleculeListCircle from "../../molecules/common/MoleculeListCircle";
import AtomListCircle from "../../atoms/portfolio/AtomListCircle";
export default {
  name: "OrganismListCircle",
  components: {AtomListCircle, MoleculeListCircle},
  props: {
    listCircleData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .organism-list-circle {
    display: flex;
    flex-direction: column;
    flex: 100%;
  }
</style>