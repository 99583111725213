var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-building": true,
          "embed-ready":
            _vm.isEmbedReady || (_vm.isEmbedV2 && _vm.isMobileView),
          "matterport-space-view": _vm.addMatterportClass,
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
      style: {
        height: "calc(" + _vm.windowHeight + "px - " + _vm.headerHeight + "px)",
      },
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _vm.loadingScreenActive
        ? _c(
            "div",
            {
              staticClass: "organism-loader-background",
              style: {
                "background-image":
                  "url(" + _vm.resourceUrl(_vm.backgroundLoaderUrl) + ")",
              },
            },
            [
              _vm.isFloorView
                ? _c("div", { staticClass: "loader-video-background" }, [
                    _vm.currentSpaceVideoLoaderUrl
                      ? _c(
                          "video",
                          {
                            key: "video-" + _vm.currentSpace.building_id,
                            staticClass: "video-loader",
                            staticStyle: { "object-fit": "cover !important" },
                            attrs: {
                              muted: "",
                              autoplay: "",
                              playsinline: "",
                              loop: "",
                              preload: "metadata",
                            },
                            domProps: { muted: true },
                          },
                          [
                            _c("source", {
                              attrs: {
                                src: _vm.resourceUrl(
                                  _vm.currentSpaceVideoLoaderUrl
                                ),
                                type: "video/mp4",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c("OrganismLoader", {
                attrs: { "content-loader": _vm.contentLoaderData },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("OrganismBuilding", { ref: "building" }),
      !_vm.availableSection &&
      !_vm.isFloorView &&
      (!_vm.isEmbedReady || (_vm.isEmbedReady && _vm.isMobileMenuOpen))
        ? _c("OrganismSidebarBuilding", { ref: "sidebar" })
        : _vm._e(),
      !_vm.availableSection &&
      !_vm.isFloorView &&
      ((!_vm.isEmbedReady && !_vm.isEmbedV2) ||
        (_vm.isEmbedReady && _vm.embedCartIsOpen) ||
        (_vm.isEmbedV2 && !_vm.isMobileView) ||
        (_vm.isEmbedV2 && _vm.isMobileView && _vm.embedCartIsOpen))
        ? _c("OrganismCartSpacesBuilding")
        : _vm._e(),
      !_vm.isFloorView
        ? _c(
            "div",
            { ref: "sliderComponent", staticClass: "slider-wrapper-building" },
            [
              _c(
                "span",
                { staticClass: "plus", on: { click: _vm.increaseZoomValue } },
                [_c("AtomIcon", { attrs: { size: 16, icon: "zoom-in" } })],
                1
              ),
              _c("div", { staticClass: "slider-parent" }, [
                _c("div", { attrs: { id: "slider" } }, [
                  _c("i", { staticClass: "line line1" }),
                  _c(
                    "div",
                    {
                      ref: "dragger",
                      staticClass: "ui-slider-handle",
                      staticStyle: { bottom: "0" },
                      attrs: { id: "dragger", draggable: "true" },
                      on: {
                        drag: _vm.handleDrag,
                        dragstart: _vm.handleDragStart,
                      },
                    },
                    [_vm._v("\n          ZOOM\n        ")]
                  ),
                ]),
              ]),
              _c(
                "span",
                { staticClass: "minus", on: { click: _vm.decreaseZoomValue } },
                [_c("AtomIcon", { attrs: { size: 16, icon: "zoom-out" } })],
                1
              ),
            ]
          )
        : _vm._e(),
      !_vm.isFloorView ? _c("OrganismSelectedSpaceMobile") : _vm._e(),
      !_vm.availableSection &&
      !_vm.isFloorView &&
      ((_vm.isEmbedReady && !_vm.isSpaceEmbedRoute) ||
        (_vm.isEmbedV2 && _vm.isMobileView))
        ? _c("div", { staticClass: "embed-controls" }, [
            _c(
              "div",
              {
                staticClass: "control embed-menu-trigger",
                class: { active: _vm.isMobileMenuOpen },
                on: { click: _vm.toggleEmbedSidebar },
              },
              [_vm._m(0)]
            ),
            _c(
              "div",
              {
                staticClass: "control embed-cart-trigger",
                class: { active: _vm.embedCartIsOpen },
                on: { click: _vm.toggleCartSidebar },
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("span", { staticClass: "custom-arrow" }),
                  _c("p", { staticClass: "label green-color" }, [
                    _vm._v("Offer & Space List"),
                  ]),
                  _c(
                    "p",
                    { staticClass: "text" },
                    [
                      _c("AtomIcon", { attrs: { icon: "ruler" } }),
                      _c("b", [_vm._v(_vm._s(_vm.totalSpace))]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.unitOfMeasureString) +
                          " GLA\n        "
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _c("OrganismSpace", {
        attrs: { "matterport-space": _vm.data.matterportSpace },
      }),
      !_vm.isFloorView && !_vm.isEmbedReady
        ? _c("MoleculeFiltersPins3D")
        : _vm._e(),
      !_vm.isEmbedReady && !_vm.isEmbedV2
        ? _c(
            "span",
            {
              class: {
                "scroll-element": true,
                "scroll-element-white": _vm.loadingScreenActive,
              },
              on: { click: _vm.goToNextSection },
            },
            [
              _c(
                "span",
                { staticClass: "icon-scroll-down" },
                [
                  _c("AtomIcon", {
                    attrs: { icon: "angle-down", size: 17, color: "#fff" },
                  }),
                ],
                1
              ),
              _vm._v("\n    Scroll down for more information\n  "),
            ]
          )
        : _vm._e(),
      !_vm.isEmbedReady && !_vm.isEmbedV2
        ? _c("div", { staticClass: "bright-logo" }, [
            _c(
              "a",
              {
                attrs: { href: "https://brightspaces.tech/", target: "_blank" },
              },
              [_vm._v("\n      Created by © BrightSpaces\n    ")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _c("span", { staticClass: "custom-arrow" }),
      _c("p", { staticClass: "label" }, [_c("b", [_vm._v("Spaces")])]),
      _c("p", { staticClass: "text" }, [_vm._v("Check Availability")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }