var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.handleClickOutside,
          expression: "handleClickOutside",
        },
      ],
      staticClass: "organism-cart-spaces-building",
    },
    [
      _c(
        "div",
        {
          class: {
            "cart-spaces": true,
            clearfix: true,
            active:
              _vm.isOpen ||
              _vm.isEmbedReady ||
              (_vm.isEmbedV2 && _vm.isMobileView),
          },
        },
        [
          _c("div", { staticClass: "floor-info clearfix" }, [
            _c(
              "div",
              {
                staticClass:
                  "row cart-trigger row-padding-5px align-items-center",
              },
              [
                _c("div", { staticClass: "col col-8 col-padding-10px" }, [
                  _c("span", { staticClass: "list-info" }, [
                    _c("span", { staticClass: "nr-cube nr-cube-white" }, [
                      _vm._v(_vm._s(_vm.spacesCounter)),
                    ]),
                    _vm._v("\n            Spaces in your list"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col col-4 col-padding-10px text-right" },
                  [
                    _c("span", { staticClass: "arrow-text" }, [
                      _c(
                        "span",
                        {
                          staticClass: "text",
                          on: { click: _vm.toggleOpenState },
                        },
                        [
                          _c("span", { staticClass: "disabled-text" }, [
                            _vm._v("View List"),
                          ]),
                          _c("span", { staticClass: "active-text" }, [
                            _vm._v("Hide"),
                          ]),
                        ]
                      ),
                      _c("span", { staticClass: "custom-arrow" }),
                    ]),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "all-spaces-selected clearfix" }, [
              _c("div", { staticClass: "row spaces-selected-info" }, [
                _c("div", { staticClass: "col col-12" }, [
                  _c(
                    "div",
                    { staticClass: "add-content clearfix" },
                    _vm._l(_vm.requestedSpaces, function (space, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "background-space-white clearfix space-floor-selected",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row row-padding-5px align-items-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col col-6 col-padding-10px" },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(space.building.name)),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col col-6 col-padding-10px text-right",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "when-text floor-number" },
                                    [
                                      _vm._v("Floor\n                      "),
                                      _c("span", { staticClass: "nr-cube" }, [
                                        _vm._v(_vm._s(space.floor.no)),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row row-padding-5px align-items-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col col-9 col-padding-10px" },
                                [
                                  _c("div", { staticClass: "content-info" }, [
                                    _vm.surfaceMultiplier &&
                                    ![0, 1].includes(_vm.surfaceMultiplier)
                                      ? _c(
                                          "span",
                                          { staticClass: "result-sqm" },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  (
                                                    Number(space.sqm) -
                                                    Number(space.sqm) *
                                                      Number(
                                                        _vm.surfaceMultiplier
                                                      )
                                                  ).toFixed(2)
                                                ) + "\n                        "
                                              ),
                                            ]),
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.unitOfMeasureString
                                                ) +
                                                " " +
                                                _vm._s(_vm.surfaceName)
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "result-sqm" },
                                          [
                                            _c("b", [
                                              _vm._v(_vm._s(space.sqm)),
                                            ]),
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.unitOfMeasureString
                                                ) +
                                                " GLA"
                                            ),
                                          ]
                                        ),
                                    _vm.showGeneratedNumberOfPeople
                                      ? _c(
                                          "span",
                                          { staticClass: "result-max-people" },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getSpacePeople(space.sqm)
                                                )
                                              ),
                                            ]),
                                            _vm._v(" People"),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col col-3 col-padding-10px text-right",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "btn-remove-item" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn-trash",
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeSpaceFromCart(
                                                space
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("AtomIcon", {
                                            attrs: {
                                              icon: "trash-can",
                                              size: 14,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "result-all-spaces clearfix" }, [
              _c(
                "div",
                {
                  staticClass:
                    "row cart-trigger row-padding-5px align-items-center",
                },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "col col-9 col-padding-10px text-right" },
                    [
                      _c("div", { staticClass: "content-info" }, [
                        _vm.surfaceMultiplier &&
                        ![0, 1].includes(_vm.surfaceMultiplier)
                          ? _c(
                              "span",
                              { staticClass: "result-sqm" },
                              [
                                _c("AtomIcon", {
                                  attrs: { icon: "ruler", size: 14 },
                                }),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      (
                                        Number(_vm.totalSpace) -
                                        Number(_vm.totalSpace) *
                                          Number(_vm.surfaceMultiplier)
                                      ).toFixed(2)
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.unitOfMeasureString) +
                                    " " +
                                    _vm._s(_vm.surfaceName)
                                ),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              { staticClass: "result-sqm" },
                              [
                                _c("AtomIcon", {
                                  attrs: { icon: "ruler", size: 14 },
                                }),
                                _c("b", [_vm._v(_vm._s(_vm.totalSpace))]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.unitOfMeasureString) +
                                    " GLA"
                                ),
                              ],
                              1
                            ),
                        _vm.showGeneratedNumberOfPeople
                          ? _c(
                              "span",
                              { staticClass: "result-max-people" },
                              [
                                _c("AtomIcon", {
                                  attrs: { icon: "group", size: 14 },
                                }),
                                _c("b", [_vm._v(_vm._s(_vm.totalPeople))]),
                                _vm._v(" People"),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "row row-padding-5px align-items-center" },
                [
                  _c("div", { staticClass: "col col-12 col-padding-10px" }, [
                    _c(
                      "a",
                      {
                        class: {
                          "btn-green": true,
                          disabled: _vm.spacesCounter === 0,
                        },
                        attrs: {
                          id: "request-offer-btn",
                          href: "javascript:void(0)",
                        },
                        on: { click: _vm.openRequestOfferModal },
                      },
                      [_vm._v("Request Offer")]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      (!_vm.isEmbedReady && !_vm.isEmbedV2) ||
      (_vm.isEmbedV2 && !_vm.isMobileView)
        ? _c(
            "div",
            {
              class: {
                "offer-spaces-list": true,
                "hidden-desktop-elements": true,
                active: _vm.isOpen,
              },
              on: { click: _vm.toggleOpenState },
            },
            [
              _vm._m(1),
              _c("div", { staticClass: "content-element" }, [
                _c(
                  "span",
                  { staticClass: "result-sqm" },
                  [
                    _c("AtomIcon", { attrs: { icon: "ruler", size: 18 } }),
                    _c("b", [_vm._v(_vm._s(_vm.totalSpace))]),
                    _vm._v(
                      "\n        " + _vm._s(_vm.unitOfMeasureString) + " GLA"
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col col-3 col-padding-10px" }, [
      _c("span", [_vm._v("Total Space")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-element" }, [
      _c("span", [_vm._v("Offer & Space List")]),
      _c("span", { staticClass: "custom-arrow" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }