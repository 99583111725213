var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "list-bullets" },
    _vm._l(_vm.lines, function (line, index) {
      return _c("li", { key: index, domProps: { innerHTML: _vm._s(line) } })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }