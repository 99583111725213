var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-facts-parent" }, [
    _c(
      "div",
      { staticClass: "row atom-facts" },
      _vm._l(_vm.facts, function (fact, index) {
        return _c("div", { key: index, staticClass: "col col-xs-12 col-4" }, [
          _c("div", { staticClass: "box-fact" }, [
            _c("h5", { domProps: { innerHTML: _vm._s(fact.title) } }),
            _c("p", { domProps: { innerHTML: _vm._s(fact.content) } }),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }