<template>
  <div
    :class="{
      section: true,
      'section-info-and-image': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="row title-and-list align-items-center">
      <div class="col col-12">
        <div class="custom-logo" v-if="data.customLogo">
          <img :src="data.customLogo" alt="" />
        </div>
      </div>
      <div class="col col-xs-12 col-7">
        <div class="top-title">
          <AtomTitle :title="data.title" :subtitle="data.subtitle" />
        </div>
      </div>
      <div class="col col-xs-12 col-5">
        <AtomLinesBullets :lines="data.lines" />
      </div>
    </div>
    <div class="image-full">
      <img :src="`${resourceUrl(data.image)}`" alt="Image Full Size" />
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";
import AtomLinesBullets from "../../components/atoms/common/AtomLinesBullet";

export default {
  name: "SectionInfoAndImage",
  components: { AtomLinesBullets, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss">
.section-info-and-image {
  padding: 7.5rem 0 0;
  @media only screen and (max-width: 767px) {
    padding: 40px 10px 0;
  }

  .custom-logo {
    margin: 25px 0;
  }

  .title-and-list {
    padding: 0 3.75rem;
    margin: 0 -0.813rem 7.5rem;
    @media only screen and (max-width: 767px) {
      margin: 0 0 40px;
      padding: 0;
    }
    & > .col {
      padding: 0 0.813rem;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
    }
  }
  .atom-title {
    max-width: 49.688rem;
    @media only screen and (max-width: 767px) {
      max-width: 100%;
      margin-bottom: 20px;
    }
    h2 {
      margin-bottom: 1.25rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }
  .image-full {
    @media only screen and (max-width: 767px) {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -20px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
