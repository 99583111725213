import { PointerCircle } from "@/components/organisms/project/building/3D/core/builders/PointerCircle";
import {
  ArcRotateCamera,
  Vector3,
  Scene,
  RenderTargetTexture,
  StandardMaterial,
  Mesh,
  Camera,
  MeshBuilder,
  Color3,
  Tools,
  Viewport,
  float,
  TransformNode,
  Texture,
} from "babylonjs";
import Constants from "@/components/organisms/project/building/3D/core/builders/Constants";

export class MinimapViewport {
  viewportCamera: ArcRotateCamera;
  minimapRatio = 0;
  scene: Scene;
  htmlViewport?: Viewport;
  minimapRTT: RenderTargetTexture;
  mapPlane?: Mesh;
  minimapCameraPosition: Mesh;
  minimapCameraCone: Mesh;
  dimensions: float[];
  minimapPointer: PointerCircle = new PointerCircle();
  minimapHolder: TransformNode;
  constructor(scene: Scene, floorName: string) {
    this.scene = scene;

    this.dimensions = this.calculateDimensions();
    this.minimapHolder = new TransformNode("minimapHolder", this.scene);

    this.viewportCamera = new ArcRotateCamera(
      Constants.VIEWPORT_CAMERA_NAME,
      Math.PI / 2,
      0,
      60,
      Vector3.Zero(),
      this.scene
    );
    this.viewportCamera.parent = this.minimapHolder;

    this.minimapRTT = new RenderTargetTexture(
      "depth",
      512,
      this.scene,
      false,
      true
    );
    this.init(floorName);
    // Add the minimap camera position
    this.minimapCameraPosition = MeshBuilder.CreateDisc(
      "minimapPosition",
      {
        radius: 0.5,
      },
      this.scene
    );
    const minimapPositionMaterial = new StandardMaterial(
      "minimapPositionMaterial",
      this.scene
    );
    minimapPositionMaterial.emissiveColor = new Color3(1, 1, 1);
    minimapPositionMaterial.freeze();
    this.minimapCameraPosition.rotation.x = Math.PI / 2;
    this.minimapCameraPosition.layerMask = Constants.MINIMAP_LAYER_MASK;
    this.minimapCameraPosition.material = minimapPositionMaterial;
    this.minimapCameraPosition.isPickable = false;
    this.minimapCameraPosition.setParent(this.minimapHolder);

    this.minimapCameraCone = MeshBuilder.CreateDisc(
      "minimapCone",
      {
        radius: 6,
        tessellation: 24,
        arc: 1 / 5,
      },
      this.scene
    );
    this.minimapCameraCone.rotation.x = Math.PI / 2;
    this.minimapCameraCone.layerMask = Constants.MINIMAP_LAYER_MASK;
    this.minimapCameraCone.material = minimapPositionMaterial;
    this.minimapCameraCone.visibility = 0.5;
    this.minimapCameraCone.isPickable = false;
    this.minimapCameraCone.setParent(this.minimapHolder);

    // Add pointer for the minimap
    this.minimapPointer.init(
      Constants.POINTER_CIRCLE_SIZE * 2,
      this.scene,
      "minimapVirtualTourPointer",
      Constants.MINIMAP_LAYER_MASK
    );
    this.minimapPointer.setParent(this.minimapHolder);
    this.toggle(false);

    return this;
  }

  init(floorName: string) {
    if (!this.scene) return console.error("Scene not defined");
    const boundingBoxMainElement = this.scene
      .getTransformNodeByName(floorName)
      ?.getHierarchyBoundingVectors();
    if (!boundingBoxMainElement) return console.error("Floor name not found");

    this.viewportCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
    this.viewportCamera.orthoTop = boundingBoxMainElement.min.z * -1;
    this.viewportCamera.orthoBottom = boundingBoxMainElement.max.z * -1;
    this.viewportCamera.orthoLeft = boundingBoxMainElement.max.x * -1;
    this.viewportCamera.orthoRight = boundingBoxMainElement.min.x * -1;

    this.minimapRatio =
      (boundingBoxMainElement.max.x - boundingBoxMainElement.min.x) /
      (boundingBoxMainElement.max.z - boundingBoxMainElement.min.z);

    // Minimap RTT camera
    const minimapCamera = new ArcRotateCamera(
      "minimapCamera",
      Math.PI / 2,
      0,
      60,
      Vector3.Zero(),
      this.scene
    );
    minimapCamera.layerMask = Constants.MESH_LAYER_MASK;
    minimapCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
    minimapCamera.orthoTop = boundingBoxMainElement.min.z * -1;
    minimapCamera.orthoBottom = boundingBoxMainElement.max.z * -1;
    minimapCamera.orthoLeft = boundingBoxMainElement.max.x * -1;
    minimapCamera.orthoRight = boundingBoxMainElement.min.x * -1;

    this.viewportCamera.customRenderTargets.push(this.minimapRTT);

    this.minimapRTT.wrapU = Texture.CLAMP_ADDRESSMODE;
    this.minimapRTT.wrapV = Texture.CLAMP_ADDRESSMODE;
    this.minimapRTT.activeCamera = minimapCamera;
    this.minimapRTT.refreshRate = -1;
    this.minimapRTT.hasAlpha = true;

    // Create minimap plane
    this.mapPlane = MeshBuilder.CreatePlane(
      Constants.MINIMAP_PLANE_NAME,
      {
        width: boundingBoxMainElement.max.x - boundingBoxMainElement.min.x,
        height: boundingBoxMainElement.max.z - boundingBoxMainElement.min.z,
        sideOrientation: 2,
      },
      this.scene
    );

    this.mapPlane.rotation.x = Math.PI / 2;
    this.mapPlane.rotation.y = Math.PI;
    this.mapPlane.position = new Vector3(
      (boundingBoxMainElement.max.x + boundingBoxMainElement.min.x) / 2,
      0,
      (boundingBoxMainElement.max.z + boundingBoxMainElement.min.z) / 2
    );

    const mapMat = new StandardMaterial("texturePlane", this.scene);
    mapMat.emissiveTexture = this.minimapRTT;
    mapMat.opacityTexture = this.minimapRTT;
    mapMat.disableLighting = true;

    this.mapPlane.material = mapMat;
    this.mapPlane.layerMask = Constants.MINIMAP_LAYER_MASK;
    this.viewportCamera.layerMask = Constants.MINIMAP_LAYER_MASK;
    this.mapPlane.freezeWorldMatrix();
    this.mapPlane.setParent(this.minimapHolder);
    this.resizeMinimap();

    minimapCamera.dispose();
  }

  toggle(enabled: boolean) {
    this.mapPlane?.setEnabled(enabled);
    this.minimapCameraPosition.setEnabled(enabled);
    this.minimapCameraCone.setEnabled(enabled);
    this.minimapPointer.togglePointer(enabled);
  }

  calculateDimensions() {
    const engine = this.scene.getEngine();
    const hardwareScaling = engine.getHardwareScalingLevel();
    const canvasWidth = engine.getRenderWidth(true) * hardwareScaling;
    const canvasHeight = engine.getRenderHeight(true) * hardwareScaling;
    return [canvasWidth, canvasHeight];
  }

  resizeMinimap() {
    this.dimensions = this.calculateDimensions();

    const windowMin = Math.min(window.innerWidth, window.innerHeight);
    const maxWidth = 400;
    const minWidth = 200;
    const maxHeight = 300;

    let minimapWidth =
      minWidth + ((maxWidth - minWidth) * (windowMin - 320)) / (1080 - 320);
    minimapWidth = Math.min(Math.max(minimapWidth, minWidth), maxWidth);

    const minimapHeight = minimapWidth / this.minimapRatio;
    if (minimapHeight > maxHeight) {
      minimapWidth = maxHeight * this.minimapRatio;
    }

    const marginTop = minimapWidth > 300 ? 30 : 15;
    const marginRight = minimapWidth > 300 ? 30 : 15;

    this.viewportCamera.viewport.width = minimapWidth / this.dimensions[0];
    this.viewportCamera.viewport.height =
      (this.viewportCamera.viewport.width / this.minimapRatio) *
      (this.dimensions[0] / this.dimensions[1]);
    this.viewportCamera.viewport.x =
      1 - minimapWidth / this.dimensions[0] - marginRight / this.dimensions[0];
    this.viewportCamera.viewport.y =
      1 - this.viewportCamera.viewport.height - marginTop / this.dimensions[1];

    this.htmlViewport = this.viewportCamera.viewport.toGlobal(
      this.dimensions[0],
      this.dimensions[1]
    );
  }

  updateMinimap() {
    this.minimapRTT.renderList = this.scene.meshes.filter((mesh) => {
      return (
        mesh.layerMask === Constants.MESH_LAYER_MASK &&
        mesh.name !== Constants.SKYBOX_NAME &&
        mesh.isEnabled() &&
        mesh.isVisible &&
        mesh.visibility !== 0
      );
    });

    this.minimapRTT.refreshRate = -1;
    this.minimapRTT.render(false);
  }

  updatePositions(cameraPosition: Vector3, rotation: number, roofOffset = 25) {
    this.minimapCameraPosition.position.set(
      cameraPosition.x,
      cameraPosition.y + roofOffset,
      cameraPosition.z
    );
    this.minimapCameraCone.position.set(
      cameraPosition.x,
      cameraPosition.y + roofOffset,
      cameraPosition.z
    );
    this.minimapCameraCone.rotation.y = Tools.ToRadians(306) + rotation;
  }
}
