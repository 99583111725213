<template>
  <div
    :class="{
      section: true,
      'section-post-and-image': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="row title-and-content align-items-center">
      <div class="col col-12">
        <div class="custom-logo" v-if="data.customLogo">
          <img :src="data.customLogo" alt="" />
        </div>
      </div>
      <div class="col col-12">
        <div class="top-title">
          <AtomTitle :title="data.title" :subtitle="data.subtitle" />
        </div>
        <div class="post-content">
          <p
            class="content-line"
            v-for="(line, i) in data.lines"
            :key="i"
            v-html="line"
          ></p>
        </div>
      </div>
    </div>
    <div class="image-full">
      <img :src="`${resourceUrl(data.image)}`" alt="Image Full Size" />
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";
import AtomLinesBullets from "../../components/atoms/common/AtomLinesBullet";

export default {
  name: "SectionPostAndImage",
  components: { AtomLinesBullets, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss">
.section-post-and-image {
  padding: 7.5rem 0 0;
  @media only screen and (max-width: 767px) {
    padding: 40px 10px 0;
  }

  .custom-logo {
    margin: 25px 0;
  }

  .title-and-content {
    padding: 0 3.75rem;
    margin: 0 -0.813rem 7.5rem;
    @media only screen and (max-width: 767px) {
      margin: 0 0 40px;
      padding: 0;
    }
    & > .col {
      padding: 0 0.813rem;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
    }
    .post-content {
      .content-line {
        color: #000000 !important;
        font-size: 2.75rem;
        line-height: 3.25rem;
        font-weight: 300;
        text-transform: none;
        margin-bottom: 2rem;
        &:first-child {
          margin-top: 2rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
        b {
          font-size: 2.75rem;
          line-height: 3.25rem;
        }
      }
    }
  }
  .atom-title {
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
    h2 {
      margin-bottom: 1.25rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }
  .image-full {
    @media only screen and (max-width: 767px) {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -20px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
