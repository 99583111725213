import { DefaultSceneManager } from "@/components/organisms/project/building/3D/client/DefaultSceneManager";

export class ClientManager {
  private sceneManager?: DefaultSceneManager;

  default(canvasContainer: HTMLCanvasElement) {
    if (!this.sceneManager)
      this.sceneManager = new DefaultSceneManager(canvasContainer);
    return this.sceneManager;
  }

  getSceneManager() {
    if (!this.sceneManager) {
      console.warn("Scene Manager not found");
      return null;
    } else {
      return this.sceneManager;
    }
  }
}

const BabylonClientManager = new ClientManager();
export { BabylonClientManager };
