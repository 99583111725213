<template>
  <div class="organism-content-slider-brochure">
    <MoleculeContentSliderBrochure ref="slider">
      <AtomContentSliderBrochure v-for="(sliderContent, index) in sliderContentData" :key="index">
        <div class="content-slide">
          <p v-for="(line, index) in sliderContent.lines" :key="index">{{line}}</p>
        </div>
      </AtomContentSliderBrochure>
    </MoleculeContentSliderBrochure>

    <MoleculeContentThumbSliderBrochure>
      <AtomContentThumbSliderBrochure v-for="(sliderContent, index) in sliderContentData" :key="index" @click="handleThumbChange" />
    </MoleculeContentThumbSliderBrochure>
  </div>
</template>

<script>
import MoleculeContentSliderBrochure from "../../molecules/common/MoleculeContentSliderBrochure";
import AtomContentSliderBrochure from "../../atoms/common/slider/AtomContentSliderBrochure";
import MoleculeContentThumbSliderBrochure from "../../molecules/common/MoleculeContentThumbSliderBrochure";
import AtomContentThumbSliderBrochure from "../../atoms/common/slider/AtomContentThumbSliderBrochure";

export default {
  name: "OrganismContentSliderBrochure",
  components: {
    AtomContentThumbSliderBrochure,
    MoleculeContentThumbSliderBrochure,
    AtomContentSliderBrochure, MoleculeContentSliderBrochure},
  props: {
    sliderContentData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    handleThumbChange(index) {
      this.$refs.slider.instance.trigger('to.owl.carousel', [index, 300]);
    }
  }
}
</script>

<style lang="scss">
  .organism-content-slider-brochure {
    position: absolute;
    left: 0;
    top: 13.188rem;
    width: 48rem;
    height: 11rem;
    margin: 0;
    padding: 0 4.813rem;
    z-index: 5;
    @media only screen and (max-width: 767px) {
      top: 38%;
      width: 100%;
      height: 62%;
    }
    .content-slide {
      p {
        @include font-main(1.188rem, $white, $light, 1.5rem);
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
</style>