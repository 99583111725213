<template>
  <div class="molecule-image-slider carousel-box">
    <div class="owl-carousel owl-theme" ref="carousel">
      <AtomImageSliderMatterportSpace v-for="(sliderImage, index) in sliderData" :slider-image="sliderImage" :key="index" />
    </div>
  </div>
</template>

<script>
import AtomImageSliderMatterportSpace from "@/components/atoms/common/slider/AtomImageSliderMatterportSpace";

export default {
  name: "MoleculeImageSlider3DTour",
  components: {AtomImageSliderMatterportSpace},
  props: {
    sliderData: {
      required: true,
      type: Object | Array,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      instance: null,
    }
  },
  mounted() {
    this.initCarousel();
  },
  beforeUpdate() {
    this.destroyCarousel();
  },
  updated() {
    this.initCarousel();
  },
  methods: {
    initCarousel() {
      const carouselOpts = {
        items: 1,
        nav: true,
        dots: false,
        margin: 20
      };
      this.instance = window.$(this.$refs.carousel).owlCarousel(carouselOpts);
      this.$emit('initialized', true);
    },
    destroyCarousel() {
      if (!this.instance) return;
      this.instance.trigger('destroy.owl.carousel');
      this.instance.off('change.owl.carousel');
      this.instance = null;
      this.$emit('initialized', false);
    },
    refreshCarousel() {
      this.instance.trigger('refresh.owl.carousel');
    }
  }
}
</script>

<style lang="scss">

</style>