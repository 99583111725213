<template>
  <div class="owl-dot col4 atom-content-thumb-slider-brochure" @click="handleClick">
    <span class="line"></span>
  </div>
</template>

<script>
export default {
  name: "AtomContentThumbSliderBrochure",
  computed: {
    index() {
      return this.$vnode.data.key;
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', this.index);
    }
  }
}
</script>

<style lang="scss">
  .owl-dots {
    .atom-content-thumb-slider-brochure {
      position: relative;
      float: left;
      padding: 0.125rem 0.625rem;
      cursor: pointer;
      @media only screen and (max-width: 767px) {
        padding: 2px 5px;
      }
      &.col4 {
        width: 25%;
        height: auto;
        border: none !important;
        margin: 0;
      }
      .line {
        width: 100%;
        display: block;
        position: relative;
        height: 1px;
        background: $white;
        &:after {
          opacity: 0;
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: -0.188rem;
          height: 0.188rem;
          background: $white;
          width: 100%;
          @include prefix(transition, all 0.5s ease-in-out 0s, ('webkit', 'moz', 'ms', 'o'));
          @media only screen and (max-width: 767px) {
            top: -3px;
            height: 3px;
          }
        }
      }
      &.active {
        .line {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
</style>