import { render, staticRenderFns } from "./OrganismBuilding.vue?vue&type=template&id=323edb3d&"
import script from "./OrganismBuilding.vue?vue&type=script&lang=js&"
export * from "./OrganismBuilding.vue?vue&type=script&lang=js&"
import style0 from "./OrganismBuilding.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('323edb3d')) {
      api.createRecord('323edb3d', component.options)
    } else {
      api.reload('323edb3d', component.options)
    }
    module.hot.accept("./OrganismBuilding.vue?vue&type=template&id=323edb3d&", function () {
      api.rerender('323edb3d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/project/building/OrganismBuilding.vue"
export default component.exports