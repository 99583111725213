<template>
  <div :style="{
    width: this.width ? `${this.width}px` : '100%',
    height: this.height ? `${this.height}px` : '100%',
  }" ref="lavContainer" class="atom-lottie-animation"></div>
</template>

<script>
import lottie from 'lottie-web';

export default {
  name: "AtomLottieAnimation",
  props: {
    options: {
      type: Object,
      required: true
    },
    height: Number,
    width: Number,
  },
  data () {
    return {
      anim: null,
    }
  },
  mounted () {
    this.initAnimation();
  },
  watch: {
    options: function () {
      this.initAnimation();
    }
  },
  methods: {
    initAnimation() {
      if (this.anim !== null) this.anim.destroy();
      this.anim = lottie.loadAnimation({
            container: this.$refs.lavContainer,
            renderer: 'svg',
            loop: this.options.loop !== false,
            autoplay: this.options.autoplay !== false,
            animationData: this.options.animationData,
            rendererSettings: this.options.rendererSettings
          }
      );
      this.anim.endFrame = this.options.animationData.endFrame;
      this.$emit('animCreated', this.anim);
    }
  }
}
</script>

<style lang="scss">
.atom-lottie-animation {
  overflow: hidden;
  margin: 0 auto;
}
</style>