<template>
  <div :class="{section: true, 'section-gallery': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <OrganismImageSlider :slider-data="content" />
  </div>
</template>

<script>
import OrganismImageSlider from "../../../../components/organisms/common/OrganismImageSlider";
export default {
  name: "SectionGalleryV1",
  components: {OrganismImageSlider},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  computed: {
    content(){
      return this.data
    },
    images() {
      return this.data.images;
    },
    videos() {
      return this.data.videos;
    },
    brochure() {
      return this.data.brochure;
    },
  }
}
</script>

<style lang="scss" scoped>
  .section-gallery {

  }
</style>