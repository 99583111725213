import { render, staticRenderFns } from "./AtomSidebarTitle.vue?vue&type=template&id=4bd2f105&scoped=true&"
import script from "./AtomSidebarTitle.vue?vue&type=script&lang=js&"
export * from "./AtomSidebarTitle.vue?vue&type=script&lang=js&"
import style0 from "./AtomSidebarTitle.vue?vue&type=style&index=0&id=4bd2f105&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd2f105",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4bd2f105')) {
      api.createRecord('4bd2f105', component.options)
    } else {
      api.reload('4bd2f105', component.options)
    }
    module.hot.accept("./AtomSidebarTitle.vue?vue&type=template&id=4bd2f105&scoped=true&", function () {
      api.rerender('4bd2f105', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/atoms/project/building/AtomSidebarTitle.vue"
export default component.exports