import { Color4, DefaultRenderingPipeline, Scene } from "babylonjs";
import { isDesktop } from "@/helpers/mobile/DeviceType";

export class Pipeline {
  renderingPipeline: DefaultRenderingPipeline;
  constructor(hdr: boolean, scene: Scene) {
    this.renderingPipeline = new DefaultRenderingPipeline(
      "DefaultRenderingPipeline",
      hdr,
      scene,
      scene.cameras
    );
    this.setFXAA(true);
  }

  setMAA(sampleNumber: number) {
    this.renderingPipeline.samples = sampleNumber;
  }

  setFXAA(enableFXAA: boolean) {
    this.renderingPipeline.samples = isDesktop() ? 8 : 4;
    this.renderingPipeline.fxaaEnabled = enableFXAA;
  }

  setImageProcessingProperties(options: Record<string, unknown>) {
    if (options.vignetteColor) {
      options.vignetteColor = Color4.FromHexString(
        options.vignetteColor as string
      );
    }
    Object.assign(this.renderingPipeline.imageProcessing, options);
  }
}
