var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.enabled !== false
    ? _c(
        "div",
        {
          ref: "sectionParking",
          class:
            ((_obj = {
              section: true,
              "section-parking": true,
              "section-parking-v2": true,
            }),
            (_obj["section" + _vm.orderNo] = true),
            _obj),
        },
        [
          _c("a", {
            staticClass: "navigation-target",
            attrs: { id: _vm.hash, href: "javascript:void(0);" },
          }),
          _vm.data.title
            ? _c(
                "div",
                { staticClass: "top-title" },
                [
                  _c("AtomTitle", {
                    attrs: {
                      title: _vm.data.title,
                      subtitle: _vm.data.subtitle,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("OrganismParkingAccessV2", {
            attrs: { "parking-access": _vm.data.items },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }