<template>
  <div class="molecule-content-slider-brochure">
    <div class="owl-carousel owl-theme" ref="carousel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoleculeContentSliderBrochure",
  data() {
    return {
      instance: null,
    }
  },
  mounted() {
    this.initCarousel();
  },
  updated() {
    this.destroyCarousel();
    this.initCarousel();
  },
  methods: {
    initCarousel() {
      this.instance = window.$(this.$refs.carousel).owlCarousel({
        items: 1,
        lazyLoad: true,
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        touchDrag: false,
        mouseDrag: false,
        autoplay: true,
        autoplayTimeout: 20000,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        dotsContainer: '#content-slide-portfolio-custom-dots'
      });

    },
    destroyCarousel() {
      this.instance('destroy');
      this.instance = null;
    }
  }
}
</script>

<style lang="scss">
  .molecule-content-slider-brochure {

  }
</style>