<template>
  <div
    :class="{ 'molecule-menu-trigger': true, active: isOpen }"
    @click="toggleSidebar"
  >
    <AtomIcon icon="bars" :size="32" color="#fff" />
    <AtomIcon icon="xmark" :size="32" color="#fff" />
  </div>
</template>

<script>
import AtomIcon from "../atoms/common/AtomIcon";
import { baseConstants } from "../../store/modules";
export default {
  name: "MoleculeMenuTrigger",
  components: { AtomIcon },
  computed: {
    isOpen() {
      return this.$store.state[baseConstants.namespace].sidebarOpen;
    },
  },
  data() {
    return {
      isMobileView:
        typeof window !== "undefined" ? window.innerWidth < 768 : false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
    document.body.classList.remove("disable-scroll");
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_SIDEBAR)
      );
      if (this.isMobileView) {
        if (this.isOpen) {
          document.body.classList.add("disable-scroll");
        } else {
          document.body.classList.remove("disable-scroll");
        }
      }
    },
    resizeListener() {
      this.isMobileView =
        typeof window !== "undefined" ? window.innerWidth < 768 : false;
      if (!this.isMobileView) {
        document.body.classList.remove("disable-scroll");
      }
    },
  },
};
</script>

<style lang="scss">
.molecule-menu-trigger {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: $white;
  border-radius: 3rem;
  transition: all 0.2s ease-in-out 0s;
  @media only screen and (max-width: 767px) {
    width: 48px;
    height: 48px;
    border-radius: 48px;
  }
  .icon {
    font-size: 1.125rem !important;
    color: $black !important;
    @media only screen and (max-width: 767px) {
      font-size: 18px !important;
    }
    &.icon-xmark {
      display: none;
    }
  }
  &:hover,
  &.active {
    background: $black;
    .icon {
      color: $white !important;
    }
  }
  &.active {
    .icon {
      &.icon-xmark {
        display: block;
      }
      &.icon-bars {
        display: none;
      }
    }
  }
}
</style>
