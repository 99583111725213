<template>
  <div class="pin-info-wrapper">
    <MoleculePinInfoContent />
  </div>
</template>

<script>
import MoleculePinInfoContent from "../../../molecules/project/building/MoleculePinInfoContent";

export default {
  name: "OrganismPinInfoBox",
  components: { MoleculePinInfoContent },
  props: {
    hightlight: {
      required: false,
      type: String,
      default: "",
    },
    title: {
      required: false,
      type: String,
      default: "",
    },
    subtitle: {
      required: false,
      type: String,
      default: "",
    },
    pinData: {
      required: false,
      type: Array || null,
      default: [
        {
          highlightAnchor: "highlightStairsExterior0",
          pinTitle: "Exterior emergency staircase",
          pinDescription: "",
          type: "",
        },
      ],
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>
