import {
  Mesh,
  MeshBuilder,
  Ray,
  Scene,
  StandardMaterial,
  Texture,
  TransformNode,
  Vector3,
} from "babylonjs";
import Constants from "./Constants";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";

export class PointerCircle {
  mesh?: Mesh;
  yPos = 0.01;
  init(size: number, scene: Scene, name: string, layerMask: number) {
    if (!scene) return console.error("Scene not defined");
    const pointer = MeshBuilder.CreatePlane(name, { size: size }, scene);
    const texture = new Texture(
      scene.metadata.cdnBase + Constants.POINTER_CIRCLE_TEXTURE,
      scene
    );
    texture.hasAlpha = true;
    texture.wrapU = Texture.CLAMP_ADDRESSMODE;
    texture.wrapV = Texture.CLAMP_ADDRESSMODE;

    const pointMaterial = new StandardMaterial("virtualTourPointer", scene);
    pointMaterial.emissiveTexture = texture;
    pointMaterial.opacityTexture = texture;
    pointMaterial.backFaceCulling = false;
    pointMaterial.disableLighting = true;
    pointMaterial.zOffset = -1;
    pointMaterial.freeze();
    pointer.material = pointMaterial;
    pointer.isPickable = false;

    pointer.rotation = new Vector3(Math.PI / 2, 0, 0);
    this.mesh = pointer;
    this.mesh.layerMask = layerMask;
    this.mesh.setEnabled(false);
  }

  togglePointer(isEnabled: boolean) {
    this.mesh?.setEnabled(isEnabled);
  }

  setParent(parent: TransformNode) {
    this.mesh?.setParent(parent);
  }

  move(x: number, y: number, z: number) {
    if (!this.mesh) return console.error("Mesh pointer undefined");
    this.mesh.position.set(x, y, z);
  }

  getMeshUnderneath() {
    if (!this.mesh) return;
    return BabylonClientManager.getSceneManager()?.scene?.pickWithRay(
      new Ray(this.mesh.position, new Vector3(0, -1, 0))
    );
  }
}
