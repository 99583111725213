<template>
  <div
    :class="{
      section: true,
      'section-contact': true,
      'section-contact-v2': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <OrganismContactForm />
    <div class="contact-background">
      <div
        class="row contacts-wrapper align-items-center"
        v-if="!data.contactFormOnly"
      >
        <div
          class="col col-xs-12 text"
          :class="{
            'col-6': data.contacts.length !== 3,
            'col-4': data.contacts.length === 3,
          }"
        >
          <div class="title" v-html="data.title"></div>
          <div class="subtitle" v-html="data.subtitle"></div>
          <div class="social">
            <div
              v-if="data.socialTitle"
              class="title"
              v-html="data.socialTitle"
            ></div>
            <a
              v-if="data.instagramUrl"
              :href="data.instagramUrl"
              target="_blank"
            >
              <AtomIcon icon="instagram" :size="40" color="#FFF" />
            </a>
            <a v-if="data.linkedinUrl" :href="data.linkedinUrl" target="_blank">
              <AtomIcon icon="linkedin-squared" :size="40" color="#FFF" />
            </a>
            <a v-if="data.facebookUrl" :href="data.facebookUrl" target="_blank">
              <AtomIcon icon="facebook-squared" :size="40" color="#FFF" />
            </a>
            <a v-if="data.youtubeUrl" :href="data.youtubeUrl" target="_blank">
              <AtomIcon icon="youtube-play" :size="40" color="#FFF" />
            </a>
          </div>
        </div>
        <AtomContact
          v-for="(member, index) in data.contacts"
          class="col col-xs-12"
          :class="{
            'col-auto': data.contacts.length <= 3 && data.contacts.length >= 2,
            'col-3': data.contacts.length > 3 || data.contacts.length === 1,
          }"
          :image="member.image"
          :name="member.name"
          :url-linkedin="member.urlLinkedin"
          :nr="member.nr"
          :position="member.position"
          :email="member.email"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrganismContactForm from "../../../components/organisms/project/OrganismContactForm";
import AtomContact from "../../../components/atoms/portfolio/AtomContact";
import AtomIcon from "../../../components/atoms/common/AtomIcon";

export default {
  name: "SectionContact",
  components: { AtomIcon, AtomContact, OrganismContactForm },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    instagramUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.instagramUrl;
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.linkedinUrl;
    },
    facebookUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.facebookUrl;
    },
    youtubeUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.youtubeUrl;
    },
  },
};
</script>

<style lang="scss">
.section-contact-v2 {
  position: relative;
  .navigation-target {
    position: absolute;
    top: -3.563rem;
    left: 0;
  }
  .organism-contact-form {
    max-width: 80.334rem;
    padding: 4rem 1rem;
    margin: 0 auto;
    @media only screen and (max-width: 1000px) {
      max-width: 100%;
      padding: 32px 16px;
    }
    .atom-title {
      margin-bottom: 4rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
    .input-atom-wrapper {
      input,
      textarea {
        background: #f5f9fb;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.063rem;
        color: #7790aa;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 17px;
        }
        &::-webkit-input-placeholder {
          color: #7790aa;
        }
        &:-ms-input-placeholder {
          color: #7790aa;
        }
        &::placeholder {
          color: #7790aa;
        }
      }
    }
    .button-atom {
      background: transparent;
      padding: 0.75rem 2rem;
      font-weight: 400;
      border-color: $main-color;
      @media only screen and (max-width: 767px) {
        padding: 8px 15px;
      }
      span {
        color: $black;
        text-transform: none;
      }
      &:hover {
        background: $main-color;
        span {
          color: $white;
        }
      }
    }
  }

  .atom-contact {
    margin-bottom: 3.125rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 50px;
    }
    &.no-margin {
      margin-bottom: 0 !important;
    }
    .atom-gradient-background {
      padding: 0 0.375rem;
      background: none;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
      .content-info {
        background: rgba(0, 0, 0, 0.2);
        padding: 1.25rem 1.5rem;
        @media only screen and (max-width: 767px) {
          padding: 15px 20px;
        }
        .col {
          padding: 0;
          margin: 0;
          &.col-7 {
            flex-basis: calc(100% / 12 * 12) !important;
          }
          span {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.375rem;
            display: block;
            width: 100%;
            text-transform: none;
            margin-bottom: 1.125rem;
            @media only screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 19px;
              margin-bottom: 15px;
            }
          }
          .name {
            font-size: 1.375rem;
            line-height: 1.625rem;
            font-weight: 400;
            margin-bottom: 0.25rem;
            max-width: 100%;
            @media only screen and (max-width: 767px) {
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 4px;
            }
          }
          a {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.188rem;
            @media only screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }

  .contact-background {
    background-color: $main-color;
  }
  .contacts-wrapper {
    max-width: 80.334rem;
    padding: 4rem 1rem;
    margin: 0 auto;
    @media only screen and (max-width: 1000px) {
      max-width: 100%;
      padding: 32px 16px;
    }
    .text {
      color: $white;
      padding: 0 0.375rem;
      margin: 3rem 0;
      @media only screen and (max-width: 767px) {
        padding: 0;
        margin: 0;
      }
      .title {
        font-weight: 400;
        font-size: 3.25rem;
        line-height: 3.875rem;
        max-width: 36.375rem;
        margin-bottom: 6rem;
        @media only screen and (max-width: 767px) {
          font-size: 22px;
          line-height: 26px;
          max-width: 100%;
          margin-bottom: 30px;
        }
        b {
          font-weight: 700;
          font-size: 3.25rem;
          line-height: 3.875rem;
          @media only screen and (max-width: 767px) {
            font-size: 22px;
            line-height: 26px;
          }
        }
      }

      .subtitle {
        font-weight: 400;
        font-size: 1.375rem;
        line-height: 1.625rem;
        margin-bottom: 0.5rem;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 5px;
        }
      }

      .social {
        @media only screen and (max-width: 767px) {
          margin-bottom: 40px;
        }
        .title {
          font-weight: 400;
          font-size: 2rem;
          line-height: 2.375rem;
          margin-bottom: 1.875rem;
          @media only screen and (max-width: 767px) {
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 15px;
          }
          span {
            font-weight: 700;
            font-size: 2rem;
            line-height: 2.375rem;
            @media only screen and (max-width: 767px) {
              font-size: 22px;
              line-height: 26px;
            }
          }
        }
        a {
          display: inline-block;
          margin-right: 1.5rem;
          @media only screen and (max-width: 767px) {
            margin-right: 12px;
          }
          &:last-child {
            margin-right: 0;
          }
          .icon {
            font-size: 2.5rem !important;
            @media only screen and (max-width: 767px) {
              font-size: 20px !important;
            }
            &:before {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
</style>
