var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "atom-contact",
      class: {
        "no-margin":
          !_vm.image &
          !_vm.name &
          !_vm.position &
          !_vm.email &
          !_vm.nr &
          !_vm.urlLinkedin,
      },
    },
    [
      _vm.image ||
      _vm.name ||
      _vm.position ||
      _vm.email ||
      _vm.nr ||
      _vm.urlLinkedin
        ? _c("div", { staticClass: "atom-gradient-background" }, [
            _vm.image
              ? _c("div", { staticClass: "image-team" }, [
                  _c("img", {
                    attrs: {
                      src: "" + _vm.resourceUrl(_vm.image),
                      alt: "" + _vm.name,
                    },
                  }),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "row content-info align-items-center same-height",
              },
              [
                _vm.name
                  ? _c("div", { staticClass: "col col-7" }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.name)),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col col-12" }, [
                  _vm.position
                    ? _c("span", [_vm._v(_vm._s(_vm.position))])
                    : _vm._e(),
                  _vm.email
                    ? _c("a", { attrs: { href: "mailto:" + _vm.email } }, [
                        _vm._v(_vm._s(_vm.email)),
                      ])
                    : _vm._e(),
                  _vm.nr
                    ? _c("a", { attrs: { href: "tel:" + _vm.nr } }, [
                        _vm._v(_vm._s(_vm.nr)),
                      ])
                    : _vm._e(),
                  _vm.urlLinkedin
                    ? _c("a", { attrs: { href: _vm.urlLinkedin } }, [
                        _vm._v("www.linkedin.com"),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }