<template>
  <div class="section section-image-list" :class="{[`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="heading" v-if="data.title || data.subtitle">
      <div class="title" v-if="data.title" v-html="data.title"></div>
      <div class="subtitle" v-if="data.subtitle" v-html="data.subtitle"></div>
    </div>
    <div class="listing">
      <div class="item" v-for="(item, index) in items" :key="index">
        <div class="icon" :style="{backgroundImage: `url(${resourceUrl(item.image)})`}"></div>
        <div class="text">{{item.text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionImageList",
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  computed: {
    items() {
      return this.data.items;
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    }
  }
}
</script>

<style lang="scss">
.section-image-list {
  .heading {
    padding: 80px 64px 64px 64px;
    .title {
      font-size: 3rem;
      font-weight: 400;
      margin-bottom: 8px;
      b {
        font-size: 3rem;
        font-weight: 600;
      }
    }
    .subtitle {
      font-size: 1.2rem;
    }
  }
  .listing {
    padding: 0 64px 0 64px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      display: flex;
      flex-direction: row;
      flex: 50%;
      flex-grow: 0;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 32px;
      .text {
        font-size: 1.6rem;
        font-weight: 400;
        color: $black;
        padding-right: 3rem;
      }
      .icon {
        width: 9rem;
        min-width: 9rem;
        height: 9rem;
        min-height: 9rem;
        background-size: cover;
        background-position: center center;
        border-radius: 81px;
        margin-right: 3rem;
      }
    }
  }

  @media (max-width: 768px) {
    .heading {
      padding: 40px 24px 24px 24px;
      .title {
        font-size: 7rem;
        b {
          font-size:  7rem;
        }
      }
      .subtitle {
        font-size: 4rem;
      }
    }
    .listing {
      padding: 0 24px;
      .item {
        flex: 100%;
        .text {
          font-size: 5rem;
        }
        .icon {
          width: 21rem;
          min-width: 21rem;
          height: 21rem;
          min-height: 21rem;
        }
      }
    }
  }

}
</style>