<template>
  <div class="organism-loader-gradient">
    <AtomLoaderProject v-for="(content, index) in contentLoader" :key="index">
      <div class="title-project">
        <h2>{{ content.project }}</h2>
      </div>
      <div class="content-loader">
        <h3>{{ content.title }}</h3>
        <h4>{{ content.subtitle }}</h4>
        <p v-html="content.text"></p>
      </div>
    </AtomLoaderProject>
    <AtomLoaderLoadingBar />
  </div>
</template>

<script>
import AtomLoaderLoadingBar from "../../atoms/common/AtomLoaderLoadingBar";
import AtomLoaderProject from "../../atoms/common/AtomLoaderProject";
export default {
  name: "OrganismLoader",
  components: { AtomLoaderProject, AtomLoaderLoadingBar },
  props: {
    contentLoader: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>

<style lang="scss">
.organism-loader-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  .title-project {
    position: absolute;
    left: 3.75rem;
    top: 3.438rem;
    h2 {
      @include font-main(2.125rem, $white, $medium, 2.5rem);
      text-transform: uppercase;
    }
  }
  .content-loader {
    position: absolute;
    left: 3.75rem;
    bottom: 10rem;
    h3 {
      @include font-main(3.375rem, $white, $bold, 4rem);
      text-transform: uppercase;
    }
    h4 {
      @include font-main(2.125rem, $white, $regular, 2.5rem);
      margin-bottom: 1.875rem;
    }
    p {
      width: 44.688rem;
      max-width: 100%;
      @include font-main(1rem, $white, $regular, 1.625rem);
    }
  }
  @media only screen and (max-width: 1200px) {
    .title-project {
      left: 0;
      top: 20px;
      padding: 0 20px;
      h2 {
        font-size: 24px;
        line-height: 29px;
      }
    }
    .content-loader {
      left: 0;
      padding: 0 20px;
      bottom: 75px;
      max-width: 100%;
      h3 {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 8px;
      }
      h4 {
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 30px;
      }
      p {
        width: 750px;
        max-width: 100%;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .title-project {
      h2 {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .content-loader {
      bottom: 75px;
      h3 {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 3px;
      }
      h4 {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 5px;
      }
      p {
        width: 750px;
        max-width: 100%;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
</style>
