export const startsWithCapital = (str: string) => {
  return str.charAt(0) === str.charAt(0).toUpperCase();
};

export const convertUCWordsOrCamelCaseToDashCase = (str: string) => {
  const conversion = str.replace(
    /[A-Z]/g,
    (letter) => `-${letter.toLowerCase()}`
  );
  const conversionLength = conversion.length + 1;
  return startsWithCapital(str)
    ? conversion.substring(1, conversionLength)
    : conversion;
};

export const smoothScrollToTargetId = (target: string) => {
  document.querySelector(target)?.scrollIntoView({
    behavior: "smooth",
  });
};
export const smoothScrollToTargetIdBottom = (target: string) => {
  document.querySelector(target)?.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "end",
  });
};

export const smoothScrollSidebarToTargetId = (target: string) => {
  document.querySelector(target)?.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "nearest",
  });
};

export const textTemplate = {
  replace: function (
    template: string,
    variables: { literal: string; value: any; type: string }[]
  ) {
    let output = template;
    variables.forEach((variable) => {
      let value;
      switch (variable.type) {
        case "Date":
          value = new Date(variable.value).toDateString();
          break;
        case "Array":
          value = variable.value.toString();
          break;
        default:
          value = variable.value;
          break;
      }
      output = output.replaceAll(`%${variable.literal}%`, variable.value);
    });
    return output;
  },
};
