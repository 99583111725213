<template>
  <div class="atom-content-text">
    <p :style="{'color': color}">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "AtomContentText",
  props: {
    text: {
      required: false,
      type: String,
      default: "",
    },
    color: {
      required: true,
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style lang="scss">
  .atom-content-text {
    text-align: justify;
    text-justify: inter-word;
    p {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5rem;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 29px;
      }
    }
  }
</style>