<template>
  <component :is="componentVariant" :data="data" :hash="hash" :order-no="orderNo" />
</template>

<script>
import SectionLocationV2 from "./location/SectionLocationV2";
import SectionLocationV1 from "./location/SectionLocationV1";

export default {
  name: "SectionLocation",
  components: {},
  props: {
    variant: {
      required: true,
      type: String,
      default: '',
    },
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  data() {
    return {
      componentVariant: null
    }
  },
  created() {
    this.handleVariant(this.variant);
  },
  watch: {
    variant: function (newVariant, oldVariant) {
      this.handleVariant(newVariant);
    }
  },
  methods: {
    handleVariant(variant) {
      switch (variant) {
        case 'v2':
          this.componentVariant = SectionLocationV2;
          break;
        default:
          this.componentVariant = SectionLocationV1;
          break;
      }
    }
  }
}
</script>

<style lang="scss">
  .section-location {

  }
</style>