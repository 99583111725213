import {
  EasingFunction,
  CubicEase,
  Animation,
  ArcRotateCamera,
  UniversalCamera,
  Vector3,
} from "babylonjs";

export class AnimationBuilder {
  static fps = 60;
  static isLoop = false;
  static fromFrame = 0;
  static toFrame = 100;
  static speedRatio = 4;

  static createAnimation(
    property: string,
    fromValue: number | Vector3,
    toValue: number | Vector3,
    speed = 1,
    animationType: number = Animation.ANIMATIONTYPE_FLOAT
  ) {
    const ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

    const animation = Animation.CreateAnimation(
      property,
      animationType,
      this.fps * speed,
      ease
    );
    animation.setKeys([
      {
        frame: this.fromFrame,
        value: fromValue,
      },
      {
        frame: this.toFrame,
        value: toValue,
      },
    ]);
    return animation;
  }

  static createMoveArcRotateCamera(
    camera: ArcRotateCamera,
    properties: {
      radius: number;
      alpha: number;
      beta: number;
      target: { x: number; y: number; z: number };
    },
    speed: number
  ) {
    return [
      this.createAnimation("radius", camera.radius, properties.radius, speed),
      this.createAnimation("alpha", camera.alpha, properties.alpha, speed),
      this.createAnimation("beta", camera.beta, properties.beta, speed),
      this.createAnimation(
        "target",
        camera.target,
        new Vector3(
          properties.target.x,
          properties.target.y,
          properties.target.z
        ),
        speed,
        Animation.ANIMATIONTYPE_VECTOR3
      ),
    ];
  }

  static createMoveUniversalCamera(
    camera: UniversalCamera,
    properties: { target: { x: number; y: number; z: number } },
    speed: number
  ) {
    return [
      this.createAnimation(
        "position",
        camera.position,
        new Vector3(
          properties.target.x,
          properties.target.y,
          properties.target.z
        ),
        speed,
        Animation.ANIMATIONTYPE_VECTOR3
      ),
    ];
  }
}
