var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section section-explore-building",
      class: ((_obj = {}), (_obj["section" + _vm.orderNo] = true), _obj),
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _vm.data.title
        ? _c(
            "div",
            { staticClass: "top-title" },
            [
              _c("AtomTitle", {
                attrs: {
                  color: "rgba(0,0,0,0.6)",
                  color2: "#000",
                  title: _vm.data.title,
                  subtitle: _vm.data.subtitle,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("OrganismExplore", { attrs: { "explore-card": _vm.data.items } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }