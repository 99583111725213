var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-atom-wrapper" }, [
    _c("label", { attrs: { for: "" + _vm.id } }, [_vm._v(_vm._s(_vm.label))]),
    _c("div", { staticClass: "select-inline-block" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selected,
              expression: "selected",
            },
          ],
          ref: "selectTarget",
          staticClass: "select2select",
          attrs: { id: "" + _vm.id },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selected = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "option",
            {
              key: index,
              attrs: { disabled: option.disabled },
              domProps: { value: option.value },
            },
            [_vm._v("\n        " + _vm._s(option.text) + "\n      ")]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }