<template>
  <div :class="{section: true, 'section-slider': true, [`section${orderNo}`]: true}">
    <organism-slider v-if="data.images" :images="data.images" :slidess="[]"></organism-slider>
    <organism-slider v-if="data.slidess" :slidess="data.slidess" :images="[]"></organism-slider>
  </div>
</template>

<script>

import OrganismSlider from "../../../components/organisms/common/OrganismSlider";
export default {
  name: "SectionSlider",
  components: {
    OrganismSlider
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {
          images: {
            required: false,
            type: Array,
            default: function () {
              return []
            }
          },
          slidess:{
            required: false,
            type: Array,
            default: function () {
              return [
                {
                  titles: {
                    required: false,
                    type: Array,
                    default: function () {
                      return []
                    }
                  },
                  descriptions: {
                    required: false,
                    type: Array,
                    default: function () {
                      return []
                    }
                  },
                  background: {
                    required: false,
                    type: String,
                    default: function () {
                      return '#5bb4e5'
                    }
                  }
                }
              ]
            }
          }
        }
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss">

</style>