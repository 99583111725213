<template>
  <div class="molecule-explore">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculeExplore"
}
</script>

<style lang="scss" scoped>
  .molecule-explore {

  }
</style>