<template>
  <div class="molecule-project-slider-thumb">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculeProjectSliderThumb"
}
</script>

<style lang="scss" scoped>
  .molecule-project-slider-thumb {
    position: absolute;
    bottom: 6.25rem;
    right: 5.625rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: nowrap;
    z-index: 9;
    @media only screen and (max-width: 767px) {
      bottom: 115px;
      right: initial;
      left: 0;
      width: 100%;
      overflow-x: auto;
      padding: 0 20px 5px;
    }
  }
</style>