import Vuex, { ActionContext } from "vuex";

export enum FloorViewMode {
  TWO_DIMENSIONAL = "2D",
  DEFAULT = "default",
  FIRST_PERSON = "firstPerson",
}

export enum CurrentView {
  BUILDING = "building",
  FLOOR = "floor",
}

export type DefaultState = {
  currentView: CurrentView;
  cdnBase: string;
  buildingCode: string;
  project: any;
  activeProject: string;
  client: string;
  space: Record<string, any> | null;
  isInitialized: boolean;
  floorViewMode: FloorViewMode;
  fitoutData: any;
  loadingScreenActive: boolean;
  finishedLoadingAssets: boolean;
  settings: any;
};

const defaultState: DefaultState = {
  currentView: CurrentView.BUILDING,
  cdnBase: "",
  buildingCode: "",
  project: {},
  activeProject: "",
  client: "",
  space: {},
  isInitialized: false,
  floorViewMode: FloorViewMode.DEFAULT,
  fitoutData: {
    initialized: false,
    data: [],
  },
  loadingScreenActive: true,
  finishedLoadingAssets: false,
  settings: {},
};

const buildingStore = function () {
  return new Vuex.Store({
    state: defaultState,
    mutations: {
      clearSpaceData(state: DefaultState) {
        state.space = {};
      },
      setInitialState(state: DefaultState, payload: DefaultState) {
        state.currentView = payload.currentView;
        state.cdnBase = payload.cdnBase;
        state.buildingCode = payload.buildingCode;
        state.project = payload.project;
        state.activeProject = payload.activeProject;
        state.client = payload.client;
        state.space = payload.space;
        state.isInitialized = true;
        state.settings = payload.settings;
        state.floorViewMode = FloorViewMode.DEFAULT;
      },

      clearState(state: DefaultState) {
        state.currentView = CurrentView.BUILDING;
        state.cdnBase = "";
        state.buildingCode = "";
        state.project = {};
        state.activeProject = "";
        state.client = "";
        state.space = {};
        state.isInitialized = false;
        state.fitoutData = {
          initialized: false,
          data: [],
        };
        state.loadingScreenActive = true;
        state.finishedLoadingAssets = false;
        state.settings = {};
        state.floorViewMode = FloorViewMode.DEFAULT;
      },
      setSpace(state: DefaultState, payload: DefaultState) {
        state.space = payload;
      },
      setCurrentView(state: DefaultState, payload: CurrentView) {
        state.currentView = payload;
      },
      setFitoutData(state: DefaultState, payload: any) {
        state.fitoutData = payload;
      },
      setLoadingScreenStatus(state: DefaultState, payload: boolean) {
        state.loadingScreenActive = payload;
      },
      setLoadingAssetsStatus(state: DefaultState, payload: boolean) {
        state.finishedLoadingAssets = payload;
      },
      setFloorViewMode(state: DefaultState, payload: FloorViewMode) {
        state.floorViewMode = payload;
      },
    },
    actions: {
      clearSpaceData({ commit }: ActionContext<any, any>) {
        commit("clearSpaceData");
      },
      changeLoadingScreenStatus(
        { commit }: ActionContext<any, any>,
        payload: boolean
      ) {
        commit("setLoadingScreenStatus", payload);
      },
      viewSpace({ commit, state }: ActionContext<any, any>, payload: any) {
        const data = {
          space: payload.space,
          floor: payload.floor,
          building: payload.building,
          floorType: payload.floorType,
          spaceData: payload.spaceData,
        };
        commit("setSpace", data);
      },

      initBuilding({ commit, state }: ActionContext<any, any>, payload: any) {
        commit("setCurrentView", CurrentView.BUILDING);
        const newState = {
          currentView: CurrentView.BUILDING,
          cdnBase: payload.cdnBase,
          project: payload.project,
          activeProject: payload.activeProject,
          client: payload.client,
          space: state.space,
          isInitialized: true,
          settings: payload.settings,
        } as DefaultState;
        commit("setInitialState", newState);
      },

      initFloor({ commit, state }: ActionContext<any, any>, payload: any) {
        commit("setCurrentView", CurrentView.FLOOR);
        commit("setInitialState", {
          currentView: CurrentView.FLOOR,
          cdnBase: payload.cdnBase,
          project: payload.project,
          activeProject: payload.activeProject,
          client: payload.client,
          space: state.space,
          isInitialized: true,
          settings: payload.settings,
          fitoutData: {},
        } as DefaultState);
      },
      destroy({ state, commit }: ActionContext<any, any>) {
        commit("clearState");
      },
      changeViewMode(
        { state, commit }: ActionContext<any, any>,
        payload: FloorViewMode
      ) {
        commit("setFloorViewMode", payload);
      },
    },
    modules: {},
    getters: {},
  });
};

const store = buildingStore();
export default store;
