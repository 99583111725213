var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.video
    ? _c(
        "div",
        {
          directives: [
            {
              name: "lazyload",
              rawName: "v-lazyload",
              value: { handle: _vm.handleLazyLoad, url: _vm.resourceUrlVideo },
              expression: "{handle: handleLazyLoad, url: resourceUrlVideo}",
            },
          ],
          class: {
            "atom-project-video-background": true,
            active: _vm.isActive,
          },
        },
        [
          _c(
            "video",
            {
              staticStyle: { "object-fit": "cover !important" },
              attrs: {
                muted: "",
                autoplay: "",
                playsinline: "",
                loop: "",
                preload: "metadata",
              },
              domProps: { muted: true },
            },
            [
              _c("source", {
                attrs: { src: "" + _vm.resourceUrlVideo, type: "video/mp4" },
              }),
            ]
          ),
        ]
      )
    : _c("div", {
        directives: [
          {
            name: "lazyload",
            rawName: "v-lazyload",
            value: { handle: _vm.handleLazyLoad, url: _vm.resourceUrl },
            expression: "{handle: handleLazyLoad, url: resourceUrl}",
          },
        ],
        class: { "atom-project-slider-background": true, active: _vm.isActive },
        style: { "background-image": "url(" + _vm.resourceUrl + ")" },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }