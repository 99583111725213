var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "menu",
      class: { "organism-menu-bar": true, scrolled: _vm.isScrolled },
    },
    [
      _c("div", { staticClass: "menu-left" }, [
        _c(
          "div",
          { on: { click: _vm.scrollTopMethod } },
          [
            _c(
              "router-link",
              { attrs: { tag: "div", to: { name: "Home" } } },
              [
                _vm.isScrolled
                  ? _c("AtomLogo")
                  : _c("AtomLogo", { attrs: { white: "" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "hidden-xs" },
          [
            _vm.projectOptions.length > 1
              ? _c("AtomDropdown", {
                  attrs: {
                    options: _vm.projectOptions,
                    "title-label": "Change Project",
                  },
                  on: { change: _vm.handleProjectChange },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("MoleculeMenuTrigger"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }