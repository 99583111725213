<template>
  <div
    class="atom-image-title"
    :style="{ 'background-image': `url(${resourceUrl(image)})` }"
  >
    <div class="background-gradient">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { smoothScrollToTargetId } from "../../../helpers/util";

export default {
  name: "AtomImageTitle",
  props: {
    image: {
      required: true,
      type: String,
      default: "",
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.atom-image-title {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .background-gradient {
    padding: 4.875rem 3.75rem 2rem;
    background: rgba(0, 0, 0, 0.3);
    @media only screen and (max-width: 767px) {
      padding: 75px 20px 30px;
    }
  }
}
</style>
