<template>
  <div>
    <div
      ref="parentFloorRef"
      class="parent-floor row-floor"
      :id="`${building.code}_${floor.code}`"
      :class="floor.isFullyLeased ? 'action-disabled' : 'activated-floor'"
      @click.stop="handleFloorClick(building.code, floor.code)"
    >
      <div class="clearfix">
        <div class="column2">
          <h6 class="get-space-floor space-floor">{{ building.name }}</h6>
        </div>
        <div class="column2 text-right">
          <span class="when-text floor-number"
            >Floor <span>{{ floor.no }}</span></span
          >
        </div>
      </div>
      <div class="clearfix" v-if="!floor.isFullyLeased">
        <div class="column1">
          <span class="when-text db">
            <span class="nr-cube">
              {{
                floor.spaces.filter(
                  (s) =>
                    (s.available && s.visibility_status !== "hidden") ||
                    s.visibility_status === "available"
                ).length
              }}
            </span>
            Options Available
          </span>
          <span
            class="sqm-gla-total"
            v-if="surfaceMultiplier && ![0, 1].includes(surfaceMultiplier)"
          >
            <b>{{
              (
                Number(floor.surface) -
                Number(floor.surface) * Number(surfaceMultiplier)
              ).toFixed(2)
            }}</b>
            {{ unitOfMeasureString }} {{ surfaceName }}
          </span>
          <span class="sqm-gla-total" v-else>
            <b>{{ floor.surface }}</b>
            {{ unitOfMeasureString }} GLA
          </span>
          <span
            class="arrow-custom"
            :class="{ 'rotate-icon': isSpaceListExpanded }"
            ><AtomIcon icon="sort-down" :size="15"
          /></span>
        </div>
      </div>
      <div class="leased-overlay" v-if="floor.isFullyLeased">
        <span>Floor Fully Leased</span>
      </div>
    </div>
    <div
      class="show-all-spaces"
      v-if="!floor.isFullyLeased"
      :class="{ active: isSpaceListExpanded }"
    >
      <div
        v-for="(space, index) in floorSpaces"
        :key="index"
        class="row-floor row-floor-active"
        :class="{
          active: space.isActive,
          'leased-element-style':
            !space.available && space.visibility_status !== 'available',
        }"
        :data-building="building.code"
        :data-floor="floor.code"
        :data-floor-model="floor.code"
        :data-space="space.space_code"
        :data-unit="space.space"
        :data-type="space.type"
        :data-active="space"
      >
        <span
          class="trigger-click-view-space"
          @click="
            viewOrHideSpace(
              space.space_code,
              building.code,
              floor.code,
              floor.floor_type.model,
              space
            )
          "
        ></span>
        <div class="parent-general-row-floor" :data-unit="space.id">
          <div class="general-dow-floor" :data-unit="space.id">
            <div class="clearfix">
              <div class="column1">
                <span v-if="!spaceIsRequested(space.id)" class="line-element"
                  ><AtomIcon icon="minus"
                /></span>
                <span v-else class="line-element line-checked"
                  ><AtomIcon icon="ok"
                /></span>

                <span
                  class="get-sqm db"
                  v-if="
                    surfaceMultiplier && ![0, 1].includes(surfaceMultiplier)
                  "
                >
                  <b class="nr-sqm">{{
                    (
                      Number(space.sqm) -
                      Number(space.sqm) * Number(surfaceMultiplier)
                    ).toFixed(2)
                  }}</b>
                  {{ unitOfMeasureString }} {{ surfaceName }}
                </span>
                <span class="get-sqm db" v-else
                  ><b class="nr-sqm">{{ space.sqm }}</b>
                  {{ unitOfMeasureString }} GLA
                </span>
                <span
                  class="get-nr-people db"
                  v-if="showGeneratedNumberOfPeople"
                  ><b class="max-people">{{ space.people }}</b> People</span
                >
                <span class="icon view-space icon-lock"
                  ><AtomIcon icon="eye" :size="15" /> view space</span
                >
                <span class="icon hide-space icon-lock"
                  ><AtomIcon icon="eye-slash" :size="15" /> hide space</span
                >
              </div>
            </div>
            <div
              class="leased-overlay"
              v-if="!space.available && space.visibility_status !== 'available'"
            >
              <span>LEASED</span>
            </div>
            <div class="clearfix">
              <div class="hover-buttons">
                <div class="row row-padding-11px">
                  <div class="col-6 col-padding-4px">
                    <a
                      class="icon icon-edit"
                      href="javascript:void(0)"
                      @click="
                        exploreSpace(
                          space.space_code,
                          building.code,
                          floor.code,
                          floor.floor_type.model,
                          space
                        )
                      "
                    >
                      <AtomIcon icon="cube-1" :size="15" />
                      3D Explore</a
                    >
                  </div>
                  <div class="col-6 col-padding-4px">
                    <div class="wrapper-btn">
                      <a
                        v-if="!spaceIsRequested(space.id)"
                        href="javascript:void(0)"
                        class="icon icon-select select-show"
                        @click="addSpaceToCart(space, building, floor)"
                      >
                        <AtomIcon icon="list-checked" :size="15" />
                        Add to my list</a
                      >
                      <a
                        v-else
                        href="javascript:void(0)"
                        class="icon icon-select btn-remove-item"
                        @click="removeSpaceFromCart(space, building, floor)"
                        >- Remove from list</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="getCombinedSpace(space.can_combine_with_id)"
              class="combine"
            >
              <p>
                Can be combined with the studio on
                {{
                  getCombinedSpace(space.can_combine_with_id).floor.floor_type
                    .name
                }}
              </p>
              <div class="clearfix">
                <div class="hover-buttons">
                  <div class="row row-padding-11px">
                    <div class="col-6 col-padding-4px">
                      <a
                        class="icon icon-edit"
                        href="javascript:void(0)"
                        @click="
                          exploreSpace(
                            getCombinedSpace(space.can_combine_with_id)
                              .space_code,
                            building.code,
                            getCombinedSpace(space.can_combine_with_id).floor
                              .code,
                            getCombinedSpace(space.can_combine_with_id).floor
                              .floor_type.model,
                            getCombinedSpace(space.can_combine_with_id)
                          )
                        "
                      >
                        <AtomIcon icon="cube-1" :size="15" />
                        3D Explore</a
                      >
                    </div>
                    <div class="col-6 col-padding-4px">
                      <div class="wrapper-btn">
                        <a
                          v-if="
                            !spaceIsRequested(
                              getCombinedSpace(space.can_combine_with_id).id
                            )
                          "
                          href="javascript:void(0)"
                          class="icon icon-select select-show"
                          @click="
                            addBothSpacesToCart(
                              space,
                              getCombinedSpace(space.can_combine_with_id),
                              building,
                              floor,
                              getCombinedSpace(space.can_combine_with_id).floor
                            )
                          "
                        >
                          <AtomIcon icon="list-checked" :size="15" />
                          Add both to my list</a
                        >
                        <a
                          v-else
                          href="javascript:void(0)"
                          class="icon icon-select btn-remove-item"
                          @click="
                            removeBothSpacesFromCart(
                              space,
                              getCombinedSpace(space.can_combine_with_id),
                              building,
                              floor,
                              getCombinedSpace(space.can_combine_with_id).floor
                            )
                          "
                          >- Remove both from list</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end floor-space -->
    </div>
  </div>
</template>

<script>
import AtomIcon from "../../../atoms/common/AtomIcon";
import buildingStore from "../../../organisms/project/building/store";
import { isMobile, isTablet } from "../../../../helpers/mobile/DeviceType";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
import { smoothScrollToTargetId } from "../../../../helpers/util";

const NOT_IN_VIEW = "is-not-intersecting";
export default {
  name: "MoleculeSidebarResult",
  data() {
    return {
      isSpaceListExpanded: false,
      listData: [],
      filters: {},
      clickedHighlight: "",
      observer: null,
    };
  },
  components: { AtomIcon },
  props: {
    building: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    floor: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    buildingStore() {
      return buildingStore;
    },
    floorSpaces() {
      return this.showRentedSpaces
        ? this.floor.spaces.filter((s) => {
            return s.visibility_status && s.visibility_status !== "default"
              ? this.spaceAvailability(s.available, s.visibility_status)
              : s;
          })
        : this.floor.spaces.filter((s) => s.available);
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces;
    },
    showRentedSpaces() {
      return this.$store.state.base.meta.general.showRentedSpaces ?? true;
    },
    spaces() {
      return this.$store.state.project.project.spaces;
    },
    floors() {
      return this.$store.state.project.project.floors;
    },
    buildings() {
      return this.$store.state?.project?.project?.buildings || [];
    },
    showGeneratedNumberOfPeople() {
      const commonFeatures =
        this.$store?.state?.base?.meta?.project?.sections?.find(
          (section) => section.type === "Building"
        ) || {};
      if ("generatedNumberOfPeople" in commonFeatures) {
        return commonFeatures.generatedNumberOfPeople;
      } else {
        return true;
      }
    },
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1;
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1;
    },
  },
  mounted() {
    this.initObserver();
    this.observeElements();
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    spaceAvailability(available, visibility) {
      switch (visibility) {
        case "available":
          return true;
        case "default":
          return available;
        case "hidden":
          return false;
        default:
          return available;
      }
    },
    viewSpace(spaceCode, buildingCode, floorCode, floorTypeCode, space) {
      this.buildingStore.dispatch("viewSpace", {
        space: spaceCode,
        building: buildingCode,
        floor: floorCode,
        floorType: floorTypeCode,
        spaceData: space,
      });
      const neededSpace = {
        ...space,
        building: this.buildingStore.state.space.building, // get the building code for clicked building
        buildingsCollection: this.buildings.map((b) => ({
          ...b,
          isHidden: b.code !== this.buildingStore.state.space.building,
        })), // get all buildings and hydrate those with isHidden property
      };
      const highlightName = buildingCode + "_" + floorCode + "_" + spaceCode;
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.highlightBuildingSpace(highlightName, buildingCode, floorCode);
      }

      if (isMobile() || isTablet()) {
        const base = this.$store.getters.constants.base;
        this.$store.dispatch(
          base.withNamespace(base.action.CHANGE_MOBILE_AVAILABILITY_MENU),
          false
        );
      }
    },
    exploreSpace(spaceCode, buildingCode, floorCode, floorTypeCode, space) {
      if (window && "analyticsLayer" in window) {
        window.analyticsLayer.send({
          event: "explore_space_trigger",
          payload: {
            zone: "building",
            spaceCode,
            buildingCode,
            floorCode,
          },
        });
      }
      const routePageData = this.$route.meta.page;
      let route = "SpecificSpace";
      if (routePageData.isEmbedReady) {
        route = "SpecificSpaceEmbed";
      } else if (routePageData.isEmbedV2) {
        route = "SpecificSpaceEmbedV2";
      }
      this.$router.push({
        name: route,
        params: {
          projectId: this.activeProject,
          spaceId: space.id,
        },
      });
    },
    resetView() {
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.hideHighlight();
      }
      if (isMobile() || isTablet()) {
        this.buildingStore.dispatch("clearSpaceData");
      }
    },
    viewOrHideSpace(spaceCode, buildingCode, floorCode, floorTypeCode, space) {
      if (window && "analyticsLayer" in window) {
        window.analyticsLayer.send({
          event: "toggle_space_on_building",
          payload: {},
        });
      }
      const routePageData = this.$route.meta.page;
      if (isMobile() || isTablet() || routePageData.isEmbedReady) {
        const base = this.$store.getters.constants.base;
        this.$store.dispatch(
          base.withNamespace(base.action.TOGGLE_MOBILE_AVAILABILITY_MENU)
        );
        this.$store.dispatch(
          base.withNamespace(base.action.CHANGE_SIDEBAR_STATE),
          false
        );
      }
      const getSiblings = function (elem) {
        let descendants = elem.parentNode.children;
        return Array.prototype.filter.call(descendants, function (sibling) {
          return sibling !== elem;
        });
      };
      const target = event.target;
      getSiblings(target).forEach((sibling) => {
        sibling.classList.remove("activated");
      });
      target.classList.toggle("activated");
      const closestFloorRow = event.target.closest(".row-floor");
      getSiblings(closestFloorRow).forEach((sibling) => {
        sibling.classList.remove("activation");
      });
      closestFloorRow.classList.toggle("activation");

      if (closestFloorRow.classList.contains("activation")) {
        this.viewSpace(
          spaceCode,
          buildingCode,
          floorCode,
          floorTypeCode,
          space
        );
      } else {
        this.resetView();
      }

      const buildingsList =
        document?.getElementsByClassName("building-list") || [];
      if (buildingsList && buildingsList.length > 0) {
        for (let bl of buildingsList) {
          const rows = bl?.querySelectorAll(".row-floor") || [];
          if (rows && rows.length > 0) {
            for (const childRow of rows) {
              if (childRow !== closestFloorRow) {
                childRow.classList.remove("activation");
              }
            }
          }
        }
      }
    },

    addSpaceToCart(space, building, floor) {
      const spaceData = {
        ...space,
        building,
        floor,
      };
      if (window && "analyticsLayer" in window) {
        window.analyticsLayer.send({
          event: "add_space_to_list",
          payload: {
            zone: "building",
            space,
          },
        });
      }
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.ADD_SPACE),
        spaceData
      );
    },
    removeSpaceFromCart(space, building, floor) {
      const spaceData = {
        ...space,
        building,
        floor,
      };
      if (window && "analyticsLayer" in window) {
        window.analyticsLayer.send({
          event: "remove_space_from_list",
          payload: {
            zone: "building",
            space,
          },
        });
      }
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.REMOVE_SPACE),
        spaceData
      );
    },
    addBothSpacesToCart(space, combinedSpace, building, floor, combinedFloor) {
      const spaceData = {
        ...space,
        building,
        floor,
      };
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.ADD_SPACE),
        spaceData
      );
      const combinedSpaceData = {
        ...combinedSpace,
        building,
        floor: combinedFloor,
      };
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.ADD_SPACE),
        combinedSpaceData
      );
    },
    removeBothSpacesFromCart(
      space,
      combinedSpace,
      building,
      floor,
      combinedFloor
    ) {
      const spaceData = {
        ...space,
        building,
        floor,
      };
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.REMOVE_SPACE),
        spaceData
      );
      const combinedSpaceData = {
        ...combinedSpace,
        building,
        floor: combinedFloor,
      };
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.REMOVE_SPACE),
        combinedSpaceData
      );
    },
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find((space) => space.id === spaceId);
    },
    getCombinedSpace(id) {
      if (!this.spaces) {
        return {
          floor: {},
        };
      }
      const foundSpace = this.spaces.find((s) => s.id === id);
      if (!foundSpace) {
        return null;
      }
      foundSpace.floor = this.floors.find((f) => f.id === foundSpace.floor_id);
      return foundSpace;
    },
    initObserver() {
      const spaceResultScrollParent =
        this.$parent?.$parent?.$refs?.spaceResultsScroll || null;
      if (spaceResultScrollParent) {
        this.observer = new IntersectionObserver(this.onElementObserved, {
          root: spaceResultScrollParent,
          rootMargin: "0px 0px -10% 0px",
          threshold: 1.0,
        });
      }
    },
    onElementObserved(entries) {
      if (!entries || entries.length === 0) return;
      entries
        .filter((entry) => !entry.target.classList.contains("action-disabled"))
        ?.forEach(({ target, isIntersecting }) => {
          target.classList.remove("is-not-intersecting");
          if (!isIntersecting) {
            target.classList.add("is-not-intersecting");
          }
          // this.observer.unobserve(target); // we want to observe the elements everytime
        });
    },
    observeElements() {
      this.observer.observe(this.$refs.parentFloorRef);
    },
    handleFloorClick(buildingCode, floorCode) {
      if (window && "analyticsLayer" in window) {
        window.analyticsLayer.send({
          event: "toggle_floor_space_list",
          payload: {
            opened: !this.isSpaceListExpanded,
            buildingCode,
            floorCode,
          },
        });
      }
      if (!this.isSpaceListExpanded) {
        if (this.$refs.parentFloorRef.classList.contains(NOT_IN_VIEW)) {
          const id = `#${buildingCode}_${floorCode}`;
          setTimeout(() => {
            smoothScrollToTargetId(id, "smooth", "center", "nearest");
          }, 300);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.molecule-sidebar-results {
  .row-padding-11px {
    padding: 0 11px;
  }

  .col-padding-4px {
    padding: 0 4px;
  }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .trigger-click-view-space {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 41px;
    z-index: 9;
    cursor: pointer;
  }

  .floor-info-parent {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .row-floor {
    position: relative;
    display: block;
    vertical-align: middle;
    width: 100%;
    padding: 12px 16px;
    cursor: pointer;
    background: #f7f7f7;

    &.action-disabled {
      pointer-events: none;
      background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 2px,
        #f7f7f7 1px,
        #f7f7f7 4px
      );

      .clearfix {
        margin: 0;
      }

      .get-space-floor {
        opacity: 0.3;
      }

      .leased-overlay {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 50%;
        margin-left: -46px;
        font-size: 14px;
        color: $black;
        font-weight: $regular;
        line-height: 16px;
      }

      h6 {
        max-width: 130px;
      }
    }

    .clearfix {
      position: relative;
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .combine {
      display: none;

      p {
        text-align: center;
        margin-bottom: 10px;
        margin-top: -5px;
        font-size: 14px;
        color: $black;
        font-weight: $regular;
        line-height: 17px;
      }
    }

    .hover-buttons {
      display: none;
      text-align: center;
      padding-bottom: 10px;

      .wrapper-btn {
        display: block;
      }

      .icon-edit {
        display: block;
        background: $white;
        border-radius: 2px;
        padding: 8px 10px;
        font-size: 14px;
        color: $black;
        font-weight: $regular;
        line-height: 17px;
        text-decoration: none;

        .icon {
          margin-right: 5px;
        }

        &:hover {
          background: $black;
          color: $white;

          .icon {
            color: $white !important;
          }
        }
      }

      .icon-select {
        display: block;
        border-radius: 2px;
        background: #4cc66d;
        padding: 8px 10px;
        font-size: 14px;
        color: $white;
        font-weight: $regular;
        line-height: 17px;
        text-decoration: none;

        svg {
          margin-right: 5px;
        }

        &.select-show {
          background: #4cc66d;

          &:hover {
            background: #3ade66;
          }

          .icon {
            margin-right: 5px;
            font-size: 15px !important;
            color: $white !important;
          }
        }

        &.btn-remove-item {
          background: #fcdfdf;
          color: #f93d3d;

          &:hover {
            background: #f93d3d;
            color: $white;
          }
        }
      }
    }

    .topTitle {
      position: relative;
      padding: 8px 239px 8px 0;
      margin-bottom: 10px;
    }

    h6 {
      display: block;
      font-size: 14px;
      color: $black;
      font-weight: $regular;
      line-height: 17px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      color: $black;
      font-weight: $regular;
      line-height: 17px;

      b {
        font-size: 14px;
        line-height: 17px;
      }

      &.floor-number {
        color: #7e7e7e;
        font-weight: $light;
        font-size: 14px;
        line-height: 17px;

        span {
          font-weight: $medium;
          margin: -1px 0 0 4px;
          font-size: 14px;
          line-height: 17px;
        }
      }

      &.when-text.db {
        color: $black;
        font-weight: $regular;
      }
    }

    .sqm-gla-total {
      color: #7e7e7e;
      font-weight: $light;
      position: absolute;
      top: 2px;
      right: 30px;

      b {
        color: $black;
        font-weight: $semiBold;
      }
    }

    .column3 {
      width: 32%;
    }

    .column2 {
      width: 49%;
    }

    .column3,
    .column2 {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }

  .show-all-spaces {
    display: none;

    &.active {
      display: block;
    }

    .row-floor {
      border: none;
      background: #f7f7f7;
      border-radius: 0;
      margin-top: 2px;
      padding: 12px 16px 2px 16px;
      cursor: initial;
      transition: all 0.2s ease-in-out 0s;

      &.leased-element-style {
        padding: 12px 16px;
        pointer-events: none;
        background: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent 2px,
          #f7f7f7 1px,
          #f7f7f7 4px
        );

        .clearfix {
          margin: 0;
        }

        .get-space-floor {
          opacity: 0.3;
        }

        .leased-overlay {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 16px;
          font-size: 14px;
          color: $black;
          font-weight: $regular;
          line-height: 17px;
        }
      }

      .clearfix {
        position: relative;

        .icon-lock {
          opacity: 0;
          position: absolute;
          right: 0;
          top: 2px;
          cursor: pointer;
          font-size: 14px;
          color: $black;
          font-weight: $light;
          line-height: 17px;
          transition: all 0.2s ease-in-out 0s;

          &.hide-space {
            display: none;
          }
        }
      }

      .line-element {
        margin-right: 12px;

        .icon {
          font-size: 14px !important;
        }

        &.line-checked {
          .icon {
            color: #4cc66d !important;
          }
        }
      }

      .textInfo {
        font-size: 14px;
        color: $black;
        font-weight: $light;
        line-height: 20px;
        margin-bottom: 9px;
      }

      .get-sqm {
        margin-right: 16px;
      }

      .get-sqm,
      .get-nr-people {
        font-weight: $light;
      }

      &:hover {
        background: #eaeaea;

        .clearfix {
          .icon-lock {
            opacity: 1;
          }
        }
      }

      &.activation {
        background: #eaeaea;

        .hover-buttons,
        .combine {
          display: block;
        }

        .icon-lock {
          &.view-space {
            display: none;
          }

          &.hide-space {
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }

  .parent-floor {
    margin-bottom: 0;
    transition: all 0.2s ease-in-out 0s;

    .arrow-custom {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.2s ease-in-out 0s;
      margin-top: -3px;

      &.rotate-icon {
        top: 30%;
        transform: rotate(180deg);
      }
    }

    &:hover {
      background: #eaeaea;
    }

    &.active {
      background: #eaeaea;
    }
  }

  .row-floor-active {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $main-color;

    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .clearfix {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }

  .nr-cube {
    width: 22px;
    height: 22px;
    line-height: 23px !important;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    background: $black;
    color: $white !important;
    border-radius: 4px;
    margin-top: -2px;
    margin-right: 5px;
    font-weight: $bold !important;

    &.nrCubeWhite {
      color: $black !important;
      background: $white;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .hiddenMobileElements {
    display: none !important;
  }
  .hiddenDesktopElements {
    display: block !important;
  }
  .mobileTextLeft {
    text-align: left !important;
  }

  .organism-building {
    #canvas-wrapper {
      width: 100%;
    }
  }
  .molecule-sidebar-results {
    .row-floor {
      &.action-disabled {
        .leased-overlay {
          font-size: 13px;
        }
      }

      h6 {
        font-size: 13px;
      }

      span {
        font-size: 13px;

        b {
          font-size: 13px;
        }

        &.floor-number {
          font-size: 13px;

          span {
            font-size: 13px;
          }
        }
      }
    }
  }

  .project {
    .organism-sidebar-building {
      display: block;
      right: 50% !important;
      top: auto;
      width: 362px;
      bottom: 79px !important;
      border-radius: 4px;
      height: calc(100vh - 204px) !important;
      @include prefix(transform, translateX(50%), ("webkit", "moz", "ms", "o"));
      @include prefix(transition, none, ("webkit", "moz", "ms", "o"));

      .header-box {
        .info-building {
          z-index: 99999;
          height: calc(100vh - 254px);
        }
      }

      .show-all-spaces {
        .row-floor {
          .line-element {
            margin-right: 5px;
          }

          .get-sqm {
            margin-right: 8px;
          }

          .clearfix {
            .icon-lock {
              top: 0;
              opacity: 1 !important;
              font-size: 13px;

              .icon {
                font-size: 12px !important;
              }
            }
          }

          span {
            font-size: 13px;

            b {
              font-size: 13px;
            }
          }

          .hover-buttons {
            a {
              padding: 6px 5px;
              font-size: 12px;

              .icon {
                font-size: 13px !important;
              }
            }
          }

          &:hover {
            background: #f7f7f7;
          }

          &.active {
            background: #eaeaea !important;
          }

          &.leased-element-style {
            .clearfix {
              .icon-lock {
                opacity: 0 !important;
              }
            }

            .leased-overlay {
              font-size: 13px;
            }
          }
        }
      }
    }

    .button-back {
      left: 0;

      .btn-cube {
        width: 55px;
        height: 55px;

        &:hover {
          @include prefix(transform, scale(1), ("webkit", "moz", "ms", "o"));
        }
      }

      &.active {
        top: 0;
      }
    }
  }
}
</style>
