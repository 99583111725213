<template>
  <div
    :class="{
      section: true,
      'section-video': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle :title="data.title" :subtitle="data.subtitle" />
    </div>
    <div class="video-top" v-if="data.video.video">
      <AtomVideoSlider :slider-video="data.video" />
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";
import AtomVideoSlider from "../../components/atoms/common/slider/AtomVideoSlider";
export default {
  name: "SectionVideo",
  components: { AtomVideoSlider, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.section-video {
  padding: 2.188rem 0 0;
  @media only screen and (max-width: 767px) {
    padding: 40px 10px 0;
  }
  .atom-title {
    padding: 0 3.75rem;
    margin-bottom: 3.125rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
      padding: 0;
    }
  }
  .video-top {
    @media only screen and (max-width: 767px) {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -20px;
    }
    .image-relative {
      @media only screen and (max-width: 767px) {
        min-height: 594px;
      }
    }
  }
}
</style>
