var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loader-element" }, [
    _c("div", { staticClass: "percent-content" }, [
      _c("p", [_vm._v("Loading 3D assets, please don’t close the page")]),
      _c("span", { staticClass: "number-percent" }, [
        _vm._v(_vm._s(_vm.percentage) + "%"),
      ]),
      _c("span", { staticClass: "line-main-color" }),
    ]),
    _c("span", {
      staticClass: "line-percent",
      style: { width: _vm.percentage + "%" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }