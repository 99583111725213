<template>
  <div class="row molecule-certifications">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculeCertifications"
}
</script>

<style lang="scss" scoped>
  .molecule-certifications {

  }
</style>