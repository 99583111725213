<template>
  <div
    class="section section-explore-building"
    :class="{ [`section${orderNo}`]: true }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title" v-if="data.title">
      <AtomTitle
        color="rgba(0,0,0,0.6)"
        color2="#000"
        :title="data.title"
        :subtitle="data.subtitle"
      />
    </div>
    <OrganismExplore :explore-card="data.items" />
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";
import OrganismExplore from "../../components/organisms/common/OrganismExplore";
export default {
  name: "SectionExploreBuilding",
  components: { OrganismExplore, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.section-explore-building {
  padding-top: 5rem;
  @media only screen and (max-width: 767px) {
    padding-top: 32px;
  }
  &.section1 {
    padding-top: 3.5rem;
    @media only screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .atom-title {
    margin-bottom: 2.25rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
  }
}
</style>
