<template>
  <div class="atom-dropdown" v-click-outside="handleClickOutside">
    <div
      class="selected-value"
      :class="{ active: isMenuOpen }"
      @click="toggleMenu"
    >
      <p class="value">
        <AtomIcon icon="location" />
        {{
          this.isCityPage
            ? this.cityProject.city
            : this.activeProject === "City/Location"
            ? this.activeProject
            : activeProjectObject.name
        }}
      </p>
    </div>
    <div v-if="isMenuOpen" class="menu-options">
      <div class="row row-elements" v-if="mappedProjects.length > 0">
        <div class="col col-6 left-sidebar">
          <p>City</p>
          <span
            v-for="(p, idx) in mappedProjects"
            :key="idx"
            class="option"
            :class="{
              active: isActive(p.city),
            }"
            @click="goToCityPage(p)"
            @mouseover="filterProjectsByCity(p.city)"
          >
            {{ p.city }}
          </span>
        </div>
        <div class="col col-6 right-sidebar">
          <p>Project</p>
          <span
            class="option"
            :class="{ active: activeProject === project.slug }"
            v-for="(project, idx1) in filteredProjects"
            :key="idx1"
            @click="handleProjectChange(project)"
          >
            {{ project.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "./AtomIcon";
import ClickOutside from "vue-click-outside";
export default {
  name: "AtomDropdown",
  components: { AtomIcon },
  directives: {
    ClickOutside,
  },
  props: {},
  data() {
    return {
      isMenuOpen: false,
      filteredProjects: [],
    };
  },
  mounted() {
    if (this.isCityPage) {
      this.filterProjectsByCity(this.cityProject.city);
    }
    if (this.activeCity) {
      this.filterProjectsByCity(this.activeCity);
    }
  },
  watch: {
    activeProject(newVal, oldVal) {
      if (oldVal !== newVal) {
        if (this.activeCity) {
          this.filterProjectsByCity(this.activeCity);
        }
      }
    },
    cityProject(newVal, oldVal) {
      this.clickedItem = newVal.city;
    },
  },
  computed: {
    projects() {
      return this.$store.state?.project?.projects || [];
    },
    containsSpaceSlug() {
      return this.$route?.params?.spaceId !== undefined;
    },
    containsProjectSlug() {
      return this.$route?.params?.projectId !== undefined;
    },
    isCityPage() {
      if (this.containsProjectSlug || this.containsSpaceSlug) {
        return false;
      }
      const path = this.$route.path;
      let status = false;
      this.buildingStaticPages.forEach((p) => {
        if (path.includes(p.path)) {
          status = true;
        }
      });
      return status;
    },
    cityProject() {
      const path = this.$route.path;
      let city = false;
      this.buildingStaticPages.forEach((p) => {
        if (path.includes(p.path)) {
          city = p;
        }
      });
      return city;
    },
    activeProject() {
      if (!this.containsSpaceSlug && !this.containsProjectSlug) {
        return "City/Location";
      }
      return this.$store.state.project?.activeProject || null;
    },
    activeProjectObject() {
      return this.projects?.find((p) => p.slug === this.activeProject) || null;
    },
    activeCity() {
      return this.activeProjectObject?.city || null;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    buildingStaticPages() {
      return this.generalConfig.buildingStaticPages || [];
    },
    mappedProjects() {
      const cities = [...this.buildingStaticPages];
      return [...new Map(cities?.map((p) => [p["city"], p])).values()] || [];
    },
  },
  methods: {
    handleProjectChange(option) {
      if (this.activeProjectObject?.slug === option.slug) return;
      const projectConstants = this.$store.getters?.constants?.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.CHANGE_ACTIVE_PROJECT
        ),
        option.slug
      );
      this.$router.push({
        name: "SpecificProject",
        params: { projectId: option.slug },
      });
      this.isMenuOpen = false;
    },
    goToCityPage(newPageOptions) {
      const routePieces = newPageOptions?.path?.split("/") ?? [];
      if (routePieces.length > 1) {
        const projectSlug = routePieces[1];
        if (projectSlug === this.activeProject) return;
        const projectConstants = this.$store.getters?.constants?.project;
        this.$store.dispatch(
          projectConstants.withNamespace(
            projectConstants.action.CHANGE_ACTIVE_PROJECT
          ),
          { projectSlug, loadProjectBasedOnRouteSlug: true }
        );
        this.$router.push({
          name: "StaticPageOfProject",
          params: { projectSlug },
        });
        this.isMenuOpen = false;
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleClickOutside() {
      this.isMenuOpen = false;
      this.resetComponent();
    },
    isActive(city) {
      if (this.clickedItem) return city === this.clickedItem;
      return city === this.activeCity;
    },
    filterProjectsByCity(city) {
      this.filteredProjects =
        this.projects?.filter((p) => p.city === city) || [];
      this.clickedItem = city;
    },
    resetComponent() {
      if (this.isCityPage) {
        this.filterProjectsByCity(this.cityProject.city);
        return;
      }
      this.filterProjectsByCity(this.activeCity);
    },
  },
};
</script>

<style lang="scss" scoped>
.atom-dropdown {
  position: relative;
  .selected-value {
    cursor: pointer;
    background: #f5f5f5;
    .value {
      padding: 0.5rem 1rem;
      @include font-main(1rem, $black, $medium, 1.188rem);
      transition: all 0.2s ease-in-out 0s;
      .icon {
        display: inline-block;
        vertical-align: middle;
        font-size: 1rem !important;
        margin-top: -0.125rem;
        margin-right: 0.5rem;
        transition: all 0.2s ease-in-out 0s;
        &:before {
          margin: 0;
        }
      }
    }
    &:hover,
    &.active {
      .value {
        background: #6894c81a;
        .icon {
          color: #6894c8 !important;
        }
      }
    }
  }

  .menu-options {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 0.5rem;
    background: $white;
    box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.12);
    padding: 1rem;
    width: 27rem;
    .row {
      margin: 0 -0.688rem;
      .col {
        padding: 0 0.688rem;
      }
    }
    p {
      letter-spacing: 0.01em;
      margin-bottom: 1rem;
      @include font-main(1.125rem, $black, $regular, 1.375rem);
    }

    .option {
      display: block;
      width: 100%;
      padding: 0.438rem 0.625rem;
      letter-spacing: 0.01em;
      text-align: center;
      margin-bottom: 0.5rem;
      cursor: pointer;
      background: #f5f5f5;
      @include font-main(1rem, $black, $regular, 1.188rem);
      transition: all 0.2s ease-in-out 0s;
      b {
        display: block;
        @include font-main(1rem, $black, $bold, 1.188rem);
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:hover,
      &.active {
        background: #6894c81a;
        color: #6893c7;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }
}
</style>
