<template>
  <div class="col col-xs-12 col-3 atom-padding">
    <div class="atom-team">
      <div class="atom-gradient-background">
        <div
          class="image-team"
          v-lazyload="{ handle: handleLazyLoad, url: assetUrl }"
        >
          <AtomLazyLoader :height="393" v-if="!isInView" />
          <picture>
            <source :srcset="assetUrl" />
            <img :src="assetUrl" :alt="name" />
          </picture>
        </div>
        <div class="row content-info align-items-center">
          <div class="col col-7">
            <span class="name">{{ name }}</span>
          </div>
          <div class="col col-5 align-items-end">
            <div class="row">
              <div class="inline-block">
                <a class="phone-icon icon-transparent" :href="`tel:${nr}`">
                  <span class="nr-hover">{{ nr }}</span>
                  <AtomIcon icon="phone" color="#fff" :size="20" />
                </a>
              </div>
              <div class="inline-block" v-if="urlLinkedin !== ''">
                <a
                  target="_blank"
                  class="linkedin-icon icon-transparent"
                  :href="`${urlLinkedin}`"
                >
                  <AtomIcon icon="linkedin" color="#fff" :size="20" />
                </a>
              </div>
            </div>
          </div>
          <div class="col col-12">
            <span>{{ position }}</span>
            <a :href="`mailto:${email}`">{{ email }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../../config";
import AtomIcon from "../common/AtomIcon";
import AtomLazyLoader from "../common/AtomLazyLoader";
export default {
  name: "AtomTeam",
  components: { AtomLazyLoader, AtomIcon },
  props: {
    image: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
      default: "",
    },
    nr: {
      required: true,
      type: String,
      default: "",
    },
    email: {
      required: true,
      type: String,
      default: "",
    },
    position: {
      required: true,
      type: String,
      default: "",
    },
    urlLinkedin: {
      required: true,
      type: String,
      default: "javascript:void(0)",
    },
  },
  data() {
    return {
      isInView: false,
    };
  },
  computed: {
    client() {
      return config.CLIENT;
    },
    cdnBase() {
      return this.$store.getters.cdnBase;
    },
    assetUrl() {
      if (!this.image || !this.isInView) return "";
      if (this.image.includes("://")) {
        return this.image;
      }
      return `${this.cdnBase}/${this.image}`;
    },
  },
  methods: {
    handleTracking() {
      if (
        config.CLIENT === "one" &&
        config.RUNTIME_ENV === "production" &&
        "lintrk" in window
      ) {
        window.lintrk("track", { conversion_id: 4403868 });
      }
    },
    handleLazyLoad() {
      this.isInView = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.atom-padding {
  margin-bottom: 1.375rem;
  padding: 0 0.688rem;
  @media only screen and (max-width: 767px) {
    padding: 0;
    margin-bottom: 30px;
  }
  .atom-team {
    position: relative;
  }
  .atom-gradient-background {
    position: relative;
    z-index: 5;
    padding: 1px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8911939775910365) 0%,
      rgba(255, 255, 255, 0.5634628851540616) 50%,
      rgba(0, 0, 0, 0) 100%
    );
    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
    .content-info {
      padding: 0.938rem 0.313rem;
      background: $black;
      @media only screen and (max-width: 767px) {
        padding: 15px 0;
      }
      .col {
        padding: 0 0.625rem;
        @media only screen and (max-width: 767px) {
          padding: 0 10px;
        }
      }
      .col-5 {
        min-height: 3.75rem;
        justify-content: center;
        @media only screen and (max-width: 767px) {
          min-height: auto;
        }
      }
      .col-12 {
        margin-top: 0.438rem;
        @media only screen and (max-width: 767px) {
          margin-top: 10px;
        }
        span,
        a {
          font-weight: 300;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #ffffff;
          text-decoration: none;
          display: inline-block;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
      .inline-block {
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.625rem;
        @media only screen and (max-width: 767px) {
          margin-right: 5px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .name {
        color: $white;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25rem;
        max-width: 9.063rem;
        text-transform: uppercase;
        display: block;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 20px;
          max-width: 300px;
        }
      }
      .icon-transparent {
        width: 2.063rem;
        height: 2.063rem;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(4px);
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 767px) {
          width: 30px;
          height: 30px;
        }
        .icon {
          font-size: 1.25rem !important;
          @media only screen and (max-width: 767px) {
            font-size: 18px !important;
          }
        }
      }
      .phone-icon {
        .nr-hover {
          position: absolute;
          display: block;
          background: $main-color;
          border-radius: 0.188rem;
          right: 2.5rem;
          color: $white;
          font-size: 0.875rem;
          letter-spacing: 1px;
          font-weight: 500;
          overflow: hidden;
          max-width: 0;
          padding: 0;
          white-space: nowrap;
          transition: all 0.2s ease-in-out 0s;
          @media only screen and (max-width: 1200px) {
            display: none !important;
          }
        }
        &:hover {
          .nr-hover {
            max-width: 9.375rem;
            padding: 0.5rem 0.938rem;
          }
        }
      }
    }
  }
}
</style>
