<template>
  <div ref="menu" :class="{ 'organism-menu-bar': true, scrolled: isScrolled }">
    <div class="menu-left">
      <div @click="scrollTopMethod">
        <router-link tag="div" :to="{ name: 'Home' }">
          <AtomLogo v-if="isScrolled" />
          <AtomLogo v-else white />
        </router-link>
      </div>
      <div class="hidden-xs">
        <AtomDropdown
          v-if="projectOptions.length > 1"
          :options="projectOptions"
          title-label="Change Project"
          @change="handleProjectChange"
        />
      </div>
    </div>
    <MoleculeMenuTrigger />
  </div>
</template>

<script>
import AtomLogo from "../atoms/common/AtomLogo";
import MoleculeMenuTrigger from "../molecules/MoleculeMenuTrigger";
import {
  convertUCWordsOrCamelCaseToDashCase,
  smoothScrollToTargetId,
} from "../../helpers/util";
import AtomDropdown from "@/components/atoms/common/AtomDropdown.vue";

export default {
  name: "OrganismMenuBar",
  components: { AtomDropdown, MoleculeMenuTrigger, AtomLogo },
  data() {
    return {
      isScrolled: false,
    };
  },
  computed: {
    currentProject() {
      return "";
    },
    projectOptions() {
      const projects = [...this.$store.state.project.projects];
      return projects.map((project) => {
        return { label: project.name, value: project.slug };
      });
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
  },
  mounted() {
    this.isScrolled = window.scrollY > 40;
    document.addEventListener("scroll", this.initScrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.initScrollListener);
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > 40;
    },
    scrollTopMethod() {
      const sections = this.$parent.sections;
      if (sections && sections.length > 1) {
        const targetSection = sections[0];
        const targetString = convertUCWordsOrCamelCaseToDashCase(
          targetSection.type
        );
        smoothScrollToTargetId(`#${targetString}`);
      }
    },
    handleProjectChange(option) {
      if (this.currentProject === option.value) return;
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.CHANGE_ACTIVE_PROJECT
        ),
        option.value
      );
      this.$router.push({
        name: "SpecificProject",
        params: { projectId: option.value },
      });
    },
  },
};
</script>

<style lang="scss">
.organism-menu-bar {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  transition: 0.5s ease-in-out 0s;
  .menu-left {
    position: fixed;
    top: 1.5rem;
    left: 1.25rem;
    display: flex;
    align-items: center;
    background: $white;
    border-radius: 6.25rem;
    padding: 0.5rem 1.5rem;
    gap: 1.25rem;
    @media only screen and (max-width: 767px) {
      border-radius: 100px;
      padding: 8px 24px 8px 8px;
      gap: 20px;
      top: 10px;
      left: 20px;
    }
  }
  .molecule-menu-trigger {
    position: fixed;
    top: 2.6rem;
    right: 1.25rem;
    @media only screen and (max-width: 767px) {
      top: 11px;
      right: 20px;
    }
  }
}
</style>
