import { Pin } from "@/components/organisms/project/building/3D/core/builders/Pin";
import { PinContentManager } from "@/components/organisms/project/building/3D/core/builders/PinContentManager";
import { isDesktop } from "@/helpers/mobile/DeviceType";
import {
  ActionManager,
  ExecuteCodeAction,
  Scene,
  TransformNode,
} from "babylonjs";

export type PinInfo = {
  spaceCode: string;
  location: { x: number; y: number; z: number };
  pinTitle: string;
  pinSubTitle?: string;
  pinDescription?: string;
  pinImageSrc?: string;
  pinVideoSrc?: string;
  scale?: number;
  static?: boolean;
  backgroundColor?: string;
  url?: string;
  openInNewTab?: boolean;
  options: Record<string, unknown>;
  category: string;
};

export class PinManager {
  pinContentManager: PinContentManager;
  managedPins: Pin[];
  scene: Scene;
  selectedPin: Pin | null;
  pinHolder: TransformNode;
  selectedCategory = "";
  constructor(pinContentManager: PinContentManager, scene: Scene) {
    this.pinContentManager = pinContentManager;
    this.managedPins = [];
    this.selectedPin = null;
    this.scene = scene;

    this.pinHolder = new TransformNode("pinsHolder", this.scene);

    return this;
  }

  addPin(data: Partial<PinInfo>) {
    const pin = new Pin(this.scene, data, this.pinContentManager);
    if (data.options) pin.options = data.options;
    pin.mesh.setParent(this.pinHolder);
    this.managedPins.push(pin);
    pin.pinManager = this;

    return pin;
  }

  addPinEvents() {
    if (this.managedPins.length > 0 && this.managedPins[0].mesh.metadata) {
      for (const pin of this.managedPins) {
        if (pin.mesh.metadata && pin.mesh.metadata.tooltipLine) {
          if (!pin.mesh.isEnabled()) continue;
          pin.mesh.metadata.tooltipLine.isVisible = !pin.mesh.isOccluded;
        }
      }
    } else {
      for (const pin of this.managedPins) {
        pin.mesh.enablePointerMoveEvents = true;
        pin.mesh.actionManager = new ActionManager(this.scene);
        pin.mesh.actionManager.registerAction(
          new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
            if (!isDesktop()) return;
            if (pin.clicked) return;

            if (this.selectedPin) {
              this.selectedPin?.reset();
              this.selectedPin = null;
            }
            pin.toggle(true);
          })
        );
        pin.mesh.actionManager.registerAction(
          new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => {
            if (!isDesktop()) return;
            if (pin.clicked) return;

            pin.toggle(false);
          })
        );

        pin.mesh.actionManager.registerAction(
          new ExecuteCodeAction(ActionManager.OnPickDownTrigger, () => {
            if (!pin.clicked) {
              this.unselectPin();
              this.selectedPin = pin;
            } else {
              this.selectedPin = null;
            }
            pin.clicked = !pin.clicked;
            if (!pin.isActive) {
              pin.toggle(!pin.isActive);
            }
            // To trigger pins properly without the point over events
            if (!isDesktop() && !pin.clicked) {
              pin.toggle(!pin.isActive);
            }
          })
        );
      }
    }
  }

  unselectPin() {
    if (this.selectedPin) {
      this.selectedPin.reset();
      this.selectedPin = null;
    }
  }

  dispose() {
    for (const pin of this.managedPins) {
      pin.dispose();
    }
  }

  showSpecificPins(category: string) {
    if (this.selectedCategory === category) return;
    this.selectedCategory = category;
    this.pinContentManager.dispose();
    if (this.selectedPin) {
      this.selectedPin.clicked = !this.selectedPin.clicked;
      this.selectedPin = null;
    }

    for (const pin of this.managedPins) {
      if (pin.category !== category && category !== "") {
        pin.hide(false);
      } else {
        pin.hide(true);
      }
    }
  }

  changeLang(lang: string) {
    for (const pin of this.managedPins) {
      pin.changeLang(lang);
    }
  }
}
