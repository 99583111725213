<template>
  <div :class="{'atom-specs-info': true, 'active': isActive}" @click="handleClick">
    <p v-html="text"></p>
  </div>
</template>

<script>
export default {
  name: "AtomSpecsInfo",
  components: {},
  props: {
    id: {
      required: false,
      type: String,
      default: ""
    },
    text: {
      required: false,
      type: String,
      default: ""
    },
    infoId: {
      required: true,
      type: String,
      default: ''
    },
    activeInfo: {
      required: true,
      type: String,
      default: 'item-0'
    }
  },
  computed: {
    isActive() {
      return this.$props.activeInfo === this.infoId;
    }
  },
  methods: {
    handleClick() {
      this.$emit('change', this.infoId);
    }
  }
}
</script>

<style lang="scss" scoped>
  .atom-specs-info {
    cursor: pointer;
    padding: 0.625rem 1.875rem;
    background: rgba(134, 123, 194, 0.04);
    transition: all .2s ease-in-out 0s;
    @media only screen and (max-width: 767px) {
      padding: 10px 30px;
    }
    &:last-child {
      border: none;
    }
    p {
      color: $black;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5rem;
      transition: all .2s ease-in-out 0s;
      @media only screen and (max-width: 767px) {
        font-size: 14px !important;
        line-height: 18px;
      }
      &::v-deep {
        a {
          color: #007dba;
          @media only screen and (max-width: 767px) {
            font-size: 14px !important;
            line-height: 18px;
          }
        }
      }
    }
    &.active, &:hover {
      background: #2E284F;
      p {
        color: $white;
      }
    }
  }
</style>