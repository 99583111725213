import { PinContentManager } from "@/components/organisms/project/building/3D/core/builders/PinContentManager";
import { AbstractMesh, Mesh, MeshBuilder, Scene } from "babylonjs";
import Constants, {
  PIN_ICON,
} from "@/components/organisms/project/building/3D/core/builders/Constants";

import {
  TextBlock,
  Image,
  Control,
  StackPanel,
  Rectangle,
  Line,
} from "babylonjs-gui";
import { PinInfo, PinManager } from "../managers/PinManager";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
import { isDesktop } from "@/helpers/mobile/DeviceType";

export class Pin {
  mesh: Mesh;
  isActive = false;
  clicked = false;
  scene: Scene;
  pinData: Partial<PinInfo>;
  pinContentManager: PinContentManager;
  pinManager?: PinManager;
  category = "default";
  backgroundColor = "0,0,0";
  sceneManager = BabylonClientManager.getSceneManager();
  options?: Record<string, unknown>;
  lang = "en";
  constructor(
    scene: Scene,
    pinData: Partial<PinInfo>,
    pinContentManager: PinContentManager
  ) {
    this.scene = scene;
    this.pinData = pinData;
    this.category = pinData.category ?? "default";
    this.backgroundColor = pinData.backgroundColor ?? "0,0,0";
    this.pinContentManager = pinContentManager;
    this.mesh = this.createMeshUI();

    return this;
  }

  createMeshUI() {
    const scale = this.pinData.scale ?? 1;
    const mesh = MeshBuilder.CreatePlane(
      Constants.PIN_NAME,
      { size: Constants.PIN_DEFAULT_SIZE },
      this.scene
    );
    mesh.occlusionQueryAlgorithmType =
      AbstractMesh.OCCLUSION_ALGORITHM_TYPE_CONSERVATIVE;
    mesh.isOccluded = true;
    mesh.occlusionType = AbstractMesh.OCCLUSION_TYPE_STRICT;
    mesh.visibility = Constants.DEBUG_PINS ? 1 : 0.0001;
    if (Constants.DEBUG_PINS) {
      mesh.showBoundingBox = true;
    }
    mesh.rotation.x = Math.PI / 4;
    mesh.position.y = 0.5;
    mesh.bakeCurrentTransformIntoVertices();
    mesh.freezeWorldMatrix();
    mesh.isPickable = false;

    this.lang = localStorage.getItem("locale") || "en";

    const ui = new StackPanel("anchor");
    ui.adaptHeightToChildren = true;
    ui.adaptWidthToChildren = true;
    ui.isHitTestVisible = false;
    ui.isVertical = false;
    ui.background = "transparent";

    this.sceneManager?.gui?.addControl(ui);

    const left = new Rectangle("left");
    left.height = 44 + 10 * (scale - 1) + "px";
    left.width = "28px";
    left.thickness = 0;
    left.background = "transparent";

    ui.addControl(left);

    const img0 = new Line("line");
    img0.x1 = 3;
    img0.y1 = 25;
    img0.x2 = 28;
    img0.y2 = 0;
    img0.lineWidth = 1;
    img0.color = `rgba(${this.backgroundColor}, 0.6)`;
    left.addControl(img0);

    const anchor = new StackPanel("anchor");
    anchor.adaptHeightToChildren = true;
    anchor.adaptWidthToChildren = true;
    anchor.isPointerBlocker = true;
    anchor.paddingLeft = "-2px";
    anchor.isVertical = false;
    anchor.color = Constants.PIN_STYLES.color;
    anchor.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    anchor.background = `rgba(${this.backgroundColor}, 0.5)`;

    anchor.onPointerEnterObservable.add(() => {
      this.pointerOver();
      anchor.background = `rgba(${this.backgroundColor}, 1.0)`;
    });
    anchor.onPointerOutObservable.add(() => {
      this.pointerOut();
      anchor.background = `rgba(${this.backgroundColor}, 0.5)`;
    });
    anchor.onPointerDownObservable.add(() => {
      this.pointerDown();
    });
    ui.addControl(anchor);

    if (this.category !== "default") {
      const url =
        "data:image/svg+xml," +
        encodeURIComponent(
          (PIN_ICON as { [key: string]: string })[this.category]
        );
      const img = new Image("image", url);
      img.width = "20px";
      img.height = "20px";
      img.paddingBottom = "5px";
      img.paddingLeft = "5px";
      img.paddingTop = "5px";
      img.paddingRight = "5px";
      img.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      anchor.addControl(img);
    }

    const text = new StackPanel("text");
    text.adaptHeightToChildren = true;
    text.adaptWidthToChildren = true;
    text.paddingLeft = 4 * (scale - 1) + "px";
    text.paddingRight = 4 * (scale - 1) + "px";
    text.paddingTop = 4 * (scale - 1) + "px";
    text.paddingBottom = 4 * (scale - 1) + "px";
    text.isVertical = true;
    anchor.addControl(text);

    if (this.pinData.pinTitle) {
      const title = new TextBlock("displayedText");
      title.fontSize = Constants.PIN_STYLES.fontSize + (scale - 1);
      title.fontFamily = Constants.PIN_STYLES.fontFamily;
      title.fontWeight = Constants.PIN_STYLES.fontWeight;
      title.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
      title.verticalAlignment = this.pinData.pinSubTitle
        ? Control.VERTICAL_ALIGNMENT_TOP
        : Control.VERTICAL_ALIGNMENT_CENTER;
      title.resizeToFit = true;
      title.forceResizeWidth = true;
      title.text = this.pinData.pinTitle;
      title.paddingLeft = this.category === "default" ? "4px" : "0px";
      title.paddingRight = this.pinData.static ? "4px" : "0px";
      title.color = Constants.PIN_STYLES.color;
      text.addControl(title);
    }

    if (this.pinData.pinSubTitle) {
      left.height = left.heightInPixels + 10 + "px";
      const subtitle = new TextBlock("displayedText2");
      subtitle.fontSize = 10 + (scale - 1);
      subtitle.fontFamily = Constants.PIN_STYLES.fontFamily;
      subtitle.fontWeight = "300";
      subtitle.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
      subtitle.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
      subtitle.resizeToFit = true;
      subtitle.forceResizeWidth = true;
      subtitle.text = this.pinData.pinSubTitle;
      subtitle.paddingLeft = this.category === "default" ? "4px" : "0px";
      subtitle.color = Constants.PIN_STYLES.color;
      text.addControl(subtitle);
    }

    if (this.pinData.url) {
      const url2 =
        "data:image/svg+xml," + encodeURIComponent(PIN_ICON.arrow_right);
      const img2 = new Image("image", url2);
      img2.width = "20px";
      img2.height = "20px";
      img2.paddingRight = "5px";
      img2.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
      img2.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
      anchor.addControl(img2);
    } else if (!this.pinData.static) {
      const url2 = "data:image/svg+xml," + encodeURIComponent(PIN_ICON.plus);
      const img2 = new Image("image", url2);
      img2.width = "20px";
      img2.height = "20px";
      img2.paddingBottom = "5px";
      img2.paddingLeft = "5px";
      img2.paddingTop = "5px";
      img2.paddingRight = "5px";
      img2.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
      img2.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
      anchor.addControl(img2);
    }

    this.sceneManager?.scene?.onAfterRenderObservable.addOnce(() => {
      left.top = anchor.heightInPixels - 1 + "px";
      ui.linkOffsetY = -Math.round(ui.heightInPixels / 2);
      ui.linkOffsetX = Math.round(ui.widthInPixels / 2);
    });

    ui.linkWithMesh(mesh);
    mesh.metadata = { tooltip: anchor, tooltipLine: ui };

    return mesh;
  }

  toggle(isActive: boolean) {
    this.sceneManager?.renderScene();
    this.isActive = isActive;
    if (this.isActive) {
      this.activate();
    } else {
      this.deactivate();
    }
  }

  reset() {
    this.toggle(false);
  }

  updateProperties(options: unknown) {
    Object.assign(this.mesh, options);
  }

  generateTextBox(
    data: {
      pinClasses: string[];
      imgClasses: string[];
      videoClasses: string[];
      titleClasses: string[];
      textClasses: string[];
      id: string | number;
    },
    yOffset: number[]
  ) {
    this.pinContentManager.generateBox(...data.pinClasses);
    this.pinContentManager.addInfoHeader(this.category, this.category);
    if (this.pinData.pinTitle) {
      this.pinContentManager.addTitle(
        this.pinData.pinTitle,
        ...data.titleClasses
      );
    }
    if (this.pinData.pinSubTitle) {
      this.pinContentManager.addSubTitle(
        this.pinData.pinSubTitle,
        ...data.textClasses
      );
    }
    if (this.pinData.pinImageSrc) {
      this.pinContentManager.addImg(
        this.pinData.pinImageSrc,
        this.mesh,
        yOffset,
        [...data.imgClasses]
      );
    }
    if (this.pinData.pinVideoSrc) {
      this.pinContentManager.addVideo(
        this.pinData.pinVideoSrc,
        this.mesh,
        yOffset,
        [...data.videoClasses]
      );
    }
    if (this.pinData.pinDescription) {
      this.pinContentManager.addText(
        this.pinData.pinDescription,
        ...data.textClasses
      );
    }

    if (!this.pinData.pinImageSrc) {
      this.pinContentManager.show(this.mesh, yOffset);
    }

    // Add close event
    this.pinContentManager.xButton?.addEventListener(
      "pointerdown",
      this.closePopUp.bind(this)
    );
  }

  showContent() {
    this.generateTextBox(
      {
        pinClasses: ["pinInfo", "pin-info-wrapper"],
        imgClasses: ["pin-img-wrapper"],
        videoClasses: ["video-pin"],
        titleClasses: ["simple-wrapper"],
        textClasses: ["simple-wrapper"],
        id: -1,
      },
      Constants.PIN_POP_UP_OFFSET
    );
  }

  dispose() {
    if (this.mesh.metadata && this.mesh.metadata.tooltip) {
      this.mesh.metadata.tooltip.onPointerOutObservable.clear();
      this.mesh.metadata.tooltip.onPointerDownObservable.clear();
      this.mesh.metadata.tooltip.onPointerEnterObservable.clear();
    }
    this.mesh.dispose();
    this.pinContentManager.dispose();
  }

  closePopUp() {
    this.pinContentManager.dispose();
    this.pinContentManager.xButton?.removeEventListener(
      "pointerdown",
      this.closePopUp
    );
    this.reset();
    this.sceneManager?.renderScene();
  }

  hide(isVisible: boolean) {
    this.mesh.setEnabled(isVisible);
    this.mesh.metadata.tooltipLine.isVisible = isVisible;
    this.mesh.metadata.tooltipLine.isHitTestVisible = isVisible;
    this.sceneManager?.renderScene();
  }

  pointerOut() {
    this.sceneManager?.renderScene(100);
    document.body.style.cursor = "default";
  }

  pointerOver() {
    this.sceneManager?.renderScene(-1);
    document.body.style.cursor = "pointer";
  }

  pointerDown() {
    if (this.pinData.static) return;
    if (this.pinData.url) {
      const target = this.pinData.openInNewTab ? "_blank" : "_self";
      window.open(this.pinData.url, target);
      return;
    }
    if (this.pinManager != null) {
      this.pinManager.unselectPin();
      this.pinManager.selectedPin = this;
    }
    this.isActive = !this.isActive;
    this.toggle(this.isActive);
    document.body.style.cursor = "default";
  }

  activate() {
    this.showContent();
    if (isDesktop()) {
      this.mesh.metadata.tooltip.alpha = 0;
    } else {
      this.mesh.metadata.tooltipLine.alpha = 0;
    }
  }

  deactivate() {
    this.pinContentManager.dispose();
    this.mesh.metadata.tooltip.background = `rgba(${this.backgroundColor}, 0.5)`;
    if (isDesktop()) {
      this.mesh.metadata.tooltip.alpha = 1;
    } else {
      this.mesh.metadata.tooltipLine.alpha = 1;
    }
  }

  changeLang(lang: string) {
    this.lang = lang;
  }
}
