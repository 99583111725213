var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "molecule-sidebar-results" },
    _vm._l(_vm.listData, function (building, i) {
      return _c(
        "div",
        { key: i, staticClass: "building-list" },
        _vm._l(building.floors, function (floor, j) {
          return _c(
            "div",
            { key: j, staticClass: "floor-info-parent" },
            [
              _c("MoleculeSidebarResult", {
                attrs: { building: building, floor: floor },
                on: {
                  buildingInteractionsRef: function (value) {
                    return _vm.$emit("buildingInteractionsRef", value)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }