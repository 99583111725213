<template>
  <div
    :class="{
      section: true,
      'section-location-v2': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title" v-if="data.heading || data.description">
      <AtomTitle :title="data.heading" :subtitle="data.description" />
    </div>
    <div v-if="data.content" class="row row-location">
      <div class="col col-xs-12 col-sm-12 col-6">
        <div class="content-location" v-html="data.content"></div>
      </div>
      <div class="col col-xs-12 col-sm-12 col-6">
        <OrganismLocation :location-data="data" />
      </div>
    </div>
    <div v-else>
      <OrganismLocation :location-data="data" />
    </div>
  </div>
</template>

<script>
import OrganismLocation from "../../../../components/organisms/project/OrganismLocation";
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
export default {
  name: "SectionLocationV2",
  components: { AtomTitle, OrganismLocation },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  created() {},
};
</script>

<style lang="scss">
.section-location-v2 {
  padding: 4rem 0 0;
  @media only screen and (max-width: 767px) {
    padding: 32px 0 0;
  }
  .top-title {
    .atom-title {
      margin-bottom: 3.125rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
  }
  .row-location {
    margin: 0 -1rem;
    @media only screen and (max-width: 991px) {
      margin: 0;
    }
    .col {
      padding: 0 1rem;
      @media only screen and (max-width: 991px) {
        padding: 0;
      }
    }
    .content-location {
      p {
        letter-spacing: -0.055rem;
        margin-bottom: 6rem;
        @include font-main(2.75rem, $black, $light, normal);
        @media only screen and (max-width: 991px) {
          margin-bottom: 16px;
          @include font-main(18px, $black, $light, 20px);
        }
        b {
          @include font-main(2.75rem, $black, $bold, normal);
          @media only screen and (max-width: 991px) {
            @include font-main(18px, $black, $bold, 20px);
          }
        }
      }
      span {
        display: block;
        letter-spacing: -0.04rem;
        @include font-main(2rem, $black, $light, normal);
        @media only screen and (max-width: 991px) {
          margin-bottom: 16px;
          @include font-main(18px, $black, $light, 20px);
        }
        b {
          @include font-main(2rem, $black, $bold, normal);
          @media only screen and (max-width: 991px) {
            @include font-main(18px, $black, $bold, 20px);
          }
        }
      }
    }
  }

  .organism-location {
    .atom-image-title {
      .background-gradient {
        background: transparent;
        padding: 0 2.188rem 2.188rem;
        @media only screen and (max-width: 767px) {
          padding: 0 10px 20px;
        }
        .atom-title {
          h2 {
            margin-bottom: 0.25rem;
            font-size: 2rem;
            line-height: 2.375rem;
            @media only screen and (max-width: 767px) {
              font-size: 22px;
              line-height: 26px;
              margin-bottom: 4px;
            }
            b {
              font-size: 2rem;
              line-height: 2.375rem;
              @media only screen and (max-width: 767px) {
                font-size: 32px;
                line-height: 38px;
              }
            }
          }
          h3 {
            font-size: 1rem;
            line-height: 1.188rem;
            @media only screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 19px;
            }
          }
        }
      }
    }
    .sidebar {
      top: 6rem;
      height: 88.8%;
      width: 33.5rem;
      @media only screen and (max-width: 767px) {
        top: initial;
        bottom: -4px;
        right: initial;
        left: -110%;
        width: 100%;
        height: 645px;
        &.active {
          left: 0;
        }
      }
      .sidebar-locations {
        height: 28.125rem;
        margin-bottom: 1.25rem;
        @media only screen and (max-width: 767px) {
          height: 410px;
          margin-bottom: 15px;
        }
        .wrapper-sidebar {
          .atom-location-type {
            background: #f8fafc;
            .change-info-boxes {
              padding: 1.25rem 9.375rem 1.25rem 1.25rem;
              border-color: transparent;
              font-weight: 700;
              font-size: 1.125rem;
              line-height: 1.313rem;
              text-transform: uppercase;
              @media only screen and (max-width: 767px) {
                padding: 15px 100px 15px 15px;
                font-size: 14px;
                line-height: 18px;
              }
              .buttons-tabs {
                right: 3.75rem;
                @media only screen and (max-width: 767px) {
                  right: 40px;
                }
                span {
                  .icon {
                    color: $blue !important;
                  }
                  &:hover,
                  &.active {
                    background: $blue;
                    .icon {
                      color: $white !important;
                    }
                  }
                }
              }
              .circle {
                right: 0.938rem;
                opacity: 0;
                transition: all 0.2s ease-in-out 0s;
                @media only screen and (max-width: 767px) {
                  right: 10px;
                }
                .icon {
                  color: $black !important;
                }
              }
            }
            .atom-route {
              padding: 0.75rem 9.688rem 0.75rem 50px;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.188rem;
              border-color: rgba(0, 0, 0, 0.05);
              @media only screen and (max-width: 767px) {
                padding: 10px 105px 10px 40px;
                font-size: 12px;
                line-height: 16px;
              }
              b {
                left: 1.25rem;
                color: $blue;
                font-weight: 400;
                transition: all 0.2s ease-in-out 0s;
                @media only screen and (max-width: 767px) {
                  left: 15px;
                }
              }
              .add-time {
                right: 1.25rem;
                @media only screen and (max-width: 767px) {
                  right: 15px;
                }
                .icon {
                  color: $blue !important;
                }
              }
              &:last-child {
                border: none;
                border-radius: 0 0 0.625rem 0.625rem;
                @media only screen and (max-width: 767px) {
                  border-radius: 0 0 10px 10px;
                }
              }
              &:hover,
              &.atom-route-active {
                background: $blue;
                b {
                  color: $white;
                }
                .add-time {
                  .icon {
                    color: $white !important;
                  }
                }
              }
            }
            &:hover {
              .change-info-boxes {
                .circle {
                  opacity: 1;
                }
              }
            }
            &.active {
              background: #f5f5f5;
              border-radius: 0.625rem;
              margin: 0.25rem 0;
              @media only screen and (max-width: 767px) {
                border-radius: 10px;
                margin: 4px 0;
              }
              .change-info-boxes {
                border-color: rgba(0, 0, 0, 0.05);
                .circle {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    &.no-sidebar {
      height: 100%;
      @media only screen and (max-width: 991px) {
        height: 50vh;
      }
      .molecule-google-map {
        @media only screen and (max-width: 767px) {
          height: 100%;
        }
      }
    }
  }
}
</style>
