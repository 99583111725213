<template>
  <div class="atom-text">
    <div v-for="(line, index) in lines" :key="index" v-html="line"></div>
  </div>
</template>

<script>
export default {
  name: "AtomText",
  props: {
    lines: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    },
  }
}
</script>

<style lang="scss">
  .atom-text {
    * {
      font-size: 1.375rem;
      line-height: 1.625rem;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 19px;
      }
    }
    p {
      margin-bottom: 0.313rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
  }
</style>