<template>
  <div
    :class="{
      section: true,
      'section-few-facts': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle :title="data.title" :subtitle="data.subtitle" />
    </div>
    <AtomFacts :facts="data.facts" />
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";
import AtomFacts from "../../components/atoms/common/AtomFacts";

export default {
  name: "SectionFewFacts",
  components: { AtomFacts, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.section-few-facts {
  border-bottom: 1px solid #ecf5f8;
  text-align: center;
  padding: 4rem 0 2.8rem;
  @media only screen and (max-width: 767px) {
    padding: 12px 0 22px;
  }
  .atom-title {
    margin-bottom: 1.875rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}
</style>
