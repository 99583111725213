<template>
  <div class="atom-tenant">
    <a v-for="(tenant, index) in tenants" :key="index" :href="tenant.url">
      <img :src="resourceUrl(tenant.image)" alt="">
    </a>
  </div>
</template>

<script>
export default {
  name: "AtomTenants",
  props: {
    tenants: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    }
  }
}
</script>

<style lang="scss">
  .atom-tenant {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    a {
      display: inline-block;
      vertical-align: middle;
      margin-right: 4.375rem;
      padding: 0.938rem 0;
      @media only screen and (max-width: 767px) {
        margin: 15px;
      }
    }
    img {
      max-height: 4.063rem;
      max-width: 8.438rem;
      @media only screen and (max-width: 767px) {
        max-height: 25px;
        max-width: 120px;
      }
    }
  }
</style>