import { TransformNode } from "babylonjs";
import { Utils } from "../builders/Utils";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";

export class FitoutsManager {
  fitoutParentNode?: TransformNode;

  constructor() {
    this.fitoutParentNode = undefined;
  }

  addFitoutNode(node: TransformNode) {
    this.fitoutParentNode = node;
    this.resetNodes();
  }

  resetNodes() {
    if (!this.fitoutParentNode)
      return console.error("No fitout node parent found");
    Utils.toggleNodes(this.fitoutParentNode.getChildren(), false);
  }

  // this method is used to retrieve fitouts names and unique values
  getFitoutNames(fitoutsNode: TransformNode) {
    if (!fitoutsNode) return [];
    return (
      fitoutsNode?.getChildren()?.map((child, idx) => {
        let name = `Fitout ${idx + 1}`;
        for (const fitoutName of BabylonClientManager.getSceneManager()?.payload
          ?.settings?.floor?.fitoutsInfo) {
          fitoutName.ids.find((id: string) => {
            if (id == child.name) {
              name = fitoutName.name;
            }
          });
        }
        return {
          text: name,
          value: child.id || child.name,
        };
      }) || []
    );
  }

  changeFitout(fitoutName: string) {
    if (!this.fitoutParentNode)
      return console.error("No fitout node parent found");
    this.resetNodes();
    if (fitoutName === "none") return;
    const nodes = this.fitoutParentNode?.getChildren((child) => {
      return child.name === fitoutName;
    });
    if (!nodes || nodes.length === 0)
      return console.error("Fitout node not found " + fitoutName);
    nodes[0].setEnabled(true);
  }
}
