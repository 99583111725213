<template>
  <div class="atom-toggle">
    <span class="toggle-element"  @click="toggle" :class="{enabled: toggleValue}"><span class="inner"></span></span>
  </div>
</template>

<script>
export default {
  name: "AtomToggle",
  props: ['value'],
  data() {
    return {
      toggleValue: this.value,
    }
  },
  computed: {
    isToggled: {
      get: function () {
        return this.toggleValue
      },
      set: function (value) {
        // if (typeof value === 'undefined') return;
        this.toggleValue = value;
        this.$emit('input', value);
      }
    },
  },
  watch: {
    value: function (value) {
      this.isToggled = value;
    }
  },
  methods: {
    toggle() {
      this.isToggled = !this.isToggled;
      this.$emit('toggle', this.isToggled);
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-element {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -0.25rem;
  margin-left: -0.5rem;
  display: inline-block;
  vertical-align: middle;
  width: 4rem;
  height: 2rem;
  background: #ECF5F8;
  border-radius: 6.25rem;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    top: -1px;
    margin-left: -8px;
    width: 54px;
    height: 26px;
    border-radius: 100px;
  }
  .inner {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background: $blue;
    border-radius: 1.5rem;
    margin: 0.25rem;
    //transition: all 0.2s ease-in-out 0s;
    @media only screen and (max-width: 767px) {
      width: 19px;
      height: 19px;
      border-radius: 19px;
      margin: 4px;
    }
  }
  &.enabled {
    .inner {
      margin: 0.25rem 0.25rem 0.25rem 2.188rem;
      @media only screen and (max-width: 767px) {
        margin: 4px 4px 4px 31px;
      }
    }
  }
}
</style>