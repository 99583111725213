var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-google-map" }, [
    _c("div", {
      ref: "map",
      staticClass: "google-map",
      attrs: { "data-marker-id": _vm.activeCenterMarkerId },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }