var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-icon-and-text" }, [
    _c("div", { staticClass: "box-content" }, [
      _c(
        "div",
        {
          staticClass: "border-color",
          style: "border-color: " + _vm.borderColor,
        },
        [
          _vm.iconImage
            ? _c("img", {
                attrs: {
                  src: _vm.resourceUrl(_vm.iconImage),
                  alt: "icon " + _vm.title,
                },
              })
            : _c("AtomIcon", { attrs: { icon: _vm.icon } }),
        ],
        1
      ),
      _vm.hasTitle
        ? _c("div", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.title) },
          })
        : _vm._e(),
      _vm.hasDescription
        ? _c("div", {
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.description) },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }