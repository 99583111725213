<template>
  <div v-if="!section || !enabled"></div>
  <SectionBuilding
    ref="buildingSection"
    v-else-if="section === 'Building'"
    :hash="hash"
    :data="activeDataSet"
    :order-no="orderNo"
  />
  <SectionBrochureButton
    v-else-if="section === 'BrochureButton'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
  />
  <SectionFeatures
    v-else-if="section === 'Features'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
  />
  <SectionSustainability
    v-else-if="section === 'Sustainability'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
    :variant="variant"
  />
  <SectionParking
    v-else-if="section === 'Parking'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
    :variant="variant"
  />
  <SectionSpecs
    v-else-if="section === 'Specs'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
    :variant="variant"
  />
  <SectionLocation
    v-else-if="section === 'Location'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
    :variant="variant"
  />
  <SectionGallery
    v-else-if="section === 'Gallery'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
    :variant="variant"
  />
  <SectionExploreBuilding
    v-else-if="section === 'Explore'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
  />
  <SectionContact
    v-else-if="section === 'Contact'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
    :variant="variant"
  />
  <SectionImageList
    v-else-if="section === 'ImageList'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
  />
  <SectionImageOrVideo
    v-else-if="section === 'ImageOrVideo'"
    :data="activeDataSet"
    :hash="hash"
    :extra="extra"
    :order-no="orderNo"
  />
  <SectionFewFacts
    v-else-if="section === 'FewFacts'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
  />
  <SectionIconsDescriptions
    v-else-if="section === 'IconsDescriptions'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
  />
  <SectionDiscoverArea
    v-else-if="section === 'DiscoverArea'"
    :data="activeDataSet"
    :hash="hash"
    :order-no="orderNo"
  />
</template>

<script>
import SectionBuilding from "./section/SectionBuilding";
import SectionParking from "./section/SectionParking";
import SectionFeatures from "./section/SectionFeatures";
import SectionContact from "./section/SectionContact";
import SectionGallery from "./section/SectionGallery";
import SectionLocation from "./section/SectionLocation";
import SectionSpecs from "./section/SectionSpecs";
import SectionSustainability from "./section/SectionSustainability";
import SectionImageList from "./section/SectionImageList";
import SectionImageOrVideo from "./section/SectionImageOrVideo";
import SectionExploreBuilding from "../sections/SectionExploreBuilding";
import SectionFewFacts from "../sections/SectionFewFacts";
import SectionIconsDescriptions from "../sections/SectionIconsDescriptions";
import SectionDiscoverArea from "../sections/SectionDiscoverArea";
import SectionBrochureButton from "./section/SectionBrochureButton";
export default {
  name: "SectionSelector",
  components: {
    SectionBrochureButton,
    SectionDiscoverArea,
    SectionIconsDescriptions,
    SectionFewFacts,
    SectionBuilding,
    SectionExploreBuilding,
    SectionParking,
    SectionFeatures,
    SectionContact,
    SectionGallery,
    SectionLocation,
    SectionSpecs,
    SectionSustainability,
    SectionImageList,
    SectionImageOrVideo,
  },
  props: {
    section: {
      required: true,
      type: String,
      default: "",
    },
    variant: {
      required: true,
      type: String,
      default: "",
    },
    multiProject: {
      required: false,
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      type: Object | Array,
      default: function () {
        return {};
      },
    },
    extra: {
      required: false,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    enabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  computed: {
    activeDataSet() {
      if (this.multiProject) {
        return this.data[this.$store.state.project.activeProject];
      }
      return this.data;
    },
  },
};
</script>
