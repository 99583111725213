var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "lazy-loader-wrapper",
      style: {
        minHeight: _vm.height + "px",
        backgroundColor: _vm.backgroundColor,
      },
    },
    [
      _c("div", { staticClass: "lazy-loader" }, [
        _c("div", { style: { background: _vm.loaderColor } }),
        _c("div", { style: { background: _vm.loaderColor } }),
        _c("div", { style: { background: _vm.loaderColor } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }