<template>
  <div class="atom-loader-project">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AtomLoaderProject",
  props: {}
}
</script>

<style lang="scss" scoped>
  .atom-loader-project {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
</style>