<template>
  <div
    :class="{
      'organism-image-slider-v2': true,
      'no-videos': !(hasImages && hasVideos),
    }"
  >
    <div class="change-carousel" v-if="hasImages && hasVideos">
      <span
        class="button-change photoButton active"
        @click="handlePhotoClick"
        data-type="image"
        >Photo</span
      >
      <span
        class="button-change videoButton"
        @click="handleVideoClick"
        data-type="video"
        >Video</span
      >
    </div>
    <MoleculeImageSlider
      ref="slider"
      :slider-data="sliderData"
      :type-of="typeOf"
      @initialized="handleCarouselInitialized"
    />
    <MoleculeImageThumbSlider
      ref="thumbSlider"
      v-if="carouselInitialized"
      :thumbnails="thumbData"
      @thumb-change="handleThumbChange"
      :type-of="typeOf"
    />
  </div>
</template>

<script>
import MoleculeImageSlider from "../../molecules/common/MoleculeImageSlider";
import MoleculeImageThumbSlider from "../../molecules/common/MoleculeImageThumbSlider";

export default {
  name: "OrganismImageSliderV2",
  components: { MoleculeImageThumbSlider, MoleculeImageSlider },
  props: {
    sliderData: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      typeOf: "image",
      carouselInitialized: false,
    };
  },
  computed: {
    thumbData() {
      return this.typeOf === "image"
        ? this.sliderData.images
        : this.sliderData.videos;
    },
    hasImages() {
      return this.sliderData.images ? this.sliderData.images.length > 0 : false;
    },
    hasVideos() {
      return this.sliderData.videos ? this.sliderData.videos.length > 0 : false;
    },
  },
  beforeUpdate() {
    if (this.typeOf !== "image" && !this.hasVideos) {
      this.typeOf = "image";
    }
  },
  methods: {
    handleThumbChange(index) {
      this.$refs.slider.instance.trigger("to.owl.carousel", [index, 300]);
    },
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
    handlePhotoClick() {
      const photoButton = this.$el.querySelector(".photoButton");
      const videoButton = this.$el.querySelector(".videoButton");
      if (!photoButton.classList.contains("active")) {
        photoButton.classList.add("active");
        this.typeOf = "image";
        videoButton.classList.remove("active");
      }
    },
    handleVideoClick() {
      const videoButton = this.$el.querySelector(".videoButton");
      const photoButton = this.$el.querySelector(".photoButton");
      if (!videoButton.classList.contains("active")) {
        videoButton.classList.add("active");
        this.typeOf = "video";
        photoButton.classList.remove("active");
      }
    },
    handleCarouselInitialized(initialized) {
      this.carouselInitialized = initialized;
    },
  },
};
</script>

<style lang="scss">
.organism-image-slider-v2 {
  position: relative;
  .background-image {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  img {
    display: block;
    width: 100%;
    opacity: 0;
  }
  .change-carousel {
    padding: 0 3.75rem;
    @media only screen and (max-width: 767px) {
      padding: 0 10px;
    }
    .button-change {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.313rem;
      text-transform: uppercase;
      color: $black;
      padding-bottom: 0.188rem;
      border-bottom: 0.25rem solid transparent;
      display: inline-block;
      cursor: pointer;
      margin-right: 2rem;
      transition: all 0.2s ease-in-out 0s;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 17px;
        padding-bottom: 3px;
        border-bottom: 4px solid transparent;
        margin-right: 20px;
      }
      &:hover {
        color: $blueDark;
      }
      &.active {
        color: $blueDark;
        border-color: $blueDark;
      }
    }
  }
  .owl-nav {
    button {
      position: absolute;
      top: 75%;
      width: 3rem;
      height: 3rem;
      border-radius: 3rem;
      margin-right: 0;
      border: 1px solid #fff !important;
      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 767px) {
        top: 50%;
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }
      span {
        display: block;
        font-weight: 300;
        font-size: 2.5rem;
        width: 3rem;
        height: 3rem;
        line-height: 2.7rem;
        @media only screen and (max-width: 767px) {
          font-size: 28px;
          width: 30px;
          height: 30px;
          line-height: 27px;
        }
      }
      &.owl-prev {
        left: 32px;
        @media only screen and (max-width: 767px) {
          left: 10px;
        }
      }
      &.owl-next {
        right: 32px;
        @media only screen and (max-width: 767px) {
          right: 10px;
        }
      }
    }
  }
  .molecule-image-slider {
    .image-relative {
      margin-bottom: 1.5rem;
      min-height: 100px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 15px;
      }
    }
    .description {
      position: relative;
      background: transparent;
      color: $black;
      padding: 0 3.75rem 1.5rem;
      font-weight: 400;
      font-size: 1.375rem;
      line-height: 1.625rem;
      display: block !important;
      @media only screen and (max-width: 767px) {
        padding: 0 10px 15px;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}
</style>
