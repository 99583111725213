var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { section: true, "section-custom-model": true }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
    },
    [
      _c("OrganismSidebarAccordion", {
        attrs: {
          "pins-info": _vm.data.pinsInfo,
          "sidebar-title": _vm.data.sidebarTitle,
        },
      }),
      _c(
        "div",
        { staticClass: "canvas-model-wrapper" },
        [
          _c("organism-building", {
            ref: "buildingRef",
            attrs: { "custom-model": _vm.data.customModel },
          }),
          _c("OrganismPinInfoBox", { attrs: { "pin-data": _vm.pinBoxInfo } }),
        ],
        1
      ),
      _c("Organism3DModelInteraction"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }