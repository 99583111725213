<template>
  <component :is="componentVariant" :data="data" :hash="hash" :order-no="orderNo" />
</template>

<script>
import SectionSpecsV1 from "./specs/SectionSpecsV1";
import SectionSpecsV2 from "./specs/SectionSpecsV2";
export default {
  name: "SectionSpecs",
  components: {},
  props: {
    variant: {
      required: true,
      type: String,
      default: '',
    },
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  data() {
    return {
      componentVariant: null
    }
  },
  created() {
    this.handleVariant(this.variant);
  },
  watch: {
    variant: function (newVariant, oldVariant) {
      this.handleVariant(newVariant);
    }
  },
  methods: {
    handleVariant(variant) {
      switch (variant) {
        case 'v2':
          this.componentVariant = SectionSpecsV2;
          break;
        default:
          this.componentVariant = SectionSpecsV1;
          break;
      }
    }
  }
}
</script>

<style lang="scss">
  .section-specs {
    .atom-title {
      h2 {
        font-weight: $medium;
        font-size: 0.875rem;
        line-height: 1.063rem;
        letter-spacing: 0.05em;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 17px;
        }
      }
      h3 {
        margin-top: 0.5rem;
        font-weight: $medium;
        font-size: 1.375rem;
        line-height: 1.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 22px;
          line-height: 26px;
          margin-top: 8px;
        }
      }
    }
    .image-technical-specs {
      position: relative;
      img {
        display: block;
        width: 100%;
      }
    }
  }
</style>