<template>
  <div :class="{'organism-slider': true}" >
    <div :class="images.length>0?'image-slider':'text-slider'">
      <atom-arrow-button-slider :right="false" @Previous="Previous"></atom-arrow-button-slider>
      <molecule-slider-slide v-if="images.length>0" v-for="(image, index) in images"
                             :key="image"
                             :image-src="image"
                             :index="index"
                             :visibleSlide="visibleSlide"
                             :direction="direction">
      </molecule-slider-slide>
      <molecule-slider-slide v-if="slidess.length>0" v-for="(slide, index) in slidess"
                             :key="slide.titles[0] + index"
                             :titles="slide.titles"
                             :descriptions="slide.descriptions"
                             :background="slide.background"
                             :index="index"
                             :visibleSlide="visibleSlide"
                             :direction="direction">
      </molecule-slider-slide>
      <atom-arrow-button-slider :right="true" @Next="Next"></atom-arrow-button-slider>
    </div>
    <span v-if="images.length>0" >{{visibleSlide + 1}} / {{sliderLength}}</span>

  </div>
</template>

<script>

import AtomArrowButtonSlider from "@/components/atoms/common/Slider2/AtomArrowButtonSlider";
import MoleculeSliderSlide from "@/components/molecules/common/MoleculeSliderSlide";
export default {
  name: "OrganismSlider",
  components: {MoleculeSliderSlide, AtomArrowButtonSlider},
  props: {
    slidess:{
      required: false,
      type: Array,
      default: function () {
        return [
            {
              titles: {
                required: false,
                type: Array,
                default: function () {
                  return []
                }
              },
              descriptions: {
                required: false,
                type: Array,
                default: function () {
                  return []
                }
              },
              background: {
                required: false,
                type: String,
                default: function () {
                  return '#5bb4e5'
                }
              }
          }
          ]
      }
    },


    images: {
      required: false,
      type: Array,
      default: function () {
        return []
      }
    }
    },
  data: function () {
    return {
      visibleSlide: 0,
      direction: 'left'
    }
  },
  methods: {
    Previous: function (){
      this.visibleSlide = ((this.visibleSlide - 1)>=0)?this.visibleSlide-1:this.sliderLength-1;
      this.direction = "right";

    },
    Next: function (){
      this.visibleSlide = ((this.visibleSlide + 1)<(this.sliderLength))?this.visibleSlide + 1:0;
      this.direction = "left";
    }
  },
  computed: {
    sliderLength(){
      if(this.images.length>0)
      return this.images.length;
      return this.slidess.length;
    }
  },
  mounted() {}
}
</script>

<style lang="scss" >
  .organism-slider {
    .image-slider, .text-slider{
      display: flex;
      width: 100%;
      background: white;
      overflow: hidden;
    }

    span{
      display: block;
      width: 100%;
      text-align: center;
      margin: 20px 0;
      font-size: 18px;
      font-weight: 600;

      @media only screen and (max-width: 760px) {
        margin: 10px 0;
      }
      @media only screen and (max-width: 580px) {
        font-size: 12px;
        margin: 5px 0;
      }
      @media only screen and (max-width: 400px) {
        margin: 0;
      }
    }
    margin-bottom: 40px;

    .image-slider{
      height: 500px;

      @media only screen and (max-width: 1000px) {
           height: 450px;
      }
      @media only screen and (max-width: 850px) {
          height: 400px;
      }
      @media only screen and (max-width: 760px) {
          height: 350px;
      }
      @media only screen and (max-width: 660px) {
          height: 300px;
      }
      @media only screen and (max-width: 580px) {
           height: 250px;
      }
      @media only screen and (max-width: 480px) {
          height: 200px;
      }
      @media only screen and (max-width: 400px) {
           height: 150px;
      }
      @media only screen and (max-width: 300px) {
         height: 100px;
      }
    }

    .text-slider{
      height: 700px;

      @media only screen and (max-width: 1000px) {
        height: 650px;
      }
      @media only screen and (max-width: 850px) {
        height: 600px;
      }
      @media only screen and (max-width: 760px) {
        height: 550px;
      }
      @media only screen and (max-width: 660px) {
        height: 500px;
      }
      @media only screen and (max-width: 580px) {
        height: 450px;
      }
      @media only screen and (max-width: 480px) {
        height: 400px;
      }
      @media only screen and (max-width: 400px) {
        height: 350px;
      }
      @media only screen and (max-width: 300px) {
        height: 300px;
      }
    }
  }
  </style>