<template>
  <div class="organism-parking-access-v2" ref="sectionToObserve">
    <div class="parking-access">
      <span
        class="button-tab-parking-access"
        v-for="(parking, index1) in parkingItems"
        :key="index1"
        @click="playAnimation(`${parking.slug}`)"
        :class="{ active: activeTab === parking.slug }"
      >
        {{ parking.name }}
      </span>
    </div>
    <div
      class="animation-tab-parking-access"
      v-for="(parking, index2) in parkingItems"
      :key="index2"
    >
      <div v-if="activeTab === parking.slug">
        <AtomLazyLoader :height="525" v-if="!isInView" />
        <picture
          v-lazyload="{ handle: handleLazyLoad, url: assetUrl(parking.image) }"
        >
          <source :srcset="assetUrl(parking.image)" />
          <img
            v-if="parking.image"
            :src="assetUrl(parking.image)"
            :alt="`Parking-${parking.name}`"
          />
        </picture>
        <div v-if="animationsLoaded" :id="`${parking.slug}`" class="animation">
          <AtomLottieAnimation
            :options="{
              loop: false,
              autoplay: skipAnimation === false && activeTab === parking.slug,
              animationData: parking.animation,
              rendererSettings: {
                progressiveLoad: false,
              },
            }"
            @animCreated="
              ($event) => handleAnimation($event, `${parking.slug}`)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomLottieAnimation from "../../atoms/common/AtomLottieAnimation";
import AtomLazyLoader from "../../atoms/common/AtomLazyLoader";
export default {
  name: "OrganismParkingAccessV2",
  components: {
    AtomLottieAnimation,
    AtomLazyLoader,
  },
  props: {
    parkingAccess: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    cdnBase() {
      return this.$store.getters.cdnBase;
    },
  },
  data() {
    let activeTab = "";
    if (this.parkingAccess && this.parkingAccess.length) {
      activeTab = this.parkingAccess[0].slug;
    }
    return {
      parkingItems: [],
      animationsLoaded: false,
      skipAnimation: true,
      isInView: false,
      animation: {},
      activeTab,
    };
  },
  watch: {
    parkingAccess() {
      this.activeTab = this.parkingAccess[0].slug;
      this.populateAnimationConfigs();
    },
  },
  beforeUpdate() {
    if (this.parkingAccess && this.parkingAccess.length && !this.activeTab) {
      this.activeTab = this.parkingAccess[0].slug;
    }
  },
  mounted() {
    this.populateAnimationConfigs();
    const options = {
      root: null, // Use the viewport as the root element
      rootMargin: "0px", // No margin around the root element
      threshold: 0.9, // Trigger when at least 50% of the element is visible
    };
    const observer = new IntersectionObserver(this.handleIntersection, options);

    // Start observing the section
    observer.observe(this.$refs.sectionToObserve);
  },
  methods: {
    async populateAnimationConfigs() {
      const parkingItems = [...this.parkingAccess];
      for (const parking of parkingItems) {
        if (typeof parking.animation === "string") {
          const result = await this.getAnimationConfig(parking.animation);
          parking.animation = result.data;
        }
      }
      this.parkingItems = parkingItems;
      this.$nextTick(() => {
        this.animationsLoaded = true;
      });
    },
    async getAnimationConfig(configFileUrl = "") {
      return this.$request.GET(`${this.cdnBase}/${configFileUrl}`);
    },
    handleLazyLoad() {
      this.isInView = true;
    },
    assetUrl(image) {
      if (!image || !this.isInView) return "";
      if (image.includes("://")) {
        return image;
      }
      return `${this.cdnBase}/${image}`;
    },
    handleAnimation(animation, id) {
      this.animation[id] = animation;
    },
    playAnimation(id) {
      this.skipAnimation = false;
      this.activeTab = id;
      this.resetAllAnimations();
      if (!this.animation[id]) return;
      this.animation[id].setSpeed(1);
      this.animation[id].play();
      const { totalFrames, endFrame } = this.animation[id];
      this.animation[id].playSegments([0, endFrame || totalFrames], true);
    },
    resetAnimation(id) {
      if (!this.animation[id]) return;
      this.animation[id].goToAndStop(0);
    },
    resetAllAnimations() {
      Object.keys(this.animation).forEach((key) => {
        this.resetAnimation(key);
      });
    },
    handleIntersection(entries, observer) {
      // The callback function to handle the intersection
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Section entered the viewport
          this.playAnimation(this.activeTab);
        } else {
          // Section left the viewport
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.organism-parking-access-v2 {
  position: relative;
  min-height: 40rem;
  @media only screen and (max-width: 900px) {
    min-height: 480px;
  }
  @media only screen and (max-width: 767px) {
    min-height: 224px;
  }
  .parking-access {
    overflow-x: auto;
    white-space: nowrap;
    .button-tab-parking-access {
      text-transform: uppercase;
      padding-bottom: 0.188rem;
      border-bottom: 0.25rem solid transparent;
      display: inline-block;
      cursor: pointer;
      margin-right: 2rem;
      transition: all 0.2s ease-in-out 0s;
      @include font-main(1.125rem, $black, $bold, 1.313rem);
      @media only screen and (max-width: 767px) {
        padding-bottom: 3px;
        border-bottom: 4px solid transparent;
        margin-right: 12px;
        @include font-main(14px, $black, $bold, 19px);
      }
      &:last-child {
        margin-right: 0;
        @media only screen and (max-width: 767px) {
          margin-right: 0;
        }
      }
      &:hover {
        color: $blueDark;
      }
      &.active {
        color: $blueDark;
        border-color: $blueDark;
      }
    }
  }
  .animation-tab-parking-access {
    position: relative;
    .animation {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
