import { AnimationBuilder } from "@/components/organisms/project/building/3D/core/builders/AnimationBuilder";
import { FreeCameraKeyboardWalkInput } from "@/components/organisms/project/building/3D/core/builders/FreeCameraKeyboardWalkInput";
import { ArcRotateCamera, FreeCameraKeyboardMoveInput } from "babylonjs";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
import { Utils } from "@/components/organisms/project/building/3D/core/builders/Utils";
import {
  CAMERA_TYPE,
  SCENE_VIEW,
} from "@/components/organisms/project/building/3D/core/builders/Constants";

export class CameraManager {
  cameraRadiusLimit: number;
  sceneManager = BabylonClientManager.getSceneManager();
  constructor() {
    this.cameraRadiusLimit = 0;
  }

  moveUniversalCamera(
    options: {
      target: { x: number; y: number; z: number };
    },
    speed: number,
    onAnimationEnd?: () => void
  ) {
    if (!this.sceneManager || !this.sceneManager.cameraFirstPerson)
      return console.error("Camera Manager not defined");

    this.sceneManager.cameraFirstPerson.animations =
      AnimationBuilder.createMoveUniversalCamera(
        this.sceneManager.cameraFirstPerson,
        options,
        AnimationBuilder.speedRatio / 8
      );
    this.sceneManager.scene?.beginAnimation(
      this.sceneManager.cameraFirstPerson,
      AnimationBuilder.fromFrame,
      AnimationBuilder.toFrame,
      AnimationBuilder.isLoop,
      speed,
      onAnimationEnd
    );
  }

  moveArcRotateCamera(
    options: {
      radius: number;
      alpha: number;
      beta: number;
      target: {
        x: number;
        y: number;
        z: number;
      };
    },
    speed: number,
    onAnimationEnd?: () => void
  ) {
    if (!this.sceneManager || !this.sceneManager.camera3DView)
      return console.error("Camera Manager not defined");
    this.sceneManager.scene?.stopAllAnimations();
    const tempLowerRadius = this.sceneManager.camera3DView.lowerRadiusLimit;
    this.sceneManager.camera3DView.lowerRadiusLimit = null;
    this.sceneManager.camera3DView.animations =
      AnimationBuilder.createMoveArcRotateCamera(
        this.sceneManager.camera3DView,
        options,
        speed
      );
    this.sceneManager.scene?.beginAnimation(
      this.sceneManager.camera3DView,
      AnimationBuilder.fromFrame,
      AnimationBuilder.toFrame,
      AnimationBuilder.isLoop,
      speed,
      () => {
        if (this.sceneManager?.camera3DView && tempLowerRadius) {
          this.sceneManager.camera3DView.lowerRadiusLimit =
            tempLowerRadius > this.sceneManager.camera3DView.radius
              ? this.sceneManager.camera3DView.radius
              : tempLowerRadius;
        }
        if (onAnimationEnd) onAnimationEnd();
      }
    );
  }

  moveCameraTo2D(onAnimationEnd?: () => void) {
    if (!this.sceneManager || !this.sceneManager.camera3DView)
      return console.error("Camera Manager not defined");
    const options = {
      radius: this.sceneManager.camera3DView.radius,
      alpha: this.sceneManager.camera3DView.alpha,
      target: this.sceneManager.camera3DView.target,
      beta: 0,
    };
    this.moveArcRotateCamera(options, 1, onAnimationEnd);
  }

  moveCameraTo3D(onAnimationEnd?: () => void) {
    if (!this.sceneManager || !this.sceneManager.camera3DView)
      return console.error("Camera Manager not defined");
    const options = {
      radius: this.sceneManager.camera3DView.radius,
      alpha: this.sceneManager.camera3DView.alpha,
      target: this.sceneManager.camera3DView.target,
      beta: 1,
    };
    this.moveArcRotateCamera(options, 1, onAnimationEnd);
  }

  zoomCamera(zoomValue: number, camera: ArcRotateCamera, zoomFactor: number) {
    camera.upperRadiusLimit = this.cameraRadiusLimit - zoomValue * zoomFactor;
    camera.lowerRadiusLimit = this.cameraRadiusLimit - zoomValue * zoomFactor;
  }

  lockCameraAxis(upperBetaLimit: number, lowerBetaLimit: number) {
    if (!this.sceneManager || !this.sceneManager.camera3DView)
      return console.error("Scene Manager not defined");
    this.sceneManager.camera3DView.upperBetaLimit = upperBetaLimit;
    this.sceneManager.camera3DView.lowerBetaLimit = lowerBetaLimit;
  }

  setActiveCamera(cameraType: CAMERA_TYPE) {
    if (!this.sceneManager) return console.error("Scene Manager not defined");
    const canvas = this.sceneManager.scene?.getEngine().getRenderingCanvas();
    if (cameraType == CAMERA_TYPE.ARC_ROTATE) {
      this.sceneManager.cameraFirstPerson?.detachControl();
      this.sceneManager.camera3DView?.attachControl(canvas);
    } else if (cameraType == CAMERA_TYPE.UNIVERSAL) {
      this.sceneManager.camera3DView?.detachControl();
      this.sceneManager.cameraFirstPerson?.attachControl(canvas);
    }
  }

  switchCamera(view: string) {
    if (
      !this.sceneManager ||
      !this.sceneManager.scene ||
      !this.sceneManager.camera3DView ||
      !this.sceneManager.cameraFirstPerson
    )
      return console.error("Scene Manager is not defined");
    switch (view) {
      case "2D":
        Utils.updateSceneProperties(this.sceneManager.scene, true);
        this.sceneManager.scene.cameraToUseForPointers =
          this.sceneManager.camera3DView;
        this.sceneManager.pointerCircle?.togglePointer(false);
        this.sceneManager.minimap?.toggle(false);
        this.sceneManager.actualSceneView = SCENE_VIEW.TWO_DIMENSIONAL;
        this.setActiveCamera(CAMERA_TYPE.ARC_ROTATE);
        this.moveCameraTo2D(() => {
          this.lockCameraAxis(0, 0);
        });
        this.sceneManager.scene.activeCameras = [];
        this.sceneManager.scene.activeCameras?.push(
          this.sceneManager.camera3DView
        );
        this.sceneManager.skybox?.setEnabled(false);
        this.sceneManager?.setCeilingEnabled(false);
        this.sceneManager?.pinManager?.unselectPin();
        break;
      case "default":
        Utils.updateSceneProperties(this.sceneManager.scene, true);
        this.sceneManager.scene.cameraToUseForPointers =
          this.sceneManager.camera3DView;
        this.sceneManager.pointerCircle?.togglePointer(false);
        this.sceneManager.minimap?.toggle(false);
        this.sceneManager.actualSceneView = SCENE_VIEW.DEFAULT;
        this.setActiveCamera(CAMERA_TYPE.ARC_ROTATE);
        this.moveCameraTo3D();
        this.lockCameraAxis(1.5708, 0);
        this.sceneManager.scene.activeCameras = [];
        this.sceneManager.scene.activeCameras?.push(
          this.sceneManager.camera3DView
        );
        this.sceneManager.skybox?.setEnabled(false);
        this.sceneManager.setCeilingEnabled(false);
        this.sceneManager?.pinManager?.unselectPin();
        break;
      case "firstPerson":
        Utils.updateSceneProperties(this.sceneManager.scene, false);
        this.sceneManager.scene.cameraToUseForPointers =
          this.sceneManager.cameraFirstPerson;
        this.sceneManager.setPointerY();
        this.sceneManager.pointerCircle?.togglePointer(true);
        this.sceneManager.minimap?.toggle(true);
        this.sceneManager.actualSceneView = SCENE_VIEW.FIRST_PERSON;
        this.setActiveCamera(CAMERA_TYPE.UNIVERSAL);
        if (!this.sceneManager.minimap)
          return console.error("Minimap not defined");
        this.sceneManager.scene.activeCameras = [];
        this.sceneManager.scene.activeCameras?.push(
          this.sceneManager.cameraFirstPerson
        );
        this.sceneManager.scene.activeCameras?.push(
          this.sceneManager.minimap.viewportCamera
        );
        this.sceneManager.skybox?.setEnabled(true);
        this.sceneManager?.setCeilingEnabled(true);
        this.sceneManager?.pinManager?.unselectPin();
        break;
      default:
    }
  }

  addVirtualTourControls(options: unknown) {
    if (!this.sceneManager || !this.sceneManager.cameraFirstPerson)
      return console.error("Scene Manager is not defined");
    // Set camera properties
    Object.assign(this.sceneManager.cameraFirstPerson, options);

    // Customize keyboard
    const keyboard = this.sceneManager.cameraFirstPerson.inputs.attached
      .keyboard as FreeCameraKeyboardMoveInput;

    keyboard.keysUp = [38, 87];
    keyboard.keysDown = [40, 83];
    keyboard.keysLeft = [81];
    keyboard.keysRight = [69];
    keyboard.keysRotateLeft = [39, 68];
    keyboard.keysRotateRight = [37, 65];

    this.sceneManager.cameraFirstPerson.invertRotation = true;
    const walkInput = new FreeCameraKeyboardWalkInput();
    walkInput.camera = this.sceneManager.cameraFirstPerson;
    this.sceneManager.cameraFirstPerson.inputs.remove(
      this.sceneManager.cameraFirstPerson.inputs.attached.touch
    );
    this.sceneManager.cameraFirstPerson.inputs.add(walkInput);
  }
}
