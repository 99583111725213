<template>
  <div class="atom-title">
    <h2 :style="color ? { color: color } : {}" v-html="title"></h2>
    <h3 :style="color2 ? { color: color2 } : {}" v-html="subtitle"></h3>
  </div>
</template>

<script>
export default {
  name: "AtomTitle",
  props: {
    title: {
      required: false,
      type: String,
      default: "",
    },
    subtitle: {
      required: false,
      type: String,
      default: "",
    },
    color: {
      required: false,
      type: String,
      default: "",
    },
    color2: {
      required: false,
      type: String,
      default: "",
    },
    disableColorProps: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.atom-title {
  h2 {
    font-weight: $regular;
    font-size: 3.25rem;
    line-height: 3.875rem;
    color: $black !important;
    @media only screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 39px;
    }
    b {
      font-size: 3.25rem;
      line-height: 3.875rem;
      @media only screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 36px;
      }
    }
  }
  h3 {
    color: $blueGreen !important;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.625rem;
    @media only screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 19px;
    }
    b {
      font-size: 1.375rem;
      line-height: 1.625rem;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}
</style>
