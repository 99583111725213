<template>
  <div class="organism-selected-space-mobile" :class="{'embed-selected-space': isEmbedReady || (isEmbedV2 && isMobileView)}" v-if="showMobileSelectedSpace || (isEmbedReady && selectedSpace.floor)">
    <div class="row">
      <div class="col col-8">
        <div class="space-info">
          <span v-if="!spaceIsRequested(selectedSpace.id)" class="line-element"><AtomIcon icon="minus" /></span>
          <span v-else class="line-element line-checked"><AtomIcon icon="ok" /></span>
          <span class="sqm-info"><b>{{ selectedSpace.sqm }}</b> {{unitOfMeasureString}} GLA</span>
          <span v-if="showGeneratedNumberOfPeople" class="people-info"><b>{{ spacePeople }}</b> People</span>
        </div>
      </div>
      <div class="col col-4 text-right">
        <span class="floor-info">Floor <b>{{ selectedSpace.floor.no }}</b></span>
      </div>
    </div>
    <div class="row">
      <div class="col col-6">
        <router-link tag="a" class="btn-edit" :to="{name: getSpaceRouteName, params: { projectId: activeProject, spaceId: selectedSpace.id }}"><AtomIcon icon="cube-1" :size="15" /> 3D Explore</router-link>
      </div>
      <div class="col col-6">
        <div class="wrapper-btn">
          <a v-if="!spaceIsRequested(selectedSpace.id)" href="javascript:void(0)" class="btn-select btn-select-show" @click="addSpaceToCart(selectedSpace)"><AtomIcon icon="list-checked" :size="15" /> Add to my list</a>
          <a v-else href="javascript:void(0)" class="btn-select btn-remove-item" @click="removeSpaceFromCart(selectedSpace)"><AtomIcon icon="minus" :size="15" />  Remove from list</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {isMobile, isTablet} from "../../../../helpers/mobile/DeviceType";
import buildingStore from './store';
import AtomIcon from "../../../atoms/common/AtomIcon";

export default {
  name: "OrganismSelectedSpaceMobile",
  components: {AtomIcon},
  props: {
  },
  data(){
    return {
      isMobileView: typeof window !== 'undefined' ? window.innerWidth < 1200 : false
    }
  },
  computed: {
    buildingStore() {
      return buildingStore;
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces;
    },
    hasSpaceSelected() {
      return !!this.buildingStore.state.space.spaceData;
    },
    buildings() {
      return this.$store.state.project.project.buildings;
    },
    floors() {
      return this.$store.state.project.project.floors;
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    selectedSpace() {
      const space = {...this.buildingStore.state.space.spaceData};
      const building = this.buildings.find((b) => b.id === space.building_id);
      const floor = this.floors.find((f) => f.id === space.floor_id);
      space.building = building;
      space.floor = floor;
      return space;
    },
    showMobileSelectedSpace() {
      if (typeof window === 'undefined') return false;
      return (window.innerWidth <= 1200) && this.hasSpaceSelected;
    },
    isEmbedReady() {
      const routePageData = this.$route.meta.page;
      return routePageData.isEmbedReady;
    },
    isEmbedV2() {
      const routePageData = this.$route.meta.page;
      return routePageData.isEmbedV2;
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1 ? "sqft" : "sqm";
    },
    spacePeople() {
      if (this.unitOfMeasure === 1) {
        const unitSQM = this.selectedSpace.sqm / 10.763910417;
        return Math.floor(unitSQM / 8)
      }
      return Math.floor(this.selectedSpace.sqm / 8);
    },
    getSpaceRouteName(){
      const routePageData = this.$route.meta.page;
      if(routePageData.isEmbedReady){
        return 'SpecificSpaceEmbed'
      }
      if(routePageData.isEmbedV2){
        return 'SpecificSpaceEmbedV2'
      }
      return 'SpecificSpace'
    },
    showGeneratedNumberOfPeople(){
      const commonFeatures = this.$store?.state?.base?.meta?.project?.sections?.find(section => section.type === "Building") || {};
      if("generatedNumberOfPeople" in commonFeatures){
        return commonFeatures.generatedNumberOfPeople;
      } else {
        return true;
      }
    }
  },
  methods: {
    resizeListener(e) {
      this.isMobileView = typeof window !== 'undefined' ? window.innerWidth < 1200 : false
    },
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find(space => space.id === spaceId);
    },
    removeSpaceFromCart(space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'remove_space_from_list',
          payload: {
            zone: 'space',
            space,
          }
        });
      }
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(requestOffer.withNamespace(requestOffer.action.REMOVE_SPACE), space);
    },
    addSpaceToCart(space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'add_space_to_list',
          payload: {
            zone: 'space',
            space,
          }
        });
      }
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(requestOffer.withNamespace(requestOffer.action.ADD_SPACE), space);
    }
  },
  mounted() {
    let self = this;
    window.addEventListener('resize', this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener);
  },
}
</script>

<style lang="scss">
  .organism-selected-space-mobile {
    position: absolute;
    bottom: 116px;
    left: 50%;
    width: 345px;
    max-width: 100%;
    transform: translateX(-50%);
    padding: 12px 10px;
    background: $white;
    border-radius: 4px;
    @media only screen and (min-width: 1201px) {
      display: none !important;
    }
    @media only screen and (max-width: 345px) {
      width: 96%;
    }
    &.embed-selected-space {
      bottom: 75px;
      width: 415px;
      @media only screen and (min-width: 1200px) {
        display: block !important;
      }
      @media only screen and (max-width: 1200px) {
        width: 345px;
      }
      @media only screen and (max-width: 345px) {
        width: 96%;
      }
    }
    .row {
      margin-left: -9px;
      margin-right: -9px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .col {
        padding: 0 9px;
      }
    }
    .space-info {
      .line-element {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        .icon {
          font-size: 14px !important;
        }
        &.line-checked {
          .icon {
            color: #4CC66D !important;
          }
        }
      }
      .sqm-info {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      color: #000000;
      font-weight: 300;
      line-height: 17px;
      b {
        font-size: 14px;
        color: #000000;
        line-height: 17px;
      }
    }
    .btn-edit {
      text-align: center;
      display: block;
      background: rgba(0,0,0,0.1);
      border-radius: 2px;
      padding: 6px 5px;
      font-size: 13px;
      color: $black;
      font-weight: $regular;
      line-height: 17px;
      text-decoration: none;
      .icon {
        font-size: 14px !important;
        margin-right: 5px;
      }
      &:hover {
        background: $black;
        color: $white;
        .icon {
          color: $white !important;
        }
      }
    }
    .btn-select {
      text-align: center;
      display: block;
      border-radius: 2px;
      background: #4CC66D;
      padding: 6px 5px;
      font-size: 13px;
      color: $white;
      font-weight: $regular;
      line-height: 17px;
      text-decoration: none;
      svg {
        margin-right: 5px;
      }
      &.btn-select-show {
        background: #4CC66D;
        .icon {
          margin-right: 5px;
          font-size: 14px !important;
          color: $white !important;
        }
        &:hover {
          background: $black;
        }
      }
      &.btn-remove-item {
        background: #FCDFDF;
        color: #F93D3D;
        .icon {
          margin-right: 5px;
          font-size: 14px !important;
          color: #F93D3D !important;
        }
      }
    }
  }
</style>