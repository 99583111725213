<template>
  <div class="owl atom-image-thumb-slider" v-lazyload="{handle: handleLazyLoad, url: resourceUrl(sliderImage.thumb)}" :class="{'active': activeThumb === index}" @click="handleClick">
    <AtomLazyLoader :height="150" v-if="!imageLoaded" />
    <div v-if="imageLoaded" class="background-image" :style="{'background-image': `url(${resourceUrl(sliderImage.thumb)})`}"></div>
  </div>
</template>

<script>
import AtomLazyLoader from "../AtomLazyLoader";
export default {
  name: "AtomImageThumbSlider",
  components: {AtomLazyLoader},
  props: {
    sliderImage: {
      required: true,
      type: Object,
      default: () => {
        return {
          image: "",
          thumb: ""
        }
      }
    },
    activeThumb: {
      required: true,
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isInView: false,
      imageLoaded: false,
    }
  },
  computed: {
    index() {
      return this.$vnode.data.key;
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', this.index);
    },
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
    handleLazyLoad() {
      this.isInView = true;
      const imageElement = new Image;
      if (imageElement) {
        imageElement.addEventListener("load", () => {
          this.imageLoaded = true;
        });
        imageElement.addEventListener("error", () => console.log("error"));
        imageElement.src = this.resourceUrl(this.sliderImage.thumb);
      }
    }
  }
}
</script>

<style lang="scss">
  .atom-image-thumb-slider {
    position: relative;
    display: inline-block;
    width: 11.25rem;
    height: 7rem;
    margin: 0 0.188rem;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
    @media only screen and (max-width: 767px) {
      width: 136px;
      height: 85px;
      margin: 0 2px;
    }
    &.active, &:hover {
      opacity: 1;
    }
    img {
      display: block;
    }
    .lazy-loader-wrapper {
      min-height: auto !important;
    }
  }
</style>