import { SceneManager } from "@/components/organisms/project/building/3D/core/managers/SceneManager";
import { Vector3 } from "babylonjs";
import Constants from "@/components/organisms/project/building/3D/core/builders/Constants";
import { isDesktop } from "@/helpers/mobile/DeviceType";
import { Utils } from "@/components/organisms/project/building/3D/core/builders/Utils";

export class DefaultSceneManager extends SceneManager {
  baseUrl?: string;
  buildingStore?: any;
  payload?: any;
  constructor(canvasContainer: HTMLCanvasElement) {
    super(canvasContainer);
  }

  initScene(
    payload: any,
    buildingStore: any,
    canvasContainer: HTMLCanvasElement
  ) {
    //// Model URL
    this.baseUrl = `${payload.cdnBase}/objects/${payload.client}/${payload.activeProject}`;
    this.buildingStore = buildingStore;
    this.payload = payload;
    //// Clear scene of anything added previously
    this.initNewScene(payload.cdnBase, canvasContainer);
  }

  loadBuilding() {
    if (!this.baseUrl || !this.buildingStore || !this.payload)
      return console.error("URL not defined");
    //// Set highlight zoom
    if (this.payload.settings.building.highlightZoom) {
      this.highlightZoom = this.payload.settings.building.highlightZoom;
    }
    //// Set target
    if (this.payload.settings.building.highlightAlpha) {
      this.targetAlphaValue = this.payload.settings.building.highlightAlpha;
    }
    const buildingUrl =
      this.baseUrl +
      `/project/${
        this.payload.settings.building.versionPath
          ? `${this.payload.settings.building.versionPath}/`
          : ""
      }`;
    //// Load scene
    this.load("project", buildingUrl, "building.gltf", () => {
      this.onLoadBuilding();
    });
    //// Load hdr image
    this.loadHDR(
      `${this.payload.cdnBase}${this.payload.settings.building.hdrTexture}`
    );
    this.loadingManager?.startLoading();
  }

  onLoadBuilding() {
    //// Create 3d view camera for the scene
    this.addArcRotateCamera();
    const target = this.payload.settings.building.target
      ? new Vector3(
          this.payload.settings.building.target.x,
          this.payload.settings.building.target.y,
          this.payload.settings.building.target.z
        )
      : Vector3.Zero();
    this.set3dCameraViewProperties({
      target: target,
    });

    this.set3dCameraViewProperties({
      fov: Constants.CAMERA_DEFAULT_FOV,
      lowerRadiusLimit: this.payload.settings.building.radius,
      upperRadiusLimit: this.payload.settings.building.radius,
      alpha: this.payload.settings.building.alpha,
      beta: this.payload.settings.building.beta,
      radius: this.payload.settings.building.radius,
      upperBetaLimit:
        this.payload.settings.building.upperBetaLimit ||
        Constants.UPPER_BETA_LIMIT,
      minZ: 10,
      panningSensibility: Constants.PANNING_SENSIBILITY,
      layerMask: Constants.CAMERA_3D_LAYER_MASK,
      metadata: {
        highlightBeta: this.payload.settings.building.highlightBeta,
      },
    });
    //// Set highlights
    this.setNodesIsVisible("highlights");
    //// Add pins
    this.initPinManager(this.payload.cdnBase);
    if (this.payload.pinsData && this.payload.pinsData.pins) {
      this.addPins(this.payload.pinsData.pins, Constants.CAMERA_3D_LAYER_MASK);
    }
    const caseNodes = [];
    for (const building of this.payload.project.buildings) {
      caseNodes.push(building.code + "_case");
      this.addHighlightNodes(building.code);
    }
    this.setNodesInteraction(caseNodes, false);
    //// Start rendering
    this.startRendering();
    this.resize();
    this.buildingStore.commit("setLoadingAssetsStatus", true);
  }

  loadFloor() {
    if (!this.baseUrl || !this.buildingStore || !this.payload)
      return console.error("URL not defined");
    //// Load Model
    const floorName = this.payload.floorType.split(".gltf")[0];
    const fitoutsModelName =
      "fitouts_" +
      floorName +
      "_" +
      Utils.formatSpaceName(Utils.checkSpaceName(this.payload.space));
    //// Model URL
    const floorUrl =
      this.baseUrl +
      `/${
        this.payload.settings.floor.versionPath
          ? `${this.payload.settings.floor.versionPath}/`
          : ""
      }`;
    //// Load Model
    this.load(
      this.payload.floorType,
      floorUrl,
      this.payload.floorType,
      () => {
        this.onLoadFloor(floorName);
      },
      () => {
        this.renderScene(Constants.MAX_RENDER_TIME);
        //// Start loading the fitout models
        if (fitoutsModelName && floorUrl)
          this.loadFitouts(fitoutsModelName, floorUrl, fitoutsModelName);
        this.resize();
      }
    );
    this.loadingManager?.startLoading();
  }

  onLoadFloor(floorName: string) {
    //// Create camera for the scene manager;
    this.addArcRotateCamera(floorName);
    this.set3dCameraViewProperties({
      alpha: this.payload.settings.floor.alpha,
      beta: this.payload.settings.floor.beta,
      fov: Constants.CAMERA_DEFAULT_FOV,
      panningDistanceLimit: this.payload.settings.floor.panningDistanceLimit,
      lowerRadiusLimit: this.payload.settings.floor.radius,
      upperRadiusLimit: this.payload.settings.floor.radius,
      radius: this.payload.settings.floor.radius,
      upperBetaLimit: Constants.UPPER_BETA_LIMIT,
      minZ: Constants.MIN_Z_FLOOR_ARC_ROTATE,
      layerMask: Constants.CAMERA_3D_LAYER_MASK,
      target: this.focusFloorSpace(
        this.payload.space,
        this.payload.floorType.split(".")[0]
      ),
    });
    this.set3dCameraViewProperties({
      lowerRadiusLimit:
        this.payload.settings.floor.radiusUpperLimit -
        this.payload.settings.floor.zoom,
    });
    //// Create camera for the new scene
    this.addUniversalCamera(
      {
        x: this.payload.spaceData.camera_position_x,
        y: this.payload.spaceData.camera_position_y,
        z: this.payload.spaceData.camera_position_z,
      },
      {
        x: this.payload.spaceData.focus_target_x,
        y: this.payload.spaceData.focus_target_y,
        z: this.payload.spaceData.focus_target_z,
      }
    );

    this.setVirtualTourProperties({
      speed: Constants.VIRTUAL_TOUR_CAMERA_SPEED,
      angularSensibility: isDesktop()
        ? Constants.ANGULAR_SENSIBILITY_DESKTOP
        : Constants.ANGULAR_SENSIBILITY_MOBILE,
      minZ: Constants.MIN_Z_FLOOR_UNIVERSAL,
      layerMask: Constants.CAMERA_3D_LAYER_MASK,
    });
    //// Load fitouts after the loading the floor
    this.initMinimap();
    this.addVirtualTourControls();
    //// Add pins
    this.initPinManager(this.payload.cdnBase);
    if (this.payload.pinsData && this.payload.pinsData.pins) {
      this.addPins(this.payload.pinsData.pins, Constants.CAMERA_3D_LAYER_MASK);
    }
    //// Set nodes
    //// Disable floor ceiling
    this.disableNode("ceiling_" + floorName, "none");
    this.setNodesMask(
      ["ceiling_" + floorName],
      Constants.VIRTUAL_TOUR_CAMERA_MASK
    );
    this.resize();
    this.addMatrixObservableFirstPerson();
    this.setNodesInteraction(["spaces_" + floorName], true);
    this.loadSkybox();
    //// Load hdr image
    this.loadHDR(
      `${this.payload.cdnBase}${this.payload.settings.floor.hdrTexture}`
    );
    this.addPointerCircle();
    this.minimap?.updateMinimap();
    //// Start rendering
    this.startRendering();
    this.resize();
  }

  loadSkybox() {
    //// Add skybox
    const skyboxUrl =
      this.payload.cdnBase + this.payload.settings.floor.skyboxTexture + "/";
    this.addSkybox(skyboxUrl);
  }
}
