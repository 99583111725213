<template>
  <div class="organism-parking-access">
    <AtomLazyLoader :height="525" v-if="!isInView" />
    <picture v-lazyload="{ handle: handleLazyLoad, url: assetUrl }">
      <source :srcset="assetUrl" />
      <img v-if="assetUrl" :src="assetUrl" alt="Image Parking" />
    </picture>
    <div
      class="wrapper-parking-access"
      v-for="(building, index) in parkingAccess"
      :key="index"
    >
      <div
        class="parking-access"
        :class="{ 'more-parking': building.marginLeft }"
        :style="{ 'margin-left': building.marginLeft }"
      >
        <p v-html="building.name"></p>
        <div class="buttons">
          <span @click="playEntries(`${building.slug}`)">Entries</span>
          <span @click="playExits(`${building.slug}`)">Exits</span>
        </div>
      </div>
      <div
        id="animation-entries"
        class="animation"
        v-if="showEntries[building.slug]"
      >
        <AtomLottieAnimation
          :options="{
            loop: false,
            autoplay: false,
            animationData: building.animations.entries,
            rendererSettings: {
              progressiveLoad: false,
            },
          }"
          @animCreated="
            ($event) => handleAnimation($event, `${building.slug}-entries`)
          "
        />
      </div>
      <div
        id="animation-exits"
        class="animation"
        v-if="showExits[building.slug]"
      >
        <AtomLottieAnimation
          :options="{
            loop: false,
            autoplay: false,
            animationData: building.animations.exits,
            rendererSettings: {
              progressiveLoad: false,
            },
          }"
          @animCreated="
            ($event) => handleAnimation($event, `${building.slug}-exits`)
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import AtomLottieAnimation from "../../atoms/common/AtomLottieAnimation";
import AtomLazyLoader from "../../atoms/common/AtomLazyLoader";
export default {
  name: "OrganismParkingAccess",
  components: {
    AtomLottieAnimation,
    AtomLazyLoader,
  },
  props: {
    parkingAccess: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
    imageUrl: {
      required: true,
      type: String,
      default: function () {
        return "";
      },
    },
  },
  computed: {
    cdnBase() {
      return this.$store.getters.cdnBase;
    },
    assetUrl() {
      if (!this.imageUrl || !this.isInView) return "";
      if (this.imageUrl.includes("://")) {
        return this.imageUrl;
      }
      return `${this.cdnBase}/${this.imageUrl}`;
    },
  },
  data() {
    const showEntries = {};
    const showExits = {};
    this.parkingAccess.forEach((pa) => {
      showEntries[pa.slug] = false;
      showExits[pa.slug] = false;
    });
    return {
      isInView: false,
      animations: [],
      showEntries: showEntries,
      showExits: showExits,
    };
  },
  watch: {
    parkingAccess: function (items, oldItems) {
      this.destroyAllAnimations();
      const showEntries = {};
      const showExits = {};
      items.forEach((pa) => {
        showEntries[pa.slug] = false;
        showExits[pa.slug] = false;
      });
      this.showEntries = showEntries;
      this.showExits = showExits;
    },
  },
  methods: {
    handleLazyLoad() {
      this.isInView = true;
    },
    handleAnimation(animation, id) {
      this.animations[id] = animation;
    },
    stopAnimation(id) {
      if (!this.animations[id]) return;
      this.animations[id].stop();
    },
    playEntries(id) {
      this.showEntries[id] = true;
      this.showExits[id] = false;
      delete this.animations[`${id}-exits`];
      if (!this.animations[`${id}-entries`]) {
        setTimeout(() => this.playEntries(id), 100);
        return;
      }
      this.playAnimation(`${id}-entries`);
    },
    playExits(id) {
      this.showEntries[id] = false;
      this.showExits[id] = true;
      delete this.animations[`${id}-entries`];
      if (!this.animations[`${id}-exits`]) {
        setTimeout(() => this.playExits(id), 100);
        return;
      }
      this.playAnimation(`${id}-exits`);
    },
    playAnimation(id) {
      this.resetAllAnimations();
      if (!this.animations[id]) return;
      this.animations[id].setSpeed(1);
      this.animations[id].play();
      const { totalFrames, endFrame } = this.animations[id];
      this.animations[id].playSegments([0, endFrame || totalFrames], true);
    },

    pauseAnimation(id) {
      if (!this.animations[id]) return;
      this.animations[id].pause();
    },

    resetAnimation(id) {
      if (!this.animations[id]) return;
      this.animations[id].goToAndStop(0);
    },

    resetAllAnimations() {
      Object.keys(this.animations).forEach((key) => {
        this.resetAnimation(key);
      });
    },

    destroyAnimation(id) {
      if (!this.animations[id]) return;
      this.animations[id].destroy();
    },

    destroyAllAnimations() {
      Object.keys(this.animations).forEach((key) => {
        this.destroyAnimation(key);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-title {
  .atom-title {
    padding: 0 3.75rem;
    margin-bottom: 3.125rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
      padding: 0 10px;
    }
  }
}
.organism-parking-access {
  position: relative;
  min-height: 40rem;
  .wrapper-parking-access {
    .parking-access {
      position: absolute;
      top: 2rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
      @media only screen and (max-width: 767px) {
        top: -120px;
        left: 50%;
      }
      p {
        background: $white;
        border-radius: 0.313rem;
        padding: 0.625rem;
        max-width: 12.5rem;
        text-align: center;
        @include font-main(1rem, $black, $bold, 1.375rem);
        @media only screen and (max-width: 767px) {
          border: 1px solid $black;
          border-radius: 5px;
          padding: 10px;
          max-width: 200px;
          width: 200px;
          @include font-main(16px, $black, $bold, 22px);
        }
      }
      .buttons {
        span {
          background: $black;
          position: relative;
          text-align: center;
          padding: 0.563rem 1.75rem 0.438rem;
          text-transform: uppercase;
          border-radius: 0.25rem;
          display: inline-block;
          vertical-align: middle;
          margin: 0.625rem 0.625rem 0 0;
          cursor: pointer;
          @include font-main(0.625rem, $white, $bold, 0.75rem);
          transition: all 0.2s ease-in-out 0s;
          @media only screen and (max-width: 767px) {
            padding: 9px 29px 7px;
            border-radius: 4px;
            margin: 10px 11px 0 0;
            @include font-main(10px, $white, $bold, 12px);
          }
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            background: $second-color;
          }
        }
      }
      &.more-parking {
        p {
          @media only screen and (max-width: 767px) {
            max-width: 150px;
            width: 150px;
            font-size: 14px;
            line-height: 19px;
          }
        }
        .buttons {
          span {
            @media only screen and (max-width: 767px) {
              padding: 9px 16px 7px;
            }
          }
        }
      }
    }
    &:first-child {
      .parking-access {
        &.more-parking {
          @media only screen and (max-width: 767px) {
            margin-left: -25% !important;
          }
        }
      }
    }
    &:last-child {
      .parking-access {
        &.more-parking {
          @media only screen and (max-width: 767px) {
            margin-left: 25% !important;
          }
        }
      }
    }
  }
  .animation {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
