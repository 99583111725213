<template>
  <div class="page brochure">
    <SectionSelector
      v-for="(section, key) in sections"
      ref="sections"
      :enabled="section.enabled"
      :hash="section.hashAnchor"
      :variant="section.variant"
      :data="section.data"
      :extra="section.extra"
      :multi-project="section.multi_project"
      :section="section.type"
      :order-no="section.order_no"
      :key="key"
    />
  </div>
</template>

<script>
import SectionSelector from "./SectionSelector";

export default {
  name: "BrochurePage",
  metaInfo() {
    return {
      title: this.sectionsMeta.baseTitle,
      titleTemplate: `${this.generalConfig.baseTitle} - ${this.sectionsMeta.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.sectionsMeta.description,
        },
      ],
    };
  },
  components: {
    SectionSelector,
  },
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    sectionsData() {
      const { projectSlug } = this.$route.params;
      const staticPageConfig = this.generalConfig.buildingStaticPages.find(
        (page) => page.path === `/${projectSlug}`
      );
      return staticPageConfig?.sections || [];
    },
    sectionsMeta() {
      const { projectSlug } = this.$route.params;
      const staticPageConfig = this.generalConfig.buildingStaticPages.find(
        (page) => page.path === `/${projectSlug}`
      );
      return staticPageConfig?.meta || {};
    },
    buildingRef() {
      const found = this.$refs.sections.find((s) => s.$refs.buildingSection);
      if (!found) return null;
      return found.$refs.buildingSection.$refs.building;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
  },
  data() {
    return {
      sections: [],
    };
  },
  watch: {
    activeProject: {
      handler: function (value, old) {
        if (value !== old) {
          this.buildingRef.initializeBuilding3D();
        }
      },
    },
    sectionsData: {
      deep: true,
      handler: function (value, old) {
        if (value !== old) {
          this.sortSections();
        }
      },
    },
  },
  mounted() {
    window.addEventListener("keydown", this.disableArrowScroll, false);
    this.buildingRef.initializeBuilding3D();
    const requestOffer = this.$store.getters.constants.requestOffer;
    this.$store.dispatch(
      requestOffer.withNamespace(requestOffer.action.CLEAR_STATE),
      null
    );
    this.headerHeight = document.getElementById("header-project")
      ? document.getElementById("header-project").clientHeight
      : 0;
  },
  created() {
    this.sortSections();
    const projectConstants = this.$store.getters.constants.project;
    this.$store.dispatch(
      projectConstants.withNamespace(
        projectConstants.action.LOAD_LANDLORD_DETAILS
      ),
      {
        loadProjectBasedOnRouteSlug: true,
      }
    );
  },
  beforeMount() {
    if (this.$store.state.base.documentIsReady) {
      const base = this.$store.getters.constants.base;
      this.$store.dispatch(
        base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
        false
      );
    }
  },
  methods: {
    sortSections() {
      let sectionsData = [...this.sectionsData].map((s) => {
        return { ...s };
      });
      this.sections = sectionsData.sort((a, b) => {
        if (a.order_no < b.order_no) {
          return -1;
        }
        if (a.order_no > b.order_no) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss">
.brochure {
  .section {
    position: relative;
    max-width: 80.334rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 auto;
    @media only screen and (max-width: 1000px) {
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }

    &.section-building,
    &.section-contact,
    &.section-few-facts {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    &.section-few-facts {
      .atom-title,
      .atom-facts-parent {
        width: 100%;
        max-width: 80.334rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 0 auto;
        @media only screen and (max-width: 1000px) {
          max-width: 100%;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
      .atom-title {
        margin: 0 auto 2.5rem;
        @media only screen and (max-width: 1000px) {
          margin: 0 0 24px;
        }
      }
    }

    &.section-building {
      .navigation-target {
        top: -5rem;
        @media only screen and (max-width: 767px) {
          top: -80px;
        }
      }
    }

    .navigation-target {
      position: absolute;
      top: -4.5rem;
      left: 0;
      @media only screen and (max-width: 767px) {
        top: -80px;
      }
    }

    .top-title {
      display: flex;
      flex-direction: column;
      flex: 100%;
    }
  }
}
</style>
