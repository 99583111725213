var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "lazyload",
          rawName: "v-lazyload",
          value: {
            handle: _vm.handleLazyLoad,
            url: _vm.resourceUrl(_vm.sliderImage.image),
          },
          expression:
            "{handle: handleLazyLoad, url: resourceUrl(sliderImage.image)}",
        },
      ],
      staticClass: "atom-image-slider slide",
    },
    [
      _c(
        "div",
        { staticClass: "image-relative" },
        [
          !_vm.imageLoaded ? _c("AtomLazyLoader") : _vm._e(),
          _c("img", {
            attrs: {
              src: _vm.resourceUrl(
                "images/placeholder-for-full-size-image.png"
              ),
              alt: "Placeholder",
            },
          }),
          _vm.imageLoaded
            ? _c("div", {
                staticClass: "background-image",
                style: {
                  "background-image":
                    "url(" + _vm.resourceUrl(_vm.sliderImage.image) + ")",
                  "background-size": _vm.backgroundSize,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.sliderImage.description
        ? _c("div", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.sliderImage.description)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }