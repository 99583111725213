<template>
  <ul class="list-bullets">
    <li v-for="(line, index) in lines" :key="index" v-html="line"></li>
  </ul>
</template>

<script>
export default {
  name: "AtomLinesBullets",
  props: {
    lines: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .list-bullets {
    list-style: none;
    padding: 0;
    li {
      position: relative;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.313rem;
      margin-bottom: 1.875rem;
      padding-left: 1.875rem;
      text-transform: uppercase;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 10px;
        padding-left: 25px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: '';
        display: block;
        width: 0.875rem;
        height: 0.875rem;
        background: $black;
        border-radius: 0.875rem;
        position: absolute;
        left: 0;
        top: 0.188rem;
        @media only screen and (max-width: 767px) {
          width: 10px;
          height: 10px;
          border-radius: 10px;
          top: 4px;
        }
      }
    }
  }
</style>