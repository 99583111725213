import { LoadingManager } from "@/components/organisms/project/building/3D/core/managers/LoadingManager";
import { HighlightManager } from "@/components/organisms/project/building/3D/core/managers/HighlightManager";
import { CameraManager } from "@/components/organisms/project/building/3D/core/managers/CameraManager";
import { EventManager } from "@/components/organisms/project/building/3D/core/managers/EventManager";
import {
  PinManager,
  PinInfo,
} from "@/components/organisms/project/building/3D/core/managers/PinManager";
import { PointerCircle } from "@/components/organisms/project/building/3D/core/builders/PointerCircle";
import { MinimapViewport } from "@/components/organisms/project/building/3D/core/builders/MinimapViewport";
import { PinContentManager } from "@/components/organisms/project/building/3D/core/builders/PinContentManager";
import {
  ArcRotateCamera,
  Engine,
  Mesh,
  Scene,
  TransformNode,
  UniversalCamera,
  Vector3,
  Color4,
  AbstractMesh,
  RenderingGroup,
  FramingBehavior,
  Vector2,
  Color3,
  PickingInfo,
} from "babylonjs";
import { FitoutsManager } from "@/components/organisms/project/building/3D/core/managers/FitoutsManager";
import buildingStore from "@/components/organisms/project/building/store";
import { Pipeline } from "@/components/organisms/project/building/3D/core/builders/Pipeline";
import { Utils } from "@/components/organisms/project/building/3D/core/builders/Utils";
import Constants, {
  SCENE_VIEW,
} from "@/components/organisms/project/building/3D/core/builders/Constants";
import { AdvancedDynamicTexture } from "babylonjs-gui";

export class SceneManager {
  yOffset = 0;
  disabledNodes: Map<string, TransformNode> = new Map();
  engine?: Engine;
  scene?: Scene;
  loadingManager?: LoadingManager;
  camera3DView?: ArcRotateCamera;
  cameraFirstPerson?: UniversalCamera;
  highlightManager?: HighlightManager;
  cameraManager?: CameraManager;
  eventManager?: EventManager;
  pinManager?: PinManager;
  pointerCircle?: PointerCircle;
  minimap?: MinimapViewport;
  fitoutsManager?: FitoutsManager;
  currentFitout = "none";
  pipeline?: Pipeline;
  targetAlphaValue = Constants.HIGHLIGHT_ALPHA;
  skybox?: Mesh;
  actualSceneView: SCENE_VIEW = SCENE_VIEW.DEFAULT;
  renderEventtimer = Constants.MAX_RENDER_TIME;
  renderEvent = true;
  originalCameraCoords = Constants.CAMERA_3D_VIEW_DEFAULT_VALUES;
  highlightZoom = Constants.HIGHLIGHT_ZOOM;
  maxLowerRadiusValue = 0;
  floorName = "";
  gui?: AdvancedDynamicTexture;
  lastCameraValues = {
    alpha: 0,
    beta: 0,
  };

  initNewScene(cdnBase: string, canvasContainer: HTMLCanvasElement) {
    if (this.engine) this.destroy();

    this.actualSceneView = SCENE_VIEW.DEFAULT;

    // Initialize new scene
    this.engine = new Engine(
      canvasContainer,
      true,
      {
        preserveDrawingBuffer: true,
        stencil: true,
      },
      Constants.ADAPT_TO_DEVICE_RATIO
    );
    this.engine.enableOfflineSupport = false;
    this.engine.disableManifestCheck = true;
    this.engine.disablePerformanceMonitorInBackground = true;
    this.engine.doNotHandleContextLost = true;
    this.scene = new Scene(this.engine);

    this.scene.metadata = { cdnBase: cdnBase };
    this.scene.preventDefaultOnPointerDown = false;
    this.scene.preventDefaultOnPointerUp = false;
    this.scene.blockMaterialDirtyMechanism = true;
    this.scene.clearColor = new Color4(0, 0, 0, 0);
    this.scene.setRenderingOrder(
      0,
      null,
      null,
      RenderingGroup.PainterSortCompare
    );
    this.loadingManager = new LoadingManager(this.scene);
    this.cameraManager = new CameraManager();
    this.eventManager = new EventManager(this.cameraManager);
    this.loadingManager = new LoadingManager(this.scene);
    this.fitoutsManager = new FitoutsManager();
    this.highlightManager = new HighlightManager();
    this.gui = AdvancedDynamicTexture.CreateFullscreenUI("bbGUI");
    if (this.gui && this.gui.layer)
      this.gui.layer.layerMask = Constants.MESH_LAYER_MASK;
  }

  initPinManager(cdnBase: string) {
    if (!this.scene) return console.error("Scene not defined");
    if (this.pinManager) this.pinManager.dispose();
    const pinContentManager = new PinContentManager(cdnBase, this.scene);
    // Add pin manager
    this.pinManager = new PinManager(pinContentManager, this.scene);
  }

  addPins(pinData: PinInfo[], layerMask: number) {
    if (!this.scene || !this.pinManager)
      return console.error("Pin Manager not defined");
    for (const pinInfo of pinData) {
      const pin = this.pinManager.addPin(pinInfo);
      // Flip existing positions to map blender scene position with babylon scene position
      pin.updateProperties({
        position: new Vector3(
          pinInfo.location.x,
          pinInfo.location.y,
          pinInfo.location.z
        ),
        layerMask: layerMask,
      });
    }
    // this.pinManager.addPinEvents();
  }

  addArcRotateCamera(floorName = "") {
    if (!this.scene) return console.error("Scene not defined");
    this.camera3DView = new ArcRotateCamera(
      Constants.ARC_ROTATE_CAMERA_NAME,
      Constants.CAMERA_3D_VIEW_DEFAULT_VALUES.alpha,
      Constants.CAMERA_3D_VIEW_DEFAULT_VALUES.beta,
      Constants.CAMERA_3D_VIEW_DEFAULT_VALUES.radius,
      Constants.CAMERA_3D_VIEW_DEFAULT_VALUES.target,
      this.scene
    );
    this.camera3DView.inputs.remove(
      this.camera3DView.inputs.attached.mousewheel
    );

    this.floorName = floorName;
    if (this.floorName !== "") {
      const framingBehavior = new FramingBehavior();
      framingBehavior.framingTime = 0;
      framingBehavior.elevationReturnTime = -1;
      framingBehavior.autoCorrectCameraLimitsAndSensibility = false;
      this.camera3DView.addBehavior(framingBehavior);
    }

    // Default rotation controls
    this.camera3DView.attachControl(
      this.scene.getEngine().getRenderingCanvas()
    );
    this.scene.activeCameras = [];
    this.scene.activeCameras?.push(this.camera3DView);

    const throttledPinEvents = Utils.throttleFunction(() => {
      this.pinManager?.addPinEvents();
    }, Constants.FUNCT_FREQUENCY);

    this.camera3DView.onViewMatrixChangedObservable.add(() => {
      this.renderScene();
      throttledPinEvents();
    });
  }

  addUniversalCamera(
    startPosition: { x: number; y: number; z: number },
    target: { x: number; y: number; z: number }
  ) {
    this.yOffset = startPosition.y;
    this.cameraFirstPerson = new UniversalCamera(
      Constants.UNIVERSAL_CAMERA_NAME,
      new Vector3(startPosition.x, startPosition.y, startPosition.z),
      this.scene
    );
    this.cameraFirstPerson.fov = Constants.VIRTUAL_TOUR_CAMERA_FOV;
    this.cameraFirstPerson.metadata = {};
    this.cameraFirstPerson.metadata.y = startPosition.y;
    this.cameraFirstPerson.setTarget(new Vector3(target.x, target.y, target.z));
    this.cameraFirstPerson.ellipsoid = new Vector3(0.5, 0.5, 0.5);
    this.cameraFirstPerson.checkCollisions = true;
  }

  renderScene(value = 0) {
    this.renderEventtimer = value;
    if (Constants.CONTINUOUS_RENDERING) this.renderEventtimer = -1;
    this.renderEvent = true;
  }

  startRendering() {
    if (!this.scene) return console.error("Scene not defined");
    if (!this.eventManager) return console.error("Event Manager not defined");
    if (!this.engine) return console.error("Engine not defined");
    // Post processing
    if (Constants.ENABLE_PIPELINE) {
      this.pipeline = new Pipeline(true, this.scene);
    }

    this.scene.onPointerObservable.add(this.eventManager.pointerEvents);
    this.engine.runRenderLoop(() => {
      if (this.scene) {
        if (this.renderEvent) {
          if (this.renderEventtimer > -1) {
            this.renderEventtimer += 1;
            if (this.renderEventtimer > Constants.MAX_RENDER_TIME) {
              this.renderEvent = false;
              this.renderEventtimer = 0;
            }
          }
          this.scene.render();
        }
      }
    });
  }

  load(
    taskName: string,
    url: string,
    sceneFilename: string,
    onLoad: () => void,
    onLoadAfterScene?: () => void
  ) {
    if (!this.loadingManager)
      return console.error("Loading manager not defined");
    this.loadingManager.addMeshLoadTask(
      taskName,
      "",
      url,
      sceneFilename,
      () => {
        if (!this.scene || !this.engine)
          return console.error("Scene not defined");
        // Render Scene
        onLoad();
        // Execute after Scene has fully loaded
        this.scene.executeWhenReady(() => {
          setTimeout(() => {
            this.pinManager?.addPinEvents();
          }, 100);
          onLoadAfterScene?.();
        });
      }
    );
  }

  loadHDR(url: string) {
    if (!this.scene) return console.error("Scene not defined");
    if (!this.loadingManager) console.error("Loading Manager not defined");
    this.loadingManager?.loadHDR(url, this.scene);
  }

  loadFitouts(taskName: string, url: string, sceneFilename: string) {
    this.loadingManager?.addMeshLoadTask(
      taskName,
      "",
      url,
      sceneFilename + ".gltf",
      () => {
        if (!this.scene) return console.error("Scene not defined");
        this.onLoadFitouts(sceneFilename);
      },
      () => {
        buildingStore.commit("setLoadingAssetsStatus", true);
        this.minimap?.updateMinimap();
      }
    );
    this.loadingManager?.startLoading();
  }

  destroy() {
    if (!this.scene || !this.engine) {
      console.error("Scene or Engine not defined");
      return;
    }
    this.pinManager?.dispose();
    this.gui?.dispose();

    // Dispose of current scene
    this.scene.onPointerObservable.clear();
    this.camera3DView?.onViewMatrixChangedObservable.clear();
    this.cameraFirstPerson?.onViewMatrixChangedObservable.clear();
    this.loadingManager?.reset();
    this.scene.dispose();
    this.engine.dispose();
    this.engine = undefined;
  }

  setPointerY() {
    if (!this.scene || !this.cameraFirstPerson)
      return console.error("Scene not defined");
    if (!this.pointerCircle) return console.error("pointerCircle not found");
    const hit = Utils.getMeshUnderneath(
      this.scene,
      this.cameraFirstPerson.position
    );

    if (hit && hit.length > 0 && hit[0].pickedPoint) {
      this.pointerCircle.yPos = hit[0].pickedPoint.y + 0.01;
    } else {
      console.error("Y coordinate not found");
    }
  }

  disableNode(nodeName: string, key: string) {
    if (!this.scene) return console.error("Scene not defined");
    const node = Utils.findNode(nodeName, this.scene) as TransformNode;
    if (node) {
      this.disabledNodes.set(key, node);
      Utils.toggleNodes([node], false);
    }
  }

  setNodesMask(nodeNames: string[], maskLayer: number) {
    if (!this.scene) return console.error("Scene not defined");
    Utils.setNodeProperties(nodeNames, this.scene, {
      layerMask: maskLayer,
    });
  }

  resize() {
    if (this.engine) {
      this.engine.resize();
    }
    if (this.minimap) {
      this.minimap.resizeMinimap();
    }
    this.fitToScreen();
    this.renderScene();
  }

  addVirtualTourControls() {
    this.cameraManager?.addVirtualTourControls({});
  }

  set3dCameraViewProperties(options: unknown) {
    if (!this.camera3DView)
      return console.error("Camera 3d view is not defined");
    Object.assign(this.camera3DView, options);
    this.update3DCameraCoords();
    this.updateLastCameraValues();
    if (this.camera3DView.lowerRadiusLimit) {
      this.maxLowerRadiusValue =
        this.camera3DView.lowerRadiusLimit - this.highlightZoom * 2;
    }
  }

  setVirtualTourProperties(options: unknown) {
    if (!this.cameraFirstPerson)
      return console.error("Virtual tour camera view is not defined");
    Object.assign(this.cameraFirstPerson, options);
  }

  addPointerCircle() {
    if (!this.scene) return console.error("Scene not defined");
    this.pointerCircle = new PointerCircle();
    this.pointerCircle.init(
      Constants.POINTER_CIRCLE_SIZE,
      this.scene,
      "virtualTourPointer",
      Constants.MESH_LAYER_MASK
    );

    this.setPointerY();
  }

  switchCamera(view: string) {
    this.cameraManager?.switchCamera(view);
    this.renderScene();
  }

  setZoomValue(val: number) {
    if (!this.cameraManager || !this.camera3DView)
      return console.error("Arc rotate camera is not defined");
    this.cameraManager.zoomCamera(val, this.camera3DView, 2);
    this.renderScene();
  }

  setNodesInteraction(nodes: string[], checkFloor: boolean) {
    if (!this.scene) return console.error("Scene not defined");
    if (checkFloor) {
      let floors: AbstractMesh[] = [];
      for (const mesh of this.scene.transformNodes) {
        if (mesh.name.includes("walkable")) {
          floors = floors.concat(mesh.getChildMeshes(true));
        }
      }
      for (const mesh of this.scene.meshes) {
        if (mesh.name.includes("walkable")) {
          floors.push(mesh);
        }
      }
      if (floors.length > 0) {
        for (const floor of floors) {
          floor.isPickable = true;
          floor.enablePointerMoveEvents = true;
        }
        return;
      }
    }
    Utils.setNodeProperties(nodes, this.scene, {
      isVisible: true,
      isPickable: true,
      enablePointerMoveEvents: true,
    });
  }

  initMinimap() {
    if (!this.scene || !this.engine || !this.camera3DView)
      return console.error("Camera 3D view not defined");
    this.minimap = new MinimapViewport(this.scene, this.floorName);
  }

  setCookieFunctions(
    addCookieHoverSpace: () => void,
    addCookieClickSpace: () => void
  ) {
    // this.highlightManager.addCookieHoverSpace = addCookieHoverSpace;
    // this.highlightManager.addCookieClickSpace = addCookieClickSpace;
  }

  addMatrixObservableFirstPerson() {
    if (!this.cameraFirstPerson) return console.error("Camera not defined");
    // After each camera input change render the Scene
    this.cameraFirstPerson.onViewMatrixChangedObservable.add(() => {
      this.renderScene();
      if (this.cameraFirstPerson) {
        this.cameraFirstPerson.position.y = this.cameraFirstPerson.metadata.y;
        // Update minimap pointer
        this.minimap?.updatePositions(
          this.cameraFirstPerson.position,
          this.cameraFirstPerson.rotation.y
        );
      }
    });
  }

  changeFitout(fitoutName: string, focusCanvas = true) {
    if (!this.fitoutsManager || !this.scene)
      return console.error("Fitouts manager not defined");
    // TO DO: send proper info from the front end
    if (fitoutName === "") return;
    //
    this.fitoutsManager.changeFitout(fitoutName);
    this.currentFitout =
      fitoutName == "none" ? "none" : "ceiling_" + fitoutName;
    this.minimap?.updateMinimap();
    this.renderScene(Constants.MAX_RENDER_TIME);
    if (focusCanvas) {
      this.scene.onAfterRenderObservable.addOnce(() => {
        this.scene?.getEngine().getRenderingCanvas()?.focus();
      });
    }
  }

  highlightBuildingSpace(
    spaceCode: string,
    buildingCode: string,
    floorCode: string
  ) {
    this.pinManager?.unselectPin();
    const highlightName = buildingCode + "_" + floorCode + "_" + spaceCode;
    if (!this.scene) return console.error("Scene not defined");
    this.hideHighlight(false);
    const checkName = Utils.checkSpaceName(highlightName);
    const floor = this.highlightManager?.highlightBuildingSpace(
      this.scene,
      checkName[0],
      buildingCode
    );
    if (checkName.length > 1) {
      for (let i = 1; i < checkName.length; i++) {
        const nextSpace = buildingCode + "_" + floorCode + "_" + checkName[i];
        this.highlightManager?.highlightBuildingSpace(
          this.scene,
          nextSpace,
          buildingCode
        );
      }
    }
    this.updateLastCameraValues();
    //// Movement animation
    if (floor && this.camera3DView) {
      let center = Vector3.Zero();
      const kids = floor.getChildMeshes();
      if (kids.length > 0) {
        const newMesh = Utils.mergeMeshes(kids as Mesh[]);
        center = (newMesh as Mesh).getBoundingInfo().boundingBox.centerWorld;
        newMesh?.dispose();
      } else {
        center = (floor as Mesh).getBoundingInfo().boundingBox.centerWorld;
      }

      this.camera3DView.rebuildAnglesAndRadius();
      const rotations = Math.floor(this.camera3DView.alpha / 6.28319);
      this.moveArcRotateCamera(
        { x: center.x, y: center.y, z: center.z },
        this.targetAlphaValue + rotations * 6.28319,
        this.camera3DView.metadata.highlightBeta,
        -1 * this.highlightZoom
      );
    }
  }

  moveArcRotateCamera(
    target: { x: number; y: number; z: number },
    alpha: number,
    beta: number,
    zoomValue: number
  ) {
    if (
      !this.scene ||
      !this.camera3DView ||
      !this.camera3DView.lowerRadiusLimit
    )
      return console.error("Scene or camera not defined");
    this.renderScene(-1);
    const actualZoom = this.originalCameraCoords.radius + zoomValue;
    this.cameraManager?.moveArcRotateCamera(
      {
        radius: actualZoom,
        alpha: alpha,
        beta: beta,
        target: target,
      },
      1,
      () => {
        this.renderScene(1);
      }
    );
  }

  hideHighlight(moveCamera = true) {
    this.pinManager?.unselectPin();
    if (!this.camera3DView) return;
    this.highlightManager?.hideMeshes();
    this.highlightManager?.resetBuildingVisibility();
    if (moveCamera) {
      this.moveArcRotateCamera(
        {
          x: this.originalCameraCoords.target.x,
          y: this.originalCameraCoords.target.y,
          z: this.originalCameraCoords.target.z,
        },
        this.lastCameraValues.alpha,
        this.lastCameraValues.beta,
        this.highlightZoom
      );
    }
  }

  addHighlightNodes(buildingCode: string) {
    if (!this.scene) return console.error("Scene not defined");
    const caseNode = Utils.findNode(
      buildingCode + "_case",
      this.scene
    ) as TransformNode;
    const floorsNode = Utils.findNode(
      buildingCode + "_floors",
      this.scene
    ) as TransformNode;
    this.highlightManager?.caseNodes.set(buildingCode, caseNode);
    this.highlightManager?.floorsNodes.set(buildingCode, floorsNode);
  }

  setNodesIsVisible(nodeName: string) {
    if (!this.scene) return console.error("Scene not defined");
    Utils.setNodeProperties([nodeName], this.scene, {
      isVisible: false,
    });
  }

  setNodesEnabled(nodeName: string) {
    if (!this.scene) return console.error("Scene not defined");
    const parentNode = Utils.findNode(nodeName, this.scene);
    if (parentNode) {
      Utils.toggleNodes(parentNode.getChildMeshes(), false);
    }
  }

  setNodesCollision(nodeName: string) {
    if (!this.scene) return console.error("Scene not defined");
    Utils.setNodeProperties([nodeName], this.scene, {
      checkCollisions: true,
    });
  }

  updateCeiling() {
    if (!this.scene?.activeCameras) return;
    // Hide ceiling
    Utils.toggleNodes(Array.from(this.disabledNodes.values()), false);
    if (this.scene.activeCameras[0].name === Constants.UNIVERSAL_CAMERA_NAME) {
      const currentFitoutNode = this.disabledNodes.get(this.currentFitout);
      if (currentFitoutNode) {
        // Show ceiling
        Utils.toggleNodes([currentFitoutNode], true);
      }
    }
  }

  setCeilingEnabled(isEnabled: boolean) {
    // Get main ceiling value
    const mainCeiling = this.disabledNodes.get("none");
    if (!mainCeiling) return console.error("Main ceiling node not found");
    // Toggle ceiling isEnabled
    Utils.toggleNodes(Array.from(this.disabledNodes.values()), isEnabled);
  }

  addSkybox(url: string) {
    if (!this.scene) return console.error("Scene not defined");
    this.skybox = this.loadingManager?.loadSkybox(url, this.scene);
    this.skybox?.setEnabled(false);
  }

  update3DCameraCoords() {
    if (!this.camera3DView) return console.error("Camera 3D not defined");
    // Update original coords for camera
    this.originalCameraCoords.radius = this.camera3DView.radius;
    this.originalCameraCoords.target = this.camera3DView.target.clone();
  }

  showPins(category: string) {
    if (!this.pinManager) return console.error("Pin manager not defined");
    this.pinManager.showSpecificPins(category);
  }

  fitToScreen() {
    if (
      !this.engine ||
      !this.camera3DView ||
      !this.cameraManager ||
      !this.scene
    )
      return;

    const aspectRatio = this.engine.getScreenAspectRatio();
    if (this.floorName === "") {
      const radius =
        this.originalCameraCoords.radius *
        (1 +
          (aspectRatio > 0 && aspectRatio < 0.85
            ? aspectRatio + (0.75 - aspectRatio) * 1.5
            : 0));

      this.camera3DView.radius = radius;
      this.camera3DView.lowerRadiusLimit = radius - this.highlightZoom;
      this.camera3DView.upperRadiusLimit = radius;
      this.cameraManager.cameraRadiusLimit = this.camera3DView.radius;
    } else {
      const bbInfo = this.scene
        .getTransformNodeByName(this.floorName)
        ?.getHierarchyBoundingVectors();

      if (!bbInfo) return;
      const framingBehavior = this.camera3DView.getBehaviorByName(
        "Framing"
      ) as FramingBehavior;

      this.camera3DView.upperRadiusLimit = null;
      this.camera3DView.lowerRadiusLimit = null;
      framingBehavior.zoomOnBoundingInfo(bbInfo.min, bbInfo.max, false, () => {
        if (this.cameraManager && this.camera3DView) {
          this.cameraManager.cameraRadiusLimit = this.camera3DView.radius;
          this.camera3DView.upperRadiusLimit = this.camera3DView.radius;
          this.camera3DView.lowerRadiusLimit = this.camera3DView.radius * 0.5;
          this.camera3DView.target = this.originalCameraCoords.target;
        }
      });

      const targetScreenOffset = new Vector2(
        0,
        aspectRatio > 0 && aspectRatio < 1
          ? Constants.CAMERE_YOFFSET_PORTRAIT_MOBILE
          : 0
      );
      this.camera3DView.targetScreenOffset = targetScreenOffset;
    }
  }

  focusFloorSpace(spaces: string, floorName: string) {
    console.error("Implement camera target function for floor");
    return Vector3.Zero();
  }

  getSpaceName(space: string, floorName: string) {
    console.error("Implement getSpaceName function");
    return "";
  }

  onLoadFitouts(sceneFilename: string) {
    const fitoutsNode = this.scene?.getTransformNodeByName(sceneFilename);
    if (!fitoutsNode) return console.error("Fitout not found ", sceneFilename);
    this.fitoutsManager?.addFitoutNode(fitoutsNode);

    for (const fitout of fitoutsNode.getChildren()) {
      this.setCeilingMask(fitout as TransformNode);
    }
    this.changeFitout("none", false);
    //// Load options
    buildingStore.commit("setLoadingAssetsStatus", true);
    buildingStore.commit("setFitoutData", {
      initialized: true,
      data: this.fitoutsManager?.getFitoutNames(fitoutsNode as TransformNode), // this method is used to populate the store fitoutData parameter with an array of objects of type {text, value}
    });
  }

  updateLastCameraValues() {
    if (!this.camera3DView) return console.error("No 3D camera view defined");
    this.lastCameraValues.alpha = this.camera3DView.alpha;
    this.lastCameraValues.beta = this.camera3DView.beta;
  }

  setSceneProperties(options: Record<string, unknown>) {
    if (options.fogColor) {
      options.fogColor = Color3.FromHexString(options.fogColor as string);
    }
    Object.assign(this.scene!, options);
  }

  setAllMaterialProperties(options: Record<string, unknown>) {
    if (!this.scene) return console.error("Scene not defined");
    for (const mat of this.scene.materials) {
      Object.assign(mat, options);
    }
  }

  setSpecificMaterialProperties(
    matName: string,
    options: Record<string, unknown>
  ) {
    if (!this.scene) return console.error("Scene not defined");
    Object.assign(this.scene.getMaterialByName(matName)!, options);
  }

  isCameraMovementValid(pointerPick: PickingInfo) {
    console.log("Implement camera movement check function");
    return true;
  }

  setCeilingMask(fitout: TransformNode) {
    for (const child of fitout.getChildren()) {
      if (child.name == "ceiling") {
        //// Disable fitout ceiling
        this.disabledNodes.set(
          "ceiling" + "_" + fitout.name,
          child as TransformNode
        );
        Utils.toggleNodes([child as TransformNode], false);
        for (const mesh of child.getChildMeshes()) {
          Object.assign(mesh, {
            layerMask: Constants.VIRTUAL_TOUR_CAMERA_MASK,
          });
        }
      }
    }
  }
}
