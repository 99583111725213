var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    staticClass: "atom-vertical-line",
    style: _vm.color ? { "background-color": _vm.color } : {},
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }