<template>
  <div class="section-spaces canvas-buttons-wrapper active">
    <div class="col-12 background-gray">
      <div class="text-center">
        <div class="inline-element text-left select-style">
          <label>
            <AtomIcon icon="chair-office" :size="14" />
          </label>
          <div class="select-inline-block">
            <AtomSelect
              id="fit-out"
              @change="changeFitout"
              :options="fitoutsOpts"
              :select-props="{
                search: false,
                dropdownCssClass: 'fitout-dropdown-list',
                containerCssClass: 'fitout-dropdown-container',
              }"
            />
            <div class="info-fit-out">
              <span class="fit-out-text">Fitout</span>
              <span class="select-text hidden-mobile-elements">Select</span>
              <div class="custom-tooltip">
                <span class="info-icon" data-fitout=""
                  ><AtomIcon icon="info" :size="16"
                /></span>
              </div>
              <div class="legends legends-space"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomSelect from "../../atoms/common/AtomSelect";
import AtomIcon from "../../atoms/common/AtomIcon";

export default {
  name: "MoleculeFitoutSelector",
  components: { AtomIcon, AtomSelect },
  props: {
    fitoutData: {
      required: true,
      type: Array,
      default: () => [],
    },
    changeFitout: {
      required: true,
      type: Function,
      default: () => {},
    },
  },
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    floorFitouts() {
      return (
        this.generalConfig?.modelDisplaySettings[this.activeProject]?.floor
          ?.fitoutsInfo || []
      );
    },
    fitoutsOpts() {
      let newfitoutData = [];
      let opts = [
        {
          text:
            "Select one of the fit-outs to see how the space will look if fitted in certain ways or none to see it empty.",
          value: "",
          disabled: "disabled",
        },
        { text: "None", value: "none" },
      ];
      if (this.fitoutData && this.fitoutData.length > 0) {
        newfitoutData = this.fitoutData.map(function (obj, index) {
          return {
            text: `Fitout ${index + 1}`,
            value: obj.value,
          };
        });
        opts = [...opts, ...newfitoutData];
      }
      return opts;
    },
  },
};
</script>

<style scoped></style>
