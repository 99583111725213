<template>
  <div class="organism-project-slider">
    <MoleculeProjectSliderThumb v-if="sliderData.length > 1">
      <AtomProjectSliderThumb v-for="(thumb, index) in sliderData" :active-thumb="activeThumb" :thumb-id="`item-${index}`" :thumb="thumb.thumb" :sold="thumb.sold" :title="thumb.title" :subtitle="thumb.subtitle" :key="index" @change="handleThumbChange" />
    </MoleculeProjectSliderThumb>

    <MoleculeProjectSliderBackground>
      <AtomProjectSliderBackground v-for="(image, index) in sliderData" :active-background="activeThumb" :background-id="`item-${index}`" :image="image.image" :video="image.video" :key="index" />
    </MoleculeProjectSliderBackground>
  </div>
</template>

<script>
import MoleculeProjectSliderThumb from "../../molecules/portfolio/MoleculeProjectSliderThumb";
import AtomProjectSliderThumb from "../../atoms/portfolio/AtomProjectSliderThumb";
import AtomProjectSliderBackground from "../../atoms/portfolio/AtomProjectSliderBackground";
import MoleculeProjectSliderBackground from "../../molecules/portfolio/MoleculeProjectSliderBackground";
export default {
  name: "OrganismProjectSlider",
  components: {
    MoleculeProjectSliderBackground,
    AtomProjectSliderBackground, AtomProjectSliderThumb, MoleculeProjectSliderThumb},
  props: {
    sliderData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {
      activeThumb: 'item-0',
    }
  },
  methods: {
    handleThumbChange(id) {
      this.activeThumb = id;
      this.$emit('thumb-change', id);
    }
  }
}
</script>

<style lang="scss" scoped>
  .organism-project-slider {

  }
</style>