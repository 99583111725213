<template>
  <div class="organism-sidebar-accordion" :class="{'active': isMobileMenuOpen}">
      <div class="space-results">
        <MoleculeSidebarAccordionResults :pins-info="pinsInfo" :sidebar-title="sidebarTitle" />
      </div>
  </div>
</template>

<script>
import MoleculeSidebarAccordionResults from "../../molecules/common/MoleculeSidebarAccordionResults";
export default {
  name: "OrganismSidebarAccordion",
  components: {MoleculeSidebarAccordionResults},
  props: {
    pinsInfo: [],
    sidebarTitle: {
      required: false,
      type: String,
      default: "",
    }
  },
  computed: {
    isMobileMenuOpen() {
      return this.$store.state.base.mobileAvailabilityMenuOpen
    }
  }
}
</script>

<style lang="scss">
  .organism-sidebar-accordion {
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 119px);
    width: 415px;
    flex-grow: 0;
    flex-shrink: 0;
    background: $white;
    z-index: 9;
    padding: 20px 0 10px;
    transition: all 0.5s ease-in-out 0s;
    @media only screen and (max-width: 1200px) {
      display: none;
      right: 50%;
      top: initial;
      width: 345px;
      padding: 20px 12px 10px;
      height: calc(100% - 122px);
      bottom: 116px;
      border-radius: 4px;
      min-height: 0;
      @include prefix(transform, translateX(50%), ('webkit', 'moz', 'ms', 'o'));
      @include prefix(transition, none, ('webkit', 'moz', 'ms', 'o'));
      &.active {
        display: block;
      }
    }
    @media only screen and (max-width: 400px) {
      width: 96%;
    }
    .space-results {
      overflow: hidden;
      overflow-y: auto;
      height: calc(100% - 115px);
    }
  }
</style>