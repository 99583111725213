var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "molecule-sidebar-header",
      attrs: { id: "molecule-sidebar-header" },
    },
    [
      _c(
        "div",
        { staticClass: "title-and-info" },
        [_c("AtomSidebarTitle", { attrs: { title: "Available Spaces" } })],
        1
      ),
      _c("div", { staticClass: "filters clearfix" }, [
        _c(
          "form",
          {
            staticClass: "row filters-row",
            attrs: { id: "filter-spaces", action: "" },
          },
          [
            Object.keys(_vm.buildingOptions).length > 2
              ? _c("div", { staticClass: "col col-padding-4px" }, [
                  _c(
                    "div",
                    { staticClass: "select-style" },
                    [
                      _c("AtomSelect", {
                        attrs: {
                          id: "building",
                          label: "Building",
                          options: _vm.buildingOptions,
                          "select-props": { search: false },
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleFilterChange($event, "building")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "col col-padding-4px" }, [
              _c(
                "div",
                { staticClass: "select-style" },
                [
                  _c("AtomSelect", {
                    attrs: {
                      id: "floor",
                      label: "Floor",
                      options:
                        _vm.selectedBuilding === ""
                          ? _vm.floorOptions
                          : _vm.availableFloorsForSelect,
                      "select-props": { search: false },
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleFilterChange($event, "floor")
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col col-padding-4px" }, [
              _c(
                "div",
                { staticClass: "select-style" },
                [
                  _c("AtomSelect", {
                    attrs: {
                      id: "surface",
                      label: "Surface",
                      options:
                        _vm.selectedFloor === ""
                          ? _vm.surfaceOptions
                          : _vm.availableSurfacesForSelect,
                      "select-props": { search: false },
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleFilterChange($event, "surface")
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("AtomButton", {
                  staticClass: "hidden",
                  attrs: { label: "Filter" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm.measurementsItems.length > 0
        ? _c(
            "div",
            { staticClass: "select-measurement" },
            _vm._l(_vm.measurementsItems, function (m, idx) {
              return _c("AtomRadio", {
                key: idx,
                attrs: {
                  id: "select-" + m.label.toLocaleLowerCase(),
                  label: "<b>" + m.label + "</b> " + _vm.unitOfMeasureString,
                  value: m.multiplier,
                  "data-text": m.label,
                  "is-checked": _vm.isRadioChecked(m),
                  name: "measurements",
                },
                on: {
                  "get-value": _vm.handleSelectMeasurement,
                  "get-data-text": _vm.handleSelectMeasurementLabel,
                },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }