var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "organism-sidebar-building",
      class: { active: _vm.isMobileMenuOpen },
    },
    [
      _c("MoleculeSidebarHeader", {
        ref: "sidebarHeader",
        attrs: { buildingInteractionsRef: _vm.buildingInteractionsRef },
      }),
      _c(
        "div",
        {
          ref: "spaceResultsScroll",
          staticClass: "space-results",
          style: { height: "calc(100% - " + _vm.sidebarHeaderHeight + "px)" },
        },
        [
          _c("MoleculeSidebarResults", {
            on: { buildingInteractionsRef: _vm.handleBuildingInteractionRef },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }