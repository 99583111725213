<template>
  <div
    :class="{
      section: true,
      'section-icons-descriptions': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title" v-if="data.title || data.subtitle">
      <AtomTitle :title="data.title" :subtitle="data.subtitle" />
    </div>
    <MoleculeIconListWithTexts
      :data-list="data.contents"
      column-number="3"
      tablet-column-number="6"
      mobile-column-number="12"
    />
  </div>
</template>

<script>
import MoleculeIconListWithTexts from "../../components/molecules/common/MoleculeIconListWithTexts";
import AtomTitle from "../../components/atoms/common/AtomTitle";

export default {
  name: "SectionIconsDescriptions",
  components: { AtomTitle, MoleculeIconListWithTexts },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.section-icons-descriptions {
  border-bottom: 1px solid #ecf5f8;
  padding: 4.688rem 3.75rem 3.75rem;
  @media only screen and (max-width: 767px) {
    padding: 0;
  }
  .atom-title {
    margin-bottom: 1.875rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  .atom-icon-and-text {
    .box-content {
      max-width: 100%;
      @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
      .border-color {
        margin: 0 0 1rem;
        justify-content: left;
        border: none;
        width: 6.25rem;
        height: 6.25rem;
        @media only screen and (max-width: 767px) {
          width: 70px;
          height: 70px;
          margin-bottom: 10px;
        }
        .icon {
          font-size: 4rem !important;
          @media only screen and (max-width: 767px) {
            font-size: 64px !important;
          }
        }
        img {
          height: 6.25rem;
          @media only screen and (max-width: 767px) {
            height: 70px;
          }
        }
      }
      .title {
        text-align: left;
        margin-bottom: 10px;
        font-weight: 600;
      }
      .description {
        font-size: 1rem;
        line-height: 1.3rem;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
        }
        p {
          font-size: 1rem;
          line-height: 1.3rem;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
</style>
