<template>
  <div class="organism-tabs-technical-specs-v2">
    <div class="row tabs-elements align-items-end">
      <div class="col col-xs-12 col-6">
        <div class="sub-tabs">
          <div class="sub-tab-trigger" v-for="(subTab, index) in subTabs" :class="{active: activeSubTab === subTab.subTabId }" @click="handleTabChange(subTab.subTabId)" :key="index">{{ subTab.subTabLabel }}</div>
        </div>
      </div>
      <div class="col col-xs-12 col-6">
        <div class="text-right">
          <slot></slot>
        </div>
      </div>
    </div>
    <div v-for="(subTab, index) in subTabs" :key="index">
      <div v-if="activeSubTab === subTab.subTabId">
        <div class="row statements" v-if="subTab.statementTitle">
          <div class="col col-12">
            <div class="statement">
              <AtomIcon icon="quotation-mark" v-if="subTab.statementName" />
              <AtomTitle :title="subTab.statementTitle" :subtitle="subTab.statementText" />
              <div class="person-info">
                <h5>{{ subTab.statementName }}</h5>
                <h6>{{ subTab.statementCompany }}</h6>
              </div>
            </div>
          </div>
        </div>
        <OrganismTechnicalSpecsV2 :bullet-technical-data="subTab.items" :image-specs="subTab.image" />
      </div>
    </div>
  </div>
</template>

<script>

import OrganismTechnicalSpecsV2 from "../OrganismTechnicalSpecsV2";
import AtomIcon from "../../../atoms/common/AtomIcon";
import AtomTitle from "../../../atoms/common/AtomTitle";
export default {
  name: "OrganismTabsTechnicalSpecsV2",
  components: {AtomTitle, AtomIcon, OrganismTechnicalSpecsV2},
  props: {
    subTabs: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    let activeSubTab = '';
    if (this.subTabs && this.subTabs.length) {
      activeSubTab = this.subTabs[0].subTabId;
    }
    return {
      activeSubTab
    }
  },
  beforeUpdate() {
    if (this.subTabs && this.subTabs.length && !this.activeSubTab) {
      this.activeSubTab = this.subTabs[0].subTabId;
    }
  },
  methods: {
    handleTabChange(subTabId) {
      this.activeSubTab = subTabId;
    }
  }
}
</script>

<style lang="scss" scoped>
  .organism-tabs-technical-specs-v2 {
    margin-top: 1.25rem;
    @media only screen and (max-width: 767px) {
      margin-top: 15px;
    }
  }
</style>