var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-post-and-image": true,
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _c("div", { staticClass: "row title-and-content align-items-center" }, [
        _c("div", { staticClass: "col col-12" }, [
          _vm.data.customLogo
            ? _c("div", { staticClass: "custom-logo" }, [
                _c("img", { attrs: { src: _vm.data.customLogo, alt: "" } }),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "col col-12" }, [
          _c(
            "div",
            { staticClass: "top-title" },
            [
              _c("AtomTitle", {
                attrs: { title: _vm.data.title, subtitle: _vm.data.subtitle },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "post-content" },
            _vm._l(_vm.data.lines, function (line, i) {
              return _c("p", {
                key: i,
                staticClass: "content-line",
                domProps: { innerHTML: _vm._s(line) },
              })
            }),
            0
          ),
        ]),
      ]),
      _c("div", { staticClass: "image-full" }, [
        _c("img", {
          attrs: {
            src: "" + _vm.resourceUrl(_vm.data.image),
            alt: "Image Full Size",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }