<template>
  <div class="atom-sidebar-title">
    <h5>{{ title }}</h5>
  </div>
</template>

<script>
export default {
  name: "AtomSidebarTitle",
  props: {
    title: {
      required: true,
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .atom-sidebar-title {
    h5 {
      font-size: 16px;
      color: $black;
      font-weight: $regular;
      line-height: 19px;
      @media only screen and (max-width: 1200px) {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
</style>