<template>
  <div
    :class="{
      section: true,
      'section-background-image': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div
      class="row background-image"
      :style="`background-image: url(${resourceUrl(data.image)})`"
    >
      <div class="col col-12">
        <div class="content-header">
          <div class="gradient-background"></div>
          <AtomTitle :title="data.title" :subtitle="data.subtitle" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";
export default {
  name: "SectionBackgroundImage",
  components: { AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss">
.section-background-image {
  .background-image {
    position: relative;
    margin: 0 -0.625rem;
    padding: 0 0.625rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media only screen and (max-width: 767px) {
      margin: 0 -10px;
      padding: 0;
    }
    & > .col {
      position: static;
    }
    .gradient-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 68, 131, 0) 44.25%,
        rgba(0, 0, 0, 0.8) 85.42%
      );
    }
    .atom-title {
      position: relative;
      z-index: 5;
      padding: 15rem 3.75rem 2.75rem;
      @media only screen and (max-width: 767px) {
        padding: 100px 20px 30px;
      }
      h2 {
        color: $white !important;
        margin-bottom: 0.75rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 5px;
        }
      }
      h3 {
        color: $white !important;
        font-weight: 400;
      }
    }
  }
}
</style>
