var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-image-or-video": true,
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
      style: {
        "margin-top": (_vm.orderNo === 1 ? _vm.headerHeight : 0) + "px",
        height: "calc(" + _vm.windowHeight + "px - " + _vm.headerHeight + "px)",
      },
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _vm.hasBackgroundOverlay
        ? _c("div", {
            staticClass: "gradient",
            style: { background: "rgba(0,0,0," + _vm.overlayOpacity + ")" },
          })
        : _vm._e(),
      _c("div", { staticClass: "title-bottom" }, [
        _c("h1", [_vm._v(_vm._s(_vm.heading))]),
        _c("p", {
          staticClass: "line-text",
          domProps: { innerHTML: _vm._s(_vm.welcome) },
        }),
        _c("br"),
        _c(
          "span",
          {
            staticClass: "scroll-element-desktop",
            on: { click: _vm.goToNextSection },
          },
          [
            _c(
              "span",
              { staticClass: "icon-scroll-down" },
              [
                _c("AtomIcon", {
                  attrs: { icon: "angle-down", size: 17, color: "#fff" },
                }),
              ],
              1
            ),
            _vm._v("\n      Scroll down for more information\n    "),
          ]
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "scroll-element-mobile",
          on: { click: _vm.goToNextSection },
        },
        [
          _c(
            "span",
            { staticClass: "icon-scroll-down" },
            [
              _c("AtomIcon", {
                attrs: { icon: "angle-down", size: 17, color: "#fff" },
              }),
            ],
            1
          ),
        ]
      ),
      _c("OrganismProjectSlider", {
        attrs: { "slider-data": _vm.sliderData },
        on: { "thumb-change": _vm.handleThumbChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }