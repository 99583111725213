var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-template embed page-wrapper" },
    [
      _vm.isSpaceRoute
        ? _c(
            "div",
            { staticClass: "embed-header" },
            [
              _c(
                "router-link",
                {
                  staticClass: "back-link",
                  attrs: {
                    tag: "a",
                    to: {
                      name: "SpecificProjectEmbed",
                      params: { projectId: _vm.activeProject },
                    },
                  },
                },
                [
                  _c("AtomIcon", {
                    attrs: { icon: "angle-left", size: 32, color: "#000" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }