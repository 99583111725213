var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section section-building",
      class: ((_obj = {}), (_obj["section" + _vm.orderNo] = true), _obj),
      style: {
        height: "calc(" + _vm.windowHeight + "px - " + _vm.headerHeight + "px)",
      },
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _vm.loadingScreenActive
        ? _c(
            "div",
            {
              staticClass: "organism-loader-background",
              style: {
                "background-image":
                  "url(" + _vm.resourceUrl(_vm.backgroundLoaderUrl) + ")",
              },
            },
            [
              _c("OrganismLoader", {
                attrs: { "content-loader": _vm.contentLoaderData },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("OrganismBuilding", { ref: "building" }),
      _c(
        "div",
        { ref: "sliderComponent", staticClass: "slider-wrapper-building" },
        [
          _c(
            "span",
            { staticClass: "plus", on: { click: _vm.increaseZoomValue } },
            [_c("AtomIcon", { attrs: { size: 16, icon: "zoom-in" } })],
            1
          ),
          _c("div", { staticClass: "slider-parent" }, [
            _c("div", { attrs: { id: "slider" } }, [
              _c("i", { staticClass: "line line1" }),
              _c(
                "div",
                {
                  ref: "dragger",
                  staticClass: "ui-slider-handle",
                  staticStyle: { bottom: "0" },
                  attrs: { id: "dragger", draggable: "true" },
                  on: { drag: _vm.handleDrag, dragstart: _vm.handleDragStart },
                },
                [_vm._v("\n          ZOOM\n        ")]
              ),
            ]),
          ]),
          _c(
            "span",
            { staticClass: "minus", on: { click: _vm.decreaseZoomValue } },
            [_c("AtomIcon", { attrs: { size: 16, icon: "zoom-out" } })],
            1
          ),
        ]
      ),
      _c(
        "span",
        {
          class: {
            "scroll-element": true,
            "scroll-element-white": _vm.loadingScreenActive,
          },
          on: { click: _vm.goToNextSection },
        },
        [
          _c(
            "span",
            { staticClass: "icon-scroll-down" },
            [
              _c("AtomIcon", {
                attrs: { icon: "angle-down", size: 17, color: "#fff" },
              }),
            ],
            1
          ),
          _vm._v("\n    Scroll down for more information\n  "),
        ]
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bright-logo" }, [
      _c(
        "a",
        { attrs: { href: "https://brightspaces.tech/", target: "_blank" } },
        [_vm._v("\n      Created by © BrightSpaces\n    ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }