var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "lazyload",
          rawName: "v-lazyload",
          value: {
            handle: _vm.handleLazyLoad,
            url: _vm.resourceUrl(_vm.sliderImage.thumb),
          },
          expression:
            "{handle: handleLazyLoad, url: resourceUrl(sliderImage.thumb)}",
        },
      ],
      staticClass: "owl atom-image-thumb-slider",
      class: { active: _vm.activeThumb === _vm.index },
      on: { click: _vm.handleClick },
    },
    [
      !_vm.imageLoaded
        ? _c("AtomLazyLoader", { attrs: { height: 150 } })
        : _vm._e(),
      _vm.imageLoaded
        ? _c("div", {
            staticClass: "background-image",
            style: {
              "background-image":
                "url(" + _vm.resourceUrl(_vm.sliderImage.thumb) + ")",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }