<template>
  <div class="molecule-statements-slider">
    <div class="owl-carousel owl-theme" ref="carousel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoleculeStatementsSlider",
  props: {
    carouselOpts: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      instance: null,
    }
  },
  mounted() {
    this.initCarousel();
  },
  beforeUpdate() {
    this.destroyCarousel();
  },
  updated() {
    this.initCarousel();
  },
  methods: {
    initCarousel() {
      this.instance = window.$(this.$refs.carousel).owlCarousel({
        items: 1,
        nav: true,
        dots: false,
        margin: 20,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        mouseDrag  : false,
        ...this.carouselOpts
      });
    },
    destroyCarousel() {
      this.instance.trigger('destroy.owl.carousel');
      this.instance = null;
    }
  }
}
</script>

<style lang="scss">
  @import '../../../scss/carousel';
  .molecule-statements-slider {

  }
</style>