var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { "organism-slider": true } }, [
    _c(
      "div",
      { class: _vm.images.length > 0 ? "image-slider" : "text-slider" },
      [
        _c("atom-arrow-button-slider", {
          attrs: { right: false },
          on: { Previous: _vm.Previous },
        }),
        _vm._l(_vm.images, function (image, index) {
          return _vm.images.length > 0
            ? _c("molecule-slider-slide", {
                key: image,
                attrs: {
                  "image-src": image,
                  index: index,
                  visibleSlide: _vm.visibleSlide,
                  direction: _vm.direction,
                },
              })
            : _vm._e()
        }),
        _vm._l(_vm.slidess, function (slide, index) {
          return _vm.slidess.length > 0
            ? _c("molecule-slider-slide", {
                key: slide.titles[0] + index,
                attrs: {
                  titles: slide.titles,
                  descriptions: slide.descriptions,
                  background: slide.background,
                  index: index,
                  visibleSlide: _vm.visibleSlide,
                  direction: _vm.direction,
                },
              })
            : _vm._e()
        }),
        _c("atom-arrow-button-slider", {
          attrs: { right: true },
          on: { Next: _vm.Next },
        }),
      ],
      2
    ),
    _vm.images.length > 0
      ? _c("span", [
          _vm._v(
            _vm._s(_vm.visibleSlide + 1) + " / " + _vm._s(_vm.sliderLength)
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }