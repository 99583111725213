<template>
  <div
    :class="{
      section: true,
      'section-building': true,
      'embed-ready': isEmbedReady || (isEmbedV2 && isMobileView),
      'matterport-space-view': addMatterportClass,
      [`section${orderNo}`]: true,
    }"
    :style="{ height: `calc(${windowHeight}px - ${headerHeight}px)` }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div
      v-if="loadingScreenActive"
      class="organism-loader-background"
      :style="{
        'background-image': `url(${resourceUrl(backgroundLoaderUrl)})`,
      }"
    >
      <div v-if="isFloorView" class="loader-video-background">
        <video
          v-if="currentSpaceVideoLoaderUrl"
          class="video-loader"
          muted
          autoplay
          playsinline
          loop
          preload="metadata"
          :key="`video-${currentSpace.building_id}`"
          style="object-fit: cover !important"
        >
          <source
            :src="resourceUrl(currentSpaceVideoLoaderUrl)"
            type="video/mp4"
          />
        </video>
      </div>
      <OrganismLoader :content-loader="contentLoaderData" />
    </div>
    <OrganismBuilding ref="building" />
    <OrganismSidebarBuilding
      ref="sidebar"
      v-if="
        !availableSection &&
        !isFloorView &&
        (!isEmbedReady || (isEmbedReady && isMobileMenuOpen))
      "
    />
    <OrganismCartSpacesBuilding
      v-if="
        !availableSection &&
        !isFloorView &&
        ((!isEmbedReady && !isEmbedV2) ||
          (isEmbedReady && embedCartIsOpen) ||
          (isEmbedV2 && !isMobileView) ||
          (isEmbedV2 && isMobileView && embedCartIsOpen))
      "
    />
    <div
      class="slider-wrapper-building"
      ref="sliderComponent"
      v-if="!isFloorView"
    >
      <span class="plus" @click="increaseZoomValue">
        <AtomIcon :size="16" icon="zoom-in" />
      </span>
      <div class="slider-parent">
        <div id="slider">
          <i class="line line1"></i>
          <div
            id="dragger"
            ref="dragger"
            class="ui-slider-handle"
            draggable="true"
            style="bottom: 0"
            @drag="handleDrag"
            @dragstart="handleDragStart"
          >
            ZOOM
          </div>
        </div>
      </div>
      <span class="minus" @click="decreaseZoomValue">
        <AtomIcon :size="16" icon="zoom-out" />
      </span>
    </div>
    <OrganismSelectedSpaceMobile v-if="!isFloorView" />
    <div
      class="embed-controls"
      v-if="
        !availableSection &&
        !isFloorView &&
        ((isEmbedReady && !isSpaceEmbedRoute) || (isEmbedV2 && isMobileView))
      "
    >
      <div
        class="control embed-menu-trigger"
        :class="{ active: isMobileMenuOpen }"
        @click="toggleEmbedSidebar"
      >
        <div class="description">
          <span class="custom-arrow"></span>
          <p class="label"><b>Spaces</b></p>
          <p class="text">Check Availability</p>
        </div>
      </div>
      <div
        class="control embed-cart-trigger"
        :class="{ active: embedCartIsOpen }"
        @click="toggleCartSidebar"
      >
        <div class="description">
          <span class="custom-arrow"></span>
          <p class="label green-color">Offer & Space List</p>
          <p class="text">
            <AtomIcon icon="ruler" />
            <b>{{ totalSpace }}</b>
            {{ unitOfMeasureString }} GLA
          </p>
        </div>
      </div>
    </div>
    <OrganismSpace :matterport-space="data.matterportSpace" />
    <MoleculeFiltersPins3D v-if="!isFloorView && !isEmbedReady" />
    <span
      :class="{
        'scroll-element': true,
        'scroll-element-white': loadingScreenActive,
      }"
      @click="goToNextSection"
      v-if="!isEmbedReady && !isEmbedV2"
    >
      <span class="icon-scroll-down">
        <AtomIcon icon="angle-down" :size="17" color="#fff" />
      </span>
      Scroll down for more information
    </span>
    <div class="bright-logo" v-if="!isEmbedReady && !isEmbedV2">
      <a href="https://brightspaces.tech/" target="_blank">
        Created by © BrightSpaces
      </a>
    </div>
  </div>
</template>

<script>
import OrganismBuilding from "../../../components/organisms/project/building/OrganismBuilding";
import OrganismSidebarBuilding from "../../../components/organisms/project/building/OrganismSidebarBuilding";
import OrganismSpace from "../../../components/organisms/project/building/OrganismSpace";
import OrganismLoader from "../../../components/organisms/project/OrganismLoader";
import { CurrentView } from "../../../components/organisms/project/building/store";
import OrganismCartSpacesBuilding from "../../../components/organisms/project/building/OrganismCartSpacesBuilding";
import AtomIcon from "../../../components/atoms/common/AtomIcon";
import { smoothScrollToTargetId } from "../../../helpers/util";
import OrganismSelectedSpaceMobile from "../../../components/organisms/project/building/OrganismSelectedSpaceMobile";
import MoleculeFiltersPins3D from "../../../components/molecules/common/MoleculeFiltersPins3D";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";

export default {
  name: "SectionBuilding",
  components: {
    MoleculeFiltersPins3D,
    OrganismSelectedSpaceMobile,
    AtomIcon,
    OrganismCartSpacesBuilding,
    OrganismLoader,
    OrganismSpace,
    OrganismSidebarBuilding,
    OrganismBuilding,
  },
  props: {
    data: {
      required: true,
      type: Object | Array,
      default: function () {
        return {
          backgroundLoader: "",
          contentLoader: [],
          matterportSpace: [],
        };
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isFloorView: false,
      loadingScreenActive: true,
      windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
      headerHeight: 0,
      embedCartIsOpen: false,
      hasMounted: false,
      isMobileView:
        typeof window !== "undefined" ? window.innerWidth < 1200 : false,
      zoomSettings: {
        zoomValue: 0,
        minValue: 0,
        maxValue: 100,
      },
    };
  },
  computed: {
    generalConfig() {
      return this.$store.state?.base?.meta?.general;
    },
    availableSection() {
      return this.generalConfig?.availableSection || true;
    },
    isMobileMenuOpen() {
      return this.$store.state.base?.mobileAvailabilityMenuOpen;
    },
    contentLoaderData() {
      return this.data?.contentLoader;
    },
    backgroundLoaderUrl() {
      return this.data?.backgroundLoader;
    },
    videosLoader() {
      return this.data?.videosLoader || [];
    },
    currentSpace() {
      return this.$store?.state?.space?.space;
    },
    currentSpaceVideoLoaderUrl() {
      if (!this.videosLoader || this.videosLoader.length === 0) return;
      const found =
        this.videosLoader.find(
          (v) => v.building_id === this.currentSpace.building_id
        ) || null;
      if (!found) return;
      return found.video;
    },
    isEmbedReady() {
      const routePageData = this.$route.meta.page;
      return routePageData.isEmbedReady;
    },
    isEmbedV2() {
      const routePageData = this.$route.meta.page;
      return routePageData.isEmbedV2;
    },
    isSpaceEmbedRoute() {
      return this.$route.name === "SpecificSpaceEmbed";
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces;
    },
    totalSpace() {
      let total = 0;
      this.requestedSpaces.forEach((s) => {
        total += s.sqm;
      });
      return total;
    },
    unitOfMeasure() {
      return this.$store.state.base?.meta?.general?.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base?.meta?.general?.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
    addMatterportClass() {
      return (
        (this.$store.state.base?.meta?.general?.matterportEnabled &&
          this.isFloorView) ||
        false
      );
    },
  },
  methods: {
    hasMatterport() {
      return this.$store.state.base?.meta?.general?.matterportEnabled;
    },
    goToNextSection() {
      const sections = this.$parent.$parent.sections;
      const targetSection = sections[this.orderNo];
      const targetString = targetSection.hashAnchor;
      if (sections && sections.length > 1) {
        smoothScrollToTargetId(`#${targetString}`);
      }
    },
    resizeListener(e) {
      this.windowHeight = window.innerHeight;
      this.headerHeight = document.getElementById("header-project")
        ? document.getElementById("header-project").clientHeight
        : 0;
      this.isMobileView =
        typeof window !== "undefined" ? window.innerWidth < 1200 : false;
    },
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
    toggleEmbedSidebar() {
      const base = this.$store.getters.constants.base;
      this.$store.dispatch(
        base.withNamespace(base.action.TOGGLE_MOBILE_AVAILABILITY_MENU)
      );
      this.$store.dispatch(
        base.withNamespace(base.action.CHANGE_SIDEBAR_STATE),
        false
      );
      this.embedCartIsOpen = false;
    },
    toggleCartSidebar() {
      const base = this.$store.getters.constants.base;
      this.$store.dispatch(
        base.withNamespace(base.action.CHANGE_SIDEBAR_STATE),
        false
      );
      this.$store.dispatch(
        base.withNamespace(base.action.CHANGE_MOBILE_AVAILABILITY_MENU),
        false
      );
      this.embedCartIsOpen = !this.embedCartIsOpen;
    },

    handleDrag(e) {
      if (e.target.id !== "dragger") {
        return;
      }
      const parentRect = e.target.parentElement.getBoundingClientRect();
      const zoomRect = e.target.getBoundingClientRect();
      const absoluteYPositionWithinContainer = e.pageY - window.pageYOffset;
      const cursorVal =
        e.pageY !== 0
          ? parentRect.bottom -
            absoluteYPositionWithinContainer -
            zoomRect.height / 2
          : 0;
      if (
        cursorVal < this.zoomSettings.maxValue - 10 &&
        cursorVal > this.zoomSettings.minValue
      ) {
        this.zoomSettings.zoomValue = cursorVal;
        this.setEngineZoomValue();
      }
    },
    handleDragStart(e) {
      if (e.target.id !== "dragger") {
        return;
      }
      e.dataTransfer.setDragImage(
        e.target,
        window.outerWidth + 100,
        window.outerHeight + 100
      );
    },
    increaseZoomValue() {
      if (this.zoomSettings.zoomValue < this.zoomSettings.maxValue - 10) {
        this.zoomSettings.zoomValue = Math.min(
          this.zoomSettings.maxValue - 10,
          this.zoomSettings.zoomValue + 5
        );
        this.setEngineZoomValue();
      }
    },
    decreaseZoomValue() {
      if (this.zoomSettings.zoomValue > this.zoomSettings.minValue) {
        this.zoomSettings.zoomValue = Math.max(
          this.zoomSettings.minValue,
          this.zoomSettings.zoomValue - 5
        );
        this.setEngineZoomValue();
      }
    },
    setEngineZoomValue() {
      this.$refs.dragger.style.bottom = `${
        (this.zoomSettings.zoomValue * 100) / this.zoomSettings.maxValue
      }%`;
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.setZoomValue(this.zoomSettings.zoomValue);
      }
    },
  },
  mounted() {
    this.$refs.building.buildingStore.subscribe((payload, state) => {
      this.isFloorView = state.currentView === CurrentView.FLOOR;
      this.loadingScreenActive = state.loadingScreenActive;
    });
    window.addEventListener("resize", this.resizeListener);
    this.headerHeight = document.getElementById("header-project")
      ? document.getElementById("header-project").clientHeight
      : 0;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
};
</script>

<style lang="scss">
.section-building {
  position: relative;
  overflow: hidden;
  background: #f7f7f7;
  z-index: 1;
  .slider-wrapper-building {
    position: absolute;
    left: 1.25rem;
    top: 50%;
    z-index: 9;
    transition: all 0.5s ease-in-out 0s;
    transform: translateY(-50%);
    @media only screen and (max-width: 1200px) {
      display: none;
    }
    & > span {
      font-size: 16px;
      position: absolute;
      left: 50%;
      z-index: 9;
      cursor: pointer;
      transform: translateX(-50%);
      transition: all 0.2s ease-in-out 0s;
      .icon {
        color: #b4c5d6 !important;
      }
      &.plus {
        top: 8px;
      }
      &.minus {
        bottom: 8px;
      }
      &:hover {
        .icon {
          color: $black !important;
        }
      }
    }
    .slider-parent {
      position: relative;
      width: 33px;
      background: $white;
      padding: 38px 0;
      border-radius: 6px;
      #slider {
        position: relative;
        width: 100%;
        height: auto;
        padding: 0;
        i {
          display: block;
          width: 2px;
          margin: 0 auto;
          height: 66px;
          background: #d8d8d8;
          transition: all 0.2s ease-in-out 0s;
        }
        .ui-slider-handle {
          display: block;
          position: absolute;
          left: 50%;
          z-index: 9;
          overflow: hidden;
          background: $black;
          width: 11px;
          height: 11px;
          border-radius: 11px;
          text-align: center;
          cursor: pointer;
          font-size: 8px;
          color: $black;
          line-height: 11px;
          font-weight: $bold;
          transform: translateX(-50%);
          transition: width 0.2s ease-in-out 0s,
            border-radius 0.2s ease-in-out 0s, color 0.5s ease-in-out 0s;
        }
      }
    }
    &:hover {
      .slider-parent {
        #slider {
          i {
            width: 4px;
          }
          .ui-slider-handle {
            width: 31px;
            border-radius: 2px;
            color: $white;
          }
        }
      }
    }
  }
  .combine-space {
    position: absolute;
    left: 3.75rem;
    bottom: 4rem;
    z-index: 9;
    padding: 10px 16px;
    background: $white;
    width: 250px;
    border-radius: 4px;
    text-align: center;
    transition: all 0.5s ease-in-out 0s;
    @media only screen and (max-width: 1200px) {
      width: 345px;
      bottom: 116px;
      left: 50%;
      transform: translateX(-50%);
    }
    @media only screen and (max-width: 400px) {
      width: 96%;
      left: 2%;
      transform: none;
    }

    p {
      font-size: 14px;
      color: $black;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 10px;
    }

    a {
      width: 100%;
    }
  }

  &.embed-ready {
    .organism-sidebar-building {
      bottom: 75px;
      top: initial;
      right: 50%;
      border-radius: 4px;
      transform: translateX(50%);
      z-index: 9;
      @media only screen and (max-width: 1200px) {
        width: 345px !important;
      }
      @media only screen and (max-width: 345px) {
        width: 96% !important;
      }
    }

    .organism-cart-spaces-building {
      position: absolute;
      bottom: 75px;
      right: 50%;
      width: 415px;
      transform: translateX(50%);
      @media only screen and (max-width: 1200px) {
        width: 345px;
      }
      @media only screen and (max-width: 345px) {
        width: 96%;
      }

      .cart-spaces {
        position: relative;
        border-radius: 4px !important;
        width: 100% !important;
        bottom: 0;
      }
    }

    .embed-controls {
      position: absolute;
      z-index: 9;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto 5px;
      width: 415px;
      display: flex;
      @media only screen and (max-width: 1200px) {
        width: 345px;
      }
      @media only screen and (max-width: 345px) {
        width: 96%;
      }

      .control {
        display: flex;
        flex-direction: row;
        background: $white;
        border-radius: 4px;
        height: 65px;

        &.embed-menu-trigger {
          flex: 1;
          margin-right: 2.5px;
        }

        &.embed-cart-trigger {
          flex: 1;
          margin-left: 2.5px;
        }

        .description {
          position: relative;
          display: block;
          width: 100%;
          padding: 10px;
          cursor: pointer;

          .custom-arrow {
            position: absolute;
            right: 10px;
            top: 12px;
            border-color: transparent transparent $black;
            border-style: solid;
            border-width: 0 5px 6px;
            height: 0;
            width: 0;
            display: inline-block;
            vertical-align: middle;
            transition: all 0.2s ease-in-out 0s;
          }

          p {
            font-size: 14px;
            color: $black;
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 7px;

            &:last-child {
              margin-bottom: 0;
            }

            &.green-color {
              color: #4cc66d !important;
            }

            .icon {
              font-size: 15px !important;
              transition: all 0.2s ease-in-out 0s;
            }

            b {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }

        &:hover {
          .description {
            .custom-arrow {
              border-color: transparent transparent #4cc66d;
            }
          }
        }

        &.active {
          background: $black;

          .description {
            .custom-arrow {
              border-color: $white transparent transparent;
              border-width: 6px 5px 0;
            }

            p {
              color: $white;

              .icon {
                color: $white !important;
              }
            }
          }
        }
      }
    }
  }

  .organism-loader-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .loader-video-background {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .bright-logo {
    position: absolute;
    bottom: 0;
    left: 0;
    a {
      display: inline-block;
      background: rgba(255, 255, 255, 0.4);
      padding: 0.313rem 1.25rem;
      @include font-main(0.75rem, #494949, $regular, 0.875rem);
      @media only screen and (max-width: 1200px) {
        padding: 3px 10px;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  .scroll-element {
    display: none;
  }

  &.section1 {
    .scroll-element {
      display: block;
      position: absolute;
      left: 13rem;
      bottom: 0;
      z-index: 9999;
      font-size: 0.75rem;
      line-height: 1.5rem;
      color: #2e284f;
      cursor: pointer;
      @media only screen and (max-width: 1200px) {
        font-weight: 300;
        font-size: 10px;
        line-height: 20px;
        bottom: 0;
        left: 155px;
      }

      .icon-scroll-down {
        display: inline-block;
        vertical-align: middle;
        width: 1.688rem;
        height: 1.688rem;
        margin-right: 0.625rem;
        line-height: 1.6rem;
        text-align: center;
        border: 0.125rem solid rgba(255, 255, 255, 0.3);
        background: rgba(255, 255, 255, 0.3);
        border-radius: 1.688rem;
        @media only screen and (max-width: 1200px) {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          line-height: 17px;
          border: 2px solid rgba(255, 255, 255, 0.3);
          border-radius: 20px;
        }

        .icon {
          font-size: 1.063rem !important;
          color: #2e284f !important;
          @media only screen and (max-width: 1200px) {
            font-size: 12px !important;
          }
        }
      }

      &.scroll-element-white {
        color: $white;
        @media only screen and (max-width: 1200px) {
          bottom: 25px;
          left: 24px;
          transform: none;
          margin-left: 0;
        }

        .icon-scroll-down {
          background: transparent;
          border-color: rgba(255, 255, 255, 0.1);

          .icon {
            color: $white !important;
          }
        }
      }
    }
  }

  &.matterport-space-view {
    height: auto !important;
    overflow: visible;
    .organism-building,
    .bright-logo,
    .scroll-element {
      display: none !important;
    }
  }
}
</style>
