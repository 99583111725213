var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.transportationType === _vm.mode
    ? _c(
        "li",
        {
          staticClass: "atom-route",
          attrs: { "data-marker": _vm.markerId, "data-mode": _vm.mode },
          on: { click: _vm.drawRouteOnItemClicked },
        },
        [
          _c("b", [_vm._v(_vm._s(_vm.orderDisplay))]),
          _vm._v("\n  " + _vm._s(_vm.name) + "\n  "),
          _c(
            "span",
            { staticClass: "add-time" },
            [
              _c("AtomIcon", { attrs: { icon: _vm.mode, size: 14 } }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.time) +
                  "   " +
                  _vm._s(_vm.distance) +
                  "\n    "
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }