<template>
  <div class="organism-sidebar-building" :class="{ active: isMobileMenuOpen }">
    <MoleculeSidebarHeader
      ref="sidebarHeader"
      :buildingInteractionsRef="buildingInteractionsRef"
    />
    <div
      class="space-results"
      :style="{ height: `calc(100% - ${sidebarHeaderHeight}px)` }"
      ref="spaceResultsScroll"
    >
      <MoleculeSidebarResults
        @buildingInteractionsRef="handleBuildingInteractionRef"
      />
    </div>
  </div>
</template>

<script>
import MoleculeSidebarHeader from "../../../molecules/project/building/MoleculeSidebarHeader";
import MoleculeSidebarResults from "../../../molecules/project/building/MoleculeSidebarResults";
import { CurrentView } from "./store";
export default {
  name: "OrganismSidebarBuilding",
  components: { MoleculeSidebarResults, MoleculeSidebarHeader },
  props: {
    spaceInfo: [{}, {}],
  },
  data() {
    return {
      buildingInteractionsRef: null,
      sidebarHeaderHeight: 0,
    };
  },
  computed: {
    isMobileMenuOpen() {
      return this.$store.state.base.mobileAvailabilityMenuOpen;
    },
    isEmbedReady() {
      const routePageData = this.$route.meta.page;
      return routePageData.isEmbedReady;
    },
  },
  methods: {
    handleBuildingInteractionRef(buildingInteractionsRef) {
      this.buildingInteractionsRef = buildingInteractionsRef;
    },
    resizeListener(e) {
      this.sidebarHeaderHeight = document.getElementById(
        "molecule-sidebar-header"
      )
        ? document.getElementById("molecule-sidebar-header").clientHeight
        : 0;
    },
  },
  mounted() {
    setTimeout(() => {
      window.addEventListener("resize", this.resizeListener);
      this.sidebarHeaderHeight = document.getElementById(
        "molecule-sidebar-header"
      )
        ? document.getElementById("molecule-sidebar-header").clientHeight
        : 0;
    }, 500);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
};
</script>

<style lang="scss">
.organism-sidebar-building {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(100% - 119px);
  width: 415px;
  background: $white;
  z-index: 9;
  transition: all 0.5s ease-in-out 0s;
  @media only screen and (max-width: 1200px) {
    display: none;
    right: 50%;
    top: initial;
    width: 345px;
    padding: 0 12px 0;
    height: calc(100% - 122px);
    bottom: 116px;
    border-radius: 4px;
    min-height: 0;
    overflow: hidden;
    @include prefix(transform, translateX(50%), ("webkit", "moz", "ms", "o"));
    @include prefix(transition, none, ("webkit", "moz", "ms", "o"));
    &.active {
      display: block;
    }
  }
  @media only screen and (max-width: 400px) {
    width: 96%;
  }
  .space-results {
    overflow: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-bottom: 8px;
    @media only screen and (max-width: 1200px) {
      height: calc(100% - 153px) !important;
    }
  }
}
</style>
