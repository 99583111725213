<template>
  <div class="organism-building" :style="{ height: `${windowHeight}px` }">
    <div
      id="canvas-wrapper"
      :class="{ 'in-floor': isFloorView, 'no-availability': availableSection }"
      :style="{ height: `${windowHeight}px` }"
    >
      <canvas ref="canvas" id="project-canvas" />
    </div>
  </div>
</template>

<script>
import buildingStore, { CurrentView } from "../../project/building/store";
import config from "../../../../config";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
import { Utils } from "@/components/organisms/project/building/3D/core/builders/Utils";

export default {
  name: "OrganismBuilding",
  components: {},
  computed: {
    buildingStore() {
      return buildingStore;
    },
    isInitialized() {
      return buildingStore.state.isInitialized;
    },
    cdnBase() {
      return this.$store.getters.cdnBase;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    buildings() {
      return this.$store.state.project.project.buildings;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    availableSection() {
      return this.generalConfig?.availableSection || true;
    },
    isFloorView() {
      return this.buildingStore.state.currentView === CurrentView.FLOOR;
    },
  },
  watch: {
    isInitialized: function (value) {
      if (value) {
        window.addEventListener("resize", this.resizeListener);
        if (this.$route.query.v === "debug") {
          this.sceneManager.scene.debugLayer.show();
        }
      }
    },
  },
  data() {
    return {
      windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
      pinData: [],
    };
  },
  beforeDestroy() {
    this.buildingStore.dispatch("destroy");
    this.sceneManager.destroy();
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    initializeBuilding3D() {
      this.pinData =
        this.$store.getters.getActiveProjectSettings?.building?.pinsData || [];
      const payload = {
        pinsData: this.pinData,
        client: config.CLIENT,
        project: this.$store.state.project.project,
        cdnBase: this.cdnBase,
        activeProject: this.activeProject,
        settings: this.$store.getters.getActiveProjectSettings,
      };
      this.buildingStore.dispatch("destroy");
      this.buildingStore.dispatch("initBuilding", payload);
      ////Init scene
      const client = Utils.convertToCamel(config.CLIENT);
      if (typeof BabylonClientManager[client] === "function") {
        this.sceneManager = BabylonClientManager[client](this.$refs.canvas);
      } else {
        this.sceneManager = BabylonClientManager["default"](this.$refs.canvas);
      }
      this.sceneManager.initScene(
        payload,
        this.buildingStore,
        this.$refs.canvas
      );
      this.sceneManager.loadBuilding();
    },
    resizeListener() {
      this.windowHeight = window.innerHeight;
      this.$nextTick(() => {
        this.sceneManager.resize();
      });
    },
  },
};
</script>

<style lang="scss">
.organism-building {
  #canvas-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 25.938rem);
    height: 100%;
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }

    &.in-floor,
    &.no-availability {
      width: 100%;
    }
  }

  #project-canvas {
    width: 100%;
    height: 100%;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
  }
}

/*vor fi sterse*/

#inspector-host {
  position: absolute !important;
  left: 0;
  bottom: auto !important;
  width: 100% !important;
  z-index: auto !important;
}

#sceneExplorer {
  position: fixed !important;
  z-index: 999999;
  top: 55px;
  left: 0 !important;
}

#actionTabs,
#inspector-host {
  position: fixed !important;
  z-index: 999999;
  top: 55px;
  right: 0;
}

.btn-wrapper {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 5rem;
}

.row-floor-active-test {
  padding: 0.313rem;
  border: 1px solid gold;
  cursor: pointer;
  margin: 0 0.313rem;
}
.pinInfo {
  left: 0;
  top: 0;
  position: absolute;
  background: $white;
  border-radius: 0.25rem;
  padding: 0.75rem 0.5rem;
  max-width: 19rem;
  box-shadow: 0 0 2.5rem $blackOpacity01;
  z-index: 99;
  @media only screen and (max-width: 1200px) {
    border-radius: 4px;
    padding: 12px 8px;
    max-width: 300px;
    box-shadow: 0 0 40px $blackOpacity01;
  }
  .pin-info-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    @media only screen and (max-width: 1200px) {
      margin-bottom: 8px;
    }
    .left-header {
      display: flex;
      align-items: center;
      img {
        max-height: 1.125rem;
        max-width: 1.125rem;
        margin: 0 0.5rem 0 0;
        @media only screen and (max-width: 1200px) {
          max-height: 18px;
          max-width: 18px;
          margin: 0 8px 0 0;
        }
      }
    }

    h3 {
      letter-spacing: 0.02em;
      @include font-main(0.75rem, $black, $medium, 1.063rem);
      @media only screen and (max-width: 1200px) {
        @include font-main(12px, $black, $medium, 17px);
      }
    }
    .close-element {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      font-size: 0.938rem;
      font-weight: $bold;
      border-radius: 1.25rem;
      background: rgba(2, 2, 2, 0.04);
      cursor: pointer;
      transition: all 0.2s ease-in-out 0s;
      @media only screen and (max-width: 1200px) {
        width: 32px;
        height: 32px;
        font-size: 15px;
        border-radius: 20px;
      }
      &:hover {
        background: $black;
        color: $white;
      }
    }
  }
  h4 {
    padding: 0 !important;
    text-transform: initial !important;
    margin-bottom: 1rem;
    letter-spacing: 0.01em;
    @include font-main(1.25rem, $black, $bold, 1.563rem);
    @media only screen and (max-width: 1200px) {
      margin-bottom: 16px;
      @include font-main(20px, $black, $bold, 25px);
    }
  }
  p {
    @include font-main(0.875rem, $black, $regular, 1.063rem);
    letter-spacing: 0.01em;
    max-height: 6rem;
    overflow: auto;
    @media only screen and (max-width: 1200px) {
      @include font-main(14px, $black, $regular, 17px);
      max-height: 80px;
    }
    a {
      @include font-main(0.875rem, $main-color, $regular, 1.063rem);
      letter-spacing: 0.01em;
      text-decoration: underline !important;
      @media only screen and (max-width: 1200px) {
        @include font-main(14px, $main-color, $regular, 17px);
      }
    }
  }
  .video-pin {
    margin-bottom: 1rem;
    @media only screen and (max-width: 1200px) {
      margin-bottom: 16px;
    }
    iframe,
    video {
      max-height: 12rem;
      display: block;
      max-width: 100%;
      border: none;
      @media only screen and (max-width: 1200px) {
        max-height: 200px;
      }
    }
  }
  .pin-img-wrapper {
    display: block;
    max-height: 12rem;
    margin: 0 auto 1rem;
    @media only screen and (max-width: 1200px) {
      max-height: 200px;
      margin: 0 auto 16px;
    }
  }
}
</style>
