<template>
  <div class="row molecule-list-circle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculeListCircle"
}
</script>

<style lang="scss" scoped>
  .molecule-list-circle {
    margin: 0 -2.188rem;
  }
</style>