<template>
  <div class="landing-template page-wrapper">
    <router-view></router-view>
    <OrganismSidebar>
      <div class="menu">
        <div v-if="buildingStaticPages.length > 1">
          <div class="subtitle">Cities</div>
          <div v-for="(page, i) in buildingStaticPages" :key="i">
            <span class="sidebar-link" @click="handleProjectChange(page)">
              {{ page.menuLabel || page.name }}
            </span>
          </div>
        </div>
        <div class="separator" v-if="projects.length > 1"></div>
        <div>
          <div v-if="customLinks.length > 0">
            <div v-for="(customLink, i) in customLinks" :key="i">
              <a
                class="sidebar-link"
                :href="`${customLink.slug}`"
                target="_blank"
              >
                {{ customLink.name }}
              </a>
            </div>
          </div>
          <div v-for="(section, i) in sections" :key="i">
            <a
              v-if="section.enabled"
              class="sidebar-link"
              :href="`#${section.hashAnchor}`"
            >
              {{ section.menuLabel }}
            </a>
          </div>
        </div>
      </div>
      <div class="social-links">
        <a :href="instagramUrl" v-if="instagramUrl">Instagram</a>
        <a :href="linkedinUrl" v-if="linkedinUrl">Linkedin</a>
        <a :href="facebookUrl" v-if="facebookUrl">Facebook</a>
      </div>
    </OrganismSidebar>
    <OrganismMenuBar />
  </div>
</template>

<script>
import OrganismMenuBar from "../components/organisms/OrganismMenuBar";
import OrganismSidebar from "../components/organisms/OrganismSidebar";
import { baseConstants } from "../store/modules";

export default {
  name: "LandingTemplate",
  components: {
    OrganismSidebar,
    OrganismMenuBar,
  },
  computed: {
    projects() {
      return this.$store.state?.project?.projects || null;
    },
    customLinks() {
      if (!this.generalConfig.customLinks) return "";
      return this.generalConfig.customLinks;
    },
    sections() {
      return this.$route.meta.page.sections;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    instagramUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.instagramUrl;
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.linkedinUrl;
    },
    facebookUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.facebookUrl;
    },
    buildingStaticPages() {
      return this.generalConfig.buildingStaticPages || [];
    },
    staticPages() {
      return this.generalConfig.staticPages || [];
    },
    portfolioPageDisabled() {
      return this.generalConfig.portfolioPageDisabled || false;
    },
    pageName() {
      const { name } = this.$route;
      return name;
    },
  },
  mounted() {
    document.querySelectorAll('a.sidebar-link[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", this.smoothScroll);
    });
    if (this.$route.hash !== "") {
      document.querySelector(this.$route.hash).scrollIntoView({
        behavior: "smooth",
      });
    }
  },
  beforeDestroy() {
    document.querySelectorAll('a.sidebar-link[href^="#"]').forEach((anchor) => {
      anchor.removeEventListener("click", this.smoothScroll);
    });
  },
  methods: {
    smoothScroll(e) {
      e.preventDefault();
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_SIDEBAR)
      );
      document.querySelector(e.target.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    },
    handleProjectChange(newPageOptions) {
      const routePieces = newPageOptions?.path?.split("/") ?? [];
      if (routePieces.length > 1) {
        const projectSlug = routePieces[1];
        if (projectSlug === this.activeProject) return;
        const projectConstants = this.$store.getters.constants.project;
        this.$store.dispatch(
          projectConstants.withNamespace(
            projectConstants.action.CHANGE_ACTIVE_PROJECT
          ),
          { projectSlug, loadProjectBasedOnRouteSlug: true }
        );
        this.$router.push({
          name: "StaticPageOfProject",
          params: { projectSlug },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.landing-template {
  display: block;
}
</style>
