<template>
  <div id="molecule-sidebar-header" class="molecule-sidebar-header">
    <div class="title-and-info">
      <AtomSidebarTitle title="Available Spaces" />
    </div>
    <div class="filters clearfix">
      <form class="row filters-row" id="filter-spaces" action="">
        <div
          v-if="Object.keys(buildingOptions).length > 2"
          class="col col-padding-4px"
        >
          <div class="select-style">
            <AtomSelect
              id="building"
              label="Building"
              :options="buildingOptions"
              :select-props="{ search: false }"
              @change="($event) => handleFilterChange($event, 'building')"
            />
          </div>
        </div>
        <div class="col col-padding-4px">
          <div class="select-style">
            <AtomSelect
              id="floor"
              label="Floor"
              :options="
                selectedBuilding === ''
                  ? floorOptions
                  : availableFloorsForSelect
              "
              :select-props="{ search: false }"
              @change="($event) => handleFilterChange($event, 'floor')"
            />
          </div>
        </div>
        <div class="col col-padding-4px">
          <div class="select-style">
            <AtomSelect
              id="surface"
              label="Surface"
              :options="
                selectedFloor === ''
                  ? surfaceOptions
                  : availableSurfacesForSelect
              "
              :select-props="{ search: false }"
              @change="($event) => handleFilterChange($event, 'surface')"
            />
          </div>
        </div>
        <div class="col-12">
          <AtomButton class="hidden" label="Filter" />
        </div>
      </form>
    </div>
    <div class="select-measurement" v-if="measurementsItems.length > 0">
      <AtomRadio
        v-for="(m, idx) in measurementsItems"
        :key="idx"
        :id="`select-${m.label.toLocaleLowerCase()}`"
        :label="`<b>${m.label}</b> ${unitOfMeasureString}`"
        :value="m.multiplier"
        :data-text="m.label"
        :is-checked="isRadioChecked(m)"
        @get-value="handleSelectMeasurement"
        @get-data-text="handleSelectMeasurementLabel"
        name="measurements"
      />
    </div>
  </div>
</template>

<script>
import AtomSidebarTitle from "../../../atoms/project/building/AtomSidebarTitle";
import AtomSelect from "../../../atoms/common/AtomSelect";
import AtomButton from "../../../atoms/common/AtomButton";
import AtomRadio from "../../../atoms/common/AtomRadio";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";

export default {
  name: "MoleculeSidebarHeader",
  components: { AtomRadio, AtomButton, AtomSelect, AtomSidebarTitle },
  data() {
    return {
      buildingOptions: [],
      floorOptions: [],
      surfaceOptions: [],
      selectedMeasurement: 1,
    };
  },
  computed: {
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    buildings() {
      return this.$store.state.project.project.buildings;
    },
    floors() {
      return this.$store.state.project.project.floors;
    },
    spaces() {
      return this.$store.state.project.project.spaces;
    },
    selectedBuilding() {
      return this.$store.state.project?.filters?.building;
    },
    selectedFloor() {
      return this.$store.state.project?.filters?.floor;
    },
    selectedFloorObject() {
      if (this.selectedBuilding === "") return "";
      return this.floors.find((f) => {
        return (
          `${f.no}` === `${this.selectedFloor}` &&
          `${f.building_id}` === `${this.selectedBuilding}`
        );
      });
    },
    availableFloorsForSelect() {
      const filteredFloors = this.floors.filter((f) => {
        return `${f.building_id}` === this.selectedBuilding;
      });
      const floors = [];
      const floorOptions = [];
      filteredFloors.forEach((f, i) => {
        if (floors.indexOf(f.no) === -1) {
          floorOptions.push({ text: f.name, value: f.no });
          floors.push(f.no);
        }
      });
      floorOptions.sort(function (a, b) {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
      floorOptions.unshift({ text: "Any", value: "" });
      return floorOptions;
    },
    availableSurfacesForSelect() {
      let filteredSpaces = [];
      if (this.selectedFloorObject === "") {
        filteredSpaces = this.spaces;
      } else {
        filteredSpaces = this.spaces.filter((s) => {
          return s.floor_id === this.selectedFloorObject?.id;
        });
      }
      const surfaces = [];
      const surfaceOptions = [];
      filteredSpaces.forEach((s, i) => {
        if (surfaces.indexOf(s.sqm_range) === -1) {
          surfaceOptions.push({
            text: `${s.sqm_range} ㎡`,
            value: s.sqm_range,
          });
          surfaces.push(s.sqm_range);
        }
      });
      surfaceOptions.sort(function (a, b) {
        const first = parseInt(a.value.split("-")[0]);
        const second = parseInt(b.value.split("-")[0]);
        return second < first ? 1 : second > first ? -1 : 0;
      });
      surfaceOptions.unshift({ text: "Any", value: "" });
      return surfaceOptions;
    },
    measurements() {
      return this.$store.state?.base?.meta?.general?.measurements || null;
    },
    measurementsEnabled() {
      return this.measurements?.isEnabled || false;
    },
    measurementsItems() {
      if (
        this.measurementsEnabled &&
        this.measurements.types &&
        this.measurements.types.length > 0
      )
        return this.measurements.types;
      return [];
    },
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1;
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1;
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
  },
  watch: {
    activeProject() {
      this.initFilterOptions();
    },
    buildings() {
      this.initFilterOptions();
    },
  },
  mounted() {
    this.initFilterOptions();
  },
  methods: {
    isRadioChecked(measurement) {
      return this.surfaceMultiplier === measurement.multiplier;
    },
    initFilterOptions() {
      this.setBuildingOptions();
      this.setFloorOptions();
      this.setSurfaceOptions();
    },
    setBuildingOptions() {
      const buildingOptions = [{ text: "Any", value: "" }];
      this.buildings.forEach((b, i) => {
        buildingOptions.push({ text: b.name, value: b.id });
      });
      buildingOptions.sort(function (a, b) {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
      this.buildingOptions = buildingOptions;
    },
    setFloorOptions() {
      const floors = [];
      const floorOptions = [{ text: "Any", value: "" }];
      this.floors.forEach((f, i) => {
        if (floors.indexOf(f.no) === -1) {
          floorOptions.push({ text: f.name, value: f.no });
          floors.push(f.no);
        }
      });
      floorOptions.sort(function (a, b) {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
      this.floorOptions = floorOptions;
    },
    setSurfaceOptions() {
      const surfaces = [];
      let surfaceOptions = [];
      this.spaces.forEach((s, i) => {
        if (surfaces.indexOf(s.sqm_range) === -1) {
          surfaceOptions.push({
            text: `${s.sqm_range} ㎡`,
            value: s.sqm_range,
          });
          surfaces.push(s.sqm_range);
        }
      });
      surfaceOptions.sort(function (a, b) {
        const first = parseInt(a.value.split("-")[0]);
        const second = parseInt(b.value.split("-")[0]);
        return second < first ? 1 : second > first ? -1 : 0;
      });
      surfaceOptions.unshift({ text: "Any", value: "" });
      this.surfaceOptions = surfaceOptions;
    },
    handleFilterChange(e, type) {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.CHANGE_FILTER_STATE
        ),
        {
          value: e,
          type: type,
        }
      );

      const buildingList = document.querySelector(".building-list");
      if (buildingList) {
        buildingList
          .querySelectorAll(".trigger-click-view-space")
          .forEach((space) => {
            space.classList.remove("activated");
          });
        buildingList.querySelectorAll(".row-floor").forEach((parentSpace) => {
          parentSpace.classList.remove("activation");
        });
        const manager = BabylonClientManager.getSceneManager();
        if (manager) {
          manager.hideHighlight();
        }
      }
    },
    handleSelectMeasurement(value) {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.UPDATE_SURFACE_MULTIPLIER
        ),
        {
          surfaceMultiplier: Number(value),
        }
      );
    },
    handleSelectMeasurementLabel(dataText) {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.UPDATE_SURFACE_NAME
        ),
        {
          surfaceName: dataText,
        }
      );
    },
  },
};
</script>

<style lang="scss">
.molecule-sidebar-header {
  position: relative;
  padding: 20px 16px 0;
  @media only screen and (max-width: 1200px) {
    padding: 20px 0 0;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .col-padding-4px {
    padding: 0 4px;
  }

  .filters-row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .select-measurement {
    padding: 10px 0 16px;
    @media only screen and (max-width: 1200px) {
      padding: 0 0 15px;
    }

    .atom-radio-box {
      margin-right: 24px;
      @media only screen and (max-width: 1200px) {
        margin-right: 16px;
      }
    }
  }

  .title-and-info {
    position: relative;
    padding-right: 60px;
    margin-bottom: 16px;
  }

  .btnShowElements {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -16px;
  }

  .filters {
    margin-bottom: 6px;

    .select-style {
      background: #eaeaea;
      width: 100%;
      border-radius: 4px;
      margin-bottom: 8px;

      label {
        display: block;
        height: auto;
        min-width: 0;
        border: none;
        background: transparent;
        letter-spacing: 0;
        text-transform: initial;
        padding: 6px 12px;
        margin: 0;
        font-size: 14px;
        color: $black;
        font-weight: $regular;
        line-height: 17px;
        @media only screen and (max-width: 1200px) {
          font-size: 13px;
        }
      }

      .select-inline-block {
        display: block;
      }
    }
  }
}
</style>
