<template>
  <div
    v-if="data.enabled !== false"
    :class="{
      section: true,
      'section-brochure-button': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="row align-items-center">
      <div class="col col-xs-12 col-sm-7 col-8">
        <div class="top-title">
          <AtomTitle
            :color="data.colorTitle"
            :color2="data.colorText"
            :title="data.title"
            :subtitle="data.subtitle"
          />
        </div>
        <p class="text" :style="`color: ${data.colorText};`">{{ data.text }}</p>
      </div>
      <div class="col col-xs-12 col-sm-5 col-4">
        <div class="button-brochure text-right">
          <a
            :href="resourceUrl(data.buttonUrl)"
            target="_blank"
            class="button-atom"
            ><span>{{ data.buttonText }}</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../../components/atoms/common/AtomTitle";
export default {
  name: "SectionBrochureButton",
  components: { AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss">
.section-brochure-button {
  padding: 5rem 4.375rem 6.5rem;
  margin: 0 -0.625rem;
  background: $main-color;
  @media only screen and (max-width: 767px) {
    padding: 30px;
    margin: 0 -10px;
  }
  .top-title {
    margin-bottom: 1.5rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
    .atom-title {
      h2 {
        font-weight: $regular;
        font-size: 0.875rem;
        line-height: 1.063rem;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 17px;
        }
      }
      h3 {
        margin-top: 0.5rem;
        font-weight: $regular;
        font-size: 1.375rem;
        line-height: 1.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 22px;
          line-height: 26px;
        }
      }
    }
  }
  .text {
    font-weight: $regular;
    font-size: 1rem;
    line-height: 1.625rem;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 30px;
    }
  }
  .button-brochure {
    @media only screen and (max-width: 767px) {
      text-align: left;
    }
    .button-atom {
      background: $white;
      padding: 0.375rem;
      border: none;
      @media only screen and (max-width: 767px) {
        padding: 6px;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 21.75rem;
        max-width: 100%;
        padding: 0.938rem 0;
        color: $main-color;
        border: 1px solid $main-color;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.25rem;
        @media only screen and (max-width: 767px) {
          width: 300px;
          padding: 10px 0;
          font-size: 14px;
          line-height: 18px;
        }
      }
      &:hover {
        background: $main-color;
        span {
          color: $white;
          border-color: $white;
        }
      }
    }
  }
}
</style>
