<template>
  <div :class="{ 'organism-sidebar': true, open: isOpen }">
    <slot></slot>
  </div>
</template>

<script>
import { baseConstants } from "../../store/modules";
export default {
  name: "OrganismSidebar",
  computed: {
    isOpen() {
      return this.$store.state[baseConstants.namespace].sidebarOpen;
    },
  },
};
</script>

<style lang="scss">
.organism-sidebar {
  .subtitle {
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 1.5rem;
    color: #194b5b80;
    font-size: 1.5rem;
    @media only screen and (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  .separator {
    width: 16rem;
    margin: 1.563rem 0;
    border: 0.063rem solid #e9edef;
    max-width: 100%;
    @media only screen and (max-width: 767px) {
      margin: 15px 0 20px;
      border: 1px solid #e9edef;
      width: 150px;
    }
  }
  .menu {
    overflow: auto;
    position: relative;
    height: 82vh;
    padding-right: 1rem;
    @media only screen and (max-width: 767px) {
      padding-right: 16px;
      height: 72vh;
    }
    .hidden-lg {
      @media only screen and (max-width: 767px) {
        margin-top: 5px;
      }
    }
    .sidebar-link {
      cursor: pointer;
      position: relative;
      display: inline-block;
      margin-bottom: 1rem;
      text-decoration: none;
      letter-spacing: 0.125rem;
      @include font-main(2rem, #2d2750, $regular, 2rem);
      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
      }
      .icon {
        font-size: 1.5rem !important;
        color: #2d2750 !important;
        @media only screen and (max-width: 767px) {
          font-size: 14px !important;
        }
      }
      &:hover {
        color: $main-color;
        .icon {
          color: $main-color !important;
        }
      }
      &.active {
        color: $main-color;
        .icon {
          color: $main-color !important;
        }
      }
    }
  }
  .social-links {
    position: absolute;
    bottom: 3rem;
    right: 6rem;
    text-align: right;
    @media only screen and (max-width: 767px) {
      bottom: 20px;
      right: 20px;
      width: 100%;
    }
    a {
      position: relative;
      display: block;
      text-decoration: none;
      @include font-main(0.875rem, $black, $regular, 1.3rem);
      @media only screen and (max-width: 767px) {
        line-height: 16px;
        font-size: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        color: #909090;
      }
    }
  }
}
</style>
