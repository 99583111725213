var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-sidebar-results" }, [
    _c("div", { staticClass: "title-list" }, [
      _c("h3", [_vm._v(_vm._s(_vm.sidebarTitle))]),
    ]),
    _c(
      "div",
      { staticClass: "building-list" },
      _vm._l(_vm.pinsInfo, function (pinSection, i) {
        return _c("div", { key: i, staticClass: "floor-info-parent" }, [
          _c("div", [
            _c(
              "div",
              {
                staticClass: "parent-floor row-floor",
                class: "activated-floor",
                on: {
                  click: function ($event) {
                    return _vm.showSpecificPins(pinSection)
                  },
                },
              },
              [
                pinSection.category
                  ? _c("div", { staticClass: "clearfix" }, [
                      _c("div", { staticClass: "column1" }, [
                        _c("span", { staticClass: "when-text db" }, [
                          _vm._v(_vm._s(pinSection.category)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "arrow-custom",
                            class: { "rotate-icon": _vm.isSpaceListExpanded },
                          },
                          [
                            _c("AtomIcon", {
                              attrs: { icon: "sort-down", size: 15 },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              { staticClass: "show-all-spaces" },
              [
                _vm._l(pinSection.pins, function (pin) {
                  return _c(
                    "div",
                    {
                      key: pin.pinId,
                      staticClass: "row-floor row-floor-active",
                      class: "active",
                      attrs: { id: pin.pinId, "data-viewmode": "defaultPin" },
                    },
                    [
                      _c("span", {
                        staticClass: "trigger-click-view-space",
                        on: {
                          click: function ($event) {
                            return _vm.activateSpecificPin(pin)
                          },
                        },
                      }),
                      _c("div", { staticClass: "parent-general-row-floor" }, [
                        _c("div", { staticClass: "general-dow-floor" }, [
                          _c("div", { staticClass: "clearfix" }, [
                            _c("div", { staticClass: "column1" }, [
                              _c(
                                "span",
                                { staticClass: "line-element line-checked" },
                                [_c("AtomIcon", { attrs: { icon: "ok" } })],
                                1
                              ),
                              _c("span", { staticClass: "get-sqm db" }, [
                                _c("b", { staticClass: "nr-sqm" }),
                                _vm._v(" " + _vm._s(pin.pinDescription)),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "clearfix" }),
                        ]),
                      ]),
                    ]
                  )
                }),
                _vm._l(pinSection.virtualTourPins, function (virtualTourPin) {
                  return _c(
                    "div",
                    {
                      key: virtualTourPin.pinId,
                      staticClass:
                        "row-floor row-floor-active virtual-tour-row",
                      class: "active",
                      attrs: {
                        "data-viewmode": "virtualTourPin",
                        id: virtualTourPin.pinId,
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "trigger-click-view-space",
                        on: {
                          click: function ($event) {
                            return _vm.goToPin(virtualTourPin)
                          },
                        },
                      }),
                      _c("div", { staticClass: "parent-general-row-floor" }, [
                        _c("div", { staticClass: "general-dow-floor" }, [
                          _c("div", { staticClass: "clearfix" }, [
                            _c("div", { staticClass: "column1" }, [
                              _c(
                                "span",
                                { staticClass: "line-element line-checked" },
                                [_c("AtomIcon", { attrs: { icon: "ok" } })],
                                1
                              ),
                              _c("span", { staticClass: "get-sqm db" }, [
                                _c("b", { staticClass: "nr-sqm" }),
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(virtualTourPin.pinDescription)
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "clearfix" }),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }