var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "organism-space" }, [
    !_vm.hasMatterport
      ? _c("div", [
          _vm.isFloorView
            ? _c("div", { staticClass: "general-elements-style" }, [
                _c(
                  "div",
                  { on: { click: _vm.closeSpaceSelectionDetails } },
                  [
                    _c("MoleculeFitoutSelector", {
                      attrs: {
                        "fitout-data": _vm.fitoutData,
                        "change-fitout": _vm.changeFitout,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    ref: "sliderComponent",
                    staticClass: "slider-wrapper hidden-mobile-elements active",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "plus hshsh",
                        on: { click: _vm.increaseZoomValue },
                      },
                      [
                        _c("AtomIcon", {
                          attrs: { size: 16, icon: "zoom-in" },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "slider-parent" }, [
                      _c("div", { attrs: { id: "slider" } }, [
                        _c("i", { staticClass: "line line1" }),
                        _c(
                          "div",
                          {
                            ref: "dragger",
                            staticClass: "ui-slider-handle",
                            staticStyle: { bottom: "0%" },
                            attrs: { id: "dragger", draggable: "true" },
                            on: {
                              drag: _vm.handleDrag,
                              dragstart: _vm.handleDragStart,
                            },
                          },
                          [_vm._v("\n              ZOOM\n            ")]
                        ),
                      ]),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "minus",
                        on: { click: _vm.decreaseZoomValue },
                      },
                      [
                        _c("AtomIcon", {
                          attrs: { size: 16, icon: "zoom-out" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "buttons-right active" }, [
                  _c("div", { staticClass: "buttons-3D" }, [
                    _c(
                      "div",
                      { ref: "button2d", staticClass: "button-tooltip" },
                      [
                        _c(
                          "span",
                          {
                            class: {
                              "btn-tooltip": true,
                              "change-view": true,
                              text2D: true,
                              iconTooltip: true,
                            },
                            on: { click: _vm.changeViewMode2D },
                          },
                          [_vm._v("2D")]
                        ),
                        _c("p", { staticClass: "hidden-mobile-elements" }, [
                          _vm._v("Select 2D View Mode"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { ref: "button3d", staticClass: "button-tooltip" },
                      [
                        _c(
                          "span",
                          {
                            class: {
                              "btn-tooltip": true,
                              "change-view": true,
                              text3D: true,
                              iconTooltip: true,
                              active: true,
                            },
                            on: { click: _vm.changeViewMode3D },
                          },
                          [_vm._v("3D")]
                        ),
                        _c("p", { staticClass: "hidden-mobile-elements" }, [
                          _vm._v("Select 3D View Mode"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { ref: "buttonVt", staticClass: "button-tooltip" },
                      [
                        _c(
                          "span",
                          {
                            class: {
                              "btn-tooltip": true,
                              "change-view": true,
                              walkThrough: true,
                              iconTooltip: true,
                            },
                            on: { click: _vm.changeViewModeFirstPerson },
                          },
                          [
                            _c("AtomIcon", {
                              attrs: { icon: "street-view", size: 20 },
                            }),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "hidden-mobile-elements" }, [
                          _vm._v("Enter Virtual Tour"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm.isDualLevel
                  ? _c(
                      "div",
                      {
                        ref: "levelComponent",
                        staticClass: "buttons-right btn-container",
                        attrs: { id: "btn-left-container" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "buttons-3D buttons-selector" },
                          [
                            _c(
                              "div",
                              { ref: "buttonM", staticClass: "button-tooltip" },
                              [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "btn-tooltip": true,
                                      iconTooltip: true,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeLevel("M", $event)
                                      },
                                    },
                                  },
                                  [_vm._v("M")]
                                ),
                                _vm._m(0),
                              ]
                            ),
                            _c(
                              "div",
                              { ref: "buttonG", staticClass: "button-tooltip" },
                              [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "btn-tooltip": true,
                                      iconTooltip: true,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeLevel("G", $event)
                                      },
                                    },
                                  },
                                  [_vm._v("G")]
                                ),
                                _vm._m(1),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.getCombinedSpace(_vm.currentSpace.can_combine_with_id)
                  ? _c("div", { staticClass: "combine-space" }, [
                      _c("p", [
                        _vm._v(
                          "\n          Can be combined with the studio on\n          " +
                            _vm._s(
                              _vm.getCombinedSpace(
                                _vm.currentSpace.can_combine_with_id
                              ).floor.floor_type.name
                            ) +
                            "\n        "
                        ),
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "btn-green",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              _vm.exploreSpace(
                                _vm.getCombinedSpace(
                                  _vm.currentSpace.can_combine_with_id
                                ).space_code,
                                _vm.spaceBuilding.code,
                                _vm.getCombinedSpace(
                                  _vm.currentSpace.can_combine_with_id
                                ).floor.code,
                                _vm.getCombinedSpace(
                                  _vm.currentSpace.can_combine_with_id
                                ).floor.floor_type.model,
                                _vm.getCombinedSpace(
                                  _vm.currentSpace.can_combine_with_id
                                )
                              )
                            },
                          },
                        },
                        [_vm._v("\n          See Studio\n        ")]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.isFloorView
            ? _c(
                "div",
                {
                  class: {
                    "cart-spaces": true,
                    active: true,
                    clearfix: true,
                    activeDropDown: _vm.showCartSpacesMobile,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "floor-info clearfix",
                      on: { click: _vm.toggleSpaceSelectionDetails },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "row row-padding-5px align-items-center",
                        },
                        [
                          _vm._m(2),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col col-sm-2 col-4 col-padding-10px text-right",
                            },
                            [
                              _c("span", { staticClass: "arrow-text" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text hidden-mobile-elements",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        ref: "requestOfferText",
                                        class: {
                                          "active-text": _vm.spacesCounter > 0,
                                          "disabled-text":
                                            _vm.spacesCounter === 0,
                                        },
                                      },
                                      [_vm._v("Request Offer")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        ref: "hideText",
                                        staticClass: "active-text",
                                        staticStyle: { display: "none" },
                                      },
                                      [_vm._v("Hide")]
                                    ),
                                  ]
                                ),
                                _c("span", { staticClass: "custom-arrow" }),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "row row-padding-5px align-items-center",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col col-5 col-padding-10px hidden-mobile-elements",
                            },
                            [
                              _c("span", [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.spaceBuilding.name)),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col col-2 col-padding-0px text-center hidden-mobile-elements",
                            },
                            [
                              _c("span", { staticClass: "when-text" }, [
                                _vm._v("Floor " + _vm._s(_vm.spaceFloor.no)),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col col-sm-12 col-5 col-padding-10px mobile-text-left text-right",
                            },
                            [
                              _vm.surfaceMultiplier &&
                              ![0, 1].includes(_vm.surfaceMultiplier)
                                ? _c(
                                    "span",
                                    { staticClass: "result-sqm" },
                                    [
                                      _c("AtomIcon", {
                                        attrs: { icon: "ruler", size: 14 },
                                      }),
                                      _c("span", [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              (
                                                Number(_vm.currentSpace.sqm) -
                                                Number(_vm.currentSpace.sqm) *
                                                  Number(_vm.surfaceMultiplier)
                                              ).toFixed(2)
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.unitOfMeasureString) +
                                          " " +
                                          _vm._s(_vm.surfaceName)
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "result-sqm" },
                                    [
                                      _c("AtomIcon", {
                                        attrs: { icon: "ruler", size: 14 },
                                      }),
                                      _c("span", [
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.currentSpace.sqm)),
                                        ]),
                                      ]),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.unitOfMeasureString) +
                                          " GLA"
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      ref: "listNode",
                      class: { "all-spaces-selected": true, clearfix: true },
                    },
                    [
                      _c("div", { staticClass: "row spaces-selected-info" }, [
                        _c("div", { staticClass: "col col-12" }, [
                          _c(
                            "div",
                            { staticClass: "row row-margin-bottom-12px" },
                            [
                              _c(
                                "div",
                                { staticClass: "col col-9 result-list-count" },
                                [
                                  _c("span", { staticClass: "list-info" }, [
                                    _c(
                                      "span",
                                      { staticClass: "nr-cube count-nr" },
                                      [_vm._v(_vm._s(_vm.spacesCounter))]
                                    ),
                                    _vm._v(
                                      "\n                  Spaces in your list"
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col col-3 text-right" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "close-drop-down",
                                      on: {
                                        click: _vm.toggleSpaceSelectionDetails,
                                      },
                                    },
                                    [
                                      _vm._v("Close "),
                                      _c(
                                        "b",
                                        [
                                          _c("AtomIcon", {
                                            attrs: { icon: "xmark", size: 18 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "row scroll-y" }, [
                            _c("div", { staticClass: "col col-12" }, [
                              _c(
                                "div",
                                { staticClass: "add-content clearfix" },
                                _vm._l(
                                  _vm.requestedSpaces,
                                  function (space, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "background-space-white clearfix space-floor-selected",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row row-padding-5px align-items-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col col-6 col-padding-10px",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(space.building.name)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col col-6 col-padding-10px text-right",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "when-text floor-number",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Floor\n                          "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "nr-cube",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(space.floor.no)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row row-padding-5px align-items-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col col-9 col-padding-10px",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "results" },
                                                  [
                                                    _vm.surfaceMultiplier &&
                                                    ![0, 1].includes(
                                                      _vm.surfaceMultiplier
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "result-sqm",
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  (
                                                                    Number(
                                                                      space.sqm
                                                                    ) -
                                                                    Number(
                                                                      space.sqm
                                                                    ) *
                                                                      Number(
                                                                        _vm.surfaceMultiplier
                                                                      )
                                                                  ).toFixed(2)
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  _vm.unitOfMeasureString
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.surfaceName
                                                                )
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "result-sqm",
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  space.sqm
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  _vm.unitOfMeasureString
                                                                ) +
                                                                " GLA"
                                                            ),
                                                          ]
                                                        ),
                                                    _vm.showGeneratedNumberOfPeople
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "result-max-people",
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  Math.floor(
                                                                    space.sqm /
                                                                      8
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              "\n                            People"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col col-3 col-padding-10px text-right",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "btn-remove-item",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "btn-trash",
                                                        attrs: {
                                                          href: "javascript:void(0)",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeSpaceFromCart(
                                                              space
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("AtomIcon", {
                                                          attrs: {
                                                            icon: "trash-can",
                                                            size: 14,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "row row-request-button" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col col-12 result-request-offer-button",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    class: {
                                      "btn-green-opacity": true,
                                      "disabled-element":
                                        _vm.spacesCounter === 0,
                                    },
                                    attrs: {
                                      id: "request-offer-btn",
                                      href: "javascript:void(0)",
                                    },
                                    on: { click: _vm.openRequestOfferModal },
                                  },
                                  [_vm._v("Request Offer")]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row floor-info-popup row-padding-5px" },
                        [
                          _c(
                            "div",
                            { staticClass: "col col-12 col-padding-10px" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row-padding-5px align-items-center",
                                },
                                [
                                  _vm._m(3),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col col-8 col-padding-10px text-right",
                                    },
                                    [
                                      !_vm.spaceIsRequested(_vm.currentSpace.id)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "space-cart space-not-added",
                                            },
                                            [_vm._v("Space not added to list")]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "space-cart space-added",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-check",
                                              }),
                                              _vm._v(" Added to your list"),
                                            ]
                                          ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row-padding-5px align-items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col col-8 col-padding-10px",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Building "),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.spaceBuilding.name)
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col col-4 col-padding-10px text-right",
                                    },
                                    [
                                      _c(
                                        "span",
                                        [
                                          _c("AtomIcon", {
                                            attrs: {
                                              icon: "buildings-alt",
                                              size: 14,
                                              color: "#000",
                                            },
                                          }),
                                          _vm._v(
                                            "\n                  Floor " +
                                              _vm._s(_vm.spaceFloor.no)
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row-padding-5px align-items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col col-6 col-padding-10px",
                                    },
                                    [
                                      _vm.surfaceMultiplier &&
                                      ![0, 1].includes(_vm.surfaceMultiplier)
                                        ? _c(
                                            "span",
                                            { staticClass: "result-sqm" },
                                            [
                                              _c("AtomIcon", {
                                                attrs: {
                                                  icon: "ruler",
                                                  size: 14,
                                                },
                                              }),
                                              _c("span", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      (
                                                        Number(
                                                          _vm.currentSpace.sqm
                                                        ) -
                                                        Number(
                                                          _vm.currentSpace.sqm
                                                        ) *
                                                          Number(
                                                            _vm.surfaceMultiplier
                                                          )
                                                      ).toFixed(2)
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.unitOfMeasureString
                                                  ) +
                                                  " " +
                                                  _vm._s(_vm.surfaceName)
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "result-sqm" },
                                            [
                                              _c("AtomIcon", {
                                                attrs: {
                                                  icon: "ruler",
                                                  size: 14,
                                                },
                                              }),
                                              _c("span", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(_vm.currentSpace.sqm)
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.unitOfMeasureString
                                                  ) +
                                                  " GLA"
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col col-6 col-padding-10px text-right",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "result-max-people" },
                                        [
                                          _c("AtomIcon", {
                                            attrs: {
                                              icon: "group",
                                              size: 14,
                                              color: "#000",
                                            },
                                          }),
                                          _c("span", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  Math.floor(
                                                    _vm.currentSpace.sqm / 8
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _vm._v("\n                  People"),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row-padding-5px align-items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col col-12 col-padding-10px",
                                    },
                                    [
                                      !_vm.spaceIsRequested(_vm.currentSpace.id)
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "btn-green",
                                              attrs: {
                                                id: "btn-add-cart",
                                                href: "javascript:void(0)",
                                              },
                                              on: {
                                                click:
                                                  _vm.addCurrentSpaceToCart,
                                              },
                                            },
                                            [_vm._v("Add to my list")]
                                          )
                                        : _c(
                                            "a",
                                            {
                                              staticClass: "btn-red",
                                              attrs: {
                                                id: "btn-remove-cart",
                                                href: "javascript:void(0)",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeSpaceFromCart(
                                                    _vm.currentSpace
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Remove from list")]
                                          ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _c("div", [
          _vm.isFloorView &&
          (_vm.currentSpace.matterport_url ||
            _vm.spaceFloor.floor_plan_url ||
            _vm.photos.length ||
            _vm.videos.length)
            ? _c("div", { staticClass: "tabs-matterport" }, [
                _c("div", { staticClass: "top-title" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col col-xs-12 col-9" },
                      [
                        _c("AtomTitle", {
                          attrs: {
                            title: _vm.matterportSpace.titleSpace,
                            subtitle: _vm.matterportSpace.subTitleSpace,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col col-xs-12 col-3" }, [
                      _c("div", { staticClass: "text-right" }, [
                        _vm.spaceFloor.floor_plan_url &&
                        _vm.activeTab === "floor-view"
                          ? _c(
                              "div",
                              {
                                staticClass: "button-atom",
                                on: {
                                  click: function ($event) {
                                    return _vm.download(
                                      _vm.spaceFloor.floor_plan_url,
                                      "floorPlan.jpg"
                                    )
                                  },
                                },
                              },
                              [_c("span", [_vm._v("Download Floor View")])]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
                _vm.currentSpace.matterport_url ||
                _vm.spaceFloor.floor_plan_url ||
                _vm.photos.length ||
                _vm.videos.length
                  ? _c("div", { staticClass: "tabs" }, [
                      _vm.currentSpace.matterport_url
                        ? _c(
                            "div",
                            {
                              staticClass: "tab-trigger",
                              class: { active: _vm.activeTab === "matterport" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTabChange("matterport")
                                },
                              },
                            },
                            [_vm._v("\n          3D Tour\n        ")]
                          )
                        : _vm._e(),
                      _vm.spaceFloor.floor_plan_url
                        ? _c(
                            "div",
                            {
                              staticClass: "tab-trigger",
                              class: { active: _vm.activeTab === "floor-view" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTabChange("floor-view")
                                },
                              },
                            },
                            [_vm._v("\n          Floor Plan\n        ")]
                          )
                        : _vm._e(),
                      _vm.videos.length
                        ? _c(
                            "div",
                            {
                              staticClass: "tab-trigger",
                              class: {
                                active: _vm.activeTab === "videos-view",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTabChange("videos-view")
                                },
                              },
                            },
                            [_vm._v("\n          Videos\n        ")]
                          )
                        : _vm._e(),
                      _vm.photos.length
                        ? _c(
                            "div",
                            {
                              staticClass: "tab-trigger",
                              class: {
                                active: _vm.activeTab === "images-view",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTabChange("images-view")
                                },
                              },
                            },
                            [_vm._v("\n          Images\n        ")]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.currentSpace.matterport_url ||
                _vm.spaceFloor.floor_plan_url ||
                _vm.photos.length ||
                _vm.videos.length
                  ? _c("div", { staticClass: "content-position" }, [
                      _vm.currentSpace.matterport_url &&
                      _vm.activeTab === "matterport"
                        ? _c(
                            "div",
                            { staticClass: "tab-content matterport-tab" },
                            [
                              _c("iframe", {
                                attrs: {
                                  id: "matterport",
                                  src:
                                    _vm.currentSpace.matterport_url +
                                    "&play=1&qs=1",
                                  frameborder: "0",
                                },
                              }),
                              _c("img", {
                                attrs: {
                                  src: _vm.resourceUrl(
                                    "images/placeholder-for-full-size-image.png"
                                  ),
                                  alt: "Placeholder",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm.spaceFloor.floor_plan_url &&
                      _vm.activeTab === "floor-view"
                        ? _c(
                            "div",
                            { staticClass: "tab-content floor-plan-tab" },
                            [
                              _c("div", { staticClass: "floor-view" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.spaceFloor.floor_plan_url,
                                    alt: "Floor Plan",
                                  },
                                }),
                              ]),
                              _c("img", {
                                attrs: {
                                  src: _vm.resourceUrl(
                                    "images/placeholder-for-full-size-image.png"
                                  ),
                                  alt: "Placeholder",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm.videos.length && _vm.activeTab === "videos-view"
                        ? _c(
                            "div",
                            { staticClass: "tab-content videos-tab" },
                            [
                              _c("MoleculeVideoSlider3DTour", {
                                attrs: { "slider-data": _vm.videos },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.photos.length && _vm.activeTab === "images-view"
                        ? _c(
                            "div",
                            { staticClass: "tab-content images-tab" },
                            [
                              _c("MoleculeImageSlider3DTour", {
                                attrs: { "slider-data": _vm.photos },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "hidden-mobile-elements" }, [
      _c("b", [_vm._v("Mezzanine")]),
      _c("br"),
      _vm._v("\n              This space has a mezzanine included to "),
      _c("br"),
      _vm._v("\n              switch between Ground(G) and Mezzanine(M) "),
      _c("br"),
      _vm._v(
        "\n              press the buttons next to the minimap\n            "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "hidden-mobile-elements" }, [
      _c("b", [_vm._v("Ground")]),
      _c("br"),
      _vm._v("\n              This space has a mezzanine included to "),
      _c("br"),
      _vm._v("\n              switch between Ground(G) and Mezzanine(M) "),
      _c("br"),
      _vm._v(
        "\n              press the buttons next to the minimap\n            "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col col-sm-10 col-8 col-padding-10px" }, [
      _c("span", { staticClass: "space-details hidden-mobile-elements" }, [
        _vm._v("Space details"),
      ]),
      _c("span", { staticClass: "offer-and-details hidden-desktop-elements" }, [
        _vm._v("Offer & Details"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col col-4 col-padding-10px" }, [
      _c("span", { staticClass: "space-details" }, [_vm._v("Space details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }