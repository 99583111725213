<template>
  <div :class="{section: true, 'section-location': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title" v-if="data.heading || data.description">
      <AtomTitle :title="data.heading" :subtitle="data.description" />
    </div>
    <OrganismLocation :location-data="data" />
  </div>
</template>

<script>
import OrganismLocation from "../../../../components/organisms/project/OrganismLocation";
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
export default {
  name: "SectionLocationV1",
  components: {AtomTitle, OrganismLocation},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  created() {
  }
}
</script>

<style lang="scss">
  .section-location {

  }
</style>