var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-loader-gradient" },
    [
      _vm._l(_vm.contentLoader, function (content, index) {
        return _c("AtomLoaderProject", { key: index }, [
          _c("div", { staticClass: "title-project" }, [
            _c("h2", [_vm._v(_vm._s(content.project))]),
          ]),
          _c("div", { staticClass: "content-loader" }, [
            _c("h3", [_vm._v(_vm._s(content.title))]),
            _c("h4", [_vm._v(_vm._s(content.subtitle))]),
            _c("p", { domProps: { innerHTML: _vm._s(content.text) } }),
          ]),
        ])
      }),
      _c("AtomLoaderLoadingBar"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }