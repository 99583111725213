var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-specs-v2": true,
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _c("div", { staticClass: "row title-top" }, [
        _c(
          "div",
          { staticClass: "col col-xs-12 col-8" },
          [
            _c("AtomTitle", {
              attrs: { title: _vm.data.title, subtitle: _vm.data.subtitle },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col col-xs-12 col-4" }, [
          _c("div", { staticClass: "text-right" }, [
            _c(
              "div",
              { staticClass: "tabs" },
              [
                _vm._l(_vm.data.tabs, function (tab, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "tab-trigger",
                      class: { active: _vm.activeTab === tab.tabId },
                      on: {
                        click: function ($event) {
                          return _vm.handleTabChange(tab.tabId)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(tab.tabLabel) +
                          "\n            "
                      ),
                      _c("AtomIcon", { attrs: { icon: "active-line" } }),
                    ],
                    1
                  )
                }),
                _c("AtomToggle", {
                  model: {
                    value: _vm.toggled,
                    callback: function ($$v) {
                      _vm.toggled = $$v
                    },
                    expression: "toggled",
                  },
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm._l(_vm.data.tabs, function (tab, i) {
        return _c("div", { key: i }, [
          _vm.activeTab === tab.tabId
            ? _c(
                "div",
                [
                  _c(
                    "OrganismTabsTechnicalSpecsV2",
                    { attrs: { "sub-tabs": tab.subTabs } },
                    [
                      _vm.hasResourcesModal
                        ? _c(
                            "a",
                            {
                              staticClass: "button-atom",
                              attrs: {
                                href: "" + _vm.resourceUrl(_vm.data.resources),
                                target: "_blank",
                              },
                            },
                            [_c("span", [_vm._v("Download Resources")])]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }