import { render, staticRenderFns } from "./SectionBuildings.vue?vue&type=template&id=97db32de&"
import script from "./SectionBuildings.vue?vue&type=script&lang=js&"
export * from "./SectionBuildings.vue?vue&type=script&lang=js&"
import style0 from "./SectionBuildings.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('97db32de')) {
      api.createRecord('97db32de', component.options)
    } else {
      api.reload('97db32de', component.options)
    }
    module.hot.accept("./SectionBuildings.vue?vue&type=template&id=97db32de&", function () {
      api.rerender('97db32de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sections/SectionBuildings.vue"
export default component.exports