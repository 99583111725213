var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-image-slider carousel-box" }, [
    _c(
      "div",
      { ref: "carousel", staticClass: "owl-carousel owl-theme" },
      _vm._l(_vm.sliderData, function (sliderVideo, index) {
        return _c("AtomVideoSliderMatterportSpace", {
          key: index,
          attrs: { "slider-video": sliderVideo },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }