<template>
  <div class="molecule-slider-slide">
  <transition :name="direction">
    <div v-show="visibleSlide === index" >
      <img v-if="imageSrc" :src="imageSrc" alt="">
      <div v-else >
        <div class="text-slide">

            <div class="titles">
              <h2 v-for="title in titles" :key="title">
                {{title}}
              </h2>
            </div>

            <div class="descriptions">
              <p v-for="description in descriptions" :key="description">
                {{description}}
              </p>
            </div>

        </div>
      </div>
    </div>
  </transition>
  </div>
</template>

<script>
export default {
  name: "MoleculeSliderSlide",
  props: {
    visibleSlide: {
      required: true,
      default: 0
    },
    index: {
      required: true,
      default: 0
    },
    imageSrc: {
      required: false,
      default: ''
    },
    direction: {
      required: false,
      default: 'left'
    },
    titles: {
      required: false,
      type: Array,
      default: function () {
        return []
      }
    },
    descriptions: {
      required: false,
      type: Array,
      default: function () {
        return []
      }
    },
    background: {
      required: false,
      type: String,
      default: function () {
        return '#5bb4e5'
      }
    }
  },
  mounted() {
    if(!this.imageSrc){
      this.$el.getElementsByTagName('div')[0].style.backgroundColor = this.background;
    }

  }
}
</script>

<style lang="scss">
  .molecule-slider-slide {
    position: absolute;
    width: 100%;
    text-align: center;

    .text-slide {
      display: flex;
      align-items: center;
      padding: 50px;
      height: 700px;
      margin: 0 auto;
      width: 1200px;
      max-width: 100%;

      .titles, .descriptions {
        width: 50%;
        color: #fff;
        letter-spacing: .1px !important;
        h2 {
          @include font-main(64px, $black, $regular, 68px);
          padding: 0 15px;
        }
        p {
          @include font-main(21px, $black, $regular, 25px);
          margin-bottom: 30px;
          padding: 0 15px;
        }
      }
      .descriptions{
        text-align: left;
      }

      @media only screen and (max-width: 1200px) {
        .titles, .descriptions {
          h2 {
            font-size: 25px;
            line-height: 35px;
          }
          p {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }

      @media only screen and (max-width: 576px) {
        display: block;
      }
    }

    img {
      height: 500px;
      padding: 10px;
      width: auto;
      @media only screen and (max-width: 1000px) {
        height: 450px;
      }
      @media only screen and (max-width: 850px) {
        height: 400px;
      }
      @media only screen and (max-width: 760px) {
        height: 350px;
      }
      @media only screen and (max-width: 660px) {
        height: 300px;
      }
      @media only screen and (max-width: 580px) {
        height: 250px;
      }
      @media only screen and (max-width: 480px) {
        height: 200px;
      }
      @media only screen and (max-width: 400px) {
        height: 150px;
      }
      @media only screen and (max-width: 300px) {
        height: 100px;
      }
    }
  }
  .left-enter-active{
    animation: left-in .3s ease-in-out;
  }
  .left-leave-active{
    animation: left-out .3s ease-in-out;
  }
  @keyframes left-in {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes left-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .right-enter-active{
    animation: right-in .3s ease-in-out;
  }
  .right-leave-active{
    animation: right-out .3s ease-in-out;
  }
  @keyframes right-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes right-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
</style>
