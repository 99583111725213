<template>
  <div class="atom-content-slider-brochure">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AtomContentSliderBrochure",
  props: {}
}
</script>

<style lang="scss" scoped>
  .atom-content-slider-brochure {
    position: relative;
  }
</style>