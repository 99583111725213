<template>
  <div
    class="organism-cart-spaces-building"
    v-click-outside="handleClickOutside"
  >
    <div
      :class="{
        'cart-spaces': true,
        clearfix: true,
        active: isOpen || isEmbedReady || (isEmbedV2 && isMobileView),
      }"
    >
      <div class="floor-info clearfix">
        <div class="row cart-trigger row-padding-5px align-items-center">
          <div class="col col-8 col-padding-10px">
            <span class="list-info">
              <span class="nr-cube nr-cube-white">{{ spacesCounter }}</span>
              Spaces in your list</span
            >
          </div>
          <div class="col col-4 col-padding-10px text-right">
            <span class="arrow-text">
              <span class="text" @click="toggleOpenState"
                ><span class="disabled-text">View List</span
                ><span class="active-text">Hide</span></span
              >
              <span class="custom-arrow"></span>
            </span>
          </div>
        </div>
        <div class="all-spaces-selected clearfix">
          <div class="row spaces-selected-info">
            <div class="col col-12">
              <div class="add-content clearfix">
                <div
                  class="background-space-white clearfix space-floor-selected"
                  v-for="(space, index) in requestedSpaces"
                  :key="index"
                >
                  <div class="row row-padding-5px align-items-center">
                    <div class="col col-6 col-padding-10px">
                      <span>{{ space.building.name }}</span>
                    </div>
                    <div class="col col-6 col-padding-10px text-right">
                      <span class="when-text floor-number"
                        >Floor
                        <span class="nr-cube">{{ space.floor.no }}</span></span
                      >
                    </div>
                  </div>
                  <div class="row row-padding-5px align-items-center">
                    <div class="col col-9 col-padding-10px">
                      <div class="content-info">
                        <span
                          class="result-sqm"
                          v-if="
                            surfaceMultiplier &&
                            ![0, 1].includes(surfaceMultiplier)
                          "
                          ><b
                            >{{
                              (
                                Number(space.sqm) -
                                Number(space.sqm) * Number(surfaceMultiplier)
                              ).toFixed(2)
                            }}
                          </b>
                          {{ unitOfMeasureString }} {{ surfaceName }}</span
                        >
                        <span class="result-sqm" v-else
                          ><b>{{ space.sqm }}</b>
                          {{ unitOfMeasureString }} GLA</span
                        >
                        <span
                          v-if="showGeneratedNumberOfPeople"
                          class="result-max-people"
                          ><b>{{ getSpacePeople(space.sqm) }}</b> People</span
                        >
                      </div>
                    </div>
                    <div class="col col-3 col-padding-10px text-right">
                      <div class="btn-remove-item">
                        <a
                          class="btn-trash"
                          href="javascript:void(0)"
                          @click="removeSpaceFromCart(space)"
                          ><AtomIcon icon="trash-can" :size="14"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="result-all-spaces clearfix">
          <div class="row cart-trigger row-padding-5px align-items-center">
            <div class="col col-3 col-padding-10px">
              <span>Total Space</span>
            </div>
            <div class="col col-9 col-padding-10px text-right">
              <div class="content-info">
                <span
                  class="result-sqm"
                  v-if="
                    surfaceMultiplier && ![0, 1].includes(surfaceMultiplier)
                  "
                  ><AtomIcon icon="ruler" :size="14" />
                  <b>{{
                    (
                      Number(totalSpace) -
                      Number(totalSpace) * Number(surfaceMultiplier)
                    ).toFixed(2)
                  }}</b>
                  {{ unitOfMeasureString }} {{ surfaceName }}</span
                >
                <span class="result-sqm" v-else
                  ><AtomIcon icon="ruler" :size="14" /> <b>{{ totalSpace }}</b>
                  {{ unitOfMeasureString }} GLA</span
                >
                <span
                  v-if="showGeneratedNumberOfPeople"
                  class="result-max-people"
                  ><AtomIcon icon="group" :size="14" />
                  <b>{{ totalPeople }}</b> People</span
                >
              </div>
            </div>
          </div>
          <div class="row row-padding-5px align-items-center">
            <div class="col col-12 col-padding-10px">
              <a
                id="request-offer-btn"
                :class="{ 'btn-green': true, disabled: spacesCounter === 0 }"
                href="javascript:void(0)"
                @click="openRequestOfferModal"
                >Request Offer</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="{
        'offer-spaces-list': true,
        'hidden-desktop-elements': true,
        active: isOpen,
      }"
      @click="toggleOpenState"
      v-if="(!isEmbedReady && !isEmbedV2) || (isEmbedV2 && !isMobileView)"
    >
      <div class="header-element">
        <span>Offer & Space List</span>
        <span class="custom-arrow"></span>
      </div>
      <div class="content-element">
        <span class="result-sqm"
          ><AtomIcon icon="ruler" :size="18" /> <b>{{ totalSpace }}</b>
          {{ unitOfMeasureString }} GLA</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../../../atoms/common/AtomIcon";
import { isMobile, isTablet } from "../../../../helpers/mobile/DeviceType";
import buildingStore from "./store";
import ClickOutside from "vue-click-outside";

export default {
  name: "OrganismCartSpacesBuilding",
  components: { AtomIcon },
  directives: {
    ClickOutside,
  },
  props: {},
  data() {
    return {
      isMobileView:
        typeof window !== "undefined" ? window.innerWidth < 1200 : false,
    };
  },
  computed: {
    isOpen() {
      return this.$store.state.requestOffer.cartSpacesIsOpen;
    },
    buildingStore() {
      return buildingStore;
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces;
    },
    spacesCounter() {
      return this.$store.state.requestOffer.spaces.length;
    },
    totalSpace() {
      let total = 0;
      this.requestedSpaces.forEach((s) => {
        total += s.sqm;
      });
      return total;
    },
    totalPeople() {
      if (this.unitOfMeasure === 1) {
        const unitSQM = this.totalSpace / 10.763910417;
        return Math.floor(unitSQM / 8);
      }
      return Math.floor(this.totalSpace / 8);
    },
    isMobileMenuOpen() {
      return this.$store.state.base.mobileAvailabilityMenuOpen;
    },
    hasSpaceSelected() {
      return !!this.buildingStore.state.space.spaceData;
    },
    buildings() {
      return this.$store.state.project.project.buildings;
    },
    floors() {
      return this.$store.state.project.project.floors;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    selectedSpace() {
      const space = { ...this.buildingStore.state.space.spaceData };
      const building = this.buildings.find((b) => b.id === space.building_id);
      const floor = this.floors.find((f) => f.id === space.floor_id);
      space.building = building;
      space.floor = floor;
      return space;
    },
    showMobileSelectedSpace() {
      return window.innerWidth <= 1200 && this.hasSpaceSelected;
    },
    isEmbedReady() {
      const routePageData = this.$route.meta.page;
      return routePageData.isEmbedReady;
    },
    isEmbedV2() {
      const routePageData = this.$route.meta.page;
      return routePageData.isEmbedV2;
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
    showGeneratedNumberOfPeople() {
      const commonFeatures =
        this.$store?.state?.base?.meta?.project?.sections?.find(
          (section) => section.type === "Building"
        ) || {};
      if ("generatedNumberOfPeople" in commonFeatures) {
        return commonFeatures.generatedNumberOfPeople;
      } else {
        return true;
      }
    },
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1;
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1;
    },
  },
  methods: {
    resizeListener(e) {
      this.isMobileView =
        typeof window !== "undefined" ? window.innerWidth < 1200 : false;
    },
    getSpacePeople(sqm) {
      if (this.unitOfMeasure === 1) {
        const unitSQM = sqm / 10.763910417;
        return Math.floor(unitSQM / 8);
      }
      return Math.floor(sqm / 8);
    },
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find((space) => space.id === spaceId);
    },
    openRequestOfferModal() {
      if (!this.spacesCounter) return;
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'building'
          }
        });
      }
      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(
        modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
        { showModal: true, modalType: "request-offer" }
      );
      document.body.classList.add("disable-scroll");
    },
    toggleOpenState() {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'toggle_cart_list',
          payload: {
            opened: !this.isOpen,
          }
        });
      }
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(
          requestOffer.action.CHANGE_CART_SPACES_STATE
        ),
        !this.isOpen
      );
      const base = this.$store.getters.constants.base;
      this.$store.dispatch(
        base.withNamespace(base.action.CHANGE_MOBILE_AVAILABILITY_MENU),
        false
      );
    },
    handleClickOutside() {
      // const requestOffer = this.$store.getters.constants.requestOffer;
      // this.$store.dispatch(requestOffer.withNamespace(requestOffer.action.CHANGE_CART_SPACES_STATE), false);
    },
    removeSpaceFromCart(space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'remove_space_from_list',
          payload: {
            zone: 'building',
            space,
          }
        });
      }
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.REMOVE_SPACE),
        space
      );
    },
    addSpaceToCart(space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'add_space_to_list',
          payload: {
            zone: 'building',
            space,
          }
        });
      }
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.ADD_SPACE),
        space
      );
    },
  },
  mounted() {
    let self = this;
    window.addEventListener("resize", this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
};
</script>

<style lang="scss">
.organism-cart-spaces-building {
  z-index: 9;
  .row {
    margin-left: -15px;
    margin-right: -15px;
    .col {
      padding: 0 15px;
    }
  }
  .cart-spaces {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 100%;
    width: 415px;
    min-height: 66px;
    background: $black;
    z-index: 999;
    padding: 14px 16px 10px;
    cursor: pointer;
    @include prefix(
      transition,
      all 0.5s ease-in-out 0s,
      ("webkit", "moz", "ms", "o")
    );
    &:hover {
      .arrow-text {
        .text {
          span {
            color: $white;
          }
        }
        .custom-arrow {
          border-color: transparent transparent #4cc66d transparent;
        }
      }
      .result-all-spaces {
        padding-top: 10px;
        margin-top: 10px;
        border-color: rgba(112, 112, 112, 0.4);
      }
    }
    &.active:hover {
      .arrow-text {
        .custom-arrow {
          border-color: $white transparent transparent transparent;
        }
      }
    }
    &.active {
      .all-spaces-selected {
        display: block;
      }
      .arrow-text {
        .text {
          .active-text {
            display: block;
            color: $white;
          }
          .disabled-text {
            display: none;
          }
        }
        .custom-arrow {
          border-color: $white transparent transparent transparent;
          border-width: 6px 5px 0 5px;
        }
      }
      .result-all-spaces {
        padding-top: 10px;
        margin-top: 10px;
        border-color: rgba(112, 112, 112, 0.4);
      }
    }
    .result-all-spaces {
      border-top: 1px solid transparent;
      @include prefix(
        transition,
        all 0.5s ease-in-out 0s,
        ("webkit", "moz", "ms", "o")
      );
      span {
        @include font-main(14px, $white, $regular, 17px);
        b {
          font-weight: $bold;
        }
        svg {
          opacity: 0.6;
          margin-right: 5px;
        }
        &.result-sqm {
          margin-right: 16px;
        }
      }
    }
    .floor-info {
      span {
        @include font-main(14px, $white, $light, 17px);
        @include prefix(
          transition,
          all 0.2s ease-in-out 0s,
          ("webkit", "moz", "ms", "o")
        );
        b {
          font-size: 14px;
          line-height: 17px;
          font-weight: $bold;
          color: $white;
          @include prefix(
            transition,
            all 0.2s ease-in-out 0s,
            ("webkit", "moz", "ms", "o")
          );
        }
        .icon {
          font-size: 14px !important;
          color: $white !important;
          margin-right: 5px;
          opacity: 0.6;
        }
      }
      .list-info {
        font-weight: $regular;
      }
      .inlineBlock {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .arrow-text {
      .text {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        span {
          color: rgba(255, 255, 255, 0.3);
          font-weight: $regular;
          @include prefix(
            transition,
            all 0.2s ease-in-out 0s,
            ("webkit", "moz", "ms", "o")
          );
        }
        .active-text {
          display: none;
        }
      }
      .custom-arrow {
        border-color: transparent transparent $white transparent;
        border-style: solid;
        border-width: 0 5px 6px 5px;
        height: 0;
        width: 0;
        display: inline-block;
        vertical-align: middle;
        @include prefix(
          transition,
          all 0.2s ease-in-out 0s,
          ("webkit", "moz", "ms", "o")
        );
      }
    }
    .btn-green {
      width: 100%;
      &.disabled {
        opacity: 0.5;
      }
    }
    .all-spaces-selected {
      display: none;
      position: relative;
      bottom: 0;
      background: transparent;
      width: 100%;
      padding: 15px 0 20px;
      .spaces-selected-info {
        max-height: calc(100vh - 253px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 1px;
        .list-info {
          @include font-main(14px, $black, $regular, 17px);
          span {
            color: #a7a7a7;
          }
        }
        .closeDropDown {
          @include font-main(14px, rgba(0, 0, 0, 0.4), $regular, 17px);
          letter-spacing: -0.34px;
          b {
            display: inline-block;
            vertical-align: middle;
            margin-left: 5px;
            color: $black;
            font-size: 18px;
            margin-top: -1px;
          }
        }
      }
      .add-content {
        .background-space-white {
          background: rgba(255, 255, 255, 0.2);
          padding: 12px 16px;
          border-radius: 4px;
          margin-top: 12px;
          &:first-child {
            margin-top: 0;
          }
          span {
            @include font-main(14px, $white, $regular, 17px);
            b {
              font-size: 14px;
              line-height: 17px;
              font-weight: $bold;
              color: $white;
            }
            &.result-sqm {
              margin-right: 16px;
            }
            &.result-sqm,
            &.result-max-people {
              font-weight: $light;
            }
          }
          .floor-number {
            .nr-cube {
              margin-right: 0;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .arrowCustom {
    width: 10px;
    height: 6px;
    line-height: 6px !important;
    cursor: pointer;
    text-align: center !important;
    display: block;
    position: absolute;
    i {
      display: inline-block;
      border-color: $white transparent transparent transparent;
      border-style: solid;
      border-width: 6px 5px 0 4px;
      height: 0;
      width: 10px;
    }
  }
  .nr-cube {
    width: 22px;
    height: 22px;
    line-height: 23px !important;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    background: $black;
    color: $white !important;
    border-radius: 4px;
    margin-top: -2px;
    margin-right: 5px;
    font-weight: $bold !important;
    &.nr-cube-white {
      color: $black !important;
      background: $white;
    }
  }
  .row-padding-5px {
    padding: 0 5px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    .col-padding-10px {
      padding: 0 10px;
    }
    .colPadding0px {
      padding: 0;
    }
  }
  .hidden-desktop-elements {
    display: none !important;
  }
  @media only screen and (max-width: 1200px) {
    .hidden-desktop-elements {
      display: block !important;
    }
    .offer-spaces-list {
      position: absolute;
      max-width: 100%;
      height: 66px;
      border-radius: 4px;
      background: $white;
      z-index: 999;
      padding: 10px;
      cursor: pointer;
      width: 345px;
      right: 50%;
      bottom: 35px;
      @include prefix(
        transition,
        all 0.5s ease-in-out 0s,
        ("webkit", "moz", "ms", "o")
      );
      @include prefix(transform, translateX(50%), ("webkit", "moz", "ms", "o"));
      &.active {
        background: $black;
        span {
          color: $white;
          b {
            color: $white;
          }
          .icon {
            color: $white !important;
          }
        }
        .header-element {
          span {
            color: #4cc66d;
          }
          .custom-arrow {
            border-color: $white transparent transparent transparent;
            border-width: 6px 5px 0 5px;
          }
        }
      }
      span {
        @include font-main(14px, #272626, $regular, 17px);
        @include prefix(
          transition,
          all 0.2s ease-in-out 0s,
          ("webkit", "moz", "ms", "o")
        );
        b {
          font-size: 14px;
          line-height: 17px;
          font-weight: $bold;
          color: $black;
          @include prefix(
            transition,
            all 0.2s ease-in-out 0s,
            ("webkit", "moz", "ms", "o")
          );
        }
      }
      .header-element {
        position: relative;
        padding-right: 20px;
        margin-bottom: 7px;
        span {
          color: #4cc66d;
        }
        .custom-arrow {
          position: absolute;
          right: 0;
          top: 7px;
          border-color: transparent transparent #000000 transparent;
          border-style: solid;
          border-width: 0 5px 6px 5px;
          height: 0;
          width: 0;
          display: inline-block;
          vertical-align: middle;
          @include prefix(
            transition,
            all 0.2s ease-in-out 0s,
            ("webkit", "moz", "ms", "o")
          );
        }
      }
    }
    .cart-spaces {
      display: none;
      right: 50%;
      width: 345px;
      padding: 10px 12px;
      bottom: 116px;
      border-radius: 4px;
      min-height: 0;
      @include prefix(transform, translateX(50%), ("webkit", "moz", "ms", "o"));
      @include prefix(transition, none, ("webkit", "moz", "ms", "o"));
      &.active {
        display: block;
      }
      .result-all-spaces {
        .cart-trigger {
          .col-padding-10px:first-child {
            padding-right: 0;
          }
        }
      }
      .all-spaces-selected {
        display: block;
        .spaces-selected-info {
          max-height: calc(100vh - 380px);
        }
        .add-content {
          .background-space-white {
            span {
              font-size: 13px;
              b {
                font-size: 13px;
              }
            }
          }
        }
      }
      .floor-info {
        span {
          font-size: 13px;
          b {
            font-size: 13px;
          }
        }
      }
      .btn-green {
        font-size: 13px;
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .offer-spaces-list,
    .cart-spaces {
      width: 96%;
    }
  }
}
</style>
